
import {Objects} from "phusion/src/Core/Objects/Objects";

export class OrganisationInvitation
{
  public id: number;
  public reference: string;
  public emailAddress: string;
  public organisationId: number;
  public status: 'pending' | 'accepted';
  public expiry: string;
  public created: string;
  public updated: string;
  
  public organisationName: string;

  public static create(data: Object = null): OrganisationInvitation
  {
    if (!data)
    {
      return new this();
    }

    return Objects.hydrate(new OrganisationInvitation(), data) as OrganisationInvitation;
  }

  public serialize(): Object
  {
    return Objects.hydrate({}, this);
  }
  
  public static getStatusDisplayText(invitation: OrganisationInvitation)
  {
    // If invitation status is pending and expiry is in the past
    if (new Date(invitation.expiry).valueOf() < Date.now() && invitation.status === 'pending')
    {
      return 'Expired';
    }
    
    switch (invitation.status)
    {
      case 'pending':
        return 'Pending'
      case 'accepted':
        return 'Accepted'
      default:
        throw `No display text found for invitation status: ${invitation.status}`;
    }
  }
}
