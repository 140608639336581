
import {Objects} from "phusion/src/Core/Objects/Objects";

export class Organisation
{
  public id: number;
  public name: string;
  public ownerUserId: number;
  public chequesHeldAccountId: number;
  public broughtForwardDate: string;
  public created: string;
  public updated: string;
  public userOrgId: number;

  public static create(data: Object = null): Organisation
  {
    if (!data)
    {
      return new this();
    }

    return Objects.hydrate(new Organisation(), data) as Organisation;
  }

  public serialize(): Object
  {
    return Objects.hydrate({}, this);
  }
}
