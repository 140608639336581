import {Objects} from "phusion/src/Core/Objects/Objects";

export class Role
{
  public id: number;
  public name: string;
  public organisationId: number;
  public permissions: Array<string> = [];
  public members: Array<number> = []; // userOrgIds
  public created: string;
  public updated: string;

  public static create(data: Object = null): Role
  {
    if (!data)
    {
      return new this();
    }

    let mutators = {
    };

    return Objects.hydrate(new Role(), data, mutators) as Role;
  }

  public serialize(): Object
  {
    let mutators = {
      // dateOfBirth: (dateOfBirth: Moment.Moment) => dateOfBirth.valueOf(),
      // created: (created: Moment.Moment) => created.valueOf(),
      // updated: (updated: Moment.Moment) => updated.valueOf()
    };

    return Objects.hydrate({}, this, mutators);
  }
}
