
import * as React from 'react';
import {Link} from 'react-router-dom';
import { Request } from "appex-theme/src/Utility/Request";
import {AbstractComponent} from "../../../../component/AbstractComponent";
import {PageBar} from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import {Breadcrumbs} from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import {Panel} from "appex-theme/src/Core/Panel/Panel";
import { Member } from "../../../../data/Member/Entity/Member";
import { MemberStore } from "../../../../data/Member/MemberStore";
import { Membership } from "../../../../data/Membership/Entity/Membership";
import { MembershipStore } from "../../../../data/Membership/MembershipStore";
import {ToastMessageStore} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import {ToastMessage} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";
import './scss/ViewAllMembershipsPage.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "appex-theme/src/Core/Button/Button";
import { PermissionsStore } from "../../../../data/Permissions/PermissionsStore";
import {faEdit} from "@fortawesome/pro-regular-svg-icons/faEdit";
import {faEye} from "@fortawesome/pro-regular-svg-icons/faEye";
import {faAddressCard} from "@fortawesome/pro-duotone-svg-icons/faAddressCard";
import {DataTable} from "appex-theme/src/Data/DataTable/DataTable/DataTable";
import {DisplayColumn} from "appex-theme/src/Data/DataTable/DataTable/Entity/DisplayColumn";
import {CtaPlaceholder} from "appex-theme/src/Placeholder/Cta/CtaPlaceholder";

export interface ViewAllMembershipsProps {}
export interface ViewAllMembershipsState {
  memberships: Array<Membership>,
  updating: boolean
}

export class ViewAllMembershipsPage extends AbstractComponent<ViewAllMembershipsProps, ViewAllMembershipsState>
{
  protected unsubscribers = {};

  public constructor(props: ViewAllMembershipsProps)
  {
    super(props);

    this.state = {
      memberships: MembershipStore.getAllMembershipsByActiveOrganisation(),
      updating: false
    };
  }

  public componentDidMount()
  {
    // Redirect if you don't have membership:read permissions
    if (!PermissionsStore.hasPermission('membership:read'))
    {
      ToastMessageStore.addMessage(ToastMessage.create('error', "Sorry, you don't have access to view this page"));
      return Request.goBack(this);
    }
    
    /**
     * Subscribe to Memberships
     */
    
    this.unsubscribers['memberships_updating'] = MembershipStore.subscribeToUpdating(
      (updating: boolean) => {
        this.setState({
          updating: updating
        })
      },
      this.state.updating
    )
  
    this.unsubscribers['memberships'] = MembershipStore.subscribeToActiveOrgMemberships(
      (memberships: Array<Membership>) => {
        this.setState({
          memberships: memberships
        })
      },
      this.state.memberships
    )
    
    MembershipStore.syncActiveOrganisationMemberships();
  }

  public componentWillUnmount()
  {
    for (let key in this.unsubscribers)
      if (this.unsubscribers.hasOwnProperty(key))
    {
      this.unsubscribers[key]();
    }
  }

  public render()
  {
    let breadcrumbs = [
      {
        key: 'home',
        title: <span><i className="fal fa-home"/> Home</span>,
        href: '/'
      },
      {
        key: 'memberships',
        title: 'Memberships'
      }
    ];

    return <div className={'ViewAllMembershipsPage'}>

      <div className="grid-container">
        <PageBar
          title={<div><FontAwesomeIcon icon={faAddressCard}/>Memberships</div>}
          rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}
        />

        <div className="grid-x">
          <div className="cell">
            <Panel>
              <DataTable
                data={this.state.memberships}
                displayColumns={[
                  DisplayColumn.create(
                    'Name',
                    'name',
                    (membership: Membership) => {
                      return membership.name;
                    },
                    2
                  ),
                  DisplayColumn.create(
                    'Assigned Users',
                    null,
                    (membership: Membership) => {
                      return membership.members.length;
                    },
                    2
                  ),
                  DisplayColumn.create(
                    'Action',
                    null,
                    (membership: Membership) => {
                      return <div>
                        <Link to={'/memberships/' + membership.id} className={'mr-6'}>
                          <FontAwesomeIcon icon={faEye}/>
                        </Link>
                        {
                          PermissionsStore.hasPermission('membership:write')
                            ? <Link to={'/memberships/' + membership.id + '/update'}>
                                <FontAwesomeIcon icon={faEdit}/>
                              </Link>
                            : null
                        }
                      </div>
                    }
                  ),
                ]}
                searchable={true}
                sortable={true}
                onSearch={MembershipStore.onSearch.bind(this)}
                noDataContent={
                  <CtaPlaceholder
                    ctaUri={'/memberships/new'}
                    icon={faAddressCard}
                    heading={'No Memberships Found'}
                    text={'No memberships have been created'}
                  />
                }
                paginationControls={true}
                dataUpdating={this.state.updating}
              />
              {
                PermissionsStore.hasPermission('membership:write')
                  ? <div className="text--align-center-sm-only text--align-right-md mt-4 mb-3">
                      <Link to={'/memberships/process'} className={'mr-2'}>
                        <Button modifiers={['primary']}>
                          Process Membership Fees
                        </Button>
                      </Link>
                      <Link to={'/memberships/new'}>
                        <Button modifiers={['primary']}>
                          Create New Membership
                        </Button>
                      </Link>
                    </div>
                  : null
              }
            </Panel>
          </div>
        </div>
      </div>
    </div>
  }
}
