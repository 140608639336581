import {DataStore} from "ts-redux/src/DataStore/DataStore";
import {Objects} from "phusion/src/Core/Objects/Objects";
import {Redux} from "ts-redux/src/Redux";
import {Attendance} from "./Entity/Attendance";
import {OrganisationStore} from "../Organisation/OrganisationStore";
import {Http} from "phusion/src/Core/Http/Http";
import {HttpResponse} from "phusion/src/Core/Http/HttpResponse";
import {HttpError} from "phusion/src/Core/Http/HttpError";
import {EventGuestStore} from "./EventGuestStore";
import {Guest} from "./Entity/Guest";
import {Transaction} from "../Transaction/Entity/Transaction";
import {TransactionStore} from "../Transaction/TransactionStore";
import {Url} from "../../utility/Url";

export class EventAttendanceStore extends DataStore
{
  public static readonly key: string = 'event_attendance';
  protected static readonly SET_UPDATING: string = 'set_updating';
  protected static readonly SET_ATTENDANCE_BY_EVENT: string = 'set_attendance_by_event';

  public static reduce(
    state = {
      updating: false,
      attendance: {}
    },
    action: {
      type: string,
      data: any
    }
  )
  {
    switch (action.type)
    {
      case EventAttendanceStore.SET_UPDATING:
        return Object.assign({}, state, {
          updating: action.data
        });
      case EventAttendanceStore.SET_ATTENDANCE_BY_EVENT:

        let attendance = state['attendance']
          ? state['attendance']
          : {};

        let attendanceByEventId = Objects.clone(attendance);
        attendanceByEventId[action.data.eventId] = action.data.attendance;

        return Object.assign({}, state, {
          attendance: attendanceByEventId
        });
      default:
        return state;
    }
  }

  public static updateBooking(
    data: {
      eventId,
      userOrgId: number,
      memberTicketId: number | 'not_going',
      guests: Array<{ name: string, eventTicketId }>
    }
  ): Promise<{ attendance: Array<Attendance>, guests: Array<Guest> }>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      EventAttendanceStore.setUpdating(true);
      TransactionStore.setUpdating(true);

      let promise = Http.post(Url.api('/organisation/' + activeOrg.id + '/event/' + data.eventId + '/booking'), data);

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let eventAttendance = Objects.getByKeyPath('attendance', responseBody);
        let eventGuests = Objects.getByKeyPath('guests', responseBody);
        let createdTransactions = Objects.getByKeyPath('createdTransactions', responseBody);

        if (!eventAttendance || !eventGuests)
        {
          EventAttendanceStore.setUpdating(false);
          TransactionStore.setUpdating(false);
          return reject(EventAttendanceStore.createError(response));
        }

        // Attendance
        let attendanceEntities = [];

        for (let key in eventAttendance)
          if (eventAttendance.hasOwnProperty(key))
        {
          attendanceEntities.push(Attendance.create(eventAttendance[key]));
        }

        EventAttendanceStore.setAttendanceByEvent(data.eventId, attendanceEntities);

        // Guests
        let guestEntities = [];

        for (let key in eventGuests)
          if (eventGuests.hasOwnProperty(key))
          {
            guestEntities.push(Attendance.create(eventGuests[key]));
          }

        EventGuestStore.setGuestsByEventId(data.eventId, guestEntities);

        EventAttendanceStore.setUpdating(false);

        // Transactions
        let createdTransactionEntities = [];

        if (createdTransactions)
        {
          for (let key in createdTransactions)
            if (createdTransactions.hasOwnProperty(key))
          {
            let transactionEntity = Transaction.create(createdTransactions[key]);
            createdTransactionEntities.push(transactionEntity);
            TransactionStore.spliceTransaction(transactionEntity);
          }
        }

        TransactionStore.setUpdating(false);

        return resolve({ attendance: attendanceEntities, guests: eventGuests, createdTransactions: createdTransactionEntities });

      });

      promise.catch((httpError: HttpError) => {
        EventAttendanceStore.setUpdating(false);
        TransactionStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  public static getAllAttendance(): any
  {
    let attendance = Objects.getByKeyPath('event_attendance:attendance', Redux.getState());

    if (attendance)
    {
      return attendance;
    }

    return {};
  }

  public static getAllAttendanceByEventId(eventId: number): Array<Attendance>
  {
    let attendance = Objects.getByKeyPath('event_attendance:attendance:' + eventId, Redux.getState());

    if (attendance)
    {
      return attendance;
    }

    return [];
  }

  public static setUpdating(updating: boolean)
  {
    Redux.dispatch({
      type: EventAttendanceStore.SET_UPDATING,
      data: updating
    });
  }

  public static setAttendanceByEvent(eventId: number, attendance: Array<Attendance>)
  {
    Redux.dispatch({
      type: EventAttendanceStore.SET_ATTENDANCE_BY_EVENT,
      data: {
        eventId: eventId,
        attendance: attendance
      }
    });
  }
  
  public static subscribeToUpdating(callback: (updating: boolean) => void, currentValue?: boolean)
  {
    return this.subscribe((state) => {
      let newValue = state.updating;
      
      if (currentValue !== newValue)
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }
  
  public static subscribeToAttendanceByEventId(
    eventId,
    callback: (attendance: Array<Attendance>) => void,
    currentValue?: Array<Attendance>
  )
  {
    return this.subscribe((state) => {
      let newValue = this.getAllAttendanceByEventId(eventId);
      
      if (JSON.stringify(currentValue) !== JSON.stringify(newValue))
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }
  
  public static subscribeToAllAttendance(
    callback: (attendance: any) => void,
    currentValue?: any
  )
  {
    return this.subscribe((state) => {
      let newValue = this.getAllAttendance();
      
      if (JSON.stringify(currentValue) !== JSON.stringify(newValue))
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }

}
