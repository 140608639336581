
import { faProjectDiagram } from "@fortawesome/pro-solid-svg-icons/faProjectDiagram";
import * as React from 'react';
import {Link} from 'react-router-dom';
import { Request } from "appex-theme/src/Utility/Request";
import {AbstractComponent} from "../../../../component/AbstractComponent";
import './scss/ViewAccountPage.scss';
import {PageBar} from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import {Breadcrumbs} from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import {Panel} from "appex-theme/src/Core/Panel/Panel";
import {AccountStore} from "../../../../data/Account/AccountStore";
import {Account} from "../../../../data/Account/Entity/Account";
import { PermissionsStore } from "../../../../data/Permissions/PermissionsStore";
import {Transaction} from "../../../../data/Transaction/Entity/Transaction";
import {Member} from "../../../../data/Member/Entity/Member";
import {TransactionStore} from "../../../../data/Transaction/TransactionStore";
import {MemberStore} from "../../../../data/Member/MemberStore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faReceipt} from '@fortawesome/pro-solid-svg-icons/faReceipt'
import {faPiggyBank} from '@fortawesome/pro-solid-svg-icons/faPiggyBank'
import {faMoneyBillWave} from '@fortawesome/pro-solid-svg-icons/faMoneyBillWave'
import {faSackDollar} from '@fortawesome/pro-solid-svg-icons/faSackDollar'
import {faMoneyCheckAlt} from '@fortawesome/pro-solid-svg-icons/faMoneyCheckAlt'
import {faLandmark} from '@fortawesome/pro-solid-svg-icons/faLandmark'
import {faFolderTree} from '@fortawesome/pro-solid-svg-icons/faFolderTree'
import {faPoundSign} from '@fortawesome/pro-solid-svg-icons/faPoundSign'
import {faExchangeAlt} from "@fortawesome/pro-duotone-svg-icons/faExchangeAlt";
import {faMoneyCheckAlt as duoFaMoneyCheckAlt} from "@fortawesome/pro-duotone-svg-icons/faMoneyCheckAlt";
import {DisplayItem} from "appex-theme/src/Data/DisplayItem/DisplayItem";
import {Money} from "appex-theme/src/Utility/Money";
import {DataTable} from "appex-theme/src/Data/DataTable/DataTable/DataTable";
import {DisplayColumn} from "appex-theme/src/Data/DataTable/DataTable/Entity/DisplayColumn";
import {Dates} from "appex-theme/src/Utility/Dates";
import {CtaPlaceholder} from "appex-theme/src/Placeholder/Cta/CtaPlaceholder";
import {faMoneyCheck} from "@fortawesome/pro-solid-svg-icons/faMoneyCheck";
import {faCheckCircle} from "@fortawesome/pro-duotone-svg-icons/faCheckCircle";
import {faArrowRight} from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import {Modal} from "appex-theme/src/Core/Modal/Modal";
import {Form} from "appex-theme/src/Form/Form/Form";
import {Button} from "appex-theme/src/Core/Button/Button";
import {faMoneyCheckEditAlt} from "@fortawesome/pro-solid-svg-icons/faMoneyCheckEditAlt";
import {faTrashAlt as solidFaTrashAlt} from "@fortawesome/pro-solid-svg-icons/faTrashAlt";
import {DateField} from "appex-theme/src/Form/Input/Date/DateField";
import {Validator} from "appex-theme/src/Form/Form/Validator/Validator";
import {TextField} from "appex-theme/src/Form/Input/Text/TextField";
import {SelectField} from "appex-theme/src/Form/Input/Select/SelectField";
import {Header} from "appex-theme/src/Form/Input/Header/Header";
import moment from "moment";
import {ToastMessageStore} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import {ToastMessage} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";
import {DataStoreError} from "ts-redux/src/DataStore/DataStoreError";
import {faTrashAlt} from "@fortawesome/pro-regular-svg-icons/faTrashAlt";
import {DateInput} from "appex-theme/src/Form/Input/Date/DateInput";

export interface ViewAccountProps {}
export interface ViewAccountState {
  accountId: number,
  account: Account,
  transactions: Array<Transaction>,
  accounts: Array<Account>,
  members: Array<Member>,
  accountUpdating: boolean,
  transactionsUpdating: boolean,
  membersUpdating: boolean,
  chequesHeldAccountId: number,
  chequesIssuedAccountId: number,
  pendingChequeTransactions: Array<Transaction>,
  cashChequeModalOpen: boolean,
  cancelChequeModalOpen: boolean,
  modalTransactionId: number,
  cashChequeModalFormSubmitting: boolean,
  cancelChequeModalFormSubmitting: boolean,
  deleteTransactionModalOpen: boolean,
  deleteTransactionModalUpdating: boolean,
  deleteTransactionModalSubmitting: boolean,
  deleteTransactionModalId: number,
  deleteTransactionModalTransactions: Array<Transaction>
}

export class ViewAccountPage extends AbstractComponent<ViewAccountProps, ViewAccountState>
{
  public constructor(props: ViewAccountProps)
  {
    super(props);
    let account: Account = null;
    let accountTransactions = [];

    let accountId = props['params'].accountId ? Number(props['params'].accountId) : null;
    
    // Get account
    if (accountId)
    {
      account = AccountStore.getByIdAndActiveOrganisation(accountId);
      accountTransactions = TransactionStore.getAllByAccountId(accountId);
    }

    let accounts = AccountStore.getAllAccountsByActiveOrganisation();
    let members = MemberStore.getAllMembersByActiveOrganisation();

    let chequesHeldAccount = accounts.find((account: Account) => {
      return (account.type == 'cheques_held');
    });

    let chequesIssuedParentAccountId = accountId;
    
    if (account.category == 'sub_account')
    {
      chequesIssuedParentAccountId = account.parentAccountId;
    }
    
    this.state = {
      account: account,
      accountId: accountId,
      transactions: accountTransactions,
      accounts: accounts,
      members: members,
      accountUpdating: false,
      transactionsUpdating: false,
      membersUpdating: false,
      chequesHeldAccountId: chequesHeldAccount ? chequesHeldAccount.id : null,
      chequesIssuedAccountId: account.chequesIssuedAccountId,
      pendingChequeTransactions: [],
      cashChequeModalOpen: false,
      cancelChequeModalOpen: false,
      modalTransactionId: null,
      cashChequeModalFormSubmitting: false,
      cancelChequeModalFormSubmitting: false,
      deleteTransactionModalOpen: false,
      deleteTransactionModalId: null,
      deleteTransactionModalUpdating: false,
      deleteTransactionModalSubmitting: false,
      deleteTransactionModalTransactions: []
    }
  }

  public componentDidMount()
  {
    // Redirect if you don't have financial:read permissions
    if (!PermissionsStore.hasPermission('financial:read'))
    {
      ToastMessageStore.addMessage(ToastMessage.create('error', "Sorry, you don't have access to view this page"));
      return Request.goBack(this);
    }
    
    /**
     * Subscribe to Accounts
     */
    
    this.unsubscribers['accounts_updating'] = AccountStore.subscribeToUpdating(
      (updating: boolean) => {
        this.setState({
          accountUpdating: updating
        })
      },
      this.state.accountUpdating
    )

    this.unsubscribers['accounts'] = AccountStore.subscribeToActiveOrgAccounts(
      (accounts: Array<Account>) =>
      {
        this.setState({
          accounts: accounts
        })
      
      // Update account
      let newAccount = accounts.find((account: Account) => {
        return account.id == this.state.accountId;
      });

      if (
        newAccount
        && JSON.stringify(newAccount) !== JSON.stringify(this.state.account)
      )
      {
        // If it's changed from state, update state
        this.setState({
          account: newAccount
        })
      }
      
      if (!this.state.chequesHeldAccountId)
      {
        let chequesHeldAccount = accounts.find((account: Account) => {
          return (account.type == 'cheques_held');
        });

        if (chequesHeldAccount)
        {
          this.setState({
            chequesHeldAccountId: chequesHeldAccount.id
          })
        }
      }
    });
    
    /**
     * Subscribe to Members
     */
    
    this.unsubscribers['members_updating'] = MemberStore.subscribeToUpdating(
      (updating: boolean) => {
        this.setState({
          membersUpdating: updating
        })
      },
      this.state.membersUpdating
    )

    this.unsubscribers['members'] = MemberStore.subscribeToActiveOrgMembers(
      (members: Array<Member>) =>
      {
        this.setState({
          members: members
        })
      },
      this.state.members
    );
    
    /**
     * Subscribe to Transactions
     */
    
    this.unsubscribers['transactions_updating'] = TransactionStore.subscribeToUpdating(
      (updating: boolean) => {
        this.setState({
          transactionsUpdating: updating
        })
      },
      this.state.transactionsUpdating
    )
    
    this.unsubscribers['account_transactions'] = TransactionStore.subscribeToTransactionsByAccountId(
      this.state.accountId,
      (transactions: Array<Transaction>) => {
        this.setState({
          transactions: transactions
        })
      },
      this.state.transactions
    );
    
    if (this.state.account.chequesIssuedAccountId)
    {
      TransactionStore
        .fetchAllPendingChequeTransactions(this.state.chequesIssuedAccountId)
        .then((transactions) => {
          this.setState({
            pendingChequeTransactions: transactions
          })
        });
    }

    AccountStore.syncActiveOrganisationAccounts();
    MemberStore.syncActiveOrganisationMembers();
    TransactionStore.syncTransactionsByAccountId(this.state.accountId);
  }

  public render()
  {
    let account = this.state.account;

    const breadcrumbs = [
      {
        key: 'home',
        title: <span><i className="fal fa-home"/> Home</span>,
        href: '/'
      },
      {
        key: 'accounts',
        title: 'Accounts',
        href: '/accounts'
      },
      {
        key: 'view_account',
        title: account ? account.name : '. . .'
      }
    ];

    let accountCategoryIcon;

    if (account && account.category == 'asset')
    {
      accountCategoryIcon = faPiggyBank;
    }
    else if (account && account.category == 'liability')
    {
      accountCategoryIcon = faReceipt;
    }
    else if (account && account.category == 'revenue')
    {
      accountCategoryIcon = faMoneyBillWave;
    }
    else if (account && account.category == 'expense')
    {
      accountCategoryIcon = faMoneyCheckAlt;
    }
    else
    {
      accountCategoryIcon = faFolderTree;
    }

    let accountTypeIcon = account && account.type == 'bank' ? faLandmark : faSackDollar;

    if (account && account.type == 'bank')
    {
      accountTypeIcon = faLandmark;
    }
    else if (account && account.type == 'cash')
    {
      accountTypeIcon = faSackDollar;
    }
    else if (account && account.type == 'general')
    {
      accountTypeIcon = faMoneyCheck
    }
    else if (account && account.type == 'cheques_held')
    {
      accountTypeIcon = faMoneyCheckAlt;
    }
    
    // Sort them by date, earliest first (the reverse of what we need to display)
    let transactions = this.state.transactions.sort((a: Transaction, b: Transaction) => {
      if (a.date === b.date)
      {
        return a.id - b.id;
      }
      return (new Date(a.date)).valueOf() - (new Date(b.date)).valueOf();
    });
    
    // Use the reverse order to add cumulative total
    // After forEach, the order will have reversed to most recent first which is correct for displaying
    const viewingAssetAccount = account.category === 'asset';
    let balance = viewingAssetAccount
      ? account.broughtForwardBalance * -100
      : account.broughtForwardBalance * 100;
    
    transactions.forEach((transaction: Transaction) => {
  
      if (viewingAssetAccount)
      {
        if (transaction.debitAccountId === account.id)
        {
          balance += transaction.amount * 100;
        }
        else if (transaction.creditAccountId === account.id)
        {
          balance -= transaction.amount * 100;
        }
      }
      else
      {
        if (transaction.debitAccountId === account.id)
        {
          balance -= transaction.amount * 100;
        }
        else if (transaction.creditAccountId === account.id)
        {
          balance += transaction.amount * 100;
        }
      }
  
      transaction.balance = parseFloat((balance / 100).toFixed(2));
    })
    
    transactions = transactions.reverse();
    
    const groupAccount = account.accountGroupId
      ? this.state.accounts.find((group) => {
          return group.id === account.accountGroupId;
        })
      : null

    return <div className={'ViewAccountPage'}>

      <div className="grid-container">

        <PageBar
          title={<div><FontAwesomeIcon icon={duoFaMoneyCheckAlt}/>{(!account && this.state.accountUpdating) ? 'Loading account . . .' : (account ? account.name : 'View Account')}</div>}
          rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}
        />

        <div className="grid-x grid-margin-x">
          <div className="cell large-3">
            <Panel
              headerLeft={<div className="Header">Account Details</div>}
              headerRight={
                PermissionsStore.hasPermission('financial:write')
                  ? <Link to={'/accounts/' + (account ? account.id : '') + '/update'}>
                      <i className="fas fa-edit"/>
                    </Link>
                  : null
              }
            >
              <div className="grid-x">
                <div className="cell">

                  <DisplayItem
                    icon={<FontAwesomeIcon icon={faMoneyCheckAlt}/>}
                    content={account ? account.name : null}
                    updating={this.state.accountUpdating}
                  />

                  <DisplayItem
                    icon={<FontAwesomeIcon icon={accountCategoryIcon}/>}
                    content={(account && account.category) ? Account.getCategoryDisplayValue(account) : null}
                    updating={this.state.accountUpdating}
                  />
                  
                  <DisplayItem
                    icon={<FontAwesomeIcon icon={faPoundSign}/>}
                    content={account ? Money.getFormattedPrice(AccountStore.getBalanceByAccountId(account.id)) : null}
                    updating={this.state.accountUpdating}
                  />
                  
                  <DisplayItem
                    icon={<FontAwesomeIcon icon={faProjectDiagram}/>}
                    content={groupAccount ? groupAccount.name : null}
                    updating={this.state.accountUpdating}
                  />

                </div>
              </div>
            </Panel>

            { this.getIssuedChequesPanel() }
          </div>

          <div className="cell large-9">
            <Panel
              headerLeft={<div className="Header">Transactions</div>}
            >
              { this.getTransactionsTable(transactions) }
            </Panel>
          </div>
        </div>
      </div>

      { this.getDeleteTransactionModal() }
    </div>
  }

  protected getDeleteTransactionModal()
  {
    let content: any = <p className={'text--align-center mt-5 mb-3'}>
      Confirming transactions to be deleted...
    </p>;

    let buttonModifiers: Array<any> = ['primary'];

    if (!this.state.deleteTransactionModalUpdating)
    {
      let text = 'Are you sure you want to delete this transaction?';

      if (this.state.deleteTransactionModalTransactions.length > 1)
      {
        text = 'Are you sure you want to delete these transactions?';
      }

      if (this.state.deleteTransactionModalSubmitting)
      {
        buttonModifiers.push('loading');
      }

      content = <div>
        <p>
          {text}
        </p>
        <div className="Hr mt-3"/>
        <div>
          {
            this
              .state
              .deleteTransactionModalTransactions
              .sort((a: Transaction, b: Transaction) => {
                if (a.date === b.date)
                {
                  return b.id - a.id;
                }
                return (new Date(b.date)).valueOf() - (new Date(a.date)).valueOf();
              })
              .map((transaction: Transaction) => {

              let accountName = 'Unknown';
              let prefix = 'from';

              if (transaction.userOrgId)
              {
                let member = MemberStore.getByUserOrgIdAndActiveOrganisation(transaction.userOrgId);
                if (member) accountName = Member.getFullPreferredName(member);
              }
              else if (transaction.debitAccountId && transaction.debitAccountId !== this.state.accountId)
              {
                let account = AccountStore.getByIdAndActiveOrganisation(transaction.debitAccountId);
                if (account) {
                  accountName = account.name;
                  prefix = 'to';
                }
              }
              else if (transaction.creditAccountId && transaction.creditAccountId !== this.state.accountId)
              {
                let account = AccountStore.getByIdAndActiveOrganisation(transaction.creditAccountId);
                if (account) accountName = account.name;
              }

              return <div className="StatsRow" key={transaction.id}>
                <div className="StatsRow__Left">
                  <div>
                    <div>
                      {
                        transaction.reconcileReference
                          ? transaction.reconcileReference + ': ' + transaction.description
                          : transaction.description
                      }
                    </div>
                    <div className="text--subtext">
                      {Money.getFormattedPrice(transaction.amount) + ' ' + prefix + ' ' + accountName}
                    </div>
                    <div className="text--subtext">
                      {DateInput.getDisplayValueFromDate(new Date(transaction.date))}
                    </div>
                  </div>
                </div>
              </div>
            })
          }
        </div>

        <div className="Hr mt-0"/>

        <div className="text--align-right mt-3 mb-3">
          <Button onClick={this.deleteTransaction.bind(this, this.state.deleteTransactionModalId)} modifiers={buttonModifiers}>Confirm</Button>
        </div>
      </div>
    }

    return <Modal
      open={this.state.deleteTransactionModalOpen}
      close={this.closeDeleteTransactionModal.bind(this)}
    >
      <Header
        text={'Delete Transaction'}
        icon={solidFaTrashAlt}
      />
      {content}
    </Modal>;
  }

  protected deleteTransaction(transactionId: number)
  {
    if (this.state.deleteTransactionModalSubmitting)
    {
      return;
    }

    this.setState({
      deleteTransactionModalSubmitting: true
    });

    let promise = TransactionStore.deleteTransaction(transactionId);

    promise.then((success: boolean) => {
      if (success)
      {
        ToastMessageStore.addMessage(
          ToastMessage.create('success', 'Transaction successfully deleted')
        );
      }
      else
      {
        ToastMessageStore.addMessage(
          ToastMessage.create('error', 'Failed to delete transaction.')
        );
      }

      this.setState({
        deleteTransactionModalSubmitting: false,
        deleteTransactionModalOpen: false
      })
    });

    promise.catch((error: DataStoreError) => {
      this.setState({
        deleteTransactionModalSubmitting: false
      })
    })
  }

  protected getAllTransactionsToBeDeleted(transactionId: number)
  {
    let transactionsToBeDeleted = [];

    let matchingTransactions = this.state.transactions.filter((transaction: Transaction) => {
      return transaction.id == transactionId;
    });

    if (matchingTransactions.length)
    {
      transactionsToBeDeleted.push(matchingTransactions[0]);
    }

    return transactionsToBeDeleted;
  }

  protected getIssuedChequesPanel()
  {
    // Don't show un cashed cheques panel unless it's a bank account
    if (this.state.account.type !== 'bank')
    {
      return null;
    }

    let content = <CtaPlaceholder
      icon={faCheckCircle}
      heading={'No Pending Cheques'}
      text={'All cheques issued by this account have been cashed'}
      modifiers={['ForceDisplayColumn']}
    />;
    
    const transactionsToInclude = this.state.pendingChequeTransactions;

    if (transactionsToInclude.length)
    {
      content = <div>
        {
          transactionsToInclude.map((transaction: Transaction) => {

            let debitAccount = 'Unknown';

            if (transaction.userOrgId)
            {
              let member = MemberStore.getByUserOrgIdAndActiveOrganisation(transaction.userOrgId);
              if (member) debitAccount = Member.getFullPreferredName(member);
            }
            else if (transaction.debitAccountId)
            {
              let account = AccountStore.getByIdAndActiveOrganisation(transaction.debitAccountId);
              if (account) debitAccount = account.name;
            }

            return <div className="StatsRow StatsRow--CursorPointer" onClick={this.openRecordChequeAsCashedModal.bind(this, transaction.id)} key={transaction.id}>
              <div className="StatsRow__Left">
                <div>
                  <div>
                    {transaction.reconcileReference + ': ' + transaction.description}
                  </div>
                  <div className="text--subtext">
                    {Money.getFormattedPrice(transaction.amount) + ' to ' + debitAccount}
                  </div>
                </div>
              </div>
              <div className="StatsRow__Right">
                <FontAwesomeIcon icon={faArrowRight}/>
              </div>
            </div>
          })
        }
      </div>
    }

    let cashChequeModal = <Modal
      open={this.state.cashChequeModalOpen}
      close={this.closeCashChequeModal.bind(this)}>
      {this.getCashChequeModalContents(this.state.modalTransactionId)}
    </Modal>;

    let cancelChequeModal = <Modal
      open={this.state.cancelChequeModalOpen}
      close={this.closeCancelChequeModal.bind(this)}>
      {this.getCancelChequeModalContents(this.state.modalTransactionId)}
    </Modal>;

    return <Panel
      headerLeft={<div className="Header">Issued Cheques</div>}
    >
      <div>
        {content}
      </div>
      {cashChequeModal}
      {cancelChequeModal}
    </Panel>
  }

  protected getCashChequeModalContents(transactionId: number)
  {
    let submitButtonModifiers: Array<any> = [
      'primary',
      this.state.cashChequeModalFormSubmitting ? 'loading' : ''
    ];

    const matches = this.state.pendingChequeTransactions.filter((transaction: Transaction) => {
      return transaction.id == transactionId;
    });

    if (!matches.length)
    {
      return 'Oops, something went wrong...';
    }

    let transaction = matches[0];

    let debitAccount = 'Unknown';
    let debitingMember = false;

    if (transaction.userOrgId)
    {
      let member = MemberStore.getByUserOrgIdAndActiveOrganisation(transaction.userOrgId);
      if (member)
      {
        debitAccount = Member.getFullPreferredName(member);
        debitingMember = true;
      }

    }
    else if (transaction.debitAccountId)
    {
      let account = AccountStore.getByIdAndActiveOrganisation(transaction.debitAccountId);
      if (account) debitAccount = account.name;
    }

    return <div>
      <div className="cell" key={'mark_cheque_cashed'}>
        <Header
          text={'Mark Cheque ' + transaction.reconcileReference + ' as Cashed'}
          icon={faMoneyCheckEditAlt}
        />
      </div>
      <p className={'mb-2'}>If this cheque has now been cashed by the recipient, please provide the date the funds were transferred, as it shows on your bank statement.</p>
      <Form
        elements={[
          DateField
            .create(
              'date',
              'When was this cheque cashed?',
              [ Validator.required() ],
              null
            ),
          // Cheque Reference
          TextField
            .create(
              'reconcileReference',
              'Cheque Reference',
              [],
              transaction.reconcileReference
            )
            .setReadOnly(true)
            .setGridClass('cell small-6'),
          // Amount
          TextField
            .create(
              'amount',
              'Amount',
              [],
              transaction.amount.toFixed(2)
            )
            .setGridClass('cell small-6')
            .setReadOnly(true),
          // From
          SelectField
            .create(
              'debitAccountId',
              'From',
              [
                {
                  'value': this.state.account.id,
                  'displayValue': this.state.account.name,
                }
              ],
              (item) => {
                return item.value;
              },
              (item) => {
                return item.displayValue;
              },
              [],
              this.state.account.id
            )
            .setGridClass('cell large-6')
            .setReadOnly(true),
          // To
          SelectField
            .create(
              'creditAccountId',
              'To',
              [
                {
                  'value': debitingMember
                    ? 'member_' + transaction.userOrgId
                    : 'account' + '_' + transaction.debitAccountId,
                  'displayValue': debitAccount,
                }
              ],
              (item) => {
                return item.value;
              },
              (item) => {
                return item.displayValue;
              },
              [],
              debitingMember
                ? 'member_' + transaction.userOrgId
                : 'account' + '_' + transaction.debitAccountId,
              null,
              null,
            )
            .setGridClass('cell large-6')
            .setReadOnly(true),
          // Description
          TextField
            .create(
              'description',
              'Description',
              [],
              transaction.description
            )
            .setReadOnly(true),
          <div className="cell" key={'submit'}>
            <div className="mt-4 mb-4 ViewAccountPage__ModalButtonContainer">
              <Button onClick={this.switchModals.bind(this)}>
                Cancel Cheque
              </Button>
              <Button type={'submit'} modifiers={submitButtonModifiers}>
                Mark Cheque as Cashed
              </Button>
            </div>
          </div>
          ]}
        onFormSubmit={this.onCashChequeModalFormSubmit.bind(this)}
        onFormCapture={() => {}}
      />
    </div>
  }

  protected switchModals()
  {
    this.setState({
      cashChequeModalOpen: !this.state.cashChequeModalOpen,
      cancelChequeModalOpen: !this.state.cancelChequeModalOpen
    })
  }

  protected getCancelChequeModalContents(transactionId: number)
  {
    let submitButtonModifiers: Array<any> = [
      'primary',
      this.state.cancelChequeModalFormSubmitting ? 'loading' : ''
    ];

    let matches = this.state.pendingChequeTransactions.filter((transaction: Transaction) => {
      return transaction.id == transactionId;
    });

    if (!matches.length)
    {
      return 'Oops, something went wrong...';
    }

    let transaction = matches[0];

    let debitAccount = 'Unknown';
    let debitingMember = false;

    if (transaction.userOrgId)
    {
      let member = MemberStore.getByUserOrgIdAndActiveOrganisation(transaction.userOrgId);
      if (member)
      {
        debitAccount = Member.getFullPreferredName(member);
        debitingMember = true;
      }

    }
    else if (transaction.debitAccountId)
    {
      let account = AccountStore.getByIdAndActiveOrganisation(transaction.debitAccountId);
      if (account) debitAccount = account.name;
    }

    return <div>
      <Header
        text={'Cancel Cheque ' + transaction.reconcileReference}
        icon={faMoneyCheckEditAlt}
      />
      <Form
        elements={[
          DateField
            .create(
              'date',
              'When was this cheque cancelled?',
              [ Validator.required() ],
              null
            ),
          // Cheque Reference
          TextField
            .create(
              'reconcileReference',
              'Cheque Reference',
              [],
              transaction.reconcileReference
            )
            .setGridClass('cell small-6')
            .setReadOnly(true),
          // Amount
          TextField
            .create(
              'amount',
              'Amount',
              [],
              transaction.amount.toFixed(2)
            )
            .setGridClass('cell small-6')
            .setReadOnly(true),
          // From
          SelectField
            .create(
              'debitAccountId',
              'From',
              [
                {
                  'value': this.state.account.id,
                  'displayValue': this.state.account.name,
                }
              ],
              (item) => {
                return item.value;
              },
              (item) => {
                return item.displayValue;
              },
              [],
              this.state.account.id
            )
            .setGridClass('cell large-6')
            .setReadOnly(true),
          // To
          SelectField
            .create(
              'creditAccountId',
              'To',
              [
                {
                  'value': debitingMember
                    ? 'member_' + transaction.userOrgId
                    : 'account' + '_' + transaction.debitAccountId,
                  'displayValue': debitAccount,
                }
              ],
              (item) => {
                return item.value;
              },
              (item) => {
                return item.displayValue;
              },
              [],
              debitingMember
                ? 'member_' + transaction.userOrgId
                : 'account' + '_' + transaction.debitAccountId,
              null,
              null,
              null
            )
            .setGridClass('cell large-6')
            .setReadOnly(true),
          // Description
          TextField
            .create(
              'description',
              'Description',
              [],
              transaction.description
            )
            .setGridClass('cell')
            .setReadOnly(true),
          <div className="cell">
            <div className="mt-4 mb-4 ViewAccountPage__ModalButtonContainer">
              <Button onClick={this.switchModals.bind(this)}>
                Mark Cheque as Cashed
              </Button>
              <Button type={'submit'} modifiers={submitButtonModifiers}>
                Cancel Cheque
              </Button>
            </div>
          </div>
        ]}
        onFormSubmit={this.onCancelChequeModalFormSubmit.bind(this)}
        onFormCapture={() => {}}
      />
    </div>
  }

  protected onCashChequeModalFormSubmit(formState)
  {
    if (
      this.state.cashChequeModalFormSubmitting
      || this.state.cancelChequeModalFormSubmitting
      || !formState.valid
    )
    {
      return;
    }

    let issueChequeTransactions = this
      .state
      .pendingChequeTransactions
      .filter(
        (transaction: Transaction) => {
          return transaction.id == this.state.modalTransactionId
        }
      );

    if (!issueChequeTransactions.length)
    {
      return ToastMessageStore.addMessage(
        ToastMessage.create(
          'error',
          'Cannot find cheque to cash'
        )
      );
    }

    let chequeTransaction = issueChequeTransactions[0];
    let chequeCashedDate = new Date(formState.value.date);
    let chequeExpiry = moment(chequeTransaction.date).add(6, 'months');

    // If older than 6 months, no sir
    if (chequeCashedDate.valueOf() >= chequeExpiry.valueOf())
    {
      return ToastMessageStore.addMessage(
        ToastMessage.create(
          'error',
          'Cheque must be cashed within 6 months of being issued'
        )
      );
    }
    else if ((new Date()).valueOf() > (new Date(formState.date)).valueOf())
    {
      return ToastMessageStore.addMessage(
        ToastMessage.create(
          'error',
          'Cannot cash a cheque before it was issued'
        )
      );
    }

    this.setState({
      cashChequeModalFormSubmitting: true
    });

    let chequePaidTransaction = Transaction.create();
    chequePaidTransaction.date = formState.value.date;
    chequePaidTransaction.amount = chequeTransaction.amount;
    chequePaidTransaction.creditAccountId = this.state.account.id;
    chequePaidTransaction.debitAccountId = this.state.account.chequesIssuedAccountId;
    chequePaidTransaction.reconcileReference = chequeTransaction.reconcileReference;
    chequePaidTransaction.description = "Cheque " + chequeTransaction.reconcileReference + " paid";

    let promise = TransactionStore.createNewTransaction(chequePaidTransaction);

    promise.then((transaction: Transaction) => {

      this.setState({
        cashChequeModalFormSubmitting: false
      });

      ToastMessageStore.addMessage(
        ToastMessage.create('success', 'Cheque successfully marked as paid')
      );

      this.setState({
        cashChequeModalOpen: false
      });

      setTimeout(() => {
        this.setState({
          modalTransactionId: null
        })
      }, 300)
    });

    promise.catch((error: DataStoreError) => {
      this.setState({
        cashChequeModalFormSubmitting: false
      })
    });
  }

  protected onCancelChequeModalFormSubmit(formState)
  {
    if (
      this.state.cashChequeModalFormSubmitting
      || this.state.cancelChequeModalFormSubmitting
      || !formState.valid
    )
    {
      return;
    }

    let issueChequeTransactions = this
      .state
      .pendingChequeTransactions
      .filter(
        (transaction: Transaction) => {
          return transaction.id == this.state.modalTransactionId
        }
      );

    if (!issueChequeTransactions.length)
    {
      return ToastMessageStore.addMessage(
        ToastMessage.create(
          'error',
          'Cannot find cheque to cancel'
        )
      );
    }

    let chequeTransaction = issueChequeTransactions[0];

    return this.cancelCheque(chequeTransaction, formState.value.date);
  }

  protected cancelCheque(chequeTransaction: Transaction, date: string)
  {
    if (
      this.state.cashChequeModalFormSubmitting
      || this.state.cancelChequeModalFormSubmitting
    )
    {
      return;
    }

    let chequeCancelledTransaction = Transaction.create();
    chequeCancelledTransaction.date = date;
    chequeCancelledTransaction.amount = chequeTransaction.amount;
    chequeCancelledTransaction.reconcileReference = chequeTransaction.reconcileReference;
    chequeCancelledTransaction.description = "Cheque " + chequeTransaction.reconcileReference + " cancelled";

    if (chequeTransaction.userOrgId)
    {
      chequeCancelledTransaction.userOrgId = chequeTransaction.userOrgId;
    }

    if (chequeTransaction.creditAccountId)
    {
      chequeCancelledTransaction.debitAccountId = chequeTransaction.creditAccountId;
    }

    if (chequeTransaction.debitAccountId)
    {
      chequeCancelledTransaction.creditAccountId = chequeTransaction.debitAccountId;
    }

    this.setState({
      cancelChequeModalFormSubmitting: true
    });

    let promise = TransactionStore.createNewTransaction(chequeCancelledTransaction);

    promise.then((transaction: Transaction) => {
      ToastMessageStore.addMessage(
        ToastMessage.create('success', 'Cheque cancelled')
      );

      this.setState({
        cancelChequeModalOpen: false,
        cancelChequeModalFormSubmitting: false
      });

      setTimeout(() => {
        this.setState({
          modalTransactionId: null
        })
      }, 300)
    });

    promise.catch((error: DataStoreError) => {
      this.setState({
        cancelChequeModalFormSubmitting: false
      });
    });
  }

  protected openRecordChequeAsCashedModal(transactionId: number)
  {
    this.setState({
      cashChequeModalOpen: true,
      modalTransactionId: transactionId
    });
  }

  protected closeCashChequeModal()
  {
    this.setState({
      cashChequeModalOpen: false
    });

    let timeout = setTimeout(() => {
      this.setState({
        modalTransactionId: null
      });
      clearTimeout(timeout);
    }, 300)
  }

  protected closeCancelChequeModal()
  {
    this.setState({
      cancelChequeModalOpen: false
    });

    let timeout = setTimeout(() => {
      this.setState({
        modalTransactionId: null
      });
      clearTimeout(timeout);
    }, 300)
  }

  protected getTransactionsTable(transactions: Array<Transaction>)
  {
    let displayColumns = [
      DisplayColumn.create(
        'Date',
        null,
        (transaction: Transaction) => {
          return Dates.getShortDisplayValue(new Date(transaction.date))
        },
        1
      ),
      DisplayColumn.create(
        'Description',
        null,
        (transaction: Transaction) => {
          return transaction.description
        },
        2
      ),
      DisplayColumn.create(
        'Amount',
        null,
        (transaction: Transaction) => {
        
          let isCreditAccount = transaction.creditAccountId == this.state.accountId;
          let prefix = isCreditAccount ? '+' : '-';
        
          let viewingAssetAccount = (this.state.account.category == 'asset');
        
          if (viewingAssetAccount)
          {
            if (prefix == '+')
            {
              prefix = '-';
            }
            else if (prefix == '-')
            {
              prefix = '+'
            }
          }
  
          return <div className={'display--width-100 flex--direction-column'}>
            <span className={'display--width-50 mb-2 mt-smo-1'}>{prefix + Money.getFormattedPrice(transaction.amount)}</span>
            <span className={'display--width-50 color--grey2 text--subtext'}>{transaction.balance >= 0 ? '+' : null}{Money.getFormattedPrice(transaction.balance)}</span>
          </div>
        },
        1
      ),
      DisplayColumn.create(
        'Account',
        null,
        (transaction: Transaction) => {
        
          if (transaction.userOrgId) // User involved
          {
            let members = this.state.members ? this.state.members : [];
          
            let matchingMembers = members.filter((member: Member) =>
            {
              return member.userOrgId == transaction.userOrgId;
            });
          
            if (matchingMembers.length)
            {
              return <div>
                <Link className={'ml-1'} to={'/members/' + matchingMembers[0].userOrgId}>
                  { Member.getFullPreferredName(matchingMembers[0]) }
                </Link>
              </div>
            }
          
            return 'Member no longer exists';
          }
          else if (transaction.creditAccountId == this.state.accountId) // If this is the credit account
          {
            let matchingAccounts = this.state.accounts.filter((account: Account) => {
              return (account.id == transaction.debitAccountId);
            });
          
            if (matchingAccounts.length)
            {
              let account = matchingAccounts[0];
              return <div>
                <Link className={'ml-1'} to={'/accounts/' + account.id}>
                  { account.name }
                </Link>
              </div>
            }
          }
          else if (transaction.debitAccountId == this.state.accountId) // If this is the debit account
          {
            let matchingAccounts = this.state.accounts.filter((account: Account) => {
              return (account.id == transaction.creditAccountId);
            });
          
            if (matchingAccounts.length)
            {
              let account = matchingAccounts[0];
              return <div>
                <Link className={'ml-1'} to={'/accounts/' + account.id}>
                  { account.name }
                </Link>
              </div>
            }
          }
        
          return 'Account no longer exists';
        },
        2
      ),
      DisplayColumn.create(
        'Reference',
        null,
        (transaction: Transaction) => {
  
          // If it's a cheque, don't link to paying slip page
          if (
            transaction.creditAccountId == this.state.chequesIssuedAccountId
            || transaction.debitAccountId == this.state.chequesIssuedAccountId
          )
          {
            return transaction.reconcileReference;
          }
          
          return <div>
            <Link className={'ml-1'} to={'/transactions/ref/' + transaction.reconcileReference}>
              { transaction.reconcileReference }
            </Link>
          </div>
        },
        1
      )
    ];
    
    if (PermissionsStore.hasPermission('financial:write'))
    {
      displayColumns.push(
        DisplayColumn.create(
          'Action',
          null,
          (transaction: Transaction) => {
            return <div onClick={this.openDeleteTransactionModal.bind(this, transaction.id)} className={'cursor--pointer'}>
              <FontAwesomeIcon icon={faTrashAlt}/>
            </div>
          },
          0.5
        )
      );
    }
    
    
    return <DataTable
      data={transactions}
      displayColumns={displayColumns}
      searchable={false}
      sortable={false}
      noDataContent={this.getNoTransactionsContent()}
      paginationControls={true}
      dataUpdating={
        Boolean(
          this.state.accountUpdating
          || this.state.transactionsUpdating
        )
      }
    />
  }

  protected openDeleteTransactionModal(transactionId: number)
  {
    let matches = this.state.transactions.filter((transaction: Transaction) => {
      return transaction.id == transactionId;
    });

    if (!matches.length)
    {
      console.warn('No transaction found for delete transaction modal. Transaction id to delete: ' + transactionId);
      return;
    }

    let transactionToDelete = matches[0];

    let debitAccount = transactionToDelete.debitAccountId
      ? AccountStore.getByIdAndActiveOrganisation(transactionToDelete.debitAccountId)
      : null;
    let creditAccount = transactionToDelete.creditAccountId
      ? AccountStore.getByIdAndActiveOrganisation(transactionToDelete.creditAccountId)
      : null;
    
    let transactionsWithSameRecRef = null;
    
    if (transactionToDelete.reconcileReference)
    {
      transactionsWithSameRecRef = TransactionStore.getAllByReconcileReference(transactionToDelete.reconcileReference);
    }

    if (
      // Issue A Cheque
      (
        creditAccount
        && creditAccount.type == 'cheques_issued'
        && (!transactionsWithSameRecRef || transactionsWithSameRecRef.length === 1)
      )
      ||
      // Receive A Cheque
      (
        debitAccount
        && debitAccount.type == 'cheques_held'
      )
      ||
      // Make A Payment
      (
        creditAccount
        && (creditAccount.type == 'bank' || creditAccount.type == 'cash')
        && !transactionToDelete.reconcileReference
      )
      ||
      // Receive Direct Credit
      (
        debitAccount
        && (debitAccount.type == 'bank' || debitAccount.type == 'cash')
        && !transactionToDelete.reconcileReference
      )
      ||
      // Receive Cash
      (
        debitAccount
        && debitAccount.type == 'cash'
      )
    )
    {
      return this.setState({
        deleteTransactionModalTransactions: [transactionToDelete],
        deleteTransactionModalUpdating: false,
        deleteTransactionModalId: transactionId,
        deleteTransactionModalOpen: true
      })
    }
    // Process A Paying In Slip
    else if (
      debitAccount
      && debitAccount.type == 'bank'
      && transactionToDelete.reconcileReference
    )
    {
      this.setState({
        deleteTransactionModalUpdating: true,
        deleteTransactionModalId: transactionId,
        deleteTransactionModalOpen: true
      });

      let promise = TransactionStore.syncTransactionsByReconcileReference(transactionToDelete.reconcileReference);

      promise.then((transactions: Array<Transaction>) => {
        this.setState({
          deleteTransactionModalTransactions: transactions,
          deleteTransactionModalUpdating: false,
        })
      });

      promise.catch((error: DataStoreError) => {

        ToastMessageStore.addMessage(
          ToastMessage.create('error', 'Failed to fetch transactions that will be deleted.')
        );

        this.setState({
          deleteTransactionModalUpdating: false
        })
      });

      return;
    }

    // Don't open modal
    return ToastMessageStore.addMessage(
      ToastMessage.create('error', 'This transaction cannot be deleted.')
    );
  }

  protected closeDeleteTransactionModal()
  {
    this.setState({
      deleteTransactionModalOpen: false
    });

    let timeout = setTimeout(() => {
      this.setState({
        deleteTransactionModalId: null,
        deleteTransactionModalTransactions: []
      });
      clearTimeout(timeout);
    }, 300)
  }

  protected getNoTransactionsContent()
  {
    let heading = 'No Transactions Found';
    let text = 'There are currently no transactions associated with this account';
    let buttonContent = 'Add Transaction';
    let ctaUri = '/transactions';

    // TODO: Switch out content depending on permission status

    return <div className="cell">
      <CtaPlaceholder
        icon={faExchangeAlt}
        heading={heading}
        text={text}
        buttonContent={buttonContent}
        ctaUri={ctaUri}
      />
    </div>;
  }

  protected getDateOfBirthDisplayValue(dateOfBirth: string): string
  {
    if (!dateOfBirth)
    {
      return '-';
    }

    let date = new Date(dateOfBirth);

    let monthNames = {
      0: 'January',
      1: 'February',
      2: 'March',
      3: 'April',
      4: 'May',
      5: 'June',
      6: 'July',
      7: 'August',
      8: 'September',
      9: 'October',
      10: 'November',
      11: 'December'
    };

    // 20 December 2019
    return date.getDate() + ' ' + monthNames[date.getMonth()] + ' ' + date.getFullYear() + ' (' + this.getAgeFromDate(date) + ' years old)'
  }

  protected getAgeFromDate(date: Date)
  {
    var ageDifMs = Date.now() - date.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
}
