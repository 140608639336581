
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons/faInfoCircle";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from 'react';
import {AbstractComponent} from "../AbstractComponent";
import './scss/SplitPanel.scss';

export interface SplitPanelProps {
  primaryContent: any,
  secondaryContent: any,
  primaryContentPosition?: 'left' | 'right'
  primaryContentWidth?: number
}
export interface SplitPanelState {
  secondaryContentVisible: boolean
}

export class SplitPanel extends AbstractComponent<SplitPanelProps, SplitPanelState>
{
  private BLOCK_NAME: 'SplitPanel' = 'SplitPanel';
  
  public constructor(props: SplitPanelProps)
  {
    super(props);
    
    this.state = {
      secondaryContentVisible: false
    }
  }
  
  public render()
  {
    return <div
      className={[
        this.BLOCK_NAME,
        this.state.secondaryContentVisible ? `${this.BLOCK_NAME}--SecondaryContentVisible` : null
      ].join(' ')}
    >
      {this.getLeftPanel()}
      {this.getRightPanel()}
    </div>
  }
  
  private getLeftPanel()
  {
    const isPrimaryContent = this.getPrimaryContentPosition() === 'left';
    const contentWidth = isPrimaryContent ? this.getPrimaryContentWidth() : this.getSecondaryContentWidth();
    const content = isPrimaryContent ? this.props.primaryContent : this.props.secondaryContent;
    const contentClass = isPrimaryContent ? `${this.BLOCK_NAME}__PrimaryContent` : `${this.BLOCK_NAME}__SecondaryContent`;
    const icon = isPrimaryContent ? this.getViewSecondaryContentIcon() : this.getCloseSecondaryContentIcon();
  
    return <div
      className={[
        `${this.BLOCK_NAME}__Left`,
        contentClass
      ].join(' ')}
      style={{
        width: `${contentWidth}%`
      }}
    >
      {icon}
      {content}
    </div>
  }
  
  private getRightPanel()
  {
    const isPrimaryContent = this.getPrimaryContentPosition() === 'right';
    const contentWidth = isPrimaryContent ? this.getPrimaryContentWidth() : this.getSecondaryContentWidth();
    const content = isPrimaryContent ? this.props.primaryContent : this.props.secondaryContent;
    const contentClass = isPrimaryContent ? `${this.BLOCK_NAME}__PrimaryContent` : `${this.BLOCK_NAME}__SecondaryContent`;
    const icon = isPrimaryContent ? this.getViewSecondaryContentIcon() : this.getCloseSecondaryContentIcon();
    
    return <div
      className={[
        `${this.BLOCK_NAME}__Right`,
        contentClass
      ].join(' ')}
      style={{
        width: `${contentWidth}%`
      }}
    >
      {icon}
      {content}
    </div>;
  }
  
  private getPrimaryContentPosition()
  {
    return this.props.primaryContentPosition || 'left';
  }
  
  private getPrimaryContentWidth()
  {
    return Number.isInteger(this.props.primaryContentWidth) ? this.props.primaryContentWidth : 40;
  }
  
  private getSecondaryContentWidth()
  {
    return 100 - this.getPrimaryContentWidth();
  }
  
  private getViewSecondaryContentIcon()
  {
    return <div
      className={`${this.BLOCK_NAME}__IconContainer`}
      onClick={() => {
        this.setState({
          secondaryContentVisible: true
        })
      }}
    >
      <FontAwesomeIcon icon={faInfoCircle}/>
    </div>
  }
  
  private getCloseSecondaryContentIcon()
  {
    return <div
      className={`${this.BLOCK_NAME}__IconContainer`}
      onClick={() => {
        this.setState({
          secondaryContentVisible: false
        })
      }}
    >
      <FontAwesomeIcon icon={faTimes}/>
    </div>
  }
}
