
import { faTrashAlt as solidFaTrashAlt } from "@fortawesome/pro-solid-svg-icons/faTrashAlt";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { Modal } from "appex-theme/src/Core/Modal/Modal";
import * as React from 'react';
import { Header } from "appex-theme/src/Form/Input/Header/Header";
import {AbstractComponent} from "../../../../component/AbstractComponent";
import {PageBar} from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import {Breadcrumbs} from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import {Form} from "appex-theme/src/Form/Form/Form";
import {TextField} from "appex-theme/src/Form/Input/Text/TextField";
import {Validator} from "appex-theme/src/Form/Form/Validator/Validator";
import {Button} from "appex-theme/src/Core/Button/Button";
import {Panel} from "appex-theme/src/Core/Panel/Panel";
import {DateField} from "appex-theme/src/Form/Input/Date/DateField";
import {Member} from "../../../../data/Member/Entity/Member";
import {MemberStore} from "../../../../data/Member/MemberStore";
import {ToastMessageStore} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import {DataStoreError} from "ts-redux/src/DataStore/DataStoreError";
import {ToastMessage} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";
import {InputGroupField} from "appex-theme/src/Form/Input/InputGroup/InputGroupField";
import './scss/CreateUpdateMemberPage.scss';
import {Objects} from "phusion/src/Core/Objects/Objects";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/pro-light-svg-icons/faHome";
import {faUserEdit} from "@fortawesome/pro-duotone-svg-icons/faUserEdit";
import {faUserPlus} from "@fortawesome/pro-duotone-svg-icons/faUserPlus";
import {faAddressCard} from "@fortawesome/pro-solid-svg-icons/faAddressCard";
import {faHomeAlt} from "@fortawesome/pro-solid-svg-icons/faHomeAlt";
import {faUser} from "@fortawesome/pro-solid-svg-icons/faUser";
import {Request} from "appex-theme/src/Utility/Request";
import { Membership } from "../../../../data/Membership/Entity/Membership";
import { MembershipStore } from "../../../../data/Membership/MembershipStore";
import { PermissionsStore } from "../../../../data/Permissions/PermissionsStore";
import { SelectField } from "appex-theme/src/Form/Input/Select/SelectField";

export interface CreateMemberProps {}

export interface CreateMemberState {
  dataUpdating: boolean,
  member: Member,
  isUpdatePage: boolean,
  formSubmitting: boolean,
  userOrgId: number,
  memberships: Array<Membership>,
  showPendingRemovalModal: boolean
}

export class CreateUpdateMemberPage extends AbstractComponent<CreateMemberProps, CreateMemberState>
{
  private latestFormValue: any;
  
  public constructor(props: CreateMemberProps)
  {
    super(props);

    let member = null;

    let userOrgId = props['params'].userOrgId;

    if (userOrgId)
    {
      member = MemberStore.getByUserOrgIdAndActiveOrganisation(userOrgId);
    }

    this.state = {
      dataUpdating: false,
      member: member,
      isUpdatePage: (userOrgId),
      formSubmitting: false,
      userOrgId: userOrgId,
      memberships: MembershipStore.getAllMembershipsByActiveOrganisation(),
      showPendingRemovalModal: false
    }
  }

  public componentDidMount()
  {
    // Redirect if you don't have member:read permissions
    if (!PermissionsStore.hasPermission('member:write'))
    {
      ToastMessageStore.addMessage(ToastMessage.create('error', "Sorry, you don't have access to view this page"));
      return Request.goBack(this);
    }

    if (!this.state.isUpdatePage)
    {
      return;
    }

    this.unsubscribers['member'] = MemberStore.subscribeToMemberByUserOrgId(
      this.state.userOrgId,
      (member: Member) => {
        this.setState({
          member: member
        })
      },
      this.state.member
    )

    MemberStore
      .syncMember(this.state.userOrgId)
      .then((member) => {
        
        if (!member)
        {
          ToastMessageStore.addMessage(ToastMessage.create('error', "Member not found"));
          return Request.goBack(this);
        }
        // Redirect if you don't have member:read permissions
        else if (this.state.member?.status === 'pending_removal')
        {
          this.defer(() => {
            ToastMessageStore.addMessage(ToastMessage.create('error', "A member that is pending removal cannot be updated"));
          }, 0);
          return Request.goBack(this);
        }
      });

    this.unsubscribers['memberships'] = MembershipStore.subscribeToActiveOrgMemberships(
      (memberships: Array<Membership>) => {
        this.setState({
          memberships: memberships
        })
      },
      this.state.memberships
    )

    MembershipStore.syncActiveOrganisationMemberships();
  }

  public render()
  {
    let breadcrumbs = this.getBreadCrumbs();

    const submitButtonModifiers: Array<any> = [
      'primary',
      this.state.dataUpdating ? 'loading' : ''
    ];

    const formElements = this.getFormElements();

    formElements.push(
      <div className="cell" key={'submit'}>
        <div className="mt-4 mb-4 text--align-right">
          <Button type={'submit'} modifiers={submitButtonModifiers}>
            { this.state.isUpdatePage ? 'Update Member' : 'Create Member' }
          </Button>
        </div>
      </div>
    )

    return <div className={'CreateUpdateMemberPage'}>

      <div className="grid-container">
        <PageBar
          title={
            this.state.isUpdatePage
              ? <div><FontAwesomeIcon icon={faUserEdit}/>{`Update ${Member.getPreferredFirstName(this.state.member)}'s Profile`}</div>
              : <div><FontAwesomeIcon icon={faUserPlus}/>Create a New Member</div>
          }
          rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}
        />
        <Panel>
          <Form
            name={'createMember'}
            elements={formElements}
            onFormSubmit={this.onFormSubmit.bind(this)}
            onFormCapture={this.onFormCapture.bind(this)}
          />
        </Panel>

      </div>
      
      {this.getPendingRemovalModal()}

    </div>
  }
  
  private getPendingRemovalModal()
  {
    return <Modal
      open={this.state.showPendingRemovalModal}
      close={() => {
        this.setState({
          showPendingRemovalModal: false
        })
      }}
    >
      <Header
        text={'Warning'}
        icon={faExclamationTriangle}
      />
      <p>
        Updating a member's status to Pending Removal is a permanent action and cannot be undone.
      </p>
      <div className="text--align-right mb-2">
        <Button
          modifiers={[ 'primary' ]}
          onClick={() => {
            this.setState({
              showPendingRemovalModal: false
            })
          }}
        >
          I Understand
        </Button>
      </div>
    </Modal>
  }

  protected getFormElements()
  {
    let member = this.state.member;

    return [
      <div className="cell" key={'personal_details'}>
        <Header
          text={'Personal Details'}
          icon={faUser}
        />
      </div>,
      // First name
      TextField
        .create(
          'firstNames',
          'First Name(s)',
          [ Validator.required() ],
          member && member.firstNames ? member.firstNames : null
        )
        .setGridClass('cell medium-6'),
      // Last Name
      TextField
        .create(
          'lastName',
          'Last Name',
          [ Validator.required() ],
          member && member.lastName ? member.lastName : null,
        )
        .setGridClass('cell medium-6'),
      // Preferred Name
      TextField
        .create(
          'preferredName',
          'Preferred Name',
          [],
          member && member.preferredName ? member.preferredName : null,
        )
        .setGridClass('cell medium-6'),
      // Date of Birth
      DateField
        .create(
          'dateOfBirth',
          'Date of Birth',
          [],
          member && member.dateOfBirth ? member.dateOfBirth : null,
        )
        .setGridClass('cell medium-6'),
      //  Address Header
      <div className="cell" key={'address_header'}>
        <Header
          text={'Address'}
          icon={faHomeAlt}
          additionalClasses={['mt-5']}
        />
      </div>,
      InputGroupField.create(
        'address',
        [
          // Address Line 1
          TextField
            .create(
            'addressLine1',
            'Address Line 1',
            [],
            null,
            )
            .setGridClass('cell medium-6'),
          // Address Line 2
          TextField
            .create(
              'addressLine2',
              'Address Line 2',
              [],
              null,
            )
            .setGridClass('cell medium-6'),
          // Town
          TextField
            .create(
              'town',
              'Town',
              [],
              null
            )
            .setGridClass('cell medium-6 large-4'),
          // County
          TextField
            .create(
              'county',
              'County',
              [],
              null
            )
            .setGridClass('cell medium-6 large-4'),
          // Post Code
          TextField
            .create(
              'postCode',
              'Post Code',
              [],
              null
            )
            .setGridClass('cell large-4'),
        ],
        member && member.address ? member.address : null,
        []
      ),
      // Contact Info Header
      <div className="cell" key={'contact_info'}>
        <Header
          text={'Contact Information'}
          icon={faAddressCard}
          additionalClasses={['mt-5']}
        />
      </div>,
      // Post Code
      TextField
        .create(
          'emailAddress',
          'Email Address',
          [ Validator.required(), Validator.email() ],
          member && member.emailAddress ? member.emailAddress : null,
        )
        .setGridClass('cell large-4')
        .setType('email'),
      // Mobile Number
      TextField
        .create(
          'mobileContactNumber',
          'Mobile',
          [],
          member && member.mobileContactNumber ? member.mobileContactNumber : null,
        )
        .setGridClass('cell large-4'),
      // Home Number
      TextField
        .create(
          'homeContactNumber',
          'Home',
          [],
          member && member.homeContactNumber ? member.homeContactNumber : null
        )
        .setGridClass('cell large-4'),
      //  Membership Header
      <div className="cell" key={'membership_header'}>
        <Header
          text={'Membership'}
          icon={faAddressCard}
          additionalClasses={['mt-5']}
        />
      </div>,
      SelectField
        .create(
          'membershipId',
          'Membership',
          this.state.memberships,
          (membership: Membership) => {
            return membership.id;
          },
          (membership: Membership) => {
            return membership.name;
          },
          [ Validator.required() ],
          member && member.membershipId ? member.membershipId : null
        )
        .setGridClass('cell medium-6'),
      SelectField
        .create(
          'status',
          'Status',
          [
            {
              value: 'active',
              displayName: 'Active',
            },
            {
              value: 'inactive',
              displayName: 'Inactive',
            },
            {
              value: 'pending_removal',
              displayName: 'Pending Removal'
            },
          ],
          (item) => {
            return item.value;
          },
          (item) => {
            return item.displayName;
          },
          [ Validator.required() ],
          member && member.status ? member.status : null
        )
        .setGridClass('cell medium-6'),
    ]
  }

  protected getBreadCrumbs()
  {
    if (
      this.state.isUpdatePage
      && this.state.member
    )
    {
      return [
        {
          key: 'home',
          title: <span><FontAwesomeIcon icon={faHome}/> Home</span>,
          href: '/'
        },
        {
          key: 'members',
          title: 'Members',
          href: '/members'
        },
        {
          key: 'update_member',
          title: 'Update ' + Member.getFullPreferredName(this.state.member)
        },
      ];
    }

    return [
      {
        key: 'home',
        title: <span><FontAwesomeIcon icon={faHome}/> Home</span>,
        href: '/'
      },
      {
        key: 'members',
        title: 'Members',
        href: '/members'
      },
      {
        key: 'create_member',
        title: 'New Member'
      },
    ];
  }

  protected onFormSubmit(formState)
  {
    // If the user is pending removal, they cannot be updated
    if (this.state.member.status && this.state.member.status === 'pending_removal')
    {
      return ToastMessageStore.addMessage(ToastMessage.create('error', 'Cannot update a member that is pending removal'));
    }
    
    if (this.state.formSubmitting)
    {
      return;
    }

    if (formState.valid)
    {
      if (this.state.member && this.state.member.id)
      {
        let newMember: any = Objects.hydrate(
          Objects.clone(this.state.member),
          formState.value
        );

        this.setState({
          dataUpdating: true
        });

        return this.updateMember(newMember);
      }

      let newMember = Member.create(formState.value);

      this.setState({
        dataUpdating: true
      });

      return this.createMember(newMember);
    }
  }

  protected updateMember(member: Member)
  {
    // Send to redux
    let promise = MemberStore.updateMember(member);

    promise.then((member: Member) => {
      this.setState({
        dataUpdating: false,
      });

      ToastMessageStore.addMessage(ToastMessage.create('success', 'Member updated'));
      Request.redirect('/members', this);
    });

    promise.catch((error: DataStoreError) => {
      this.setState({
        dataUpdating: false
      });
    })
  }

  protected createMember(member: Member)
  {
    this.setState({
      formSubmitting: true
    });
    // Send to redux
    let promise = MemberStore.createNewMember(member);

    promise.then((member: Member) => {
      this.setState({
        dataUpdating: false,
        formSubmitting: false,
      });

      ToastMessageStore.addMessage(ToastMessage.create('success', 'Member created'));
      Request.redirect('/members', this);
    });

    promise.catch((error: DataStoreError) => {
      this.setState({
        dataUpdating: false,
        formSubmitting: false
      });
    })
  }

  protected onFormCapture(formValue)
  {
    // If status has been changed to pending removal, show warning modal
    if (
      this.latestFormValue
      && this.latestFormValue.status !== 'pending_removal'
      && formValue.status === 'pending_removal'
    )
    {
      this.setState({
        showPendingRemovalModal: true
      })
    }
    
    this.latestFormValue = formValue;
    
    console.log('formValue', formValue);
  }
}
