
import * as React from 'react';
import { Header } from "appex-theme/src/Form/Input/Header/Header";
import { Numbers } from "appex-theme/src/Utility/Numbers";
import {AbstractComponent} from "../../../../component/AbstractComponent";

import './scss/CreateEventPage.scss';
import {PageBar} from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import {Breadcrumbs} from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import {Panel} from "appex-theme/src/Core/Panel/Panel";
import {Form} from "appex-theme/src/Form/Form/Form";
import {Button} from "appex-theme/src/Core/Button/Button";
import {TextField} from "appex-theme/src/Form/Input/Text/TextField";
import {Validator} from "appex-theme/src/Form/Form/Validator/Validator";
import {DateField} from "appex-theme/src/Form/Input/Date/DateField";
import {DateTimeField} from "appex-theme/src/Form/Input/DateTime/DateTimeField";
import {InputGroupField} from "appex-theme/src/Form/Input/InputGroup/InputGroupField";
import {SelectField} from "appex-theme/src/Form/Input/Select/SelectField";
import { Guest } from "../../../../data/Event/Entity/Guest";
import { EventGuestStore } from "../../../../data/Event/EventGuestStore";
import {Member} from "../../../../data/Member/Entity/Member";
import {Event} from "../../../../data/Event/Entity/Event";
import {MemberStore} from "../../../../data/Member/MemberStore";
import {ToastMessageStore} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import {ToastMessage} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";
import {Objects} from "phusion/src/Core/Objects/Objects";
import {DataStoreError} from "ts-redux/src/DataStore/DataStoreError";
import {EventStore} from "../../../../data/Event/EventStore";
import {Attendance} from "../../../../data/Event/Entity/Attendance";
import {EventAttendanceStore} from "../../../../data/Event/EventAttendanceStore";
import {Ticket} from "../../../../data/Event/Entity/Ticket";
import {EventTicketStore} from "../../../../data/Event/EventTicketStore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {TextAreaField} from "appex-theme/src/Form/Input/TextArea/TextAreaField";
import {AccountStore} from "../../../../data/Account/AccountStore";
import {Account} from "../../../../data/Account/Entity/Account";
import {faHome} from "@fortawesome/pro-light-svg-icons/faHome";
import {faCalendarAlt} from "@fortawesome/pro-solid-svg-icons/faCalendarAlt";
import {faTicketAlt} from "@fortawesome/pro-solid-svg-icons/faTicketAlt";
import {faUsers} from "@fortawesome/pro-solid-svg-icons/faUsers";
import {Request} from "appex-theme/src/Utility/Request";
import {faCalendarDay} from "@fortawesome/pro-duotone-svg-icons/faCalendarDay";
import { PermissionsStore } from "../../../../data/Permissions/PermissionsStore";

export interface CreateUpdateEventProps {}
export interface CreateUpdateEventState {
  event: Event,
  eventId: number,
  attendance: Array<Attendance>,
  guests: Array<Guest>,
  tickets: Array<Ticket>,
  members: Array<Member>,
  accounts: Array<Account>,
  formSubmitting: boolean,
  dataUpdating: boolean,
  redirectToEventsPage: boolean,
  isUpdatePage: boolean
}

export class CreateUpdateEventPage extends AbstractComponent<CreateUpdateEventProps, CreateUpdateEventState>
{
  protected unsubscribers = {};

  public constructor(props: CreateUpdateEventProps)
  {
    super(props);

    // Get members
    let members = MemberStore.getAllMembersByActiveOrganisation();

    // Get event
    let event = null;

    let eventId = props['params'].eventId;

    if (eventId)
    {
      event = EventStore.getByIdAndActiveOrganisation(eventId);
    }

    // Get event attendance and tickets
    let attendance = [];
    let tickets = [];
    let guests = [];

    if (event)
    {
      attendance = EventAttendanceStore.getAllAttendanceByEventId(eventId);
      tickets = EventTicketStore.getAllTicketsByEventId(eventId);
      guests = EventGuestStore.getAllGuestsByEventId(eventId);
    }

    // Get accounts
    let accounts = AccountStore.getAllAccountsByActiveOrganisation();

    this.state = {
      event: event,
      members: members ? members : [],
      accounts: accounts ? accounts : [],
      formSubmitting: false,
      dataUpdating: false,
      redirectToEventsPage: false,
      attendance: attendance,
      guests: guests,
      tickets: tickets,
      isUpdatePage: !!(eventId),
      eventId: eventId
    }
  }

  public componentDidMount()
  {
    // Redirect if you don't have event:write permissions
    if (!PermissionsStore.hasPermission('event:write'))
    {
      ToastMessageStore.addMessage(ToastMessage.create('error', "Sorry, you don't have access to view this page"));
      return Request.goBack(this);
    }

    // If we're on the update page
    if (this.state.isUpdatePage)
    {
      /**
       * Subscribe to Event
       */

      this.unsubscribers['event'] = EventStore.subscribeToEventById(
        this.state.eventId,
        (event: Event) => {
          this.setState({
            event: event
          })
        },
        this.state.event
      );

      /**
       * Subscribe to Event Attendance
       */

      this.unsubscribers['attendance'] = EventAttendanceStore.subscribeToAttendanceByEventId(
        this.state.eventId,
        (attendance: Array<Attendance>) => {
          this.setState({
            attendance: attendance
          })
        },
        this.state.attendance
      )

      /**
       * Subscribe to Event Guests
       */

      this.unsubscribers['guests'] = EventGuestStore.subscribeToGuestsByEventId(
        this.state.eventId,
        (guests: Array<Guest>) => {
          this.setState({
            guests: guests
          })
        },
        this.state.guests
      )

      /**
       * Subscribe to Tickets
       */
      this.unsubscribers['tickets'] = EventTicketStore.subscribeToTicketsByEventId(
        this.state.eventId,
        (tickets: Array<Ticket>) => {
          this.setState({
            tickets: tickets
          })
        },
        this.state.tickets
      );

      // Sync event, attendance & tickets
      EventStore.syncEvent(this.state.eventId);
    }

    /**
     * Subscribe to Members
     */

    this.unsubscribers['members'] = MemberStore.subscribeToActiveOrgMembers(
      (members: Array<Member>) => {
        this.setState({
          members: members
        })
      },
      this.state.members
    );

    /**
     * Subscribe to Accounts
     */

    this.unsubscribers['accounts'] = AccountStore.subscribeToActiveOrgAccounts(
      (accounts: Array<Account>) => {
        this.setState({
          accounts: accounts
        })
      },
      this.state.accounts
    )

    // Sync Members
    MemberStore.syncActiveOrganisationMembers();
    // Sync Accounts
    AccountStore.syncActiveOrganisationAccounts();
  }

  public componentWillUnmount()
  {
    if (Object.keys(this.unsubscribers).length)
    {
      for (let key in this.unsubscribers)
      {
        this.unsubscribers[key]();
      }
    }
  }

  public render()
  {
    let breadcrumbs = this.getBreadCrumbs();

    let submitButtonModifiers: Array<any> = [
      'primary',
      this.state.dataUpdating ? 'loading' : ''
    ];

    let pageBarTitle = '...';

    if (this.state.isUpdatePage)
    {
      if (this.state.event)
      {
        pageBarTitle = 'Update ' + this.state.event.name;
      }
    }
    else
    {
      pageBarTitle = 'Create a New Event';
    }

    const formElements = this.getFormElements();

    formElements.push(
      <div className="cell" key={'submit'}>
        <div className="mt-4 mb-4 text--align-right">
          <Button type={'submit'} modifiers={submitButtonModifiers}>
          { this.state.isUpdatePage ? 'Update Event' : 'Create Event' }
        </Button>
        </div>
      </div>
    )

    return <div className={'CreateUpdateEventPage'}>

      <div className="grid-container">
        <PageBar title={<div><FontAwesomeIcon icon={faCalendarDay}/>{pageBarTitle}</div>} rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}/>

        <Panel>
          <Form
            name={'createAccount'}
            elements={formElements}
            onFormSubmit={this.onFormSubmit.bind(this)}
            onFormCapture={() => {}}
          />
        </Panel>
      </div>

    </div>
  }

  protected getBreadCrumbs()
  {
    if (
      this.state.isUpdatePage
      && this.state.event
    )
    {
      return [
        {
          key: 'home',
          title: <span><FontAwesomeIcon icon={faHome}/> Home</span>,
          href: '/'
        },
        {
          key: 'events',
          title: 'Events',
          href: '/events'
        },
        {
          key: 'update_event',
          title: 'Update ' + this.state.event.name
        },
      ];
    }

    return [
      {
        key: 'home',
        title: <span><FontAwesomeIcon icon={faHome}/> Home</span>,
        href: '/'
      },
      {
        key: 'events',
        title: 'Events',
        href: '/events'
      },
      {
        key: 'create_event',
        title: 'New Event'
      },
    ];
  }

  protected getFormElements()
  {
    let members = this.state.members;

    let event = this.state.event;

    let invitedUserOrgs = [];

    if (this.state.attendance)
    {
      invitedUserOrgs = this.state.attendance.map((attendance: Attendance) => {
        return attendance.userOrgId;
      })
    }

    let tickets = this.state.tickets.map((ticket: Ticket) => {

      if (!ticket.debitAccountId)
      {
        ticket.debitAccountId = 'charge_member';
      }
      return ticket;
    });

    let accounts = this.state.accounts.filter((account: Account) => {
      return account.category !== 'sub_account';
    });

    return [
      <div className="cell" key={'event_details'}>
        <Header
          text={'Event Details'}
          icon={faCalendarAlt}
        />
      </div>,
      // Name
      TextField
        .create(
          'name',
          'Name',
          [ Validator.required() ],
          event ? event.name : null
        )
        .setGridClass('cell medium-6'),
      // Closing Date
      DateField
        .create(
          'closing',
          'Closing Date',
          [ Validator.required() ],
          event ? event.closing : null,
        )
        .setGridClass('cell medium-6'),
      // Start DateTime
      DateTimeField
        .create(
          'start',
          'Start Date',
          [ Validator.required() ],
          event ? event.start : null,
        )
        .setGridClass('cell medium-6'),
      // Finish DateTime
      DateTimeField
        .create(
          'finish',
          'Finish Date',
          [],
          event ? event.finish : null
        )
        .setGridClass('cell medium-6'),
      // Description
      TextAreaField
        .create(
          'description',
          'Description',
          [ Validator.required() ],
          event ? event.description : null
        ),
      // Invite Members
      <div className="cell" key={'invite_members'}>
        <Header
          text={'Invite Members'}
          icon={faUsers}
          additionalClasses={[ 'mt-5' ]}
        />
      </div>,
      SelectField
        .create(
          'invitedMembers',
          'Invited Members',
          members,
          (member: Member) =>
          {
            return member.userOrgId;
          },
          (member: Member) =>
          {
            return Member.getFullPreferredName(member);
          },
          [Validator.required()],
          invitedUserOrgs,
          'multi',
          true,
          MemberStore.onSearch
        ),
      <div className="cell" key={'create_tickets'}>
        <Header
          text={'Create Tickets'}
          icon={faTicketAlt}
          additionalClasses={[ 'mt-5' ]}
        />
      </div>,
      // Tickets Input Group
      InputGroupField
        .create(
          'tickets',
          [
            // Ticket name
            TextField
              .create(
                'name',
                'Ticket Name',
                [ Validator.required() ],
                null,
              )
              .setGridClass('cell medium-6'),
            // Ticket price
            TextField
              .create(
                'price',
                'Price',
                [
                  Validator.required(),
                  Validator.decimal()
                ],
                null
              )
              .setGridClass('cell medium-6')
              .setFormatter(
                (inputValue: string) => {
                  if (inputValue === '') return inputValue;
                  return Numbers.toDecimal(inputValue)
                }
              ),
          // Credit account
          SelectField
            .create(
              'creditAccountId',
              'Credit Account',
              accounts,
              (account: Account | { id: 'charge_member', name: string }) => {
                return account.id;
              },
              (account: Account | { id: 'charge_member', name: string }) => {

                if (account.id == 'charge_member')
                {
                  return account.name;
                }

                let subtext = Account.getCategoryDisplayValue(account);

                return <div>
                  <div>{account.name}</div>
                  <div className="text--subtext">{subtext}</div>
                </div>;
              },
              [ Validator.required() ],
              null,
              null,
              true,
              AccountStore.onSearch,
            )
            .setGridClass('cell medium-6'),
          // Debit account
          SelectField
            .create(
              'debitAccountId',
              'Debit Account',
              []
              .concat([
                {
                  id: 'charge_member',
                  name: 'Charge Member For Their Tickets',
                }
              ])
              .concat(accounts),
              (account: Account) => {
                return account.id;
              },
              (account: Account) => {

                let subtext = Account.getCategoryDisplayValue(account);

                return <div>
                  <div>{account.name}</div>
                  <div className="text--subtext">{subtext}</div>
                </div>;
              },
              [ Validator.required() ],
              null,
              null,
              true,
              AccountStore.onSearch
            )
            .setGridClass('cell medium-6')
        ],
        tickets,
        [],
        true,
      )
        .setGetReadOnlyFieldsCallback((instanceValue: Ticket) => {
            if (this.isTicketPurchased(instanceValue.id))
            {
              return ['price', 'debitAccountId', 'creditAccountId']
            }

            return [];
          }
          )
        .setCanRemoveInstanceCallback(
          (instanceValue: Ticket) => {
            return !this.isTicketPurchased(instanceValue.id);
          }
        )
    ]
  }

  protected isTicketPurchased(ticketId: number): boolean
  {
    if (!ticketId) return false;

    let purchased: any = this.state.attendance.find((attendance: Attendance) => {
      return attendance.eventTicketId == ticketId;
    })

    if (purchased)
    {
      return true;
    }

    purchased = this.state.guests.find((guest: Guest) => {
      return guest.eventTicketId == ticketId;
    })

    return Boolean(purchased);
  }

  protected onFormSubmit(formState)
  {
    if (this.state.formSubmitting)
    {
      return;
    }

    if (formState.valid)
    {
      if (this.state.event && this.state.event.id)
      {
        let newEvent: any = Objects.hydrate(
          Event.create(this.state.event),
          formState.value
        );

        this.setState({
          dataUpdating: true
        });

        return this.updateEvent(newEvent);
      }

      let newEvent = Event.create(formState.value);

      this.setState({
        dataUpdating: true
      });

      return this.createEvent(newEvent);
    }
  }

  protected updateEvent(event: Event)
  {
    // Send to redux
    let promise = EventStore.updateEvent(event);

    promise.then((event: Event) => {
      this.setState({
        dataUpdating: false
      });

      ToastMessageStore.addMessage(ToastMessage.create('success', 'Event updated'));

      let timeout = setTimeout(() => {
        Request.redirect("/events/" + this.state.eventId, this);
        clearTimeout(timeout);
      }, 0)
    });

    promise.catch((error: DataStoreError) => {
      this.setState({
        dataUpdating: false
      });
    })
  }

  protected createEvent(event: Event)
  {
    this.setState({
      formSubmitting: true
    });

    // Send to redux
    let promise = EventStore.createNewEvent(event);

    promise.then((event: Event) => {
      this.setState({
        dataUpdating: false,
        formSubmitting: false,
      });

      ToastMessageStore.addMessage(ToastMessage.create('success', 'Event created'));

      return Request.redirect("/events/" + event.id, this);
    });

    promise.catch((error: DataStoreError) => {
      this.setState({
        dataUpdating: false,
        formSubmitting: false
      });
    })
  }

}
