import {DataStore} from "ts-redux/src/DataStore/DataStore";
import {Objects} from "phusion/src/Core/Objects/Objects";
import {Redux} from "ts-redux/src/Redux";
import {Attendance} from "./Entity/Attendance";
import {Ticket} from "./Entity/Ticket";

export class EventTicketStore extends DataStore
{
  public static readonly key: string = 'event_ticket';
  protected static readonly SET_UPDATING: string = 'set_event_ticket_updating';
  protected static readonly SET_TICKETS_BY_EVENT: string = 'set_tickets_by_event';

  public static reduce(
    state = {
      updating: false,
      tickets: {}
    },
    action: {
      type: string,
      data: any
    }
  )
  {
    switch (action.type)
    {
      case EventTicketStore.SET_UPDATING:
        return Object.assign({}, state, {
          updating: action.data
        });
      case EventTicketStore.SET_TICKETS_BY_EVENT:

        let attendance = state['tickets']
          ? state['tickets']
          : {};

        let ticketsByEventId = Objects.clone(attendance);
        ticketsByEventId[action.data.eventId] = action.data.tickets;

        return Object.assign({}, state, {
          tickets: ticketsByEventId
        });
      default:
        return state;
    }
  }

  public static getAllTicketsByEventId(eventId: number): Array<Ticket>
  {
    let tickets = Objects.getByKeyPath('event_ticket:tickets:' + eventId, Redux.getState());

    if (tickets)
    {
      return tickets;
    }

    return [];
  }

  public static getAllTickets(): any
  {
    let tickets = Objects.getByKeyPath('event_ticket:tickets', Redux.getState());

    if (tickets)
    {
      return tickets;
    }

    return {};
  }

  public static setUpdating(updating: boolean)
  {
    Redux.dispatch({
      type: EventTicketStore.SET_UPDATING,
      data: updating
    });
  }

  public static setTicketsByEvent(eventId: number, tickets: Array<Ticket>)
  {
    Redux.dispatch({
      type: EventTicketStore.SET_TICKETS_BY_EVENT,
      data: {
        eventId: eventId,
        tickets: tickets
      }
    });
  }
  
  public static subscribeToUpdating(callback: (updating: boolean) => void, currentValue?: boolean)
  {
    return this.subscribe((state) => {
      let newValue = state.updating;
      
      if (currentValue !== newValue)
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }
  
  public static subscribeToTicketsByEventId(
    eventId,
    callback: (tickets: Array<Ticket>) => void,
    currentValue?: Array<Ticket>
  )
  {
    return this.subscribe((state) => {
      let newValue = this.getAllTicketsByEventId(eventId);
      
      if (JSON.stringify(currentValue) !== JSON.stringify(newValue))
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }

}
