import {DataStore} from "ts-redux/src/DataStore/DataStore";
import {Http} from "phusion/src/Core/Http/Http";
import {HttpResponse} from "phusion/src/Core/Http/HttpResponse";
import {HttpError} from "phusion/src/Core/Http/HttpError";
import {Objects} from "phusion/src/Core/Objects/Objects";
import {Redux} from "ts-redux/src/Redux";
import { Transaction } from "../Transaction/Entity/Transaction";
import { TransactionStore } from "../Transaction/TransactionStore";
import {Membership} from "./Entity/Membership";
import {OrganisationStore} from "../Organisation/OrganisationStore";
import {Url} from "../../utility/Url";

declare let Promise: any;

export class MembershipStore extends DataStore
{
  public static readonly key: string = 'membership';
  protected static readonly SET_UPDATING: string = 'set_membership_updating';
  protected static readonly SET_MEMBERSHIPS_BY_ORGANISATION: string = 'set_memberships_by_organisation';

  public static reduce(
    state = {
      updating: false,
      memberships: {}
    },
    action: {
      type: string,
      data: any
    }
  )
  {
    switch (action.type)
    {
      case MembershipStore.SET_UPDATING:
        return Object.assign({}, state, {
          updating: action.data
        });
      case MembershipStore.SET_MEMBERSHIPS_BY_ORGANISATION:

        let memberships = state['memberships']
          ? state['memberships']
          : {};

        let membershipsByOrgId = Objects.clone(memberships);
        membershipsByOrgId[action.data.organisationId] = action.data.memberships;

        return Object.assign({}, state, {
          memberships: membershipsByOrgId
        });
      default:
        return state;
    }
  }

  public static getByIdAndActiveOrganisation(id: number): Membership
  {
    let activeOrganisation = OrganisationStore.getActiveOrganisation();
    let allMemberships = this.getMembershipsByOrganisationId(activeOrganisation.id);

    if (!allMemberships)
    {
      return null;
    }

    let match = allMemberships.find((membership: Membership) => {
      return membership.id == id;
    });

    return match ? match : null;
  }

  public static getAllMembershipsByActiveOrganisation(): Array<Membership>
  {
    let activeOrganisation = OrganisationStore.getActiveOrganisation();
    return this.getMembershipsByOrganisationId(activeOrganisation.id);
  }

  protected static getMembershipsByOrganisationId(organisationId: number): Array<Membership>
  {
    let memberships = Objects.getByKeyPath('membership:memberships:' + organisationId, Redux.getState());

    if (memberships)
    {
      return memberships;
    }

    return [];
  }

  public static syncActiveOrganisationMemberships(): Promise<Array<Membership>>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      MembershipStore.setUpdating(true);

      let promise = Http.get(Url.api('/organisation/' + activeOrg.id + '/memberships'));

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let memberships = Objects.getByKeyPath('memberships', responseBody);

        if (memberships)
        {
          let membershipEntities = [];

          for (let key in memberships)
            if (memberships.hasOwnProperty(key))
          {
            membershipEntities.push(Membership.create(memberships[key]));
          }

          MembershipStore.setMembershipsByOrganisation(activeOrg.id, membershipEntities);
          MembershipStore.setUpdating(false);

          return resolve(membershipEntities);
        }

        MembershipStore.setUpdating(false);
        return reject(MembershipStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        MembershipStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  public static syncMembership(id: number): Promise<Membership>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      MembershipStore.setUpdating(true);

      let promise = Http.get(Url.api('/organisation/' + activeOrg.id + '/membership/' + id));

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let membership = Objects.getByKeyPath('membership', responseBody);

        if (membership)
        {
          membership = Membership.create(membership);

          this.spliceMembershipIntoOrgMemberships(activeOrg.id, membership);
          MembershipStore.setUpdating(false);

          return resolve(membership);
        }

        MembershipStore.setUpdating(false);
        return reject(MembershipStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        MembershipStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  protected static spliceMembershipIntoOrgMemberships(organisationId: number, membership: Membership): Array<Membership>
  {
    let allOrgMemberships = this.getMembershipsByOrganisationId(organisationId);

    let existingKey = null;

    for (let key in allOrgMemberships)
      if (allOrgMemberships.hasOwnProperty(key))
    {
      let existingMembership = allOrgMemberships[key];

      if (existingMembership.id == membership.id)
      {
        existingKey = Number(key);
        break;
      }
    }

    if (typeof existingKey == 'number')
    {
      allOrgMemberships.splice(existingKey, 1, membership);
    }
    else
    {
      allOrgMemberships.push(membership);
    }

    this.setMembershipsByOrganisation(organisationId, allOrgMemberships);

    return allOrgMemberships;
  }

  public static createNewMembership(membership: Membership): Promise<Membership>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      MembershipStore.setUpdating(true);

      let promise = Http.post(Url.api('/organisation/' + activeOrg.id + '/membership'), membership);

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let membership = Objects.getByKeyPath('membership', responseBody);

        if (membership)
        {
          membership = Membership.create(membership);
          this.spliceMembershipIntoOrgMemberships(activeOrg.id, membership);
          MembershipStore.setUpdating(false);

          return resolve(membership);
        }

        MembershipStore.setUpdating(false);
        return reject(MembershipStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        MembershipStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  public static processMemberships(transactionDate: string): Promise<Array<Transaction>>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      MembershipStore.setUpdating(true);

      let promise = Http.post(Url.api('/organisation/' + activeOrg.id + '/memberships/process'), { date: transactionDate });

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let transactions = Objects.getByKeyPath('transactions', responseBody);

        if (transactions)
        {
          
          for (let key in transactions)
            if (transactions.hasOwnProperty(key))
          {
            const transaction = Transaction.create(transactions[key])
            TransactionStore.spliceTransaction(transaction);
          }
          
          MembershipStore.setUpdating(false);

          return resolve(transactions);
        }

        MembershipStore.setUpdating(false);
        return reject(MembershipStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        MembershipStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  public static updateMembership(membership: Membership): Promise<Membership>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      MembershipStore.setUpdating(true);

      let promise = Http.put(Url.api('/organisation/' + activeOrg.id + '/membership/' + membership.id), membership);

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let membership = Objects.getByKeyPath('membership', responseBody);

        if (membership)
        {
          membership = Membership.create(membership);

          this.spliceMembershipIntoOrgMemberships(activeOrg.id, membership);
          MembershipStore.setUpdating(false);

          return resolve(membership);
        }

        MembershipStore.setUpdating(false);
        return reject(MembershipStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        MembershipStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  protected static setUpdating(updating: boolean)
  {
    Redux.dispatch({
      type: MembershipStore.SET_UPDATING,
      data: updating
    });
  }

  public static setMembershipsByOrganisation(organisationId: number, memberships: Array<Membership>)
  {
    Redux.dispatch({
      type: MembershipStore.SET_MEMBERSHIPS_BY_ORGANISATION,
      data: {
        organisationId: organisationId,
        memberships: memberships
      }
    });
  }

  public static onSearch(value: string, item: Membership)
  {
    let lowerCaseSearchValue = MembershipStore.strip(value);

    return (
      value == ''
      || MembershipStore.strip(item.name).indexOf(lowerCaseSearchValue) !== -1
    )
  }

  protected static strip(string: string)
  {
    return string.toLowerCase().replace(' ', '');
  }
  
  public static subscribeToUpdating(callback: (updating: boolean) => void, currentValue?: boolean)
  {
    return this.subscribe((state) => {
      let newValue = state.updating;
      
      if (currentValue !== newValue)
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }
  
  public static subscribeToMembershipById(
    id: number,
    callback: (membership: Membership) => void,
    currentValue?: Membership
  )
  {
    return this.subscribe((state) => {
      let memberships = this.getAllMembershipsByActiveOrganisation();
      
      let newValue = memberships.find((membership: Membership) => {
        return membership.id === id;
      })
      
      if (JSON.stringify(currentValue) !== JSON.stringify(newValue))
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }
  
  public static subscribeToActiveOrgMemberships(
    callback: (memberships: Array<Membership>) => void,
    currentValue?: Array<Membership>
  )
  {
    return this.subscribe((state) => {
      let newValue = this.getAllMembershipsByActiveOrganisation();
      
      if (JSON.stringify(currentValue) !== JSON.stringify(newValue))
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }
  
}
