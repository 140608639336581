
import * as React from 'react';
import {Link} from 'react-router-dom';
import {AbstractComponent} from "../AbstractComponent";
import './scss/Logo.scss';

const logoWhite = require('./img/logo-white.png').default;
const logoPrimary = require('./img/logo-primary.png').default;

export interface LogoProps { color?: 'white' | 'primary' }
export interface LogoState {}

export class Logo extends AbstractComponent<LogoProps, LogoState>
{
  public render()
  {
    let color = this.props.color ? this.props.color : 'primary';

    let logo = logoPrimary;

    if (color == 'white')
    {
      logo = logoWhite;
    }

    return <div className={['Logo'].join(' ')}>

      <img src={logo}/>

    </div>
  }
}
