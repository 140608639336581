import {Objects} from "phusion/src/Core/Objects/Objects";
import { User } from "../../User/Entity/User";
import {Address} from "./Address";

export class Member
{
  public id: number;
  public firstNames: string;
  public lastName: string;
  public preferredName: string;
  public emailAddress: string;
  public dateOfBirth: string;
  public homeContactNumber: string;
  public mobileContactNumber: string;
  public created: string;
  public updated: string;
  public address: Address;

  public userOrgId: number;
  public membershipId: number;
  public status: string;
  public broughtForwardBalance: number;
  public accountBalance: number; // Sometimes hydrated for display and sorting purposes
  
  public static create(data: Object = null): Member
  {
    if (!data)
    {
      return new this();
    }

    const mutators = {
      address: (address: any) => {
        return Address.create(address);
      }
    };

    return Objects.hydrate(new Member(), data, mutators) as Member;
  }

  public serialize(): Object
  {
    let mutators = {
      // dateOfBirth: (dateOfBirth: Moment.Moment) => dateOfBirth.valueOf(),
      // created: (created: Moment.Moment) => created.valueOf(),
      // updated: (updated: Moment.Moment) => updated.valueOf()
    };

    return Objects.hydrate({}, this, mutators);
  }

  public static getFullPreferredName(member: Member | User, fallback: string = '')
  {
    if (!member)
    {
      return fallback;
    }

    let firstName = member.firstNames.split(' ')[0];
    firstName = member.preferredName ? member.preferredName : firstName;
    return firstName + ' ' + member.lastName;
  }

  public static getPreferredFirstName(member: Member)
  {
    if (!member)
    {
      return '';
    }

    let firstName = member.firstNames.split(' ')[0];
    return member.preferredName ? member.preferredName : firstName;
  }

  public static onSearch(value: string, item: Member)
  {
    let lowerCaseSearchValue = Member.strip(value);

    return (
      value == ''
      || Member.strip(item.firstNames).indexOf(lowerCaseSearchValue) !== -1
      || Member.strip(item.lastName).indexOf(lowerCaseSearchValue) !== -1
      || (Member.strip(item.firstNames) + Member.strip(item.lastName)).indexOf(lowerCaseSearchValue) !== -1
      || item.preferredName && (Member.strip(item.preferredName + item.lastName)).indexOf(lowerCaseSearchValue) !== -1
      || Member.strip(item.emailAddress).indexOf(lowerCaseSearchValue) !== -1
    )
  }

  protected static strip(string: string)
  {
    return string.toLowerCase().replace(' ', '');
  }
}
