import {Objects} from "phusion/src/Core/Objects/Objects";

export class Account
{
  public id: number;
  public name: string;
  public accountGroupId: number;
  public category: 'asset' | 'liability' | 'revenue' | 'expense' | 'sub_account';
  public type: 'cash' | 'bank' | 'general' | 'cheques_held' | 'cheques_issued' | 'paying_in';
  public parentAccountId: number;
  public broughtForwardBalance: number;
  public chequesIssuedAccountId: number;
  public payingInAccountId: number;
  
  public balance: number;

  public static create(data: Object = null): Account
  {
    if (!data)
    {
      return new this();
    }

    return Objects.hydrate(new Account(), data) as Account;
  }

  public static getCategoryDisplayValue(account: Account)
  {
    switch (account.category)
    {
      case "asset":
        return 'Asset';
      case "liability":
        return 'Liability';
      case "revenue":
        return 'Revenue';
      case "expense":
        return 'Expense';
      default:
        return null;
    }
  }

  public static getTypeDisplayValue(account: Account)
  {
    switch (account.type)
    {
      case "cash":
        return 'Cash Account';
      case "bank":
        return 'Bank Account';
      case "general":
        return 'General Account';
      case "cheques_held":
        return 'Cheques Held';
      default:
        return null;
    }
  }
}
