
import * as React from 'react';
import {Link} from 'react-router-dom';
import { Request } from "appex-theme/src/Utility/Request";
import {AbstractComponent} from "../../../../component/AbstractComponent";
import {PageBar} from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import {Breadcrumbs} from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import {Panel} from "appex-theme/src/Core/Panel/Panel";
import { PermissionsStore } from "../../../../data/Permissions/PermissionsStore";
import {RoleStore} from "../../../../data/Role/RoleStore";
import {OrganisationStore} from "../../../../data/Organisation/OrganisationStore";
import {Member} from "../../../../data/Member/Entity/Member";
import {ToastMessageStore} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import {ToastMessage} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Role} from "../../../../data/Role/Entity/Role";
import {MemberStore} from "../../../../data/Member/MemberStore";
import {Arrays} from "phusion/src/Core/Arrays/Arrays";
import './scss/ViewRolePage.scss';
import {faUserTag} from "@fortawesome/pro-solid-svg-icons/faUserTag";
import {faCheckCircle} from "@fortawesome/pro-light-svg-icons/faCheckCircle";
import {faTimesCircle} from "@fortawesome/pro-light-svg-icons/faTimesCircle";
import {faEye} from "@fortawesome/pro-regular-svg-icons/faEye";
import {faUsers} from "@fortawesome/pro-duotone-svg-icons/faUsers";
import {faUserTag as dueFaUserTag} from "@fortawesome/pro-duotone-svg-icons/faUserTag";
import {DisplayItem} from "appex-theme/src/Data/DisplayItem/DisplayItem";
import {DataTable} from "appex-theme/src/Data/DataTable/DataTable/DataTable";
import {DisplayColumn} from "appex-theme/src/Data/DataTable/DataTable/Entity/DisplayColumn";
import {CtaPlaceholder} from "appex-theme/src/Placeholder/Cta/CtaPlaceholder";

export interface ViewRoleProps {}
export interface ViewRoleState {
  roleId: number,
  role: Role,
  members: Array<Member>,
  roleUpdating: boolean,
  membersUpdating: boolean
}

export class ViewRolePage extends AbstractComponent<ViewRoleProps, ViewRoleState>
{
  protected unsubscribers = {};

  public constructor(props: ViewRoleProps)
  {
    super(props);

    let role = null;

    let roleId = parseInt(props['params'].roleId);
    
    if (roleId)
    {
      role = RoleStore.getByIdAndActiveOrganisation(roleId);
    }

    let members = MemberStore.getAllMembersByActiveOrganisation();

    this.state = {
      role: role,
      roleId: roleId,
      members: members,
      roleUpdating: false,
      membersUpdating: false
    }
  }

  public componentDidMount()
  {
    // Redirect if you don't have permissions:read permissions
    if (!PermissionsStore.hasPermission('permissions:read'))
    {
      ToastMessageStore.addMessage(ToastMessage.create('error', "Sorry, you don't have access to view this page"));
      return Request.goBack(this);
    }
    
    /**
     * Subscribe to Role
     */
    
    this.unsubscribers['role_updating'] = RoleStore.subscribeToUpdating(
      (updating: boolean) => {
        this.setState({
          roleUpdating: updating
        })
      },
      this.state.roleUpdating
    )
    
    this.unsubscribers['role'] = RoleStore.subscribeToRoleById(
      this.state.roleId,
      (role: Role) => {
        this.setState({
          role: role
        })
      },
      this.state.role
    )
    
    /**
     * Subscribe to Members
     */
    
    this.unsubscribers['members_updating'] = MemberStore.subscribeToUpdating(
      (updating: boolean) => {
        this.setState({
          membersUpdating: updating
        })
      },
      this.state.membersUpdating
    )
    
    this.unsubscribers['members'] = MemberStore.subscribeToActiveOrgMembers(
      (members: Array<Member>) => {
        this.setState({
          members: members
        })
      },
      this.state.members
    )

    RoleStore.syncRole(this.state.roleId);
    MemberStore.syncActiveOrganisationMembers();

  }

  public componentWillUnmount()
  {
    if (this.unsubscribers)
    {
      for (let key in this.unsubscribers)
      {
        this.unsubscribers[key]();
      }
    }
  }

  public render()
  {
    let role = this.state.role;
    let breadcrumbs = [
      {
        key: 'home',
        title: <span><i className="fal fa-home"/> Home</span>,
        href: '/'
      },
      {
        key: 'roles',
        title: 'Roles',
        href: '/roles'
      },
      {
        key: 'view_role',
        title: role ? role.name : '. . .'
      }
    ];

    return <div className={'ViewRolePage'}>

      <div className="grid-container">

        <PageBar
          title={<div><FontAwesomeIcon icon={dueFaUserTag}/>{(!this.state.role && this.state.roleUpdating) ? 'Loading role . . .' :(this.state.role ? this.state.role.name : 'View Role')}</div>}
          rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}
        />

        <div className="grid-x grid-margin-x">
          <div className="cell large-4">
            <Panel
              headerLeft={<div className="Header">Role Details</div>}
              headerRight={
                PermissionsStore.hasPermission('permissions:write')
                  ? <Link to={'/roles/' + this.state.roleId + '/update'}>
                      <i className="fas fa-edit"/>
                    </Link>
                  : null
              }
            >

              <div className="grid-x">
                <div className="cell">
                  <DisplayItem icon={<FontAwesomeIcon icon={faUserTag}/>} content={ this.state.role ? this.state.role.name : null } updating={this.state.roleUpdating}/>
                </div>
              </div>
            </Panel>

            <Panel
              headerLeft={<div className="Header">Assigned Members</div>}
              headerRight={
                PermissionsStore.hasPermission('permissions:write')
                  ? <Link to={'/roles/' + (this.state.role ? this.state.role.id : '') + '/update'}>
                    <i className="fas fa-edit"/>
                  </Link>
                  : null
              }
            >
              {
                this.getAssignedMembersContent(this.state.role)
              }
            </Panel>

          </div>

          <div className="cell large-8">
            <Panel
              headerLeft={<div className="Header">Permissions</div>}
              headerRight={
                PermissionsStore.hasPermission('permissions:write')
                  ? <Link to={'/roles/' + (this.state.role ? this.state.role.id : '') + '/update'}>
                    <i className="fas fa-edit"/>
                  </Link>
                  : null
              }
            >
              <div className="grid-x">
                <div className="cell">

                  <DisplayItem
                    icon={
                      <FontAwesomeIcon
                        icon={Arrays.inArray('member:read', this.state.role ? this.state.role.permissions : []) ? faCheckCircle : faTimesCircle}
                        className={Arrays.inArray('member:read', this.state.role ? this.state.role.permissions : []) ? 'color--success' : 'color--error'}
                      />
                    }
                    header={'Members: Read Access'}
                    content={'View all member information.'}
                  />
                  <div className="Hr"/>

                  <DisplayItem
                    icon={
                      <FontAwesomeIcon
                        icon={Arrays.inArray('member:write', this.state.role ? this.state.role.permissions : []) ? faCheckCircle : faTimesCircle}
                        className={Arrays.inArray('member:write', this.state.role ? this.state.role.permissions : []) ? 'color--success' : 'color--error'}
                      />
                    }
                    header={'Members: Write Access'}
                    content={'Create and update all member information.'}
                  />

                  <div className="Hr"/>

                  <DisplayItem
                    icon={
                      <FontAwesomeIcon
                        icon={Arrays.inArray('event:read', (this.state.role ? this.state.role.permissions: [])) ? faCheckCircle : faTimesCircle}
                        className={Arrays.inArray('event:read', (this.state.role ? this.state.role.permissions : [])) ? 'color--success' : 'color--error'}
                      />
                    }
                    header={'Events: Read Access'}
                    content={'View all event information including attendance data.'}
                  />

                  <div className="Hr"/>

                  <DisplayItem
                    icon={
                      <FontAwesomeIcon
                        icon={Arrays.inArray('event:write', (this.state.role ? this.state.role.permissions: [])) ? faCheckCircle : faTimesCircle}
                        className={Arrays.inArray('event:write', (this.state.role ? this.state.role.permissions : [])) ? 'color--success' : 'color--error'}
                      />
                    }
                    header={'Events: Write Access'}
                    content={'Create & update event information including inviting and un-inviting members.'}
                  />

                  <div className="Hr"/>

                  <DisplayItem
                    icon={
                      <FontAwesomeIcon
                        icon={Arrays.inArray('financial:read', (this.state.role ? this.state.role.permissions: [])) ? faCheckCircle : faTimesCircle}
                        className={Arrays.inArray('financial:read', (this.state.role ? this.state.role.permissions : [])) ? 'color--success' : 'color--error'}
                      />
                    }
                    header={'Financial Data: Read Access'}
                    content={'View all financial information including account information and associated transactions.'}
                  />

                  <div className="Hr"/>

                  <DisplayItem
                    icon={
                      <FontAwesomeIcon
                        icon={Arrays.inArray('financial:write', (this.state.role ? this.state.role.permissions: [])) ? faCheckCircle : faTimesCircle}
                        className={Arrays.inArray('financial:write', (this.state.role ? this.state.role.permissions : [])) ? 'color--success' : 'color--error'}
                      />
                    }
                    header={'Financial Data: Write Access'}
                    content={'Create and update all finance information including account information and adding, removing and membershipsUpdating transactions.'}
                  />

                  <div className="Hr"/>

                  <DisplayItem
                    icon={
                      <FontAwesomeIcon
                        icon={Arrays.inArray('permissions:read', (this.state.role ? this.state.role.permissions: [])) ? faCheckCircle : faTimesCircle}
                        className={Arrays.inArray('permissions:read', (this.state.role ? this.state.role.permissions : [])) ? 'color--success' : 'color--error'}
                      />
                    }
                    header={'Member Permissions: Read Access'}
                    content={'View all roles and their associated permissions.'}
                  />

                  <div className="Hr"/>

                  <DisplayItem
                    icon={
                      <FontAwesomeIcon
                        icon={Arrays.inArray('permissions:write', (this.state.role ? this.state.role.permissions: [])) ? faCheckCircle : faTimesCircle}
                        className={Arrays.inArray('permissions:write', (this.state.role ? this.state.role.permissions : [])) ? 'color--success' : 'color--error'}
                      />
                    }
                    header={'Member Permissions: Write Access'}
                    content={'Create and update roles within the organisation, assign permissions and grant/revoke member access.'}
                  />

                  <div className="Hr"/>

                  <DisplayItem
                    icon={
                      <FontAwesomeIcon
                        icon={Arrays.inArray('membership:read', (this.state.role ? this.state.role.permissions: [])) ? faCheckCircle : faTimesCircle}
                        className={Arrays.inArray('membership:read', (this.state.role ? this.state.role.permissions : [])) ? 'color--success' : 'color--error'}
                      />
                    }
                    header={'Memberships: Read Access'}
                    content={'View all memberships and associated details.'}
                  />

                  <div className="Hr"/>

                  <DisplayItem
                    icon={
                      <FontAwesomeIcon
                        icon={Arrays.inArray('membership:write', (this.state.role ? this.state.role.permissions: [])) ? faCheckCircle : faTimesCircle}
                        className={Arrays.inArray('membership:write', (this.state.role ? this.state.role.permissions : [])) ? 'color--success' : 'color--error'}
                      />
                    }
                    header={'Memberships: Write Access'}
                    content={'Create and update memberships within the organisation.'}
                  />

                </div>
              </div>
            </Panel>
          </div>
        </div>
      </div>
    </div>
  }

  protected getAssignedMembersContent(role: Role)
  {
    const members = this.state.members.filter((member: Member) => {
      return role.members.indexOf(member.userOrgId) !== -1;
    });
    
    return <DataTable
      data={members}
      displayColumns={[
        DisplayColumn.create(
          'Name',
          'lastName',
          (member: Member) => {
            return Member.getFullPreferredName(member);
          },
          4
        ),
        DisplayColumn.create(
          'Action',
          null,
          (member: Member) => {
            return <div>
              <Link to={'/members/' + member.userOrgId} className={'mr-6'}>
                <FontAwesomeIcon icon={faEye}/>
              </Link>
            </div>
          }
        ),
      ]}
      searchable={true}
      sortable={true}
      onSearch={Member.onSearch}
      noDataContent={
        this.getNoMembersContent()
      }
      paginationControls={true}
      showRowsPerPage={false}
      defaultRowsPerPage={5}
      dataUpdating={this.state.membersUpdating}
    />
  }

  protected getNoMembersContent()
  {
    let heading = 'No Members Found';
    let text = 'No members have been assigned to this role';
    let buttonContent = 'Assign Members';
    let ctaUri = '/roles/' + (this.state.role ? this.state.role.id: '') + '/update';

    // TODO: Switch out content depending on permission status

    return <div className="cell">
      <CtaPlaceholder
        icon={faUsers}
        heading={heading}
        text={text}
        buttonContent={buttonContent}
        ctaUri={ctaUri}
      />
    </div>;
  }

}
