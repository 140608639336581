
import { faAddressCard } from "@fortawesome/pro-solid-svg-icons/faAddressCard";
import { faCheckCircle as solidFaCheckCircle } from "@fortawesome/pro-solid-svg-icons/faCheckCircle";
import { faTimesCircle as solidFaTimesCircle } from "@fortawesome/pro-solid-svg-icons/faTimesCircle";
import { Strings } from "phusion/src/Core/Strings/Strings";
import * as React from 'react';
import {Link} from 'react-router-dom';
import { Request } from "appex-theme/src/Utility/Request";
import {AbstractComponent} from "../../../../component/AbstractComponent";

import './scss/ViewMemberPage.scss';
import {PageBar} from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import {Breadcrumbs} from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import {Panel} from "appex-theme/src/Core/Panel/Panel";
import {MemberStore} from "../../../../data/Member/MemberStore";
import { Membership } from "../../../../data/Membership/Entity/Membership";
import { MembershipStore } from "../../../../data/Membership/MembershipStore";
import { PermissionsStore } from "../../../../data/Permissions/PermissionsStore";
import {CreateMemberProps} from "../Create/CreateUpdateMemberPage";
import {Member} from "../../../../data/Member/Entity/Member";
import {ToastMessageStore} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import {ToastMessage} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";
import {Transaction} from "../../../../data/Transaction/Entity/Transaction";
import {Account} from "../../../../data/Account/Entity/Account";
import {Button} from "appex-theme/src/Core/Button/Button";
import {AccountStore} from "../../../../data/Account/AccountStore";
import {TransactionStore} from "../../../../data/Transaction/TransactionStore";
import {Event} from "../../../../data/Event/Entity/Event";
import {Attendance} from "../../../../data/Event/Entity/Attendance";
import {EventCard} from "appex-theme/src/Event/EventCard/EventCard";
import {EventStore} from "../../../../data/Event/EventStore";
import {EventAttendanceStore} from "../../../../data/Event/EventAttendanceStore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBirthdayCake} from "@fortawesome/pro-solid-svg-icons/faBirthdayCake";
import {faEdit} from "@fortawesome/pro-solid-svg-icons/faEdit";
import {faEnvelope} from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import {faHome} from "@fortawesome/pro-solid-svg-icons/faHome";
import {faIdCard} from "@fortawesome/pro-solid-svg-icons/faIdCard";
import {faMobile} from "@fortawesome/pro-solid-svg-icons/faMobile";
import {faPhone} from "@fortawesome/pro-solid-svg-icons/faPhone";
import {faCalendarAlt} from "@fortawesome/pro-duotone-svg-icons/faCalendarAlt";
import {faExchangeAlt} from "@fortawesome/pro-duotone-svg-icons/faExchangeAlt";
import {faUser} from "@fortawesome/pro-duotone-svg-icons/faUser";
import {faCheckCircle} from "@fortawesome/pro-duotone-svg-icons/faCheckCircle";
import {faEnvelopeOpenText} from "@fortawesome/pro-regular-svg-icons/faEnvelopeOpenText";
import {DisplayItem} from "appex-theme/src/Data/DisplayItem/DisplayItem";
import {DisplayColumn} from "appex-theme/src/Data/DataTable/DataTable/Entity/DisplayColumn";
import {Money} from "appex-theme/src/Utility/Money";
import {DataTable} from "appex-theme/src/Data/DataTable/DataTable/DataTable";
import {Dates} from "appex-theme/src/Utility/Dates";
import {PaginationControls} from "appex-theme/src/Data/DataTable/PaginationControls/PaginationControls";
import {CtaPlaceholder} from "appex-theme/src/Placeholder/Cta/CtaPlaceholder";

export interface ViewMemberProps {}
export interface ViewMemberState {
  userOrgId: number,
  member: Member,
  transactions: Array<Transaction>,
  accounts: Array<Account>,
  events: Array<Event>,
  attendanceByEventId: Object,
  rowsPerPage: number,
  rowsPerPageOptions: Array<number>,
  currentPage: number,
  memberUpdating: boolean,
  accountsUpdating: boolean,
  transactionsUpdating: boolean,
  eventsUpdating: boolean,
  attendanceUpdating: boolean,
  membershipsUpdating: boolean,
  memberships: Array<Membership>
}

export class ViewMemberPage extends AbstractComponent<ViewMemberProps, ViewMemberState>
{
  public constructor(props: CreateMemberProps)
  {
    super(props);

    let member = null;
    let transactions = [];
    let userOrgId = props['params'].userOrgId;

    if (userOrgId)
    {
      member = MemberStore.getByUserOrgIdAndActiveOrganisation(userOrgId);
      transactions = TransactionStore.getAllByUserOrgId(userOrgId);
    }

    let accounts = AccountStore.getAllAccountsByActiveOrganisation();
    let events = EventStore.getAllEventsByActiveOrganisation();
    let attendanceByEventId = EventAttendanceStore.getAllAttendance();

    this.state = {
      member: member,
      userOrgId: userOrgId,
      transactions: transactions,
      accounts: accounts,
      events: events,
      attendanceByEventId: attendanceByEventId,
      rowsPerPage: 4,
      rowsPerPageOptions: [5, 10],
      currentPage: 1,
      memberUpdating: false,
      accountsUpdating: false,
      transactionsUpdating: false,
      eventsUpdating: false,
      attendanceUpdating: false,
      membershipsUpdating: false,
      memberships: MembershipStore.getAllMembershipsByActiveOrganisation()
    }
  }

  public componentDidMount()
  {
    // Redirect if you don't have member:read permissions
    if (!PermissionsStore.hasPermission('member:read'))
    {
      ToastMessageStore.addMessage(ToastMessage.create('error', "Sorry, you don't have access to view this page"));
      return Request.goBack(this);
    }
    
    /**
     * Subscribe to Member
     */
    
    this.unsubscribers['member_updating'] = MemberStore.subscribeToUpdating(
      (updating: boolean) => {
        this.setState({
          memberUpdating: updating
        })
      },
      this.state.memberUpdating
    )

    this.unsubscribers['member'] = MemberStore.subscribeToMemberByUserOrgId(
      this.state.userOrgId,
      (member: Member) => {
        this.setState({
          member: member
        })
      },
      this.state.member
    )
    
    /**
     * Subscribe to Memberships
     */
    
    this.unsubscribers['memberships_updating'] = MembershipStore.subscribeToUpdating(
      (updating: boolean) => {
        this.setState({
          membershipsUpdating: updating
        })
      },
      this.state.membershipsUpdating
    )
    
    this.unsubscribers['memberships'] = MembershipStore.subscribeToActiveOrgMemberships(
      (memberships: Array<Membership>) => {
        this.setState({
          memberships: memberships
        })
      },
      this.state.memberships
    )
    
    /**
     * Subscribe to Events
     */
    
    this.unsubscribers['events_updating'] = EventStore.subscribeToUpdating(
      (updating) => {
        this.setState({
          eventsUpdating: updating
        })
      },
      this.state.eventsUpdating
    )
    
    this.unsubscribers['events'] = EventStore.subscribeToActiveOrgEvents(
      (events: Array<Event>) => {
        this.setState({
          events: events
        })
      },
      this.state.events
    );
    
    /**
     * Subscribe to Event Attendance
     */
    
    this.unsubscribers['attendance_updating'] = EventAttendanceStore.subscribeToUpdating(
      (updating: boolean) => {
        this.setState({
          attendanceUpdating: updating
        })
      },
      this.state.attendanceUpdating
    )
    
    this.unsubscribers['attendance'] = EventAttendanceStore.subscribeToAllAttendance(
      (attendanceByEventId) => {
        this.setState({
          attendanceByEventId: attendanceByEventId
        })
      },
      this.state.attendanceByEventId
    );
    
    MemberStore.syncMember(this.state.userOrgId);
    // Sync events from API with redux store
    EventStore.syncActiveOrganisationEvents();
    
    if (!PermissionsStore.hasPermission('financial:read'))
    {
      return;
    }
    
    /**
     * Subscribe to Accounts
     */
    
    this.unsubscribers['accounts_updating'] = AccountStore.subscribeToUpdating(
      (updating: boolean) => {
        this.setState({
          accountsUpdating: updating
        })
      },
      this.state.accountsUpdating
    )
    
    this.unsubscribers['accounts'] = AccountStore.subscribeToActiveOrgAccounts(
      (accounts) =>
      {
        this.setState({
          accounts: accounts
        })
      },
      this.state.accounts
      );
    
    /**
     * Subscribe to Transactions
     */
    
    this.unsubscribers['transactions_updating'] = TransactionStore.subscribeToUpdating(
      (updating: boolean) => {
        this.setState({
          transactionsUpdating: updating
        })
      },
      this.state.transactionsUpdating
    )
    
    this.unsubscribers['transactions'] = TransactionStore.subscribeToTransactionsByUserOrgId(
      this.state.userOrgId,
      (transactions: Array<Transaction>) => {
        this.setState({
          transactions: transactions
        })
      },
      this.state.transactions
    )
    
    AccountStore.syncActiveOrganisationAccounts();
    TransactionStore.syncTransactionsByUserOrgId(this.state.userOrgId);
    MembershipStore.syncActiveOrganisationMemberships();
  }

  public componentWillUnmount()
  {
    for (let key in this.unsubscribers)
    {
      this.unsubscribers[key]();
    }
  }

  public render()
  {
    let member = this.state.member;
    let breadcrumbs = [
      {
        key: 'home',
        title: <span><i className="fal fa-home"/> Home</span>,
        href: '/'
      },
      {
        key: 'members',
        title: 'Members',
        href: '/members'
      },
      {
        key: 'view_member',
        title: member ? Member.getFullPreferredName(member) : '. . .'
      }
    ];

    let memberEvents = this.state.events.filter((event: Event, index: number) => {

      if ((new Date(event.finish)).valueOf() < (new Date()).valueOf())
      {
        return false;
      }

      let attendance = this.state.attendanceByEventId[event.id];

      if (!attendance || !attendance.length)
      {
        return false;
      }

      let matchingAttendance = attendance.filter((attendance: Attendance) => {
        return attendance.userOrgId == this.state.userOrgId;
      });

      return matchingAttendance.length;
    });

    memberEvents = this.applySort(memberEvents);
    let memberEventsCount = memberEvents.length;
    memberEvents = this.applyPagination(memberEvents);

    return <div className={'ViewMemberPage'}>

      <div className="grid-container">

        <PageBar
          title={<div><FontAwesomeIcon icon={faUser}/>{(!member && this.state.memberUpdating) ? 'Loading member . . .' : Member.getFullPreferredName(member, 'View Member')}</div>}
          rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}
        />

        <div className="grid-x grid-margin-x">
          <div className="cell large-4">
            {this.getPersonalDetailsPanel()}
          </div>

          <div className="cell large-8">

            <Panel
              headerLeft={<div className="Header">Events</div>}
              footer={memberEvents.length ? this.getTableControls(memberEventsCount) : null}
              modifiers={['FillVerticalSpace']}
            >
              { this.getEventCards(memberEvents) }
              {
                !memberEvents.length && !this.state.eventsUpdating
                  ? <CtaPlaceholder
                    icon={faCalendarAlt}
                    heading={'No Events Found'}
                    text={'No upcoming events for ' + Member.getPreferredFirstName(member)}
                    buttonContent={'View All Events'}
                    ctaUri={'/events'}
                  />
                  : null
              }
            </Panel>
          </div>

          <div className="cell">
            {this.getTransactionsTablePanel()}
          </div>
        </div>
      </div>
    </div>
  }
  
  private getPersonalDetailsPanel()
  {
    const member = this.state.member;
    const membership = this.state.memberships.find((membership: Membership) => {
      return Boolean(membership.id === member?.membershipId);
    })
    
    return <Panel
      headerLeft={<div className="Header">Personal Details</div>}
      headerRight={
        PermissionsStore.hasPermission('member:write')
          && member.status !== 'pending_removal'
          ? <Link to={'/members/' + (member ? member.userOrgId: '') + '/update'}>
            <FontAwesomeIcon icon={faEdit}/>
          </Link>
          : null
      }
    >
      
      <div className="grid-x">
        <div className="cell">
          <DisplayItem
            icon={<FontAwesomeIcon icon={faIdCard}/>}
            content={member ? Member.getFullPreferredName(member) : null}
            updating={this.state.memberUpdating}
          />
          <DisplayItem
            icon={<FontAwesomeIcon icon={faEnvelope}/>}
            content={ member ? member.emailAddress : null }
            updating={this.state.memberUpdating}
          />
          <DisplayItem
            icon={<FontAwesomeIcon icon={faMobile}/>}
            content={ member && member.mobileContactNumber ? member.mobileContactNumber : null }
            updating={this.state.memberUpdating}
          />
          <DisplayItem
            icon={<FontAwesomeIcon icon={faPhone}/>}
            content={ member && member.homeContactNumber ? member.homeContactNumber : null }
            updating={this.state.memberUpdating}
          />
          <DisplayItem
            icon={<FontAwesomeIcon icon={faHome}/>}
            content={
              
              member && member.address
                ? <div>
                  { member && member.address && member.address.addressLine1 ? <p>{member.address.addressLine1}</p> : null }
                  { member && member.address && member.address.addressLine2 ? <p>{member.address.addressLine2}</p> : null }
                  { member && member.address && member.address.town ? <p>{member.address.town}</p> : null }
                  { member && member.address && member.address.county ? <p>{member.address.county}</p> : null }
                  { member && member.address && member.address.postCode ? <p>{member.address.postCode}</p> : null }
                  { !member || !member.address ? '-' : '' }
                </div>
                : null
            }
            updating={this.state.memberUpdating}
          />
          <DisplayItem
            icon={<FontAwesomeIcon icon={faBirthdayCake}/>}
            content={ member && member.dateOfBirth ? this.getDateOfBirthDisplayValue(member.dateOfBirth) : null }
            updating={this.state.memberUpdating}
          />
          <DisplayItem
            icon={<FontAwesomeIcon icon={faAddressCard}/>}
            content={ membership ? membership.name : null }
            updating={this.state.membershipsUpdating}
          />
          <DisplayItem
            icon={<FontAwesomeIcon icon={member && member.status && member.status === 'active' ? solidFaCheckCircle : solidFaTimesCircle}/>}
            content={ member && member.status ? Strings.initialCaps(member.status.replace('_', ' ')) : null }
            updating={this.state.membershipsUpdating}
          />
        </div>
      </div>
    </Panel>
  }

  protected getTableControls(filteredDataLength: number)
  {
    return <PaginationControls
      start={this.getCurrentPageStartIndex(filteredDataLength) + 1}
      end={this.getCurrentPageEndIndex(filteredDataLength)}
      total={filteredDataLength}
      currentPage={this.state.currentPage}
      rowsPerPage={this.state.rowsPerPage}
      nextPage={this.nextPage.bind(this, filteredDataLength)}
      prevPage={this.prevPage.bind(this)}
      firstPage={this.firstPage.bind(this)}
      lastPage={this.lastPage.bind(this, filteredDataLength)}
      setRowsPerPage={this.setRowsPerPage.bind((this))}
      showRowsPerPage={false}
      modifiers={['NoBorder']}
    />
  }

  protected applyPagination(events: Array<Event>)
  {
    let startIndex = this.getCurrentPageStartIndex(events.length);
    let endIndex = this.getCurrentPageEndIndex(events.length);
    events = events.slice(startIndex, endIndex);

    return events;
  }

  protected applySort(events: Array<Event>)
  {
    return events.sort((a: Event, b: Event) => {
      return (new Date(a.start)).valueOf() - (new Date(b.start)).valueOf();
    });
  }

  protected getCurrentPageStartIndex(dataLength: number): number
  {
    let endIndex = this.getCurrentPageEndIndex(dataLength);
    return (endIndex - this.state.rowsPerPage);
  }

  protected getCurrentPageEndIndex(dataLength: number)
  {
    return this.state.currentPage * this.state.rowsPerPage;
  }

  protected getEventCards(events: Array<Event>)
  {
    if (!events)
    {
      return [];
    }

    if (!events.length && this.state.eventsUpdating)
    {
      const eventPlaceholders = [1,2,3];
      
      if (this.state.member && this.state.member.address)
      {
        eventPlaceholders.push(4);
      }

      return <div>
        {
          eventPlaceholders.map((eventPlaceholder: number, index: number) => {
            return <div
              className={[
                index == 0 ? 'mt-smo-2' : '',
                index == eventPlaceholders.length - 1 ? 'mb-smo-6' : ''
              ].join(' ')}
              key={index}
            >
              <EventCard
                index={index}
                name={null}
                startDate={null}
                invitedCount={false}
                goingCount={false}
                attendanceStatus={null}
                cta={null}
                updating={true}
                placeholder={true}
              />
              { index == eventPlaceholders.length - 1 ? '' : <div className="Hr mt-smo-4 mb-smo-4"/> }
            </div>
          })
        }
      </div>
    }

    return events.map((event: Event, index) =>
    {
      let attendance = this.state.attendanceByEventId[event.id] ? this.state.attendanceByEventId[event.id] : [];
      let memberAttendanceRow = attendance.filter((attendanceRow: Attendance) => {
        return attendanceRow.userOrgId == this.state.userOrgId;
      });
      if (memberAttendanceRow.length) memberAttendanceRow = memberAttendanceRow[0];
      let viewEventHref = '/events/' + event.id;

      return <div
        className={[
          index == 0 ? 'mt-smo-2' : '',
          index == events.length - 1 ? 'mb-smo-6' : ''
        ].join(' ')}
        key={index}
      >
        <EventCard
          index={index}
          name={event.name}
          startDate={new Date(event.start)}
          attendanceStatus={this.getStatusContent(memberAttendanceRow)}
          cta={<Link to={viewEventHref}>
            <Button modifiers={['primary']}>View Event</Button>
          </Link>}
        />
        { index == events.length - 1 ? '' : <div className="Hr mt-smo-4 mb-smo-4"/> }
      </div>
    });
  }

  protected getTransactionsTablePanel()
  {
    if (!PermissionsStore.hasPermission('financial:read'))
    {
      return null;
    }
    
    let member = this.state.member;
    
    // Sort them by date, earliest first (the reverse of what we need to display)
    let transactions = this.state.transactions.sort((a: Transaction, b: Transaction) => {
      if (a.date === b.date)
      {
        return a.id - b.id;
      }
      return (new Date(a.date)).valueOf() - (new Date(b.date)).valueOf();
    });
    
    // Use the reverse order to add cumulative total
    // After forEach, the order will have reversed to most recent first which is correct for displaying
    let balance = member?.broughtForwardBalance || 0;
    
    transactions.forEach((transaction: Transaction) => {
      if (transaction.debitAccountId)
      {
        balance += transaction.amount;
      }
      else
      {
        balance -= transaction.amount;
      }
      
      transaction.balance = balance;
    })
    
    return <Panel
      headerLeft={<div className="Header">Transactions</div>}
      headerRight={<div>
        Current Balance: {Money.getFormattedPrice(member ? MemberStore.getBalanceByUserOrgId(member.userOrgId): 0)}
      </div>}
    >
      <DataTable
        data={transactions}
        displayColumns={[
          DisplayColumn.create(
            'Date',
            null,
            (transaction: Transaction) => {
              return Dates.getShortDisplayValue(new Date(transaction.date))
            },
            1
          ),
          DisplayColumn.create(
            'Description',
            null,
            (transaction: Transaction) => {
              return transaction.description
            },
            2
          ),
          DisplayColumn.create(
            'Amount',
            null,
            (transaction: Transaction) => {
          
              let userBeingCredited = Boolean(transaction.debitAccountId);
              let prefix = userBeingCredited ? '+' : '-';
              
              return <div className={'display--width-100 flex--direction-column'}>
                <span className={'display--width-50 mb-2 mt-smo-1'}>{prefix + Money.getFormattedPrice(transaction.amount)}</span>
                <span className={'display--width-50 color--grey2 text--subtext'}>{transaction.balance >= 0 ? '+' : null}{Money.getFormattedPrice(transaction.balance)}</span>
              </div>
          
              return <div className={'display--width-100'}>
                <span className={'display--width-50'}>{prefix + Money.getFormattedPrice(transaction.amount)}</span>
                <span className={'display--width-50 color--grey2 text--subtext text--align-right-sm-only'}>{transaction.balance >= 0 ? '+' : null}{Money.getFormattedPrice(transaction.balance)}</span>
              </div>
            },
            1
          ),
          DisplayColumn.create(
            'Account',
            null,
            (transaction: Transaction) => {
          
              if (transaction.creditAccountId)
              {
                let matchingAccounts = this.state.accounts.filter((account: Account) => {
                  return account.id == transaction.creditAccountId;
                });
            
                let account: Account = matchingAccounts.length ? matchingAccounts[0] : null;
            
                if (account)
                {
                  return <div>
                    <Link className={'ml-1'} to={'/accounts/' + account.id}>
                      { account.name }
                    </Link>
                  </div>;
                }
              }
              else
              {
                let matchingAccounts = this.state.accounts.filter((account: Account) => {
                  return account.id == transaction.debitAccountId
                });
            
                let account: Account = matchingAccounts.length ? matchingAccounts[0] : null;
            
                if (account)
                {
                  return <div>
                    <Link className={'ml-1'} to={'/accounts/' + account.id}>
                      { account.name }
                    </Link>
                  </div>
                }
              }
          
              return 'Account no longer exists';
            },
            2
          )
        ]}
        searchable={false}
        sortable={false}
        noDataContent={
          <CtaPlaceholder
            icon={faExchangeAlt}
            heading={'No Transactions Found'}
            text={'No transactions associated with this member'}
            buttonContent={'Add Transaction'}
            ctaUri={'/transactions'}
          />
        }
        paginationControls={true}
        defaultRowsPerPage={5}
        rowsPerPageOptions={[5, 10, 25]}
        dataUpdating={this.state.transactionsUpdating}
      />
    </Panel>
  }

  protected getDateOfBirthDisplayValue(dateOfBirth: string): string
  {
    if (!dateOfBirth)
    {
      return '-';
    }

    let date = new Date(dateOfBirth);

    let monthNames = {
      0: 'January',
      1: 'February',
      2: 'March',
      3: 'April',
      4: 'May',
      5: 'June',
      6: 'July',
      7: 'August',
      8: 'September',
      9: 'October',
      10: 'November',
      11: 'December'
    };

    // 20 December 2019
    return date.getDate() + ' ' + monthNames[date.getMonth()] + ' ' + date.getFullYear() + ' (' + this.getAgeFromDate(date) + ' years old)'
  }

  protected getAgeFromDate(date: Date)
  {
    var ageDifMs = Date.now() - date.getTime();
    var ageDate = new Date(ageDifMs); // milliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  protected getStatusContent(attendance: Attendance)
  {
    if (!attendance)
    {
      return '';
    }

    switch (attendance.status)
    {
      case 'invited':
        return <div><FontAwesomeIcon icon={faEnvelopeOpenText} className={'mr-1'}/> Invited</div>;
      case 'going':
        return <div><FontAwesomeIcon icon={faCheckCircle} className={'mr-1'}/> Going</div>
    }
  }

  /**
   * Pagination Controls
   */

  protected prevPage()
  {
    if (this.state.currentPage > 1)
    {
      this.setState({
        currentPage: (this.state.currentPage - 1)
      })
    }
  }

  protected nextPage(filteredDataLength: number)
  {
    let totalPages = Math.ceil(filteredDataLength / this.state.rowsPerPage);
    if (this.state.currentPage < totalPages && this.state.currentPage !== totalPages)
    {
      this.setState({
        currentPage: (this.state.currentPage + 1)
      })
    }
  }

  protected firstPage()
  {
    this.setState({
      currentPage: 1
    })
  }

  protected lastPage(filteredDataLength: number)
  {
    this.setState({
      currentPage: Math.ceil(filteredDataLength / this.state.rowsPerPage)
    })
  }

  protected setRowsPerPage(rowsPerPage: number)
  {
    return this.setState({
      rowsPerPage: rowsPerPage,
      currentPage: 1
    })
  }
}
