
import * as React from 'react';
import {Link} from 'react-router-dom';
import { Header } from "appex-theme/src/Form/Input/Header/Header";
import {AbstractComponent} from "../../../../component/AbstractComponent";
import './scss/BookingPage.scss';
import {PageBar} from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import {Breadcrumbs} from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import {Panel} from "appex-theme/src/Core/Panel/Panel";
import {Form} from "appex-theme/src/Form/Form/Form";
import {Button} from "appex-theme/src/Core/Button/Button";
import {TextField} from "appex-theme/src/Form/Input/Text/TextField";
import {Validator} from "appex-theme/src/Form/Form/Validator/Validator";
import {InputGroupField} from "appex-theme/src/Form/Input/InputGroup/InputGroupField";
import {SelectField} from "appex-theme/src/Form/Input/Select/SelectField";
import {Event} from "../../../../data/Event/Entity/Event";
import {MemberStore} from "../../../../data/Member/MemberStore";
import {OrganisationStore} from "../../../../data/Organisation/OrganisationStore";
import {ToastMessageStore} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import {ToastMessage} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";
import {DataStoreError} from "ts-redux/src/DataStore/DataStoreError";
import {EventStore} from "../../../../data/Event/EventStore";
import {Attendance} from "../../../../data/Event/Entity/Attendance";
import {EventAttendanceStore} from "../../../../data/Event/EventAttendanceStore";
import {Ticket} from "../../../../data/Event/Entity/Ticket";
import {EventTicketStore} from "../../../../data/Event/EventTicketStore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/pro-light-svg-icons/faHome";
import {faCalendarAlt} from "@fortawesome/pro-solid-svg-icons/faCalendarAlt";
import {faClock} from "@fortawesome/pro-solid-svg-icons/faClock";
import {faTicketAlt} from "@fortawesome/pro-solid-svg-icons/faTicketAlt";
import {faUserFriends} from "@fortawesome/pro-solid-svg-icons/faUserFriends";
import {faTicketAlt as faDuoTicketAlt} from "@fortawesome/pro-duotone-svg-icons/faTicketAlt";
import {faUsers} from "@fortawesome/pro-duotone-svg-icons/faUsers";
import {EventGuestStore} from "../../../../data/Event/EventGuestStore";
import {Guest} from "../../../../data/Event/Entity/Guest";
import {StatsBlock} from "appex-theme/src/Data/StatsBlock/StatsBlock";
import {DisplayItem} from "appex-theme/src/Data/DisplayItem/DisplayItem";
import {Times} from "appex-theme/src/Utility/Times";
import {Dates} from "appex-theme/src/Utility/Dates";
import {Money} from "appex-theme/src/Utility/Money";
import {Request} from "appex-theme/src/Utility/Request";
import {CtaPlaceholder} from "appex-theme/src/Placeholder/Cta/CtaPlaceholder";
import {TextLoader} from "appex-theme/src/Loader/TextLoader";
import {Member} from "../../../../data/Member/Entity/Member";
import {faUser} from "@fortawesome/pro-solid-svg-icons/faUser";
import {faArrowRight} from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import { PermissionsStore } from "../../../../data/Permissions/PermissionsStore";

export interface BookingProps {}
export interface BookingState {
  event: Event,
  eventId: number,
  attendance: Array<Attendance>,
  guests: Array<Guest>,
  tickets: Array<Ticket>,
  members: Array<Member>,
  formSubmitting: boolean,
  eventUpdating: boolean,
  attendanceUpdating: boolean,
  guestsUpdating: boolean,
  ticketsUpdating: boolean,
  isUpdatePage: boolean,
  bookingUserOrgId: number,
  formValue: any,
  includeMemberSwitcher: boolean
}

export class BookingPage extends AbstractComponent<BookingProps, BookingState>
{
  protected unsubscribers = {};

  public constructor(props: BookingProps)
  {
    super(props);

    // Get event
    let event = null;

    let eventId = props['params'].eventId;
    let userOrgId = props['params'].userOrgId;

    let includeMemberSwitcher = Boolean(userOrgId);

    if (eventId)
    {
      event = EventStore.getByIdAndActiveOrganisation(eventId);
    }

    let bookingUserOrgId = userOrgId ? userOrgId : OrganisationStore.getActiveOrganisation().userOrgId;

    // Get event attendance and tickets
    let attendance = [];
    let tickets = [];
    let guests = [];
    let isUpdatePage = false;

    if (event)
    {
      attendance = EventAttendanceStore.getAllAttendanceByEventId(eventId);
      tickets = EventTicketStore.getAllTicketsByEventId(eventId);
      guests = EventGuestStore.getAllGuestsByEventId(eventId);

      let bookingUserAttendanceRows = attendance.filter((attendance: Attendance) => {
        return (attendance.userOrgId == bookingUserOrgId);
      });

      let userHasTicket = bookingUserAttendanceRows.length
        ? !!(bookingUserAttendanceRows['0'].ticketId)
        : false;

      let bookingUserGuests = guests.filter((guest: Guest) => {
        return (guest.userOrgId == bookingUserOrgId);
      });

      if (userHasTicket || bookingUserGuests.length)
      {
        isUpdatePage = true;
      }
    }

    let members = [];

    if (includeMemberSwitcher)
    {
      members = MemberStore.getAllMembersByActiveOrganisation();
    }


    this.state = {
      event: event,
      formSubmitting: false,
      eventUpdating: false,
      attendanceUpdating: false,
      ticketsUpdating: false,
      guestsUpdating: false,
      attendance: attendance,
      guests: guests,
      tickets: tickets,
      members: members,
      isUpdatePage: (!event || isUpdatePage),
      eventId: eventId,
      bookingUserOrgId: bookingUserOrgId,
      formValue: null,
      includeMemberSwitcher: includeMemberSwitcher
    }
  }

  public componentDidMount()
  {
    let userAttendance = this.getBookingUserAttendance();
    let organisation = OrganisationStore.getActiveOrganisation();
    
    if (
      (
        !userAttendance
        || this.state.bookingUserOrgId !== organisation.userOrgId
      )
      && !PermissionsStore.hasPermission('event:write')
    )
    {
      ToastMessageStore.addMessage(ToastMessage.create('error', "Sorry, you don't have access to view this page"));
      return Request.goBack(this);
    }
    
    let activeOrg = OrganisationStore.getActiveOrganisation();

    // If we're on the update page
    if (this.state.isUpdatePage)
    {
      /**
       * Subscribe to Event
       */
      
      this.unsubscribers['event_updating'] = EventStore.subscribeToUpdating(
        (updating: boolean) => {
          this.setState({
            eventUpdating: updating
          })
        },
        this.state.eventUpdating
      )
      
      this.unsubscribers['event'] = EventStore.subscribeToEventById(
        this.state.eventId,
        (event: Event) => {
          this.setState({
            event: event
          })
        },
        this.state.event
      );
      
      /**
       * Subscribe to Event Attendance
       */
      
      this.unsubscribers['attendance_updating'] = EventAttendanceStore.subscribeToUpdating(
        (updating: boolean) => {
          this.setState({
            attendanceUpdating: updating
          })
        },
        this.state.attendanceUpdating
      );
      
      this.unsubscribers['attendance'] = EventAttendanceStore.subscribeToAttendanceByEventId(
        this.state.eventId,
        (attendance: Array<Attendance>) => {
          this.setState({
            attendance: attendance
          })
        },
        this.state.attendance
      )
      
      /**
       * Subscribe to Tickets
       */
      
      this.unsubscribers['tickets_updating'] = EventTicketStore.subscribeToUpdating(
        (updating: boolean) => {
          this.setState({
            ticketsUpdating: updating
          })
        },
        this.state.ticketsUpdating
      )
      
      // Listen for tickets changes
      this.unsubscribers['tickets'] = EventTicketStore.subscribeToTicketsByEventId(
        this.state.eventId,
        (tickets: Array<Ticket>) => {
          this.setState({
            tickets: tickets
          })
        },
        this.state.tickets
      );
      
      /**
       * Subscribe to Guests
       */
      
      this.unsubscribers['guests_updating'] = EventGuestStore.subscribeToUpdating((updating: boolean) => {
        this.setState({
          guestsUpdating: updating
        })
      })
      
      this.unsubscribers['guests'] = EventGuestStore.subscribeToGuestsByEventId(
        this.state.eventId,
        (guests: Array<Guest>) => {
          this.setState({
            guests: guests
          })
        },
        this.state.guests
      )
      
      /**
       * Subscribe to Members
       */
      
      this.unsubscribers['members'] = MemberStore.subscribeToActiveOrgMembers(
        (members: Array<Member>) => {
          this.setState({
            members: members
          })
        },
        this.state.members
      )

      // Sync event, attendance & tickets
      EventStore.syncEvent(this.state.eventId);
      MemberStore.syncActiveOrganisationMembers();
    }
  }

  public componentWillUnmount()
  {
    if (Object.keys(this.unsubscribers).length)
    {
      for (let key in this.unsubscribers)
      {
        this.unsubscribers[key]();
      }
    }
  }

  public render()
  {
    let owner =  'Your';
    let ownerSuffix =  '';

    // If it's someone else's userOrg
    if (Number(this.state.bookingUserOrgId) !== OrganisationStore.getActiveOrganisation().userOrgId)
    {
      // Get their member
      let member = this.getMemberByUserOrgId(this.state.bookingUserOrgId);
      owner = Member.getPreferredFirstName(member);
      ownerSuffix = "'s";
    }

    let ownerAndSuffix = owner + ownerSuffix;

    let breadcrumbs = this.getBreadCrumbs(ownerAndSuffix);

    let submitButtonModifiers: Array<any> = [
      'primary',
      this.state.formSubmitting ? 'loading' : ''
    ];

    let pageBarTitle = <div><FontAwesomeIcon icon={faDuoTicketAlt}/> {ownerAndSuffix + ' Tickets'}</div>;

    if (this.state.event)
    {
      pageBarTitle = <div><FontAwesomeIcon icon={faDuoTicketAlt}/>{ownerAndSuffix + ' Tickets for ' + this.state.event.name}</div>
    }
    let totalTickets = this.getTotalTickets();
    let totalGuests = this.getTotalGuests();
    let bookingTotal = this.getBookingTotal();

    let event = this.state.event;
    let descriptionParagraphs: Array<any> = event && event.description ? event.description.split('\n') : [];

    if (!descriptionParagraphs.length && this.state.eventUpdating)
    {
      descriptionParagraphs = [
        <span>
          <span className={'mr-2'}><TextLoader width={100}/></span>
          <span className={'mr-2'}><TextLoader width={87}/></span>
          <span className={'mr-2'}><TextLoader width={110}/></span>
          <span className={'mr-2'}><TextLoader width={93}/></span>
          <span className={'mr-2'}><TextLoader width={140}/></span>
          <span className={'mr-2'}><TextLoader width={54}/></span>
          <span className={'mr-2'}><TextLoader width={62}/></span>
          <span className={'mr-2'}><TextLoader width={97}/></span>
          <span className={'mr-2'}><TextLoader width={127}/></span>
          <span className={'mr-2'}><TextLoader width={87}/></span>
        </span>,
        <span>
          <span className={'mr-2'}><TextLoader width={100}/></span>
          <span className={'mr-2'}><TextLoader width={87}/></span>
          <span className={'mr-2'}><TextLoader width={110}/></span>
          <span className={'mr-2'}><TextLoader width={93}/></span>
          <span className={'mr-2'}><TextLoader width={140}/></span>
          <span className={'mr-2'}><TextLoader width={54}/></span>
          <span className={'mr-2'}><TextLoader width={62}/></span>
          <span className={'mr-2'}><TextLoader width={97}/></span>
          <span className={'mr-2'}><TextLoader width={127}/></span>
          <span className={'mr-2'}><TextLoader width={87}/></span>
        </span>
      ];
    }
    
    const formElements = this.getFormElements(owner, ownerAndSuffix);
    
    formElements.push(
      <div className="cell" key={'booking_stats'}>
        <div>
          <div className="Hr Hr--Space-2"/>
          <StatsBlock stats={[
            {
              title: 'Total Tickets',
              value: totalTickets,
              gridClasses: 'medium-4',
            },
            {
              title: 'Total Guests',
              value: totalGuests,
              gridClasses: 'medium-4',
            },
            {
              title: 'Booking Total',
              value: Money.getFormattedPrice(bookingTotal),
              gridClasses: 'medium-4',
            },
          ]}/>
          <div className="Hr Hr--Space-2"/>
          <div className="mt-4 mb-4 text--align-right">
            <div>
              <Button type={'submit'} modifiers={submitButtonModifiers}>
                { this.state.isUpdatePage ? 'Update Booking' : 'Book Tickets' }
              </Button>
            </div>
          </div>
        </div>
      </div>
    )

    return <div className={'BookingPage'}>

      <div className="grid-container">
        <PageBar title={pageBarTitle} rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}/>

        <div className="grid-x grid-margin-x">
          {/* Left Column */}
          <div className="cell medium-5 large-4">

            {/* Event Details */}
            <Panel
              headerLeft={<div className="Header">Event Details</div>}
            >
              <div className="grid-x">
                <div className="cell">

                  <div
                    className={[ 'mt-2 mb-2' ].join(' ')}
                  >
                    <DisplayItem
                      icon={<FontAwesomeIcon icon={faCalendarAlt}/>}
                      content={Dates.getDisplayValue(event && event.start ? new Date(event.start) : null)}
                      updating={this.state.eventUpdating}
                    />
                    <DisplayItem
                      icon={<FontAwesomeIcon icon={faClock}/>}
                      content={Times.getDisplayValue(event && event.start ? new Date(event.start) : null)}
                      updating={this.state.eventUpdating}
                    />

                  </div>

                  <div className="Hr Hr--Space-3"/>

                  {
                    descriptionParagraphs.length
                      ? descriptionParagraphs.map((paragraph: string, index: number) => {
                        return <p
                          key={index}
                          className={[ (index == descriptionParagraphs.length - 1) ? '' : 'mb-4' ].join(' ')}
                        >
                          {paragraph}
                        </p>;
                      })
                      : '-'
                  }

                  <div className="Hr Hr--Space-3"/>

                  <p
                    className={[
                      'mb-2',
                    ].join(' ')}
                  >
                    {
                      this.state.eventUpdating
                      && !this.state.event
                        ? <TextLoader width={234}/>
                        : (
                          event
                            ? (
                                this.areBookingClosed(event)
                                  ? 'Tickets are no longer available for this event.'
                                  : <span>Get your tickets by <span className="text--semi-bold">{ Dates.getDisplayValue(event ? new Date(event.closing): null) }</span></span>
                              )
                            : '-'
                        )
                    }
                  </p>
                </div>
              </div>
            </Panel>


            {/* Tickets */}
            <Panel
              headerLeft={<div className="Header">Ticket Prices</div>}
              headerRight={
                PermissionsStore.hasPermission('event:write')
                ? <Link to={'/events/' + (event ? event.id : '') + '/update'}>
                    <i className="fas fa-edit"/>
                  </Link>
                : null
              }
            >
              <div className="grid-x">
                <div className="cell">

                  <div>
                    { this.state.tickets.length ? this.getTicketsPanelContent(this.state.tickets) : this.getNoTicketsContent() }
                  </div>

                </div>

              </div>
            </Panel>

            {/* Options */}
            {this.getAdminOptionsPanel(event)}
          </div>

          {/* Right Column */}

          <div className="cell medium-7 large-8">

            <Panel
              headerLeft={<div className="Header">{ownerAndSuffix + ' Booking'}</div>}
            >
              <Form
                name={'booking'}
                elements={formElements}
                onFormSubmit={this.onFormSubmit.bind(this)}
                onFormCapture={this.onFormCapture.bind(this)}
              />
            </Panel>
          </div>
        </div>

      </div>

    </div>
  }

  protected getAdminOptionsPanel(event: Event)
  {
    if (!PermissionsStore.hasPermission('event:write'))
    {
      return null;
    }
    
    let toggleText = 'Book tickets for another member';
    let toggleLink = '/events/' + (event ? event.id : '') + '/booking/' + this.state.bookingUserOrgId;

    if (this.state.includeMemberSwitcher)
    {
      toggleText = 'Book your own tickets';
      toggleLink = '/events/' + (event ? event.id : '') + '/booking';
    }

    return <Panel headerLeft={<div className="Header">More</div>}>
      <div className="grid-x">
        <div className="cell">

          <div>
            <Link className={'display--block'} to={toggleLink}>
              <div className={'StatsRow'}>
                <div className="StatsRow__Left">
                  {toggleText}
                </div>
                <div className="StatsRow__Right">
                  <FontAwesomeIcon icon={faArrowRight}/>
                </div>
              </div>
            </Link>
          </div>

        </div>

      </div>
    </Panel>
  }

  protected getBreadCrumbs(ownerAndSuffix)
  {
    if (
      this.state.isUpdatePage
      && this.state.event
    )
    {
      return [
        {
          key: 'home',
          title: <span><FontAwesomeIcon icon={faHome}/> Home</span>,
          href: '/'
        },
        {
          key: 'events',
          title: 'Events',
          href: '/events'
        },
        {
          key: 'update_event',
          title: this.state.event.name,
          href: '/events/' + this.state.event.id
        },
        {
          key: 'book_tickets',
          title: ownerAndSuffix + ' Tickets'
        },
      ];
    }

    return [
      {
        key: 'home',
        title: <span><FontAwesomeIcon icon={faHome}/> Home</span>,
        href: '/'
      },
      {
        key: 'events',
        title: 'Events',
        href: '/events'
      },
      {
        key: 'create_event',
        title: 'New Event'
      },
    ];
  }

  protected getFormElements(owner, ownerAndSuffix)
  {
    let tickets = this.state.tickets;
    let members = this.state.attendance.map((attendance: Attendance) => {
      return this.getMemberByUserOrgId(attendance.userOrgId);
    });
    let bookingUserAttendance = this.getBookingUserAttendance();
    let bookingUserGuests = this.state.guests.filter((guest: Guest) => {
      return (guest.userOrgId == this.state.bookingUserOrgId);
    });

    let displayText = 'I\'m not going';

    if (owner !== 'Your')
    {
      displayText = owner + " isn't going";
    }

    let ticketsAndNotGoingOption = []
      .concat(tickets)
      .concat([ { value: 'not_going', displayValue: displayText } ]);

    const memberHeading = <div className="cell" key={'member_heading'}>
      <Header text={'Member'} additionalClasses={['mt-5']} icon={faUser}/>
    </div>

    let memberInput = SelectField
      .create(
        'userOrgId',
        'Booking Tickets On Behalf Of',
        members,
        (member: Member) =>
        {
          return member.userOrgId;
        },
        (member: Member) =>
        {
          return Member.getFullPreferredName(member);
        },
        [ Validator.required() ],
        this.state.bookingUserOrgId,
        'single',
        true,
        Member.onSearch
      );

    let allFields = [
      // Your ticket
      <div className="cell" key={'your_ticket'}>
        <Header
          text={ownerAndSuffix + ' Ticket'}
          icon={faTicketAlt}
          additionalClasses={[ 'mt-5' ]}
        />
      </div>,
      SelectField.create(
        'memberTicketId',
        ownerAndSuffix + ' Ticket',
        ticketsAndNotGoingOption,
        (ticket: Ticket | { value: string, displayValue: string }) =>
        {
          if (ticket.hasOwnProperty('value') && ticket.hasOwnProperty('displayValue'))
          {
            return ticket['value']
          }

          return ticket['id'];
        },
        (ticket: Ticket | { value: string, displayValue: string }) =>
        {
          if (ticket.hasOwnProperty('value') && ticket.hasOwnProperty('displayValue'))
          {
            return ticket['displayValue']
          }

          return ticket['name'] + ' ' + '(' + Money.getFormattedPrice(ticket['price']) + ')';
        },
        [],
        bookingUserAttendance && bookingUserAttendance.eventTicketId
          ? bookingUserAttendance.eventTicketId
          : bookingUserAttendance && bookingUserAttendance.status == 'not_going' ? 'not_going' : null,
        'single',
        false
      ),
      <div className="cell" key={'guests_header'}>
        <Header
          text={'Guests'}
          additionalClasses={['mt-5']}
          icon={faUserFriends}
        />
      </div>,
      // Guest tickets Input Group
      InputGroupField
        .create(
          'guests',
          [
            // Guest name
            TextField
              .create(
              'name',
              'Guest Name',
              [ Validator.required() ],
              null
            )
              .setGridClass('cell medium-6'),
          SelectField
            .create(
              'eventTicketId',
              'Guest Ticket',
              tickets,
              (ticket: Ticket) =>
              {
                return ticket.id;
              },
              (ticket: Ticket) =>
              {
                return ticket.name + ' ' + '(' + Money.getFormattedPrice(ticket.price) + ')';
              },
              [Validator.required()],
              null,
              'single',
              false,
              MemberStore.onSearch
            )
            .setGridClass('cell medium-6')
        ],
        bookingUserGuests,
        [],
        true,
      )
    ];

    if (this.state.includeMemberSwitcher)
    {
      allFields = []
        .concat([
          memberHeading,
          memberInput
        ])
        .concat(allFields);
    }

    return allFields;
  }

  protected getMemberByUserOrgId(userOrgId: number)
  {
    let matches = this.state.members.filter((member: Member) => {
      return member.userOrgId == userOrgId;
    });

    if (matches.length)
    {
      return matches[0];
    }

    return null;
  }

  protected onFormSubmit(formState)
  {
    if (this.state.formSubmitting)
    {
      return;
    }

    if (formState.valid)
    {
      this.setState({
        eventUpdating: true,
        formSubmitting: true
      });

      let data = formState.value;
      data['eventId'] = this.state.event.id;
      data['userOrgId'] = this.state.bookingUserOrgId;

      return this.updateBooking(data);
    }
  }

  protected onFormCapture(formValue: any)
  {
    // If member input changed, change page url
    if (formValue.userOrgId && (this.state.bookingUserOrgId !== formValue.userOrgId))
    {
      return Request.redirect('/events/' + this.state.eventId + '/booking/' + formValue.userOrgId, this);
    }

    this.setState({
      formValue: formValue
    })
  }

  protected updateBooking(data: {
      eventId,
      userOrgId: number,
      memberTicketId: number | 'not_going',
      guests: Array<{ name: string, eventTicketId }>
    }
  )
  {
    // Send to redux
    let promise = EventAttendanceStore.updateBooking(data);

    promise.then((data: { attendance: Array<Attendance>, guests: Array<Guest> }) => {
      this.setState({
        eventUpdating: false,
        formSubmitting: false
      });

      ToastMessageStore.addMessage(ToastMessage.create('success', 'Booking updated'));

      let timeout = setTimeout(() => {
        Request.redirect("/events/" + this.state.eventId, this);
        clearTimeout(timeout);
      }, 0)
    });

    promise.catch((error: DataStoreError) => {
      this.setState({
        eventUpdating: false,
        formSubmitting: false
      });
    })
  }

  protected getBookingUserAttendance(): Attendance
  {
    let bookingUserOrgId = this.state.bookingUserOrgId;

    let matchingBookingUserAttendance = this.state.attendance.filter((attendance: Attendance) => {
      return (attendance.userOrgId == bookingUserOrgId);
    });

    return matchingBookingUserAttendance.length ? matchingBookingUserAttendance[0] : null;
  }

  protected getBookingUserGuests(): Array<Guest>
  {
    let bookingUserOrgId = this.state.bookingUserOrgId;

    return this.state.guests.filter((guest: Guest) => {
      return (guest.userOrgId == bookingUserOrgId);
    });
  }

  protected getBookingTotal(): number
  {
    let formValue = this.state.formValue;

    if (!formValue) return 0;

    let memberTicketId = formValue.memberTicketId;
    let guests = formValue.guests;

    let bookingTotal = 0;

    if (memberTicketId)
    {
      let matchingTickets = this.state.tickets.filter((ticket: Ticket) => {
        return (ticket.id == memberTicketId)
      });

      if (matchingTickets.length)
      {
        bookingTotal += matchingTickets[0].price;
      }
    }

    if (guests.length)
    {
      bookingTotal = bookingTotal += guests.reduce((accumulator: number, guest: Guest) => {

        let newValue = accumulator;

        let matchingTickets = this.state.tickets.filter((ticket: Ticket) => {
          return (ticket.id == guest.eventTicketId);
        });

        if (matchingTickets.length)
        {
          newValue = newValue += matchingTickets[0].price;
        }

        return newValue;
      }, 0)
    }

    return bookingTotal;
  }

  protected getTotalTickets(): number
  {
    let formValue = this.state.formValue;

    if (!formValue) return 0;

    let memberTicketId = formValue.memberTicketId;

    let totalTickets = 0;

    if (memberTicketId && memberTicketId !== 'not_going')
    {
      totalTickets++;
    }

    totalTickets = totalTickets += this.getTotalGuests();

    return totalTickets;
  }

  protected getTotalGuests(): number
  {
    let formValue = this.state.formValue;

    if (!formValue) return 0;

    let guests = formValue.guests;

    let totalTickets = 0;

    totalTickets += guests
      .filter((guest: Guest) => {
        return Boolean(guest.eventTicketId);
      })
      .length;


    return totalTickets;
  }

  protected areBookingClosed(event: Event)
  {
    if (!event) return true;
    return Boolean((new Date()).valueOf() > (new Date(event.closing)).valueOf());
  }

  protected getTicketsPanelContent(tickets: Array<Ticket>) {

    return <div>
      {
        tickets.map((ticket: Ticket, index) => {
          return <div className={'StatsRow'} key={index}>
            <div className="StatsRow__Left">
              { ticket.name }
            </div>
            <div className="StatsRow__Right">
              { Money.getFormattedPrice(ticket.price) }
            </div>
          </div>
        })
      }
    </div>
  }

  protected getNoTicketsContent()
  {
    if (this.state.ticketsUpdating && !this.state.tickets.length)
    {
      let placeholderTickets = [
        126, 145, 151, 127
      ];
      return <div>
        {
          placeholderTickets.map((ticket: number, index) => {
            return <div className={'StatsRow'} key={index}>
              <div className="StatsRow__Left">
                <TextLoader width={ticket}/>
              </div>
              <div className="StatsRow__Right">
                <TextLoader width={50}/>
              </div>
            </div>
          })
        }
      </div>
    }

    let event = this.state.event;
    let heading = 'No Tickets Found';
    let text = 'No tickets have been created for this event';
    let buttonContent = 'Create Tickets';
    let ctaUri = '/events/' + (event ? event.id : '') + '/update';

    // TODO: Switch out content depending on permission status

    return <div className="cell">
      <CtaPlaceholder
        icon={faUsers}
        heading={heading}
        text={text}
        buttonContent={buttonContent}
        ctaUri={ctaUri}
      />
    </div>;
  }

}
