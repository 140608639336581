
import * as React from 'react';
import {Link} from 'react-router-dom';
import {AbstractComponent} from "../../../../component/AbstractComponent";
import './scss/PayingInSlipPage.scss';
import {PageBar} from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import {Breadcrumbs} from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import {Panel} from "appex-theme/src/Core/Panel/Panel";
import {OrganisationStore} from "../../../../data/Organisation/OrganisationStore";
import {AccountStore} from "../../../../data/Account/AccountStore";
import {Account} from "../../../../data/Account/Entity/Account";
import {Transaction} from "../../../../data/Transaction/Entity/Transaction";
import {Member} from "../../../../data/Member/Entity/Member";
import {TransactionStore} from "../../../../data/Transaction/TransactionStore";
import {MemberStore} from "../../../../data/Member/MemberStore";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExchangeAlt} from "@fortawesome/pro-duotone-svg-icons/faExchangeAlt";
import {faMoneyCheckAlt as duoFaMoneyCheckAlt} from "@fortawesome/pro-duotone-svg-icons/faMoneyCheckAlt";
import {Money} from "appex-theme/src/Utility/Money";
import {DataTable} from "appex-theme/src/Data/DataTable/DataTable/DataTable";
import {DisplayColumn} from "appex-theme/src/Data/DataTable/DataTable/Entity/DisplayColumn";
import {Dates} from "appex-theme/src/Utility/Dates";
import {CtaPlaceholder} from "appex-theme/src/Placeholder/Cta/CtaPlaceholder";
import {StatsBlock} from "appex-theme/src/Data/StatsBlock/StatsBlock";

export interface PayingInSlipPageProps {}
export interface PayingInSlipPageState {
  reconcileReference: number,
  transactions: Array<Transaction>,
  accounts: Array<Account>,
  members: Array<Member>,
  accountUpdating: boolean,
  transactionsUpdating: boolean,
  membersUpdating: boolean,
}

export class PayingInSlipPage extends AbstractComponent<PayingInSlipPageProps, PayingInSlipPageState>
{
  protected unsubscribers = {};

  public constructor(props: PayingInSlipPageProps)
  {
    super(props);
    let reconcileReferenceTransactions = [];

    let reconcileReference = props['params'].reconcileReference;

    // Get account
    if (reconcileReference)
    {
      reconcileReferenceTransactions = TransactionStore.getAllByReconcileReference(reconcileReference);
    }

    let accounts = AccountStore.getAllAccountsByActiveOrganisation();
    let members = MemberStore.getAllMembersByActiveOrganisation();

    this.state = {
      reconcileReference: reconcileReference,
      transactions: reconcileReferenceTransactions,
      accounts: accounts,
      members: members,
      accountUpdating: false,
      transactionsUpdating: false,
      membersUpdating: false
    }
  }

  public componentDidMount()
  {
    /**
     * Subscribe to Accounts
     */
    
    this.unsubscribers['accounts_updating'] = AccountStore.subscribeToUpdating(
      (updating: boolean) => {
        this.setState({
          accountUpdating: updating
        })
      },
      this.state.accountUpdating
    )
    
    this.unsubscribers['accounts'] = AccountStore.subscribeToActiveOrgAccounts(
      (accounts: Array<Account>) => {
        this.setState({
          accounts: accounts
        })
      },
      this.state.accounts
    )
    
    /**
     * Subscribe to Members
     */
    
    this.unsubscribers['members_updating'] = MemberStore.subscribeToUpdating(
      (updating: boolean) => {
        this.setState({
          membersUpdating: updating
        })
      },
      this.state.membersUpdating
    )
    
    this.unsubscribers['members'] = MemberStore.subscribeToActiveOrgMembers(
      (members: Array<Member>) => {
        this.setState({
          members: members
        })
      },
      this.state.members
    )
    
    /**
     * Subscribe to Transactions
     */
    
    this.unsubscribers['transactions_updating'] = TransactionStore.subscribeToUpdating(
      (updating: boolean) => {
        this.setState({
          transactionsUpdating: updating
        })
      },
      this.state.transactionsUpdating
    )

    this.unsubscribers['transactions'] = TransactionStore.subscribeToTransactionsByReconcileReference(
      this.state.reconcileReference,
      (transactions: Array<Transaction>) => {
        this.setState({
          transactions: transactions
        })
      },
      this.state.transactions
    )
    
    AccountStore.syncActiveOrganisationAccounts();
    MemberStore.syncActiveOrganisationMembers();
    TransactionStore.syncTransactionsByReconcileReference(this.state.reconcileReference);
  }

  public componentWillUnmount()
  {
    if (this.unsubscribers)
    {
      for (let key in this.unsubscribers)
      {
        this.unsubscribers[key]();
      }
    }
  }

  public render()
  {
    let breadcrumbs = [
      {
        key: 'home',
        title: <span><i className="fal fa-home"/> Home</span>,
        href: '/'
      },
      {
        key: 'transactions',
        title: 'Transactions',
        href: '/transactions'
      },
      {
        key: 'paying_in_slip',
        title: this.state.reconcileReference
      }
    ];

    let transactionsToDisplay = this.getTransactionsToDisplay();

    return <div className={'PayingInSlipPage'}>

      <div className="grid-container">

        <PageBar
          title={<div><FontAwesomeIcon icon={duoFaMoneyCheckAlt}/>{'Paying In Slip: ' + this.state.reconcileReference}</div>}
          rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}
        />

        <div className="grid-x grid-margin-x">
          <div className="cell">
            <Panel
              headerLeft={<div className="Header">Transactions</div>}
            >
              { this.getTransactionsTable(transactionsToDisplay) }
              { this.getSummaryContainer(transactionsToDisplay) }
            </Panel>
          </div>
        </div>
      </div>
    </div>
  }

  protected getTransactionsTable(transactions: Array<Transaction>)
  {
    return <DataTable
      data={transactions}
      displayColumns={[
        DisplayColumn.create(
          'Date',
          null,
          (transaction: Transaction) => {
            return Dates.getShortDisplayValue(new Date(transaction.date))
          },
          1
        ),
        DisplayColumn.create(
          'Description',
          null,
          (transaction: Transaction) => {
            return transaction.description
          },
          2
        ),
        DisplayColumn.create(
          'Amount',
          null,
          (transaction: Transaction) => {
            return Money.getFormattedPrice(transaction.amount)
          },
          1
        ),
        DisplayColumn.create(
          'Type',
          null,
          (transaction: Transaction) => {

            let creditAccount = null;
            let debitAccount = null;
            let from: any = 'Unknown';
            let isCheque = false;

            if (transaction.creditAccountId)
            {
              let matches = this.state.accounts.filter((account: Account) => {
                return account.id == transaction.creditAccountId;
              });

              if (matches.length)
              {
                creditAccount = matches[0];
              }
            }
            if (transaction.debitAccountId)
            {
              let matches = this.state.accounts.filter((account: Account) => {
                return account.id == transaction.debitAccountId;
              });

              if (matches.length)
              {
                debitAccount = matches[0];
              }
            }

            // TODO: If it's a cheque
            if (
              creditAccount && creditAccount.type == 'cheques_issued'
              || debitAccount && debitAccount.type == 'cheques_held'
            )
            {
              isCheque = true;

            }

            if (transaction.userOrgId)
            {
              let member = MemberStore.getByUserOrgIdAndActiveOrganisation(transaction.userOrgId);
              from = <Link className={'ml-1'} to={'/members/' + transaction.userOrgId}>
                { Member.getFullPreferredName(member) }
              </Link>
            }
            else
            {
              let account = creditAccount ? creditAccount : debitAccount;

              from = <Link className={'ml-1'} to={'/accounts/' + account.id}>
                { account.name }
              </Link>
            }

            return <div>
              <div>
                {isCheque ? 'Cheque' : 'Cash'} from {from}
              </div>
            </div>
          },
          2
        ),
        DisplayColumn.create(
          'Reference',
          null,
          (transaction: Transaction) => {
            return transaction.reconcileReference;
          },
          1
        ),
      ]}
      searchable={false}
      sortable={false}
      noDataContent={this.getNoTransactionsContent()}
      paginationControls={false}
      dataUpdating={
        Boolean(
          this.state.accountUpdating
          || this.state.transactionsUpdating
        )
      }
    />
  }
  protected getTransactionsToDisplay(): Array<Transaction>
  {
    return this
      .state
      .transactions
      // Filter out any transactions where credit account is cheques held or paying in
      .filter((transaction: Transaction) => {

        let creditAccount = transaction.creditAccountId
          ? AccountStore.getByIdAndActiveOrganisation(transaction.creditAccountId)
          : null;

        let debitAccount = transaction.debitAccountId
          ? AccountStore.getByIdAndActiveOrganisation(transaction.debitAccountId)
          : null;

        // include if
        return (
          (debitAccount && debitAccount.type == 'cheques_held')
          || (creditAccount && creditAccount.type == 'cash')
        );
      })
      .sort((a: Transaction, b: Transaction) => {
        if (a.date === b.date)
        {
          return b.id - a.id;
        }
        return (new Date(b.date)).valueOf() - (new Date(a.date)).valueOf();
      });
  }

  protected getNoTransactionsContent()
  {
    let heading = 'No Transactions Found';
    let text = 'There are currently no transactions associated with this reference';
    let buttonContent = 'Add Transaction';
    let ctaUri = '/transactions';

    // TODO: Switch out content depending on permission status

    return <div className="cell">
      <CtaPlaceholder
        icon={faExchangeAlt}
        heading={heading}
        text={text}
        buttonContent={buttonContent}
        ctaUri={ctaUri}
      />
    </div>;
  }

  protected getDateOfBirthDisplayValue(dateOfBirth: string): string
  {
    if (!dateOfBirth)
    {
      return '-';
    }

    let date = new Date(dateOfBirth);

    let monthNames = {
      0: 'January',
      1: 'February',
      2: 'March',
      3: 'April',
      4: 'May',
      5: 'June',
      6: 'July',
      7: 'August',
      8: 'September',
      9: 'October',
      10: 'November',
      11: 'December'
    };

    // 20 December 2019
    return date.getDate() + ' ' + monthNames[date.getMonth()] + ' ' + date.getFullYear() + ' (' + this.getAgeFromDate(date) + ' years old)'
  }

  protected getAgeFromDate(date: Date)
  {
    var ageDifMs = Date.now() - date.getTime();
    var ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }

  protected getSummaryContainer(transactions: Array<Transaction>)
  {
    let chequesCount = 0;
    let chequesTotal = 0;
    let cashTotal = 0;

    transactions.forEach((transaction: Transaction) => {

      let creditAccount = null;
      let debitAccount = null;

      if (transaction.creditAccountId)
      {
        let matches = this.state.accounts.filter((account: Account) => {
          return account.id == transaction.creditAccountId;
        });

        if (matches.length)
        {
          creditAccount = matches[0];
        }
      }
      if (transaction.debitAccountId)
      {
        let matches = this.state.accounts.filter((account: Account) => {
          return account.id == transaction.debitAccountId;
        });

        if (matches.length)
        {
          debitAccount = matches[0];
        }
      }

      // TODO: If it's a cheque
      if (
        creditAccount && creditAccount.type == 'cheques_issued'
        || debitAccount && debitAccount.type == 'cheques_held'
      )
      {
        chequesCount++;
        chequesTotal += transaction.amount;
      }
      // TODO: If it's cash
      else
      {
        cashTotal += transaction.amount;
      }
    });

    return <div>
      <div className="Hr Hr--Space-2"/>
      <StatsBlock
        stats={[
          {
            title: 'No. of Cheques',
            value: chequesCount,
            gridClasses: 'medium-6 large-3'
          },
          {
            title: 'Cheques Total',
            value: Money.getFormattedPrice(chequesTotal),
            gridClasses: 'medium-6 large-3'
          },
          {
            title: 'Cash Total',
            value: Money.getFormattedPrice(cashTotal),
            gridClasses: 'medium-6 large-3'
          },
          {
            title: 'Total',
            value: Money.getFormattedPrice(cashTotal + chequesTotal),
            gridClasses: 'medium-6 large-3'
          },

        ]}
      />
      <div className="Hr Hr--Space-2"/>
    </div>;
  }
}
