import {Http} from "phusion/src/Core/Http/Http";
import {HttpResponse} from "phusion/src/Core/Http/HttpResponse";
import {HttpError} from "phusion/src/Core/Http/HttpError";
import {Objects} from "phusion/src/Core/Objects/Objects";
import {Redux} from "ts-redux/src/Redux";
import {Url} from "../../utility/Url";
import {Account} from "./Entity/Account";
import {OrganisationStore} from "../Organisation/OrganisationStore";
import {TransactionStore} from "../Transaction/TransactionStore";
import {Transaction} from "../Transaction/Entity/Transaction";
import { DataStore } from "ts-redux/src/DataStore/DataStore";

declare let Promise: any;

export class AccountStore extends DataStore
{
  public static readonly key: string = 'account';
  protected static readonly SET_UPDATING: string = 'set_account_updating';
  protected static readonly SET_ACCOUNTS_BY_ORGANISATION: string = 'set_accounts_by_organisation';
  
  public static reduce(
    state = {
      updating: false,
      accounts: {}
    },
    action: {
      type: string,
      data: any
    }
  )
  {
    switch (action.type)
    {
      case AccountStore.SET_UPDATING:
        return Object.assign({}, state, {
          updating: action.data
        });
      case AccountStore.SET_ACCOUNTS_BY_ORGANISATION:

        let accounts = state['accounts']
          ? state['accounts']
          : {};

        let accountsByOrgId = Objects.clone(accounts);
        accountsByOrgId[action.data.organisationId] = action.data.accounts;

        return Object.assign({}, state, {
          accounts: accountsByOrgId
        });
      default:
        return state;
    }
  }
  
  /**
   * Getters: Balances
   */
  
  public static getAllBalancesById()
  {
    const accounts = this.getAllAccountsByActiveOrganisation();
    
    const balances = {};
    
    for (let key in accounts)
      if (accounts.hasOwnProperty(key))
      {
        const account = accounts[key];
        const accountId = account.id;
        let isAssetAccount = account.category == 'asset';
  
        let accountTransactions = TransactionStore.getAllByAccountId(accountId);
  
        let balance = isAssetAccount
          ? account.broughtForwardBalance * -100
          : account.broughtForwardBalance * 100;
  
        accountTransactions.forEach((transaction: Transaction) => {
    
          if (isAssetAccount)
          {
            if (transaction.debitAccountId == accountId)
            {
              balance += transaction.amount * 100;
            }
            else if (transaction.creditAccountId == accountId)
            {
              balance -= transaction.amount * 100;
            }
          }
          else
          {
            if (transaction.debitAccountId == accountId)
            {
              balance -= transaction.amount * 100;
            }
            else if (transaction.creditAccountId == accountId)
            {
              balance += transaction.amount * 100;
            }
          }
    
        });
  
        balances[accountId] = parseFloat((balance / 100).toFixed(2));
      }
    
    return balances;
  }

  public static getBalanceByAccountId(accountId: number): number
  {
    let account = AccountStore.getByIdAndActiveOrganisation(accountId);

    if (!account)
    {
      return 0;
    }

    let isAssetAccount = account.category == 'asset';

    let accountTransactions = TransactionStore.getAllByAccountId(accountId);

    let balance = isAssetAccount
      ? account.broughtForwardBalance * -100
      : account.broughtForwardBalance * 100;

    accountTransactions.forEach((transaction: Transaction) => {

      if (isAssetAccount)
      {
        if (transaction.debitAccountId === accountId)
        {
          balance += transaction.amount * 100;
        }
        else if (transaction.creditAccountId === accountId)
        {
          balance -= transaction.amount * 100;
        }
      }
      else
      {
        if (transaction.debitAccountId === accountId)
        {
          balance -= transaction.amount * 100;
        }
        else if (transaction.creditAccountId === accountId)
        {
          balance += transaction.amount * 100;
        }
      }

    });

    return parseFloat((balance / 100).toFixed(2));
  }

  /**
   * Getters: Accounts
   */

  public static getByIdAndActiveOrganisation(id: number): Account
  {
    let activeOrganisation = OrganisationStore.getActiveOrganisation();
    let allAccounts = this.getAccountsByOrganisationId(activeOrganisation.id);
  
    if (!allAccounts)
    {
      return null;
    }
  
    let matchingAccounts = allAccounts.filter((account: Account) => {
      return account.id == id;
    });
  
    if (matchingAccounts.length)
    {
      return matchingAccounts[0];
    }
  
    return null;
  }
  
  public static getAllAccountsByActiveOrganisation(): Array<Account>
  {
    let activeOrganisation = OrganisationStore.getActiveOrganisation();
    return this.getAccountsByOrganisationId(activeOrganisation.id);
  }

  protected static getAccountsByOrganisationId(organisationId: number): Array<Account>
  {
    let accounts = Objects.getByKeyPath('account:accounts:' + organisationId, Redux.getState());

    if (accounts)
    {
      return accounts;
    }

    return [];
  }
  
  /**
   * Sync: Accounts
   */

  public static syncActiveOrganisationAccounts(): Promise<Array<Account>>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      AccountStore.setUpdating(true);
      TransactionStore.setUpdating(true);

      let promise = Http.get(Url.api('/organisation/' + activeOrg.id + '/accounts'));

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let accounts = Objects.getByKeyPath('accounts', responseBody);

        let accountEntities = null;

        // Add accounts to redux
        if (accounts)
        {
          accountEntities = [];

          for (let key in accounts)
            if (accounts.hasOwnProperty(key))
          {
            accountEntities.push(Account.create(accounts[key]));
          }

          AccountStore.setAccountsByOrganisation(activeOrg.id, accountEntities);
        }

        let transactionsByAccountId = Objects.getByKeyPath('transactionsByAccountId', responseBody);
        let transactionEntitiesByAccountId = {};

        // Add account transactions to redux
        if (transactionsByAccountId)
        {
          for (let accountId in transactionsByAccountId)
          if (transactionsByAccountId.hasOwnProperty(accountId))
          {
            let accountTransactions = transactionsByAccountId[accountId] ? transactionsByAccountId[accountId] : [];
            
            transactionEntitiesByAccountId[parseInt(accountId)] = accountTransactions.map((transaction) => {
              return Transaction.create(transaction);
            });
          }
          
          TransactionStore.setAllTransactionsByAccountId(transactionEntitiesByAccountId);
        }

        let transactionsByUserOrgId = Objects.getByKeyPath('transactionsByUserOrgId', responseBody);
        let transactionEntitiesByUserOrgId = {};

        // Add user transactions to redux
        if (transactionsByUserOrgId)
        {
          for (let userOrgId in transactionsByUserOrgId)
          if (transactionsByUserOrgId.hasOwnProperty(userOrgId))
          {
            let memberTransactions = transactionsByUserOrgId[userOrgId] ? transactionsByUserOrgId[userOrgId] : [];
            
            transactionEntitiesByUserOrgId[parseInt(userOrgId)] = memberTransactions.map((transaction) => {
              return Transaction.create(transaction);
            });
          }
          
          TransactionStore.setAllTransactionsByUserOrgId(transactionEntitiesByUserOrgId);
        }

        let transactionsByReconcileReference = Objects.getByKeyPath('transactionsByReconcileReference', responseBody);
        let transactionEntitiesByReconcileReference = {};

        // Add user transactions to redux
        if (transactionsByReconcileReference)
        {
          for (let reconcileReference in transactionsByReconcileReference)
          if (transactionsByReconcileReference.hasOwnProperty(reconcileReference))
          {
            let reconcileReferenceTransactions = transactionsByReconcileReference[reconcileReference] ? transactionsByReconcileReference[reconcileReference] : [];
            
            transactionEntitiesByReconcileReference[parseInt(reconcileReference)] = reconcileReferenceTransactions.map((transaction) => {
              return Transaction.create(transaction);
            });
          }
          
          TransactionStore.setAllTransactionsByReconcileReference(transactionEntitiesByReconcileReference);
        }

        AccountStore.setUpdating(false);
        TransactionStore.setUpdating(false);

        if (accountEntities)
        {
          return resolve(accountEntities);
        }

        return reject(AccountStore.createError(response));
      });

      promise.catch((httpError: HttpError) => {
        AccountStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  public static syncAccount(accountId: number): Promise<Account>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      AccountStore.setUpdating(true);

      let promise = Http.get(Url.api('/organisation/' + activeOrg.id + '/account/' + accountId));

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let account = Objects.getByKeyPath('account', responseBody);

        if (account)
        {
          account = Account.create(account);

          this.spliceAccountIntoOrgAccounts(activeOrg.id, account);
          AccountStore.setUpdating(false);

          return resolve(account);
        }

        AccountStore.setUpdating(false);
        return reject(AccountStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        AccountStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  protected static spliceAccountIntoOrgAccounts(organisationId: number, account: Account): Array<Account>
  {
    let allOrgAccounts = this.getAccountsByOrganisationId(organisationId);

    let existingKey = null;

    for (let key in allOrgAccounts)
      if (allOrgAccounts.hasOwnProperty(key))
    {
      let existingAccount = allOrgAccounts[key];

      if (existingAccount.id == account.id)
      {
        existingKey = Number(key);
        break;
      }
    }

    if (typeof existingKey == 'number')
    {
      allOrgAccounts.splice(existingKey, 1, account);
    }
    else
    {
      allOrgAccounts.push(account);
    }

    this.setAccountsByOrganisation(organisationId, allOrgAccounts);

    return allOrgAccounts;
  }
  
  /**
   * Create/Update/Delete: Accounts
   */
  
  public static createNewAccount(account: Account): Promise<Account>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      AccountStore.setUpdating(true);

      let promise = Http.post(Url.api('/organisation/' + activeOrg.id + '/account'), account);

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let account = Objects.getByKeyPath('account', responseBody);

        if (account)
        {
          account = Account.create(account);

          this.spliceAccountIntoOrgAccounts(activeOrg.id, account);
          AccountStore.setUpdating(false);

          return resolve(account);
        }

        AccountStore.setUpdating(false);
        return reject(AccountStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        AccountStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  public static updateAccount(account: Account): Promise<Account>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      AccountStore.setUpdating(true);

      let promise = Http.put(Url.api('/organisation/' + activeOrg.id + '/account/' + account.id), account);

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let account = Objects.getByKeyPath('account', responseBody);

        if (account)
        {
          account = Account.create(account);

          this.spliceAccountIntoOrgAccounts(activeOrg.id, account);

          AccountStore.setUpdating(false);

          return resolve(account);
        }

        AccountStore.setUpdating(false);
        return reject(AccountStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        AccountStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  public static deleteAccount(accountId: number): Promise<Array<number>>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      AccountStore.setUpdating(true);

      let promise = Http.put(Url.api('/organisation/' + activeOrg.id + '/account/' + accountId + '/delete'), accountId);

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let deletedAccountIds = Objects.getByKeyPath('deletedAccountIds', responseBody);

        if (deletedAccountIds && deletedAccountIds.length)
        {
          // Remove these accounts from the organisations account list
          let accounts = this.getAllAccountsByActiveOrganisation();

          let remainingAccounts = accounts.filter((account: Account) => {
            return Boolean(deletedAccountIds.indexOf(account.id) === -1)
          });

          this.setAccountsByOrganisation(OrganisationStore.getActiveOrganisation().id, remainingAccounts);

          // Remove all transactions associated with these accounts
          deletedAccountIds.forEach((deletedAccountId: number) => {
            TransactionStore.clearAllTransactionsByAccountId(deletedAccountId);
          });

          AccountStore.setUpdating(false);

          return resolve(deletedAccountIds);
        }

        AccountStore.setUpdating(false);
        return reject(AccountStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        AccountStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }
  
  /**
   * Redux
   */

  protected static setUpdating(updating: boolean)
  {
    Redux.dispatch({
      type: AccountStore.SET_UPDATING,
      data: updating
    });
  }

  public static setAccountsByOrganisation(organisationId: number, accounts: Array<Account>)
  {
    Redux.dispatch({
      type: AccountStore.SET_ACCOUNTS_BY_ORGANISATION,
      data: {
        organisationId: organisationId,
        accounts: accounts
      }
    });
  }

  /**
   * Subscribers
   */
  
  public static subscribeToAllAccountBalances(callback: (allBalancesById: Object) => void, currentValue: Object)
  {
    return this.subscribe((state) => {
      const newValue = this.getAllBalancesById();
      
      if (JSON.stringify(currentValue) !== JSON.stringify(newValue))
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }
  
  public static subscribeToAccountById(id: number, callback: (account: Account) => void, currentValue?: Account)
  {
    return this.subscribe((state) => {
      let newValue = this.getByIdAndActiveOrganisation(id);
      
      if (JSON.stringify(currentValue) !== JSON.stringify(newValue))
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }

  public static subscribeToActiveOrgAccounts(callback: (accounts: Array<Account>) => void, currentValue?: Array<Account>)
  {
    return this.subscribe((state) => {
      let newValue = this.getAllAccountsByActiveOrganisation();
      
      if (JSON.stringify(currentValue) !== JSON.stringify(newValue))
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }

  public static subscribeToUpdating(callback: (updating: boolean) => void, currentValue?: boolean)
  {
    return this.subscribe((state) => {
      let newValue = state.updating;
      
      if (currentValue !== newValue)
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }

  public static onSearch(searchValue: string, account: Account)
  {
    let lowerCaseSearchValue = AccountStore.strip(searchValue);

    return (
      searchValue == ''
      || AccountStore.strip(account.name).indexOf(lowerCaseSearchValue) !== -1
      || AccountStore.strip(account.category).indexOf(lowerCaseSearchValue) !== -1
      || AccountStore.strip(account.type).indexOf(lowerCaseSearchValue) !== -1
    )
  }

  protected static strip(string: string)
  {
    if (!string)
    {
      return '';
    }

    return string.toLowerCase().replace(' ', '');
  }

}
