import {DataStore} from "ts-redux/src/DataStore/DataStore";
import {Objects} from "phusion/src/Core/Objects/Objects";
import {Redux} from "ts-redux/src/Redux";
import {Guest} from "./Entity/Guest";

export class EventGuestStore extends DataStore
{
  public static readonly key: string = 'event_guest';
  protected static readonly SET_UPDATING: string = 'set_event_guest_updating';
  protected static readonly SET_GUESTS_BY_EVENT: string = 'set_guests_by_event';

  public static reduce(
    state = {
      updating: false,
      guests: {}
    },
    action: {
      type: string,
      data: any
    }
  )
  {
    switch (action.type)
    {
      case EventGuestStore.SET_UPDATING:
        return Object.assign({}, state, {
          updating: action.data
        });
      case EventGuestStore.SET_GUESTS_BY_EVENT:

        let guests = state['guests']
          ? state['guests']
          : {};

        let guestsByEventId = Objects.clone(guests);
        guestsByEventId[action.data.eventId] = action.data.guests;

        return Object.assign({}, state, {
          guests: guestsByEventId
        });
      default:
        return state;
    }
  }

  public static getAllGuests(): any
  {
    let guests = Objects.getByKeyPath('event_guest:guests', Redux.getState());

    if (guests)
    {
      return guests;
    }

    return {};
  }

  public static getAllGuestsByEventId(eventId: number): Array<Guest>
  {
    let guests = Objects.getByKeyPath('event_guest:guests:' + eventId, Redux.getState());

    if (guests)
    {
      return guests;
    }

    return [];
  }

  protected static setUpdating(updating: boolean)
  {
    Redux.dispatch({
      type: EventGuestStore.SET_UPDATING,
      data: updating
    });
  }

  public static setGuestsByEventId(eventId: number, guests: Array<Guest>)
  {
    Redux.dispatch({
      type: EventGuestStore.SET_GUESTS_BY_EVENT,
      data: {
        eventId: eventId,
        guests: guests
      }
    });
  }
  
  public static subscribeToUpdating(callback: (updating: boolean) => void, currentValue?: boolean)
  {
    return this.subscribe((state) => {
      let newValue = state.updating;
      
      if (currentValue !== newValue)
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }
  
  public static subscribeToGuestsByEventId(
    eventId,
    callback: (tickets: Array<Guest>) => void,
    currentValue?: Array<Guest>
  )
  {
    return this.subscribe((state) => {
      let newValue = this.getAllGuestsByEventId(eventId);
      
      if (JSON.stringify(currentValue) !== JSON.stringify(newValue))
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }

}
