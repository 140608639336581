import {Objects} from "phusion/src/Core/Objects/Objects";

export class Guest
{
  public id: number;
  public eventId: number;
  public userOrgId: number;
  public name: string;
  public eventTicketId: number;
  public created: string;
  public updated: string;

  public static create(data: Object = null): Guest
  {
    if (!data)
    {
      return new this();
    }

    let mutators = {};

    return Objects.hydrate(new Guest(), data, mutators) as Guest;
  }

  public serialize(): Object
  {
    let mutators = {
      // dateOfBirth: (dateOfBirth: Moment.Moment) => dateOfBirth.valueOf(),
      // created: (created: Moment.Moment) => created.valueOf(),
      // updated: (updated: Moment.Moment) => updated.valueOf()
    };

    return Objects.hydrate({}, this, mutators);
  }
}
