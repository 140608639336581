
import * as React from 'react';
import { Header } from "appex-theme/src/Form/Input/Header/Header";
import { Numbers } from "appex-theme/src/Utility/Numbers";
import {AbstractComponent} from "../../../../component/AbstractComponent";
import {PageBar} from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import {Breadcrumbs} from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import {Form} from "appex-theme/src/Form/Form/Form";
import {TextField} from "appex-theme/src/Form/Input/Text/TextField";
import {Validator} from "appex-theme/src/Form/Form/Validator/Validator";
import {Button} from "appex-theme/src/Core/Button/Button";
import {Panel} from "appex-theme/src/Core/Panel/Panel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SelectField} from "appex-theme/src/Form/Input/Select/SelectField";
import {Account} from "../../../../data/Account/Entity/Account";
import {DateField} from "appex-theme/src/Form/Input/Date/DateField";
import {AccountStore} from "../../../../data/Account/AccountStore";
import {ToastMessageStore} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import {ToastMessage} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";
import {MemberStore} from "../../../../data/Member/MemberStore";
import {Member} from "../../../../data/Member/Entity/Member";
import './scss/ProcessPayingInSlipPage.scss';
import {InputGroupField} from "appex-theme/src/Form/Input/InputGroup/InputGroupField";
import {faHome} from "@fortawesome/pro-light-svg-icons/faHome";
import {faExchangeAlt} from "@fortawesome/pro-duotone-svg-icons/faExchangeAlt";
import {faMoneyBillAlt} from "@fortawesome/pro-solid-svg-icons/faMoneyBillAlt";
import {faMoneyCheck} from "@fortawesome/pro-solid-svg-icons/faMoneyCheck";
import {faMoneyCheckAlt} from "@fortawesome/pro-solid-svg-icons/faMoneyCheckAlt";
import { PermissionsStore } from "../../../../data/Permissions/PermissionsStore";
import {TransactionStore} from "../../../../data/Transaction/TransactionStore";
import {Transaction} from "../../../../data/Transaction/Entity/Transaction";
import {DataStoreError} from "ts-redux/src/DataStore/DataStoreError";
import {StatsBlock} from "appex-theme/src/Data/StatsBlock/StatsBlock";
import {Money} from "appex-theme/src/Utility/Money";
import {Request} from "appex-theme/src/Utility/Request";

export interface ProcessPayingInSlipProps {}

export interface ProcessPayingInSlipState {
  formSubmitting: boolean,
  accounts: Array<Account>
  members: Array<Member>,
  chequesHeldAccountId: number,
  chequesHeldTransactions: Array<Transaction>,
  dataUpdating: boolean,
  formValue: any
}

export class ProcessPayingInSlipPage extends AbstractComponent<ProcessPayingInSlipProps, ProcessPayingInSlipState>
{
  public constructor(props: ProcessPayingInSlipProps)
  {
    super(props);

    let accounts = AccountStore.getAllAccountsByActiveOrganisation();
    let members = MemberStore.getAllMembersByActiveOrganisation();
    let chequesHeldTransactions = [];
    let chequesHeldAccountId = null;
    
    let chequesHeldAccount = accounts.find((account: Account) => {
      return (account.type == 'cheques_held');
    });

    if (chequesHeldAccount)
    {
      chequesHeldAccountId = chequesHeldAccount.id;
      chequesHeldTransactions = TransactionStore.getAllByAccountId(chequesHeldAccount.id);
    }
    else
    {
      console.error('No cheques held account found. Cannot get cheques to pay in.');
    }

    this.state = {
      formSubmitting: false,
      accounts: accounts,
      members: members,
      chequesHeldAccountId: chequesHeldAccountId,
      chequesHeldTransactions: chequesHeldTransactions,
      dataUpdating: false,
      formValue: null
    }
  }

  public componentDidMount(): void
  {
    if (!PermissionsStore.hasPermission('financial:write'))
    {
      ToastMessageStore.addMessage(ToastMessage.create('error', "Sorry, you don't have access to view this page"));
      return Request.goBack(this);
    }
    
    
    /**
     * Subscribe to Accounts
     */
    
    this.unsubscribers['accounts'] = AccountStore.subscribeToActiveOrgAccounts((accounts) => {

      if (!this.state.chequesHeldAccountId)
      {
        let chequesHeldAccount = accounts.find((account: Account) => {
          return (account.type == 'cheques_held');
        });
        
        this.setState({
          accounts: accounts,
          chequesHeldAccountId: chequesHeldAccount ? chequesHeldAccount.id : undefined
        });
  
        if (chequesHeldAccount)
        {
          this.syncTransactions(chequesHeldAccount.id);
        }
      }
    });
    
    
    /**
     * Subscribe to Transactions
     */
    
    this.unsubscribers['transactions'] = TransactionStore.subscribeToTransactionsByAccountId(
      this.state.chequesHeldAccountId,
      (transactions: Array<Transaction>) => {
        this.setState({
          chequesHeldTransactions: transactions
        })
      },
      this.state.chequesHeldTransactions
    )
    
    
    /**
     * Subscribe to Members
     */

    this.unsubscribers['members'] = MemberStore.subscribeToActiveOrgMembers(
      (members: Array<Member>) => {
        this.setState({
          members: members
        })
      },
      this.state.members
    )
    
    // Sync Accounts
    AccountStore.syncActiveOrganisationAccounts();
    MemberStore.syncActiveOrganisationMembers();

    if (this.state.chequesHeldAccountId)
    {
      this.syncTransactions(this.state.chequesHeldAccountId);
    }
  }

  private syncTransactions(accountId: number)
  {
    // Sync Cheques Held Transactions
    TransactionStore.syncTransactionsByAccountId(accountId);
  }

  public render()
  {
    let breadcrumbs = this.getBreadCrumbs();

    let submitButtonModifiers: Array<any> = [
      'primary',
      this.state.formSubmitting ? 'loading' : ''
    ];
    
    const formElements = this.getFormElements();
    
    formElements.push(
      <div className="cell" key={'submit'}>
        <div>
          {this.getSummaryContainer()}
          <div className="mt-4 mb-4 text--align-right">
            <Button type={'submit'} modifiers={submitButtonModifiers}>
              { 'Save Transaction' }
            </Button>
          </div>
        </div>
      </div>
    )

    return <div className={'ProcessPayingInSlipPage'}>

      <div className="grid-container">
        <PageBar
          title={<div><FontAwesomeIcon icon={faExchangeAlt}/>Process A Paying In Slip</div>}
          rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}
        />
        <Panel>
          <Form
            name={'createAccount'}
            elements={formElements}
            onFormSubmit={this.onFormSubmit.bind(this)}
            onFormCapture={this.onFormCapture.bind(this)}
          />
        </Panel>
      </div>

    </div>
  }

  protected getSummaryContainer()
  {
    let chequesCount = this.state.formValue ? this.state.formValue.cheques.length : 0;
    let chequesTotal = 0;

    if (this.state.formValue && this.state.formValue.cheques)
    {
      let selectedCheques = this.state.chequesHeldTransactions.filter((transaction: Transaction) => {
        return (this.state.formValue.cheques.indexOf(transaction.id) !== -1);
      });

      selectedCheques.forEach((transaction: Transaction) => {
        chequesTotal += transaction.amount;
      });
    }

    let cashTotal = 0;

    if (this.state.formValue && this.state.formValue.cash)
    {
      this.state.formValue.cash.forEach((cash: { cashAccountId: number, amount: any }) => {

        let cashAmount = parseFloat(cash.amount);

        if (cashAmount)
        {
          cashTotal+= cashAmount;
        }
      });
    }

    return <div>
      <div className="Hr Hr--Space-2"/>
      <StatsBlock
        stats={[
          {
            title: 'No. of Cheques',
            value: chequesCount,
            gridClasses: 'medium-6 large-3'
          },
          {
            title: 'Cheques Total',
            value: Money.getFormattedPrice(chequesTotal),
            gridClasses: 'medium-6 large-3'
          },
          {
            title: 'Cash Total',
            value: Money.getFormattedPrice(cashTotal),
            gridClasses: 'medium-6 large-3'
          },
          {
            title: 'Total',
            value: Money.getFormattedPrice(cashTotal + chequesTotal),
            gridClasses: 'medium-6 large-3'
          },

        ]}
      />
      <div className="Hr Hr--Space-2"/>
    </div>;
  }

  protected getFormElements()
  {
    let accounts = this.state.accounts;
    let bankAccounts = accounts.filter((account: Account) => {
      return account.type == 'bank';
    });
    let cashAccounts = accounts.filter((account: Account) => {
      return account.type == 'cash';
    });

    let chequeTransactions = this.state.chequesHeldTransactions.filter((transaction: Transaction) => {
      return !transaction.reconcileReference && transaction.debitAccountId == this.state.chequesHeldAccountId;
    });

    return [
      <div className="cell" key={'details'}>
        <Header
          text={'Paying In Slip Details'}
          icon={faMoneyCheck}
        />
      </div>,
      // Bank account issuing the cheque
      SelectField
        .create(
          'bankAccountId',
          'Bank Account',
          bankAccounts,
          (item: Account) =>
          {
            return item.id;
          },
          (item: Account) =>
          {
            return <div>
              <div>{item.name}</div>
              <div className="text--subtext">{Account.getTypeDisplayValue(item)}</div>
            </div>;
          },
          [Validator.required()],
        )
        .setGridClass('cell medium-6'),
      // Date
      DateField
        .create(
          'date',
          'Date',
          [ Validator.required() ],
          null
        )
        .setGridClass('cell medium-6'),
      TextField
        .create(
          'reconcileReference',
          'Paying In Slip Reference',
          [ Validator.required() ],
          null
        )
        .setGridClass('cell medium-6'),
      TextField
        .create(
          'description',
          'Description',
          [ Validator.required() ],
          null
        )
        .setGridClass('cell medium-6'),
      <div className="cell" key={'add_cheques'}>
        <Header
          text={'Add Cheques'}
          icon={faMoneyCheckAlt}
        />
      </div>,
      // Cheques to pay in
      SelectField
        .create(
          'cheques',
          'Cheques To Pay In',
          chequeTransactions,
          (item: Transaction) =>
          {
            return item.id;
          },
          (item: Transaction) =>
          {
            let subText = '';
  
            if (item.userOrgId)
            {
              let from = 'Unknown';
  
              let matchingMembers = this.state.members.filter((member: Member) => {
                return (member.userOrgId == item.userOrgId)
              });
  
              if (matchingMembers.length)
              {
                from = Member.getFullPreferredName(matchingMembers[0]);
              }
  
              subText = Money.getFormattedPrice(item.amount) + ' from ' + from;
            }
            else if (item.creditAccountId)
            {
              let from = 'Unknown';
  
              let matchingAccounts = this.state.accounts.filter((account: Account) => {
                return (account.id == item.creditAccountId)
              });
  
              if (matchingAccounts.length)
              {
                from = matchingAccounts[0].name;
              }
  
              subText = Money.getFormattedPrice(item.amount) + ' from ' + from;
            }
  
            return <div>
              <div>
                {item.description}
              </div>
              <div className={'text--subtext'}>
                {subText}
              </div>
            </div>
          },
          [],
          null,
          'multi'
        ),

      <div className="cell" key={'add_cash'}>
        <Header
          text={'Add Cash'}
          icon={faMoneyBillAlt}
        />
      </div>,

      InputGroupField.create(
        'cash',
        [
          SelectField
            .create(
              'cashAccountId',
              'Cash Account',
              cashAccounts,
              (item: Account) =>
              {
                return item.id;
              },
              (item: Account) =>
              {
                let balance = AccountStore.getBalanceByAccountId(item.id);
                return <div className={'display--flex'}>
                  <div>{item.name + ' (' + Money.getFormattedPrice(balance) + ')'}</div>
                  <div className="text--subtext">{Account.getTypeDisplayValue(item)}</div>
                </div>;
              },
              [Validator.required()],
            )
            .setGridClass('cell medium-6'),
          TextField
            .create(
              'amount',
              'Amount',
              [ Validator.required(), Validator.decimal() ],
              null
            )
            .setFormatter(
              (inputValue: string) => {
                if (inputValue === '') return inputValue;
                return Numbers.toDecimal(inputValue)
              }
            )
            .setGridClass('cell medium-6')
        ],
        null,
        [],
        true
      )
    ]
  }

  protected getBreadCrumbs()
  {
    return [
      {
        key: 'home',
        title: <span><FontAwesomeIcon icon={faHome}/> Home</span>,
        href: '/'
      },
      {
        key: 'transactions',
        title: 'Transactions',
        href: '/transactions'
      },
      {
        key: 'process_paying_in_slip',
        title: 'Process A Paying In Slip'
      },
    ];
  }

  protected onFormSubmit(formState)
  {
    if (this.state.formSubmitting)
    {
      return;
    }

    if (formState.valid)
    {
      let formValue = formState.value;

      let payingInSlipTotal = 0;

      // Add cheques to total
      if (formValue.cheques.length)
      {
        let selectedCheques = this.state.chequesHeldTransactions.filter((transaction: Transaction) => {
          return (formValue.cheques.indexOf(transaction.id) !== -1);
        });

        selectedCheques.forEach((transaction: Transaction) => {
          payingInSlipTotal += transaction.amount;
        });
      }

      // Add cash to total
      if (formValue.cash.length)
      {
        formValue.cash.forEach((cash: { cashAccountId: number, amount: any }) => {

          let cashAmount = parseFloat(cash.amount);

          if (cashAmount)
          {
            payingInSlipTotal+= cashAmount;
          }
        });
      }

      if (!payingInSlipTotal)
      {
        return ToastMessageStore.addMessage(
          ToastMessage.create('error', 'Paying in slip total cannot be 0')
        )
      }

      formValue['bankAccountId'] = parseInt(formValue['bankAccountId']);
      formValue['reconcileReference'] = parseInt(formValue['reconcileReference']);

      // Make sure cash amounts don't exceed account balances
      let noAccountsWillBeOverdrawn = true;

      for (let key in formValue.cash)
        if (formValue.cash.hasOwnProperty(key))
      {
        formValue.cash[key]['amount'] = parseFloat(formValue.cash[key]['amount']);
        let cashEntry = formValue.cash[key];
        let accountBalance = AccountStore.getBalanceByAccountId(cashEntry['cashAccountId']);
        let cashAccount = AccountStore.getByIdAndActiveOrganisation(cashEntry['cashAccountId']);

        if (cashEntry['amount'] > accountBalance)
        {
          let toastMessage = ToastMessage.create('warning', 'Cannot pay in more than ' + Money.getFormattedPrice(accountBalance) + ' from ' + cashAccount.name + '. Account would be left overdrawn.');
          ToastMessageStore.addMessage(toastMessage);
          noAccountsWillBeOverdrawn = false;
        }
      }

      if (!noAccountsWillBeOverdrawn)
      {
        return;
      }

      this.setState({
        formSubmitting: true
      });

      let promise = TransactionStore.processPayingInSlip(formValue);

      promise.then((transactions: Array<Transaction>) =>
      {
        this.setState({
          dataUpdating: false,
          formSubmitting: false
        });

        ToastMessageStore.addMessage(ToastMessage.create('success', 'Paying in slip processed'));

        let timeout = setTimeout(() =>
        {
          Request.redirect('/accounts/' + formValue.bankAccountId, this);

          clearTimeout(timeout);
        }, 0);
      });

      promise.catch((error: DataStoreError) => {
        this.setState({
          dataUpdating: false,
          formSubmitting: false
        });
      })
    }
  }

  protected onFormCapture(formValue)
  {
    this.setState({
      formValue: formValue
    })
  }
}
