
import * as React from 'react';
import {AbstractComponent} from "../../../component/AbstractComponent";
import './scss/EntryNotFoundPage.scss';
import { Navigate } from "appex-theme/src/Core/Navigate/Navigate";

export interface EntryNotFoundPageProps {}
export interface EntryNotFoundPageState {}

export class EntryNotFoundPage extends AbstractComponent<EntryNotFoundPageProps, EntryNotFoundPageState>
{
  public render()
  {
    return <Navigate to={`/login?destination=${window.location.pathname}`}/>
  }
}
