
import * as React from 'react';
import {Link} from 'react-router-dom';
import { TextField } from "appex-theme/src/Form/Input/Text/TextField";
import {AbstractComponent} from "../../../component/AbstractComponent";
import './scss/LoginPage.scss';
import {Form} from "appex-theme/src/Form/Form/Form";
import { Logo } from "../../../component/Logo/Logo";
import { SplitPanel } from "../../../component/SplitPanel/SplitPanel";
import {UserStore} from "../../../data/User/UserStore";
import {Validator} from "appex-theme/src/Form/Form/Validator/Validator";
import {Button} from "appex-theme/src/Core/Button/Button";
import {DataStoreError} from "ts-redux/src/DataStore/DataStoreError";

export interface LoginPageProps {}
export interface LoginPageState {
  authenticating: boolean
}

export class LoginPage extends AbstractComponent<LoginPageProps, LoginPageState>
{
  private BLOCK_NAME: 'LoginPage' = 'LoginPage';
  protected userStoreSubscriber;

  public constructor(props: LoginPageProps)
  {
    super(props);

    this.state = {
      authenticating: false
    };
  }

  public render()
  {
    // email
    let emailField = TextField
      .create(
        'email',
        'Email Address',
        [
          Validator.required(),
          Validator.email()
        ]
      )
      .setType('email');

    // password
    let passwordField = TextField
      .create(
        'password',
        'Password',
        [
          Validator.required(),
          Validator.minLength(6)
        ]
      )
      .setType('password');

    let modifierArray = [
      'primary' as 'primary',
      this.state.authenticating ? 'loading' as 'loading' : 'primary' as 'primary'
    ];

    return <div className={this.BLOCK_NAME}>
      <SplitPanel
        primaryContent={
          <div className={`${this.BLOCK_NAME}__LeftPanel`}>
  
            <Logo/>
            
            <Form
              elements={[
                emailField,
                passwordField,
                <div className="cell" key={'submit'}>
                  <div className="mt-6 text--align-right" key={'submit'}>
                    <Button
                      type={'submit'}
                      modifiers={modifierArray}
                    >
                      Login
                    </Button>
                  </div>
                </div>
              ]}
              onFormSubmit={this.onFormSubmit.bind(this)}
              onFormCapture={() => {}}
            />
            
            <div className={`${this.BLOCK_NAME}__LinksContainer`}>
              <Link to={'/signup'}>Create an Account</Link>
              <Link to={'/forgotten-password'}>Forgotten Password?</Link>
            </div>
          </div>
        }
        secondaryContent={
          <div className={`${this.BLOCK_NAME}__RightPanel`}>
            <h3>Log into your account.</h3>
            <p>Enter your email address and password to get started.</p>
            
            <div className={`${this.BLOCK_NAME}__Logo`}>
              <Logo color={'white'}/>
            </div>
          </div>
        }
      />
    </div>
  }


  protected onFormSubmit(formState)
  {
    if (!formState.valid)
    {
      return;
    }

    this.setState({
      authenticating: true
    });

    this.login(formState.value.email, formState.value.password);
  }

  private login(email: string, password: string)
  {
    let promise = UserStore.authenticate(email, password);

    promise.catch((error: DataStoreError) => {
      this.setState({
        authenticating: false
      });
    })
  }
}
