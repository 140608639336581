import {DataStore} from "ts-redux/src/DataStore/DataStore";
import {Http} from "phusion/src/Core/Http/Http";
import {HttpResponse} from "phusion/src/Core/Http/HttpResponse";
import {HttpError} from "phusion/src/Core/Http/HttpError";
import {Objects} from "phusion/src/Core/Objects/Objects";
import {Redux} from "ts-redux/src/Redux";
import {Url} from "../../utility/Url";
import {Transaction} from "./Entity/Transaction";
import {OrganisationStore} from "../Organisation/OrganisationStore";

declare let Promise: any;

export class TransactionStore extends DataStore
{
  public static readonly key: string = 'transaction';
  protected static readonly SET_UPDATING: string = 'set_transaction_updating';
  protected static readonly SET_TRANSACTIONS_BY_ACCOUNT: string = 'set_transactions_by_account';
  protected static readonly SET_ALL_TRANSACTIONS_BY_ACCOUNT: string = 'set_all_transactions_by_account';
  protected static readonly CLEAR_TRANSACTIONS_BY_ACCOUNT: string = 'clear_transactions_by_account';
  protected static readonly SET_TRANSACTIONS_BY_RECONCILE_REFERENCE: string = 'set_transactions_by_reconcile_reference';
  protected static readonly SET_ALL_TRANSACTIONS_BY_RECONCILE_REFERENCE: string = 'set_all_transactions_by_reconcile_reference';
  protected static readonly SET_TRANSACTIONS_BY_USER_ORG: string = 'set_transactions_by_user_org';
  protected static readonly SET_ALL_TRANSACTIONS_BY_USER_ORG: string = 'set_all_transactions_by_user_org';

  public static reduce(
    state = {
      updating: false,
      transactionsByAccountId: {},
      transactionsByUserOrgId: {}
    },
    action: {
      type: string,
      data: any
    }
  )
  {
    switch (action.type)
    {
      case TransactionStore.SET_UPDATING:
        return Object.assign({}, state, {
          updating: action.data
        });
      case TransactionStore.SET_TRANSACTIONS_BY_ACCOUNT:

        let allTransactionsByAccount = Objects.getByKeyPath('transactionsByAccountId', state);

        allTransactionsByAccount = allTransactionsByAccount
          ? allTransactionsByAccount
          : {};

        let transactionsByAccountId = Objects.clone(allTransactionsByAccount);
        transactionsByAccountId[action.data.accountId] = action.data.transactions;

        return Object.assign({}, state, {
          transactionsByAccountId: transactionsByAccountId
        });
      case TransactionStore.SET_ALL_TRANSACTIONS_BY_ACCOUNT:
        return Object.assign({}, state, {
          transactionsByAccountId: action.data.transactionsByAccountId
        });
      case TransactionStore.CLEAR_TRANSACTIONS_BY_ACCOUNT:

        let existingTransactionsByAccount = Objects.getByKeyPath('transactionsByAccountId', state);

        existingTransactionsByAccount = existingTransactionsByAccount
          ? existingTransactionsByAccount
          : {};

        let newTransactionsByAccountId = Objects.clone(existingTransactionsByAccount);

        delete newTransactionsByAccountId[action.data.accountId];

        return Object.assign({}, state, {
          transactionsByAccountId: newTransactionsByAccountId
        });
      case TransactionStore.SET_TRANSACTIONS_BY_RECONCILE_REFERENCE:

        let allTransactionsByReconcileReference = Objects.getByKeyPath('transactionsByReconcileReference', state);

        allTransactionsByReconcileReference = allTransactionsByReconcileReference
          ? allTransactionsByReconcileReference
          : {};

        let transactionsByReconcileReference = Objects.clone(allTransactionsByReconcileReference);
        transactionsByReconcileReference[action.data.accountId] = action.data.transactions;

        return Object.assign({}, state, {
          transactionsByReconcileReference: transactionsByReconcileReference
        });
      case TransactionStore.SET_ALL_TRANSACTIONS_BY_RECONCILE_REFERENCE:
        return Object.assign({}, state, {
          transactionsByReconcileReference: action.data.transactionsByReconcileReference
        });
      case TransactionStore.SET_TRANSACTIONS_BY_USER_ORG:

        let allTransactionsByUserOrg = Objects.getByKeyPath('transactionsByUserOrgId', state);

        allTransactionsByUserOrg = allTransactionsByUserOrg
          ? allTransactionsByUserOrg
          : {};

        let transactionsByUserOrgId = Objects.clone(allTransactionsByUserOrg);
        transactionsByUserOrgId[action.data.userOrgId] = action.data.transactions;

        return Object.assign({}, state, {
          transactionsByUserOrgId: transactionsByUserOrgId
        });
      case TransactionStore.SET_ALL_TRANSACTIONS_BY_USER_ORG:
        return Object.assign({}, state, {
          transactionsByUserOrgId: action.data.transactionsByUserOrgId
        });
      default:
        return state;
    }
  }

  public static getAllByAccountId(accountId: number): Array<Transaction>
  {
    let state = Redux.getState();
    let transactions = Objects.getByKeyPath('transaction:transactionsByAccountId:' + accountId, state);

    if (transactions)
    {
      return transactions;
    }

    return [];
  }

  public static getAllByReconcileReference(reconcileReference: number): Array<Transaction>
  {
    let state = Redux.getState();
    let transactions = Objects.getByKeyPath('transaction:transactionsByReconcileReference:' + reconcileReference, state);

    if (transactions)
    {
      return transactions;
    }

    return [];
  }

  public static getAllByUserOrgId(userOrgId: number): Array<Transaction>
  {
    let transactions = Objects.getByKeyPath('transaction:transactionsByUserOrgId:' + userOrgId, Redux.getState());

    if (transactions)
    {
      return transactions;
    }

    return [];
  }
  
  public static generateBalanceSheet(periodEnd?: string): Promise<{ profitAndLoss: any, liabilities: any, assets: any }>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();
    
    return new Promise((resolve, reject) => {
      const data = periodEnd ? { periodEnd: periodEnd } : null;
      
      Http
        .get(Url.api(`/organisation/${activeOrg.id}/balance-sheet/generate`), data)
        .then((response) => {
            return resolve(response.data['data']);
          }
        )
    })
  }

  public static syncTransactionsByAccountId(accountId: number): Promise<Array<Transaction>>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      TransactionStore.setUpdating(true);

      let promise = Http.get(Url.api('/organisation/' + activeOrg.id + '/account/' + accountId + '/transactions'));

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let transactions = Objects.getByKeyPath('transactions', responseBody);

        if (transactions)
        {
          let transactionEntities = [];

          for (let key in transactions)
            if (transactions.hasOwnProperty(key))
            {
              transactionEntities.push(Transaction.create(transactions[key]));
            }

          TransactionStore.setTransactionsByAccountId(accountId, transactionEntities);
          TransactionStore.setUpdating(false);

          return resolve(transactionEntities);
        }

        TransactionStore.setUpdating(false);
        return reject(TransactionStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        TransactionStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  public static syncTransactionsByReconcileReference(reconcileReference: number): Promise<Array<Transaction>>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      TransactionStore.setUpdating(true);

      let promise = Http.get(Url.api('/organisation/' + activeOrg.id + '/transactions/reference/' + reconcileReference));

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let transactions = Objects.getByKeyPath('transactions', responseBody);

        if (transactions)
        {
          let transactionEntities = [];

          for (let key in transactions)
            if (transactions.hasOwnProperty(key))
            {
              transactionEntities.push(Transaction.create(transactions[key]));
            }

          TransactionStore.setTransactionsByReconcileReference(reconcileReference, transactionEntities);
          TransactionStore.setUpdating(false);

          return resolve(transactionEntities);
        }

        TransactionStore.setUpdating(false);
        return reject(TransactionStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        TransactionStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  public static fetchAllPendingChequeTransactions(chequesIssuedAccountId: number): Promise<Array<Transaction>>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      TransactionStore.setUpdating(true);
      const promise = Http.get(Url.api(`/organisation/${activeOrg.id}/account/${chequesIssuedAccountId}/pending-cheque-transactions`));

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let transactions = Objects.getByKeyPath('transactions', responseBody);

        if (transactions)
        {
          let transactionEntities = [];

          for (let key in transactions)
            if (transactions.hasOwnProperty(key))
            {
              transactionEntities.push(Transaction.create(transactions[key]));
            }

          return resolve(transactionEntities);
        }

        return reject(TransactionStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        return reject(httpError);
      })
    });
  }

  public static syncTransactionsByUserOrgId(userOrgId: number): Promise<Array<Transaction>>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      TransactionStore.setUpdating(true);

      let promise = Http.get(Url.api('/organisation/' + activeOrg.id + '/member/' + userOrgId + '/transactions'));

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let transactions = Objects.getByKeyPath('transactions', responseBody);

        if (transactions)
        {
          let transactionEntities = [];

          for (let key in transactions)
            if (transactions.hasOwnProperty(key))
            {
              transactionEntities.push(Transaction.create(transactions[key]));
            }

          TransactionStore.setTransactionsByUserOrgId(userOrgId, transactionEntities);
          TransactionStore.setUpdating(false);

          return resolve(transactionEntities);
        }

        TransactionStore.setUpdating(false);
        return reject(TransactionStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        TransactionStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  public static createNewTransaction(transaction: Transaction): Promise<Transaction>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      TransactionStore.setUpdating(true);

      let promise = Http.post(Url.api('/organisation/' + activeOrg.id + '/transaction'), transaction.serialize());

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let transaction = Objects.getByKeyPath('transaction', responseBody);

        if (transaction)
        {
          transaction = Transaction.create(transaction);

          // creditAccountId
          if (transaction.creditAccountId)
          {
            this.spliceTransactionIntoAccountTransactions(transaction.creditAccountId, transaction);
          }
          // debitAccountId
          if (transaction.debitAccountId)
          {
            this.spliceTransactionIntoAccountTransactions(transaction.debitAccountId, transaction);
          }
          // userOrgId
          if (transaction.userOrgId)
          {
            this.spliceTransactionIntoUserOrgTransactions(transaction.userOrgId, transaction);
          }

          TransactionStore.setUpdating(false);

          return resolve(transaction);
        }

        TransactionStore.setUpdating(false);
        return reject(TransactionStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        TransactionStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  public static deleteTransaction(transactionId: number): Promise<boolean>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      TransactionStore.setUpdating(true);

      let promise = Http.put(Url.api('/organisation/' + activeOrg.id + '/transaction/' + transactionId + '/delete'), {});

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let transactionsByAccountId = Objects.getByKeyPath('transactionsByAccountId', responseBody);

        // Add account transactions to redux
        if (transactionsByAccountId)
        {
          for (let accountId in transactionsByAccountId)
            if (transactionsByAccountId.hasOwnProperty(accountId))
            {
              let accountTransactions = transactionsByAccountId[accountId] ? transactionsByAccountId[accountId] : [];

              accountTransactions = accountTransactions.map((transaction) => {
                return Transaction.create(transaction);
              });

              TransactionStore.setTransactionsByAccountId(parseInt(accountId), accountTransactions)
            }
        }

        let transactionsByUserOrgId = Objects.getByKeyPath('transactionsByUserOrgId', responseBody);

        // Add user transactions to redux
        if (transactionsByUserOrgId)
        {
          for (let userOrgId in transactionsByUserOrgId)
            if (transactionsByUserOrgId.hasOwnProperty(userOrgId))
            {
              let memberTransactions = transactionsByUserOrgId[userOrgId] ? transactionsByUserOrgId[userOrgId] : [];

              memberTransactions = memberTransactions.map((transaction) => {
                return Transaction.create(transaction);
              });

              TransactionStore.setTransactionsByUserOrgId(parseInt(userOrgId), memberTransactions);
            }
        }

        TransactionStore.setUpdating(false);

        let deletedTransactions = Objects.getByKeyPath('deletedTransactionIds', responseBody);

        return resolve(Boolean(deletedTransactions && deletedTransactions.length));
      });

      promise.catch((httpError: HttpError) => {
        TransactionStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  public static processPayingInSlip(
    data: {
      bankAccountId: number,
      date: string,
      reconcileReference: string,
      description: string,
      cheques: Array<number>,
      cash: Array<{
        cashAccountId: number,
        amount: number
      }>
    }
  )
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      TransactionStore.setUpdating(true);

      let promise = Http.post(Url.api('/organisation/' + activeOrg.id + '/transaction/process-paying-in-slip'), data);

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let transactions = Objects.getByKeyPath('transactions', responseBody);

        let transactionEntities = [];

        if (transactions)
        {
          for (let key in transactions)
            if (transactions.hasOwnProperty(key))
          {
            let transactionEntity = Transaction.create(transactions[key]);
            transactionEntities.push(transactionEntity);

            // creditAccountId
            if (transactionEntity.creditAccountId)
            {
              this.spliceTransactionIntoAccountTransactions(transactionEntity.creditAccountId, transactionEntity);
            }
            // debitAccountId
            if (transactionEntity.debitAccountId)
            {
              this.spliceTransactionIntoAccountTransactions(transactionEntity.debitAccountId, transactionEntity);
            }
            // userOrgId
            if (transactionEntity.userOrgId)
            {
              this.spliceTransactionIntoUserOrgTransactions(transactionEntity.userOrgId, transactionEntity);
            }
          }

          TransactionStore.setUpdating(false);

          return resolve(transactionEntities);
        }

        TransactionStore.setUpdating(false);
        return reject(TransactionStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        TransactionStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  public static updateTransaction(transaction: Transaction): Promise<Transaction>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      TransactionStore.setUpdating(true);

      let promise = Http.put(Url.api('/organisation/' + activeOrg.id + '/transaction/' + transaction.id), transaction);

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let transaction = Objects.getByKeyPath('transaction', responseBody);

        if (transaction)
        {
          transaction = Transaction.create(transaction);

          // creditAccountId
          if (transaction.creditAccountId)
          {
            this.spliceTransactionIntoAccountTransactions(transaction.creditAccountId, transaction);
          }
          // debitAccountId
          if (transaction.debitAccountId)
          {
            this.spliceTransactionIntoAccountTransactions(transaction.debitAccountId, transaction);
          }
          // userOrgId
          if (transaction.userOrgId)
          {
            this.spliceTransactionIntoUserOrgTransactions(transaction.userOrgId, transaction);
          }

          TransactionStore.setUpdating(false);

          return resolve(transaction);
        }

        TransactionStore.setUpdating(false);
        return reject(TransactionStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        TransactionStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  public static setTransactionsByAccountId(accountId: number, transactions: Array<Transaction>)
  {
    Redux.dispatch({
      type: TransactionStore.SET_TRANSACTIONS_BY_ACCOUNT,
      data: {
        accountId: accountId,
        transactions: transactions
      }
    });
  }

  public static setAllTransactionsByAccountId(transactionsByAccountId: any)
  {
    Redux.dispatch({
      type: TransactionStore.SET_ALL_TRANSACTIONS_BY_ACCOUNT,
      data: {
        transactionsByAccountId: transactionsByAccountId
      }
    });
  }

  public static setTransactionsByReconcileReference(reconcileReference: number, transactions: Array<Transaction>)
  {
    Redux.dispatch({
      type: TransactionStore.SET_TRANSACTIONS_BY_RECONCILE_REFERENCE,
      data: {
        accountId: reconcileReference,
        transactions: transactions
      }
    });
  }

  public static setAllTransactionsByReconcileReference(transactionsByReconcileReference: any)
  {
    Redux.dispatch({
      type: TransactionStore.SET_ALL_TRANSACTIONS_BY_RECONCILE_REFERENCE,
      data: {
        transactionsByReconcileReference: transactionsByReconcileReference
      }
    });
  }

  public static setTransactionsByUserOrgId(userOrgId: number, transactions: Array<Transaction>)
  {
    Redux.dispatch({
      type: TransactionStore.SET_TRANSACTIONS_BY_USER_ORG,
      data: {
        userOrgId: userOrgId,
        transactions: transactions
      }
    });
  }

  public static setAllTransactionsByUserOrgId(transactionsByUserOrgId: any)
  {
    Redux.dispatch({
      type: TransactionStore.SET_ALL_TRANSACTIONS_BY_USER_ORG,
      data: {
        transactionsByUserOrgId: transactionsByUserOrgId
      }
    });
  }

  protected static spliceTransactionIntoAccountTransactions(accountId: number, transaction: Transaction): Array<Transaction>
  {
    let allAccountTransactions = this.getAllByAccountId(accountId);

    let existingKey = null;

    for (let key in allAccountTransactions)
      if (allAccountTransactions.hasOwnProperty(key))
      {
        let existingTransaction = allAccountTransactions[key];

        if (existingTransaction.id == transaction.id)
        {
          existingKey = Number(key);
          break;
        }
      }

    if (typeof existingKey == 'number')
    {
      allAccountTransactions.splice(existingKey, 1, transaction);
    }
    else
    {
      allAccountTransactions.push(transaction);
    }

    this.setTransactionsByAccountId(accountId, allAccountTransactions);

    return allAccountTransactions;
  }

  protected static spliceTransactionIntoUserOrgTransactions(userOrgId: number, transaction: Transaction): Array<Transaction>
  {
    let allUserOrgTransactions = this.getAllByUserOrgId(userOrgId);

    let existingKey = null;

    for (let key in allUserOrgTransactions)
      if (allUserOrgTransactions.hasOwnProperty(key))
      {
        let existingTransaction = allUserOrgTransactions[key];

        if (existingTransaction.id == transaction.id)
        {
          existingKey = Number(key);
          break;
        }
      }

    if (typeof existingKey == 'number')
    {
      allUserOrgTransactions.splice(existingKey, 1, transaction);
    }
    else
    {
      allUserOrgTransactions.push(transaction);
    }

    this.setTransactionsByUserOrgId(userOrgId, allUserOrgTransactions);

    return allUserOrgTransactions;
  }

  public static spliceTransaction(transaction: Transaction)
  {
    if (transaction.creditAccountId)
    {
      TransactionStore.spliceTransactionIntoAccountTransactions(transaction.creditAccountId, transaction);
    }
    if (transaction.debitAccountId)
    {
      TransactionStore.spliceTransactionIntoAccountTransactions(transaction.debitAccountId, transaction);
    }
    if (transaction.userOrgId)
    {
      TransactionStore.spliceTransactionIntoUserOrgTransactions(transaction.userOrgId, transaction);
    }

    return transaction;
  }

  public static setUpdating(updating: boolean)
  {
    Redux.dispatch({
      type: TransactionStore.SET_UPDATING,
      data: updating
    });
  }

  public static clearAllTransactionsByAccountId(accountId: number)
  {
    Redux.dispatch({
      type: TransactionStore.CLEAR_TRANSACTIONS_BY_ACCOUNT,
      data: {
        accountId: accountId
      }
    });
  }
  
  public static subscribeToUpdating(callback: (updating: boolean) => void, currentValue?: boolean)
  {
    return this.subscribe((state) => {
      let newValue = state.updating;
      
      if (currentValue !== newValue)
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }
  
  public static subscribeToTransactionsByAccountId(
    accountId: number,
    callback: (accounts: Array<Transaction>) => void, currentValue?: Array<Transaction>
  )
  {
    return this.subscribe((state) => {
      let newValue = this.getAllByAccountId(accountId);
      
      if (JSON.stringify(currentValue) !== JSON.stringify(newValue))
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }
  
  public static subscribeToTransactionsByUserOrgId(
    userOrgId: number,
    callback: (accounts: Array<Transaction>) => void,
    currentValue?: Array<Transaction>
  )
  {
    return this.subscribe((state) => {
      let newValue = this.getAllByUserOrgId(userOrgId);
      
      if (JSON.stringify(currentValue) !== JSON.stringify(newValue))
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }
  
  public static subscribeToTransactionsByReconcileReference(
    reconcileReference: number,
    callback: (accounts: Array<Transaction>) => void,
    currentValue?: Array<Transaction>
  )
  {
    return this.subscribe((state) => {
      let newValue = this.getAllByReconcileReference(reconcileReference);
      
      if (JSON.stringify(currentValue) !== JSON.stringify(newValue))
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }
}
