
import * as React from 'react';
import { ToastMessage } from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";
import { ToastMessageStore } from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import { Request } from "appex-theme/src/Utility/Request";
import {AbstractComponent} from "../../../../component/AbstractComponent";
import {PageBar} from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import {Breadcrumbs} from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import {Panel} from "appex-theme/src/Core/Panel/Panel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from 'react-router-dom';
import './scss/TransactionsPage.scss';
import {InfoCard} from "appex-theme/src/Info/InfoCard/InfoCard";
import {faHome} from "@fortawesome/pro-light-svg-icons/faHome";
import {faCashRegister} from "@fortawesome/pro-duotone-svg-icons/faCashRegister"
import {faExchangeAlt} from "@fortawesome/pro-duotone-svg-icons/faExchangeAlt"
import {faHandHoldingUsd} from "@fortawesome/pro-duotone-svg-icons/faHandHoldingUsd"
import {faMoneyCheck} from "@fortawesome/pro-duotone-svg-icons/faMoneyCheck"
import {faMoneyCheckAlt} from "@fortawesome/pro-duotone-svg-icons/faMoneyCheckAlt"
import {faMoneyCheckEditAlt} from "@fortawesome/pro-duotone-svg-icons/faMoneyCheckEditAlt"
import {faUsdCircle} from "@fortawesome/pro-duotone-svg-icons/faUsdCircle"
import { PermissionsStore } from "../../../../data/Permissions/PermissionsStore";

export interface TransactionsProps {}
export interface TransactionsState {}

export class TransactionsPage extends AbstractComponent<TransactionsProps, TransactionsState>
{
  public constructor(props: TransactionsProps)
  {
    super(props);
  }
  
  public componentDidMount()
  {
    if (!PermissionsStore.hasPermission('financial:write'))
    {
      ToastMessageStore.addMessage(ToastMessage.create('error', "Sorry, you don't have access to view this page"));
      return Request.goBack(this);
    }
  }
  
  public render()
  {
    let breadcrumbs = this.getBreadCrumbs();

    return <div className={'TransactionsPage'}>

      <div className="grid-container">
        <div className="cell">
          <PageBar
            title={<div><FontAwesomeIcon icon={faExchangeAlt}/>Add a Transaction</div>}
            rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}
          />
        </div>

        <div className="grid-x grid-margin-x">

          <div className="cell medium-6 large-4">
            <Panel>
              <InfoCard
                icon={faMoneyCheckEditAlt}
                title={'Issue A Cheque'}
                description={'Issue a cheque from one bank account to another.'}
                cta={<Link to={'/transactions/issue-cheque'} className={'Button Button--primary'} >Issue A Cheque</Link>}
              />
            </Panel>
          </div>

          <div className="cell medium-6 large-4">
            <Panel>
              <InfoCard
                icon={faMoneyCheckAlt}
                title={'Receive A Cheque'}
                description={'Register a cheque as received, ready to pay in to a bank account.'}
                cta={<Link to={'/transactions/receive-cheque'} className={'Button Button--primary'} >Receive A Cheque</Link>}
              />
            </Panel>
          </div>

          <div className="cell medium-6 large-4">
            <Panel>
              <InfoCard
                icon={faUsdCircle}
                title={'Make A Payment'}
                description={'Transfer funds from a bank or cash account to another.'}
                cta={<Link to={'/transactions/make-online-payment'} className={'Button Button--primary'} >Make A Payment</Link>}
              />
            </Panel>
          </div>

          <div className="cell medium-6 large-4">
            <Panel>
              <InfoCard
                icon={faHandHoldingUsd}
                title={'Receive Direct Credit'}
                description={'Receive funds from a bank transfer or online payment.'}
                cta={<Link to={'/transactions/receive-direct-credit'} className={'Button Button--primary'} >Receive Direct Credit</Link>}
              />
            </Panel>
          </div>

          <div className="cell medium-6 large-4">
            <Panel>
              <InfoCard
                icon={faCashRegister}
                title={'Receive Cash'}
                description={'Register receipt of cash. This can be paid in by adding it to a paying in slip.'}
                cta={<Link to={'/transactions/receive-cash'} className={'Button Button--primary'} >Receive Cash</Link>}
              />
            </Panel>
          </div>

          <div className="cell medium-6 large-4">
            <Panel>
              <InfoCard
                icon={faMoneyCheck}
                title={'Process Paying In Slip'}
                description={'Add cheques and cash to a paying in slip to pay into a bank account.'}
                cta={<Link to={'/transactions/process-paying-in-slip'} className={'Button Button--primary'} >Process Paying In Slip</Link>}
              />
            </Panel>
          </div>

          <div className="cell">
            <p className={'text--align-center'}>
              Or click <Link to={'/transactions/other'}>here</Link> for any other transactions
            </p>
          </div>
        </div>

      </div>

    </div>
  }

  protected getBreadCrumbs()
  {
    return [
      {
        key: 'home',
        title: <span><FontAwesomeIcon icon={faHome}/> Home</span>,
        href: '/'
      },
      {
        key: 'transactions',
        title: 'Transactions',
      },
    ];
  }
}
