
import { faAddressCard } from "@fortawesome/pro-solid-svg-icons/faAddressCard";
import { faAlignLeft } from "@fortawesome/pro-solid-svg-icons/faAlignLeft";
import { faCreditCard } from "@fortawesome/pro-solid-svg-icons/faCreditCard";
import { faPoundSign } from "@fortawesome/pro-solid-svg-icons/faPoundSign";
import * as React from 'react';
import {Link} from 'react-router-dom';
import { Request } from "appex-theme/src/Utility/Request";
import {AbstractComponent} from "../../../../component/AbstractComponent";
import {PageBar} from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import {Breadcrumbs} from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import {Panel} from "appex-theme/src/Core/Panel/Panel";
import { AccountStore } from "../../../../data/Account/AccountStore";
import { Account } from "../../../../data/Account/Entity/Account";
import { Membership } from "../../../../data/Membership/Entity/Membership";
import { MembershipStore } from "../../../../data/Membership/MembershipStore";
import { PermissionsStore } from "../../../../data/Permissions/PermissionsStore";
import {Member} from "../../../../data/Member/Entity/Member";
import {ToastMessageStore} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import {ToastMessage} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {MemberStore} from "../../../../data/Member/MemberStore";
import './scss/ViewMembershipPage.scss';
import {faEye} from "@fortawesome/pro-regular-svg-icons/faEye";
import {faUsers} from "@fortawesome/pro-duotone-svg-icons/faUsers";
import {faAddressCard as duoFaAddressCard} from "@fortawesome/pro-duotone-svg-icons/faAddressCard";
import {DisplayItem} from "appex-theme/src/Data/DisplayItem/DisplayItem";
import {DataTable} from "appex-theme/src/Data/DataTable/DataTable/DataTable";
import {DisplayColumn} from "appex-theme/src/Data/DataTable/DataTable/Entity/DisplayColumn";
import {CtaPlaceholder} from "appex-theme/src/Placeholder/Cta/CtaPlaceholder";
import { Money } from 'appex-theme/src/Utility/Money';

export interface ViewMembershipProps {}
export interface ViewMembershipState {
  membershipId: number,
  membership: Membership,
  members: Array<Member>,
  membershipUpdating: boolean,
  membersUpdating: boolean,
  creditAccount: Account
}

export class ViewMembershipPage extends AbstractComponent<ViewMembershipProps, ViewMembershipState>
{
  protected unsubscribers = {};

  public constructor(props: ViewMembershipProps)
  {
    super(props);

    let membership = null;

    let membershipId = parseInt(props['params'].membershipId);
    
    if (membershipId)
    {
      membership = MembershipStore.getByIdAndActiveOrganisation(membershipId);
    }

    let members = MemberStore.getAllMembersByActiveOrganisation();

    this.state = {
      membership: membership,
      membershipId: membershipId,
      members: members,
      membershipUpdating: false,
      membersUpdating: false,
      creditAccount: membership ? AccountStore.getByIdAndActiveOrganisation(membership.creditAccountId) : null
    }
  }

  public componentDidMount()
  {
    // Redirect if you don't have membership:read permissions
    if (!PermissionsStore.hasPermission('membership:read'))
    {
      ToastMessageStore.addMessage(ToastMessage.create('error', "Sorry, you don't have access to view this page"));
      return Request.goBack(this);
    }
    
    /**
     * Subscribe to Membership
     */
    
    this.unsubscribers['membership_updating'] = MembershipStore.subscribeToUpdating(
      (updating: boolean) => {
        this.setState({
          membershipUpdating: updating
        })
      },
      this.state.membershipUpdating
    )
    
    this.unsubscribers['membership'] = MembershipStore.subscribeToMembershipById(
      this.state.membershipId,
      (membership: Membership) => {
        this.setState({
          membership: membership
        })
      },
      this.state.membership
    )
    
    /**
     * Subscribe to Members
     */
    
    this.unsubscribers['members_updating'] = MemberStore.subscribeToUpdating(
      (updating: boolean) => {
        this.setState({
          membersUpdating: updating
        })
      },
      this.state.membersUpdating
    )
    
    this.unsubscribers['members'] = MemberStore.subscribeToActiveOrgMembers(
      (members: Array<Member>) => {
        this.setState({
          members: members
        })
      },
      this.state.members
    )
    
    /**
     * Subscribe to Accounts
     */
    
    this.unsubscribers['accounts'] = AccountStore.subscribeToActiveOrgAccounts(
      (accounts: Array<Account>) => {
        
        const creditAccount = accounts.find((account: Account) => {
          return account.id === this.state.membership.creditAccountId;
        });
        
        this.setState({
          creditAccount: creditAccount
        })
      }
    )

    MembershipStore.syncMembership(this.state.membershipId);
    MemberStore.syncActiveOrganisationMembers();
  }

  public componentWillUnmount()
  {
    if (this.unsubscribers)
    {
      for (let key in this.unsubscribers)
      {
        this.unsubscribers[key]();
      }
    }
  }

  public render()
  {
    let membership = this.state.membership;
    let breadcrumbs = [
      {
        key: 'home',
        title: <span><i className="fal fa-home"/> Home</span>,
        href: '/'
      },
      {
        key: 'memberships',
        title: 'Memberships',
        href: '/memberships'
      },
      {
        key: 'view_membership',
        title: membership ? membership.name : '. . .'
      }
    ];
    
    return <div className={'ViewMembershipPage'}>

      <div className="grid-container">

        <PageBar
          title={<div><FontAwesomeIcon icon={duoFaAddressCard}/>{(!this.state.membership && this.state.membershipUpdating) ? 'Loading membership . . .' :(this.state.membership ? this.state.membership.name : 'View Membership')}</div>}
          rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}
        />

        <div className="grid-x grid-margin-x">
          <div className="cell large-4">
            <Panel
              headerLeft={<div className="Header">Membership Details</div>}
              headerRight={
                PermissionsStore.hasPermission('membership:write')
                  ? <Link to={'/memberships/' + this.state.membershipId + '/update'}>
                      <i className="fas fa-edit"/>
                    </Link>
                  : null
              }
            >

              <div className="grid-x">
                <div className="cell">
                  <DisplayItem icon={<FontAwesomeIcon icon={faAddressCard}/>} content={ this.state.membership ? this.state.membership.name : '-' } updating={this.state.membershipUpdating}/>
                  <DisplayItem icon={<FontAwesomeIcon icon={faPoundSign}/>} content={ this.state.membership ? Money.getFormattedPrice(this.state.membership.price) : '-' } updating={this.state.membershipUpdating}/>
                  <DisplayItem icon={<FontAwesomeIcon icon={faCreditCard}/>} content={ this.state.membership && this.state.creditAccount ? this.state.creditAccount.name : '-' } updating={this.state.membershipUpdating}/>
                  <DisplayItem icon={<FontAwesomeIcon icon={faAlignLeft}/>} content={ this.state.membership ? this.state.membership.transactionDescription : '-' } updating={this.state.membershipUpdating}/>
                </div>
              </div>
            </Panel>

          </div>
          
          <div className="cell large-8">
            <Panel
              headerLeft={<div className="Header">Assigned Members</div>}
            >
              {
                this.getAssignedMembersContent(this.state.membership)
              }
            </Panel>
          </div>
        </div>
      </div>
    </div>
  }

  protected getAssignedMembersContent(membership: Membership)
  {
    const members = this.state.members.filter((member) => {
      return member.membershipId === this.state.membershipId;
    })
    
    return <DataTable
      data={members}
      displayColumns={[
        DisplayColumn.create(
          'Name',
          'lastName',
          (member: Member) => {
            return Member.getFullPreferredName(member);
          },
          3
        ),
        DisplayColumn.create(
          'Action',
          null,
          (member: Member) => {
            return <div>
              <Link to={'/members/' + member.userOrgId} className={'mr-6'}>
                <FontAwesomeIcon icon={faEye}/>
              </Link>
            </div>
          }
        ),
      ]}
      searchable={true}
      sortable={true}
      onSearch={Member.onSearch}
      noDataContent={this.getNoMembersContent()}
      paginationControls={true}
      showRowsPerPage={false}
      defaultRowsPerPage={5}
      dataUpdating={this.state.membersUpdating}
    />
  }

  protected getNoMembersContent()
  {
    let heading = 'No Members Found';
    let text = 'No members have been assigned to this membership';

    // TODO: Switch out content depending on permission status

    return <div className="cell">
      <CtaPlaceholder
        icon={faUsers}
        heading={heading}
        text={text}
      />
    </div>;
  }

}
