
import {ReactApp} from "./ReactApp";
import { Http } from "phusion/src/Core/Http/Http";
import { PermissionsStore } from "./src/data/Permissions/PermissionsStore";

Http.onSuccess((res) => {
  const permissionsByOrgId = res?.data?.permissionsByOrgId;
  if (!permissionsByOrgId) return;
  
  for (const orgId in permissionsByOrgId)
  {
    PermissionsStore.setPermissionsByOrganisation(Number(orgId), permissionsByOrgId[orgId]);
  }
})

window.addEventListener('load', () => {
  let app = new ReactApp();
  app.init('#root');
});
