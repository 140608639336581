import { faBan } from "@fortawesome/pro-regular-svg-icons/faBan";
import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons/faCalendarAlt";
import { faAddressCard } from "@fortawesome/pro-solid-svg-icons/faAddressCard";
import { Button } from "appex-theme/src/Core/Button/Button";
import { Form } from "appex-theme/src/Form/Form/Form";
import { Validator } from "appex-theme/src/Form/Form/Validator/Validator";
import { SelectField } from "appex-theme/src/Form/Input/Select/SelectField";
import { TextField } from "appex-theme/src/Form/Input/Text/TextField";
import { Dates } from "appex-theme/src/Utility/Dates";
import * as React from 'react';
import { Link } from 'react-router-dom';
import { Request } from "appex-theme/src/Utility/Request";
import { AbstractComponent } from "../../../../component/AbstractComponent";
import { PageBar } from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import { Breadcrumbs } from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import { Panel } from "appex-theme/src/Core/Panel/Panel";
import { Membership } from "../../../../data/Membership/Entity/Membership";
import { MembershipStore } from "../../../../data/Membership/MembershipStore";
import { Organisation } from "../../../../data/Organisation/Entity/Organisation";
import { OrganisationInvitation } from "../../../../data/Organisation/Entity/OrganisationInvitation";
import { OrganisationStore } from "../../../../data/Organisation/OrganisationStore";
import { PermissionsStore } from "../../../../data/Permissions/PermissionsStore";
import { ToastMessageStore } from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import { ToastMessage } from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './scss/ViewOrganisationPage.scss';
import { faUsers } from "@fortawesome/pro-duotone-svg-icons/faUsers";
import { faLandmark as duoFaLandmark } from "@fortawesome/pro-duotone-svg-icons/faLandmark";
import { DisplayItem } from "appex-theme/src/Data/DisplayItem/DisplayItem";
import { DataTable } from "appex-theme/src/Data/DataTable/DataTable/DataTable";
import { DisplayColumn } from "appex-theme/src/Data/DataTable/DataTable/Entity/DisplayColumn";
import { CtaPlaceholder } from "appex-theme/src/Placeholder/Cta/CtaPlaceholder";

export interface ViewOrganisationProps {}

export interface ViewOrganisationState
{
  organisation: Organisation,
  organisationUpdating: boolean,
  inviteMemberFormSubmitting: boolean,
  invitations: Array<OrganisationInvitation>,
  memberships: Array<Membership>
}

export class ViewOrganisationPage extends AbstractComponent<ViewOrganisationProps, ViewOrganisationState>
{
  private BLOCK_NAME: 'ViewOrganisationPage' = 'ViewOrganisationPage';

  public constructor(props: ViewOrganisationProps)
  {
    super(props);
    
    this.state = {
      organisation: OrganisationStore.getActiveOrganisation(),
      organisationUpdating: OrganisationStore.isUpdating(),
      inviteMemberFormSubmitting: false,
      invitations: OrganisationStore.getInvitations(),
      memberships: MembershipStore.getAllMembershipsByActiveOrganisation()
    }
  }

  public componentDidMount()
  {
    // Redirect if user isn't the organisation owner
    if (!PermissionsStore.isOrganisationOwner())
    {
      ToastMessageStore.addMessage(ToastMessage.create('error', "Sorry, you don't have access to view this page"));
      return Request.goBack(this);
    }

    /**
     * Subscribe to Organisation
     */

    this.unsubscribers['organisation'] = OrganisationStore.subscribeToActiveOrganisation((organisation) => {
      this.setState({
        organisation: organisation
      })
    }, this.state.organisation)

    this.unsubscribers['organisation_updating'] = OrganisationStore.subscribeToActiveOrganisationUpdating((updating) => {
      this.setState({
        organisationUpdating: updating
      })
    }, this.state.organisationUpdating)

    this.unsubscribers['invitations'] = OrganisationStore.subscribeToInvitations((invitations) => {
      this.setState({
        invitations: invitations
      })
    }, this.state.invitations)

    this.unsubscribers['memberships'] = MembershipStore.subscribeToActiveOrgMemberships((memberships) => {
      this.setState({
        memberships: memberships
      })
    }, this.state.memberships)

    OrganisationStore.syncActiveOrganisation();
    OrganisationStore.syncInvitations();
    MembershipStore.syncActiveOrganisationMemberships();
  }

  public render()
  {
    let activeOrg = this.state.organisation;
    let breadcrumbs = [
      {
        key: 'home',
        title: <span><i className="fal fa-home"/> Home</span>,
        href: '/'
      },
      {
        key: 'organisation',
        title: activeOrg.name,
        href: '/organisation'
      }
    ];

    const submitButtonModifiers: Array<any> = ['primary'];

    if (this.state.inviteMemberFormSubmitting)
    {
      submitButtonModifiers.push('loading');
    }

    return <div className={this.BLOCK_NAME}>
      
      <div className="grid-container">

        <PageBar
          title={<div><FontAwesomeIcon
            icon={duoFaLandmark}/>{(!this.state.organisation && this.state.organisationUpdating) ? 'Loading organisation . . .' : (this.state.organisation ? this.state.organisation.name : 'View Organisation')}
          </div>}
          rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}
        />

        <div className="grid-x grid-margin-x">

          <div className="cell large-4">
            <div className="grid-x grid-margin-x">
              <div className="cell medium-6 large-12">
                <Panel
                  headerLeft={<div className="Header">Organisation Details</div>}
                  headerRight={
                    <Link to={'/organisation/update'}>
                      <i className="fas fa-edit"/>
                    </Link>
                  }
                >
                  <div className="grid-x">
                    <div className="cell">
                      <DisplayItem
                        icon={<FontAwesomeIcon icon={faAddressCard}/>}
                        content={this.state.organisation ? this.state.organisation.name : '-'}
                        updating={this.state.organisationUpdating}
                      />
                      <DisplayItem
                        icon={<FontAwesomeIcon icon={faCalendarAlt}/>}
                        content={
                          this.state.organisation
                            ? Dates.getShortDisplayValue(new Date(this.state.organisation.broughtForwardDate))
                            : '-'
                        }
                        updating={this.state.organisationUpdating}
                      />
                    </div>
                  </div>
                </Panel>

              </div>

              <div className="cell medium-6 large-12">
                <Panel headerLeft={<div className="Header">Invite New Member</div>}>
                  <Form
                    elements={[
                      TextField.create('emailAddress', 'Email Address', [Validator.required(), Validator.email()]),
                      SelectField.create(
                        'membershipId',
                        'Membership',
                        this.state.memberships,
                        (membership: Membership) => {
                          return membership.id;
                        },
                        (membership: Membership) => {
                          return membership.name;
                        },
                        [Validator.required()]
                      ),
                      <div key={'submit'} className="cell mt-2 mb-2 text--align-right">
                        <Button type={'submit'} modifiers={submitButtonModifiers}>Invite</Button>
                      </div>
                    ]}
                    onFormCapture={() => {
                    }}
                    onFormSubmit={(formState) => {
                      if (!formState.valid)
                      {
                        return;
                      }

                      this.setState({
                        inviteMemberFormSubmitting: true
                      })

                      OrganisationStore
                        .sendInvitation(formState.value.emailAddress, formState.value.membershipId)
                        .then((invitation) => {
                          this.setState({
                            inviteMemberFormSubmitting: false
                          })

                          ToastMessageStore.addMessage(ToastMessage.create('success', 'Invitation sent'));
                        })
                        .catch(() => {
                          this.setState({
                            inviteMemberFormSubmitting: false
                          })
                        })
                    }}
                  />
                </Panel>
              </div>
            </div>
          </div>

          <div className="cell large-8">
            <Panel
              headerLeft={<div className="Header">Member Invitations</div>}
            >
              {
                this.getPendingMemberInvitationsContent()
              }
            </Panel>
          </div>
        </div>
      </div>
    </div>
  }

  protected getPendingMemberInvitationsContent()
  {
    const data = this
      .state
      .invitations
      .filter((invitation) => {
        return invitation.status === 'pending' && (new Date(invitation.expiry)).valueOf() > Date.now();
      })
      .sort((a, b) => {
        return (new Date(b.created)).valueOf() - (new Date(a.created)).valueOf();
      });
    
    return <DataTable
      data={data}
      displayColumns={[
        DisplayColumn.create(
          'Email',
          'emailAddress',
          (invitation: OrganisationInvitation) => {
            return invitation.emailAddress;
          },
          3
        ),
        DisplayColumn.create(
          'Status',
          null,
          (invitation: OrganisationInvitation) => {
            return OrganisationInvitation.getStatusDisplayText(invitation);
          }
        ),
        DisplayColumn.create(
          'Action',
          null,
          (invitation: OrganisationInvitation) => {
            return <>
              {
                invitation.status === 'pending'
                && new Date(invitation.expiry).valueOf() > Date.now()
                 ? <div
                    className={'mr-6 cursor--pointer'}
                    onClick={() => {
                      OrganisationStore.cancelInvitation(invitation.id)
                    }}>
                    <FontAwesomeIcon icon={faBan}/>
                  </div>
                  : '-'
              }
            </>
          }
        ),
      ]}
      searchable={true}
      sortable={true}
      noDataContent={this.getPendingInvitesContent()}
      paginationControls={true}
      showRowsPerPage={false}
      defaultRowsPerPage={5}
      dataUpdating={this.state.organisationUpdating}
    />
  }

  protected getPendingInvitesContent()
  {
    return <div className="cell">
      <CtaPlaceholder
        icon={faUsers}
        heading={'No Pending Invites Found'}
        text={`No pending member invitations for ${this.state.organisation.name}`}
      />
    </div>;
  }

}
