
import * as React from 'react';
import {Link} from 'react-router-dom';
import { Money } from "appex-theme/src/Utility/Money";
import { Request } from "appex-theme/src/Utility/Request";
import {AbstractComponent} from "../../../../component/AbstractComponent";
import {PageBar} from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import {Breadcrumbs} from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import {Panel} from "appex-theme/src/Core/Panel/Panel";
import { AccountStore } from "../../../../data/Account/AccountStore";
import {MemberStore} from "../../../../data/Member/MemberStore";
import {Member} from "../../../../data/Member/Entity/Member";
import {ToastMessageStore} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import {ToastMessage} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";
import './scss/ViewAllMembersPage.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "appex-theme/src/Core/Button/Button";
import {faEye} from '@fortawesome/pro-regular-svg-icons/faEye'
import {faEdit} from '@fortawesome/pro-regular-svg-icons/faEdit'
import {faUsers} from "@fortawesome/pro-duotone-svg-icons/faUsers";
import {DataTable} from "appex-theme/src/Data/DataTable/DataTable/DataTable";
import {DisplayColumn} from "appex-theme/src/Data/DataTable/DataTable/Entity/DisplayColumn";
import {CtaPlaceholder} from "appex-theme/src/Placeholder/Cta/CtaPlaceholder";
import { PermissionsStore } from "../../../../data/Permissions/PermissionsStore";

export interface ViewAllMembersProps {}
export interface ViewAllMembersState {
  members: Array<Member>,
  memberBalancesByUserOrgId: Object,
  updating: boolean
}

export class ViewAllMembersPage extends AbstractComponent<ViewAllMembersProps, ViewAllMembersState>
{
  public constructor(props: ViewAllMembersProps)
  {
    super(props);

    const members = MemberStore.getAllMembersByActiveOrganisation();
    let memberBalancesByUserOrgId = {};
    
    if (PermissionsStore.hasPermission('financial:read'))
    {
      memberBalancesByUserOrgId = MemberStore.getAllBalancesByUserOrgId()
    }

    this.state = {
      members: members ? members : [],
      memberBalancesByUserOrgId: memberBalancesByUserOrgId,
      updating: false
    };
  }

  public componentDidMount()
  {
    // Redirect if you don't have member:read permissions
    if (!PermissionsStore.hasPermission('member:read'))
    {
      ToastMessageStore.addMessage(ToastMessage.create('error', "Sorry, you don't have access to view this page"));
      return Request.goBack(this);
    }
    
    this.unsubscribers['member_updating'] = MemberStore.subscribeToUpdating(
      (updating: boolean) => {
        this.setState({
          updating: updating
        })
      },
      this.state.updating
    )
    
    this.unsubscribers['members'] = MemberStore.subscribeToActiveOrgMembers(
      (members: Array<Member>) => {
        this.setState({
          members: members
        })
      },
      this.state.members
    )
    
    this.unsubscribers['member_balances'] = MemberStore.subscribeToAllMemberBalances(
      (allBalancesByUserOrgId: Array<Object>) => {
        this.setState({
          memberBalancesByUserOrgId: allBalancesByUserOrgId
        })
      },
      this.state.memberBalancesByUserOrgId
    )

    MemberStore.syncActiveOrganisationMembers();
    AccountStore.syncActiveOrganisationAccounts(); // To fetch transaction data for balances
  }

  public render()
  {
    let hasMemberWritePermissions = PermissionsStore.hasPermission('member:write');
    
    let breadcrumbs = [
      {
        key: 'home',
        title: <span><i className="fal fa-home"/> Home</span>,
        href: '/'
      },
      {
        key: 'members',
        title: 'Members'
      }
    ];

    let displayColumns = [
      DisplayColumn.create(
        'Name',
        'lastName',
        (member: Member) => {
          let firstName = member.firstNames.split(' ')[0];
          firstName = member.preferredName ? member.preferredName : firstName;
          return firstName + ' ' + member.lastName;
        },
        2
      ),
      DisplayColumn.create(
        'Email',
        'emailAddress',
        (member: Member) => {
          return member.emailAddress;
        },
        2
      ),
      DisplayColumn.create(
        'Address',
        'address:town',
        (member: Member) => {
          
          if (member.address)
          {
            let firstLine = member.address.town;
            let secondLine = member.address.county;
            
            if (firstLine && secondLine)
            {
              return firstLine + ', ' + secondLine;
            }
            
            if (firstLine) return firstLine;
            
            if (secondLine) return secondLine;
          }
          return '';
        },
        2
      )
    ]
    
    let members = this.state.members;
    
    if (PermissionsStore.hasPermission('financial:read'))
    {
      members.forEach((member: Member) => {
        member.accountBalance = this.state.memberBalancesByUserOrgId[member.userOrgId] || 0;
      })
      
      displayColumns.push(
        DisplayColumn.create(
          'Account Balance',
          'accountBalance',
          (member: Member) => {
            let prefix;
            
            if (member.accountBalance >= 0)
            {
              prefix = '+';
            }
            
            return `${prefix ? prefix : ''}${Money.getFormattedPrice(member.accountBalance)}`;
          },
          2
        )
      )
    }
    
    displayColumns.push(
      DisplayColumn.create(
        'Action',
        null,
        (member: Member) => {
          
          let updateIcon = hasMemberWritePermissions
            ? <Link to={'/members/' + member.userOrgId + '/update'}>
              <FontAwesomeIcon icon={faEdit}/>
            </Link>
            : null;
          
          return <div>
            <Link to={'/members/' + member.userOrgId} className={'mr-6'}>
              <FontAwesomeIcon icon={faEye}/>
            </Link>
            {updateIcon}
          </div>
        }
      )
    );
    
    let createNewMemberButton = hasMemberWritePermissions
      ? <div className="text--align-center-sm-only text--align-right-md mt-4 mb-3">
          <Link to={'/members/new'}>
            <Button modifiers={['primary']}>
              Create New Member
            </Button>
          </Link>
        </div>
      : null;
    return <div className={'ViewAllMembersPage'}>

      <div className="grid-container">
        <PageBar title={<div><FontAwesomeIcon icon={faUsers}/>Members</div>} rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}/>

        <div className="grid-x">
          <div className="cell">
            <Panel>
              {
                <div>
                  <DataTable
                    data={members}
                    displayColumns={displayColumns}
                    searchable={true}
                    sortable={true}
                    onSearch={this.onSearch.bind(this)}
                    noDataContent={
                      <CtaPlaceholder
                        icon={faUsers}
                        heading={'No Members Found'}
                        text={'This organisation currently has no members'}
                      />
                    }
                    paginationControls={true}
                    dataUpdating={this.state.updating}
                  />
                  {createNewMemberButton}
                </div>
              }
            </Panel>
          </div>
        </div>
      </div>
    </div>
  }

  protected onSearch(value: string, item: Member)
  {
    let lowerCaseSearchValue = this.strip(value);

    return (
      value == ''
      || this.strip(item.firstNames).indexOf(lowerCaseSearchValue) !== -1
      || this.strip(item.lastName).indexOf(lowerCaseSearchValue) !== -1
      || (this.strip(item.firstNames) + this.strip(item.lastName)).indexOf(lowerCaseSearchValue) !== -1
      || item.preferredName && (this.strip(item.preferredName + item.lastName)).indexOf(lowerCaseSearchValue) !== -1
      || this.strip(item.emailAddress).indexOf(lowerCaseSearchValue) !== -1
    )
  }

  protected strip(string: string)
  {
    return string.toLowerCase().replace(' ', '');
  }
}
