
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "appex-theme/src/Core/Button/Button";
import { Modal } from "appex-theme/src/Core/Modal/Modal";
import { Header } from "appex-theme/src/Form/Input/Header/Header";
import { ToastMessage } from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";
import { ToastMessageStore } from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import * as React from 'react';
import {AbstractComponent} from "../../../component/AbstractComponent";
import {PageBar} from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import {Breadcrumbs} from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import {Panel} from "appex-theme/src/Core/Panel/Panel";
import './scss/DashboardPage.scss';
import { Organisation } from "../../../data/Organisation/Entity/Organisation";
import { OrganisationInvitation } from "../../../data/Organisation/Entity/OrganisationInvitation";
import { OrganisationStore } from "../../../data/Organisation/OrganisationStore";
import { faLandmark } from "@fortawesome/pro-solid-svg-icons/faLandmark";

export interface DashboardPageProps {}
export interface DashboardPageState {
  activeOrganisation: Organisation,
  organisations: Array<Organisation>,
  myInvitations: Array<OrganisationInvitation>,
  acceptOrgInvitationModalOpen: boolean,
  modalOrgInvitation: OrganisationInvitation,
  acceptingOrgInvitation: boolean
}

export class DashboardPage extends AbstractComponent<DashboardPageProps, DashboardPageState>
{
  public constructor(props: DashboardPageProps)
  {
    super(props);
    
    this.state ={
      activeOrganisation: OrganisationStore.getActiveOrganisation(),
      organisations: OrganisationStore.getOrganisations(),
      myInvitations: OrganisationStore.getMyInvitations(),
      acceptOrgInvitationModalOpen: false,
      modalOrgInvitation: null,
      acceptingOrgInvitation: false
    }
  }
  
  public componentDidMount()
  {
    this.unsubscribers['activeOrganisation'] = OrganisationStore.subscribeToActiveOrganisation(
      (activeOrganisation: Organisation) => {
        this.setState({
          activeOrganisation: activeOrganisation
        })
      },
      this.state.activeOrganisation
    );
    
    this.unsubscribers[ 'organisations' ] = OrganisationStore.subscribeToOrganisations((organisations: Array<Organisation>) =>
      {
        this.setState({
          organisations: organisations
        })
      },
      this.state.organisations
    );
    
    this.unsubscribers[ 'my_invitations' ] = OrganisationStore.subscribeToMyInvitations((organisationInvitations: Array<OrganisationInvitation>) =>
      {
        this.setState({
          myInvitations: organisationInvitations
        })
      },
      this.state.myInvitations
    );
    
    OrganisationStore.syncMyInvitations();
  }
  
  public render()
  {
    const breadcrumbs = [
      {
        key: 'home',
        title: <span><i className="fal fa-home"/> Home</span>,
        href: '/'
      },
      {
        key: 'dashboard',
        title: 'Dashboard',
      },
    ];

    return <div className={'DashboardPage'}>

      <div className="grid-container">
        <PageBar title={'Dashboard'} rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}/>
        
        <div className="grid-x grid-margin-x">
          <div className="cell medium-4">
            {this.getOrganisationsPanel()}
          </div>
          
          <div className="cell medium-8">
            {this.getOrganisationInvitationsPanel()}
          </div>
        </div>
      </div>
  
  
      {this.getAcceptOrgInvitationModal()}
      
    </div>
  }
  
  private getOrganisationsPanel()
  {
    return <Panel
      headerLeft={<div className="Header">{this.state.organisations.length > 1 ? 'Switch Organisation' : 'My Organisations'}</div>}
    >
      {
        !this.state.organisations.length
          ? 'No organisations found.'
          : this.state.organisations.map((organisation: Organisation, index) => {
              return <div
                className={'StatsRow cursor--pointer'}
                key={index}
                onClick={OrganisationStore.setActiveOrganisation.bind(undefined, organisation)}
              >
                <div className="StatsRow__Left">
                  { organisation.name }
                </div>
                <div className="StatsRow__Right">
                  {
                    this.state.activeOrganisation?.id === organisation.id
                      ? <span><FontAwesomeIcon className={'mr-1'} icon={faCheckCircle}/> Active</span>
                      : <FontAwesomeIcon icon={faArrowRight}/>
                  }
                </div>
              </div>
            })
      }
    </Panel>
  }
  
  private getOrganisationInvitationsPanel()
  {
    const pendingInvitations = this
      .state
      .myInvitations
      .filter((invitation) => {
        return invitation.status === 'pending' && (new Date(invitation.expiry).valueOf() > Date.now());
      });
    
    return <Panel
      headerLeft={<div className="Header">Organisation Invitations</div>}
    >
      {
        !pendingInvitations.length
          ? 'You have no new invitations.'
          : pendingInvitations.map((invitation: OrganisationInvitation, index) => {
              return <div
                className={'StatsRow cursor--pointer'}
                key={index}
                onClick={() => {
                  this.setState({
                    acceptOrgInvitationModalOpen: true,
                    modalOrgInvitation: invitation
                  })
                }}
              >
                <div className="StatsRow__Left">
                  { invitation.organisationName }
                </div>
                <div className="StatsRow__Right">
                  <Button modifiers={['primary']}>Accept</Button>
                </div>
              </div>
            })
      }
    </Panel>
  }
  
  private getAcceptOrgInvitationModal()
  {
    const buttonModifiers: Array<any> = ['primary'];
    
    if (this.state.acceptingOrgInvitation)
    {
      buttonModifiers.push('loading');
    }
    
    return <Modal
      open={this.state.acceptOrgInvitationModalOpen}
      close={async () => {
        this.setState({
          acceptOrgInvitationModalOpen: false
        })
    
        await this.wait(300);
    
        this.setState({
          modalOrgInvitation: null
        })
      }}
    >
      <Header
        text={'Confirm'}
        icon={faLandmark}
      />
      
      <div className="pt-3 pb-2">
        <p>
          Click the "Confirm" button below to accept your invitation to join {this.state.modalOrgInvitation?.organisationName}.
        </p>
        <p>
          By doing so, you agree to share your Shumi profile information with the {this.state.modalOrgInvitation?.organisationName} administrators which includes your name, email address, date of birth and contact information.
        </p>
      </div>
      
      <div className="Hr mt-0"/>
  
      <div className="text--align-right mt-3 mb-1">
        <Button
          modifiers={buttonModifiers}
          onClick={() => {
            this.setState({
              acceptingOrgInvitation: true
            })
            
            OrganisationStore
              .acceptInvitation(this.state.modalOrgInvitation.id, this.state.modalOrgInvitation.organisationId)
              .then(async () => {
                this.setState({
                  acceptOrgInvitationModalOpen: false
                })
                
                await this.wait(300);
                
                this.setState({
                  modalOrgInvitation: null
                })
              })
              .catch((error) => {
                ToastMessageStore.addMessage(
                  ToastMessage.create('error', error.message)
                );
              })
              .finally(() => {
                this.setState({
                  acceptingOrgInvitation: false
                })
              })
          }}
        >
          Confirm
        </Button>
      </div>
      
    </Modal>
  }
}
