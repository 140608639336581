import {Objects} from "phusion/src/Core/Objects/Objects";

export class Ticket
{
  public id: number;
  public name: number;
  public price: number;
  public creditAccountId: string;
  public debitAccountId: number | 'charge_member';

  public static create(data: Object = null): Ticket
  {
    if (!data)
    {
      return new this();
    }

    let mutators = {
      'price': (price) => {
        return Number(price);
      },
      'debitAccountId': (debitAccountId) => {
        if (!debitAccountId)
        {
          return 'charge_member';
        }

        return debitAccountId;
      },
    };

    return Objects.hydrate(new Ticket(), data, mutators) as Ticket;
  }

  public serialize(): Object
  {
    let mutators = {
      'debitAccountId': (debitAccountId) => {
        if (debitAccountId == 'charge_member')
        {
          return null;
        }

        return debitAccountId;
      },
    };
    return Objects.hydrate({}, this, mutators);
  }
}
