
import { CtaPlaceholder } from "appex-theme/src/Placeholder/Cta/CtaPlaceholder";
import { Navigate } from "appex-theme/src/Core/Navigate/Navigate";
import * as React from 'react';
import {AbstractComponent} from "../../../component/AbstractComponent";
import './scss/DashboardNotFoundPage.scss';
import {faExclamationTriangle} from "@fortawesome/pro-duotone-svg-icons/faExclamationTriangle";

export interface DashboardNotFoundPageProps {}
export interface DashboardNotFoundPageState {}

export class DashboardNotFoundPage extends AbstractComponent<DashboardNotFoundPageProps, DashboardNotFoundPageState>
{
  private BLOCK_NAME: 'DashboardNotFoundPage' = 'DashboardNotFoundPage';
  
  public render()
  {
    const url = new URL(window.location.href);
    
    if (url.pathname.startsWith('/login') || url.pathname.startsWith('/signup'))
    {
      return <Navigate to={url.searchParams.has('destination') ? url.searchParams.get('destination') : '/'}/>
    }
    
    return <div className={this.BLOCK_NAME}>
      <div className={`${this.BLOCK_NAME}__Container`}>
  
        <CtaPlaceholder
          icon={faExclamationTriangle}
          heading={'Not Found'}
          text={"Sorry, this page can't be found"}
          buttonContent={'Back to Dashboard'}
          ctaUri={"/"}
        />
      </div>
    </div>
  }
}
