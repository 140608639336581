
import { SelectField } from "appex-theme/src/Form/Input/Select/SelectField";
import { Numbers } from "appex-theme/src/Utility/Numbers";
import * as React from 'react';
import { Header } from "appex-theme/src/Form/Input/Header/Header";
import {AbstractComponent} from "../../../../component/AbstractComponent";
import {PageBar} from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import {Breadcrumbs} from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import {Form} from "appex-theme/src/Form/Form/Form";
import {TextField} from "appex-theme/src/Form/Input/Text/TextField";
import {Validator} from "appex-theme/src/Form/Form/Validator/Validator";
import {Button} from "appex-theme/src/Core/Button/Button";
import {Panel} from "appex-theme/src/Core/Panel/Panel";
import { AccountStore } from "../../../../data/Account/AccountStore";
import { Account } from "../../../../data/Account/Entity/Account";
import {ToastMessageStore} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import {DataStoreError} from "ts-redux/src/DataStore/DataStoreError";
import {ToastMessage} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";
import './scss/CreateUpdateMembershipPage.scss';
import {Objects} from "phusion/src/Core/Objects/Objects";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { Membership } from "../../../../data/Membership/Entity/Membership";
import { MembershipStore } from "../../../../data/Membership/MembershipStore";
import { PermissionsStore } from "../../../../data/Permissions/PermissionsStore";
import {faHome} from "@fortawesome/pro-light-svg-icons/faHome";
import {Request} from 'appex-theme/src/Utility/Request';
import {faAddressCard} from "@fortawesome/pro-solid-svg-icons/faAddressCard";
import {faAddressCard as duoFaAddressCard} from "@fortawesome/pro-duotone-svg-icons/faAddressCard";

export interface CreateMembershipProps {}

export interface CreateMembershipState {
  dataUpdating: boolean,
  membership: Membership,
  isUpdatePage: boolean,
  formSubmitting: boolean,
  membershipId: number,
  accounts: Array<Account>
}

export class CreateUpdateMembershipPage extends AbstractComponent<CreateMembershipProps, CreateMembershipState>
{
  protected unsubscribers = {};

  public constructor(props: CreateMembershipProps)
  {
    super(props);

    let membership = null;

    let membershipId = parseInt(props['params'].membershipId);

    if (membershipId)
    {
      membership = MembershipStore.getByIdAndActiveOrganisation(membershipId);
    }

    this.state = {
      dataUpdating: false,
      membership: membership,
      isUpdatePage: Boolean(membershipId),
      formSubmitting: false,
      membershipId: membershipId,
      accounts: AccountStore.getAllAccountsByActiveOrganisation()
    }

  }

  public componentDidMount()
  {
    // Redirect if you don't have membership:read permissions
    if (!PermissionsStore.hasPermission('membership:write'))
    {
      ToastMessageStore.addMessage(ToastMessage.create('error', "Sorry, you don't have access to view this page"));
      return Request.goBack(this);
    }
    
    this.unsubscribers['accounts'] = AccountStore.subscribeToActiveOrgAccounts(
      (accounts: Array<Account>) => {
        this.setState({
          accounts: accounts
        })
      },
      this.state.accounts
    );
    
    AccountStore.syncActiveOrganisationAccounts();

    if (!this.state.isUpdatePage)
    {
      return;
    }

    this.unsubscribers['membership'] = MembershipStore.subscribeToMembershipById(
      this.state.membershipId,
      (membership: Membership) => {
        this.setState({
          membership: membership
        })
      },
      this.state.membership
    );

    // Sync membership
    MembershipStore.syncMembership(this.state.membershipId);
  }

  public componentWillUnmount()
  {
    if (this.unsubscribers)
    {
      for (let key in this.unsubscribers)
        if (this.unsubscribers.hasOwnProperty(key))
      {
        this.unsubscribers[key]();
      }
    }
  }

  public render()
  {
    let breadcrumbs = this.getBreadCrumbs();

    let submitButtonModifiers: Array<any> = [
      'primary',
      this.state.dataUpdating ? 'loading' : ''
    ];
    
    const formElements = this.getFormElements()
    
    formElements.push(
      <div className="cell" key={'submit'}>
        <div className="mt-4 mb-4 text--align-right"><Button type={'submit'} modifiers={submitButtonModifiers}>
          { this.state.isUpdatePage ? 'Update Membership' : 'Create Membership' }
        </Button></div>
      </div>
    )

    return <div className={'CreateUpdateMembershipPage'}>

      <div className="grid-container">
        <PageBar
          title={<div><FontAwesomeIcon icon={duoFaAddressCard}/>{this.state.isUpdatePage ? 'Update Membership' : 'Create a New Membership'}</div>}
          rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}
        />

        <Panel>
          <Form
            name={'createMembership'}
            elements={formElements}
            onFormSubmit={this.onFormSubmit.bind(this)}
            onFormCapture={this.onFormSubmit.bind(this)}
          />
        </Panel>

      </div>

    </div>
  }

  protected getFormElements()
  {
    let membership = this.state.membership;
    
    const revenueAccounts = this.state.accounts.filter((account: Account) => {
      return account.category === 'revenue';
    })

    return [
      <div className="cell" key={'membership_details'}>
        <Header
          text={'Membership Details'}
          icon={faAddressCard}
        />
      </div>,
      // Name
      TextField
        .create(
          'name',
          'Name',
          [ Validator.required() ],
          membership ? membership.name : null
        )
        .setGridClass('cell medium-6'),
      // Credit account
      SelectField
        .create(
          'creditAccountId',
          'Credit Account',
          revenueAccounts,
          (account: Account) => {
            return account.id;
          },
          (account: Account) => {
            return <div>
              <div>{account.name}</div>
              <div className="text--subtext">{Account.getCategoryDisplayValue(account)}</div>
            </div>;
          },
          [ Validator.required() ],
          membership ? membership.creditAccountId : null,
          null,
          true,
          AccountStore.onSearch,
        )
        .setGridClass('cell medium-6'),
      // Price
      TextField
        .create(
          'price',
          'Price',
          [
            Validator.required(),
            Validator.decimal()
          ],
          membership ? membership.price : null
        )
        .setGridClass('cell medium-6')
        .setFormatter(
          (inputValue: string) => {
            if (inputValue === '') return inputValue;
            return Numbers.toDecimal(inputValue)
          }
        ),
      // Transaction Description
      TextField
        .create(
          'transactionDescription',
          'Transaction Description',
          [ Validator.required() ],
          membership ? membership.transactionDescription : null
        )
        .setGridClass('cell medium-6'),
    ]
  }

  protected getBreadCrumbs()
  {
    if (
      this.state.isUpdatePage
      && this.state.membership
    )
    {
      return [
        {
          key: 'home',
          title: <span><FontAwesomeIcon icon={faHome}/> Home</span>,
          href: '/'
        },
        {
          key: 'membership',
          title: 'Memberships',
          href: '/memberships'
        },
        {
          key: 'update_membership',
          title: 'Update ' + this.state.membership.name
        },
      ];
    }

    return [
      {
        key: 'home',
        title: <span><FontAwesomeIcon icon={faHome}/> Home</span>,
        href: '/'
      },
      {
        key: 'memberships',
        title: 'Memberships',
        href: '/memberships'
      },
      {
        key: 'create_membership',
        title: 'New Membership'
      },
    ];
  }

  protected onFormSubmit(formState)
  {
    if (this.state.formSubmitting)
    {
      return;
    }

    if (formState.valid)
    {
      if (this.state.membership && this.state.membership.id)
      {
        let newMembership: any = Objects.hydrate(
          Objects.clone(this.state.membership),
          formState.value
        );

        this.setState({
          dataUpdating: true
        });

        return this.updateMembership(newMembership);
      }

      let newMembership = Membership.create(formState.value);

      this.setState({
        dataUpdating: true
      });

      return this.createMembership(newMembership);
    }
  }

  protected updateMembership(membership: Membership)
  {
    // Send to redux
    let promise = MembershipStore.updateMembership(membership);

    promise.then((membership: Membership) => {
      this.setState({
        dataUpdating: false,
      });

      ToastMessageStore.addMessage(ToastMessage.create('success', 'Membership updated'));
      Request.redirect('/memberships', this);
    });

    promise.catch((error: DataStoreError) => {
      this.setState({
        dataUpdating: false
      });
    })
  }

  protected createMembership(membership: Membership)
  {
    this.setState({
      formSubmitting: true
    });
    // Send to redux
    let promise = MembershipStore.createNewMembership(membership);

    promise.then((membership: Membership) => {
      this.setState({
        dataUpdating: false,
        formSubmitting: false,
      });

      ToastMessageStore.addMessage(ToastMessage.create('success', 'Membership created'));
      Request.redirect('/memberships', this);
    });

    promise.catch((error: DataStoreError) => {
      this.setState({
        dataUpdating: false,
        formSubmitting: false
      });
    })
  }

  protected onFormCapture(formValue)
  {

  }
}
