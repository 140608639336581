
import * as React from 'react';
import { Header } from "appex-theme/src/Form/Input/Header/Header";
import {AbstractComponent} from "../../../../component/AbstractComponent";
import {PageBar} from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import {Breadcrumbs} from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import {Form} from "appex-theme/src/Form/Form/Form";
import {TextField} from "appex-theme/src/Form/Input/Text/TextField";
import {Validator} from "appex-theme/src/Form/Form/Validator/Validator";
import {Button} from "appex-theme/src/Core/Button/Button";
import {Panel} from "appex-theme/src/Core/Panel/Panel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SelectField} from "appex-theme/src/Form/Input/Select/SelectField";
import {Account} from "../../../../data/Account/Entity/Account";
import {DateField} from "appex-theme/src/Form/Input/Date/DateField";
import {AccountStore} from "../../../../data/Account/AccountStore";
import {ToastMessageStore} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import {ToastMessage} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";
import {MemberStore} from "../../../../data/Member/MemberStore";
import {Member} from "../../../../data/Member/Entity/Member";
import './scss/AnyOtherTransactionPage.scss';
import {faCreditCard} from "@fortawesome/pro-light-svg-icons/faCreditCard";
import {faHome} from "@fortawesome/pro-light-svg-icons/faHome";
import {faUser} from "@fortawesome/pro-light-svg-icons/faUser";
import {faExchangeAlt} from "@fortawesome/pro-duotone-svg-icons/faExchangeAlt";
import {faCreditCardFront} from "@fortawesome/pro-solid-svg-icons/faCreditCardFront";
import { PermissionsStore } from "../../../../data/Permissions/PermissionsStore";
import {Transaction} from "../../../../data/Transaction/Entity/Transaction";
import {TransactionStore} from "../../../../data/Transaction/TransactionStore";
import {Request} from "appex-theme/src/Utility/Request";
import {DataStoreError} from "ts-redux/src/DataStore/DataStoreError";

export interface AnyOtherTransactionProps {}

export interface AnyOtherTransactionState {
  formSubmitting: boolean,
  accounts: Array<Account>
  members: Array<Member>,
  dataUpdating: boolean
}

export class AnyOtherTransactionPage extends AbstractComponent<AnyOtherTransactionProps, AnyOtherTransactionState>
{
  public constructor(props: AnyOtherTransactionProps)
  {
    super(props);

    let accounts = AccountStore.getAllAccountsByActiveOrganisation();
    let members = MemberStore.getAllMembersByActiveOrganisation();

    this.state = {
      formSubmitting: false,
      accounts: accounts,
      members: members,
      dataUpdating: false
    }
  }

  public componentDidMount(): void
  {
    if (!PermissionsStore.hasPermission('financial:write'))
    {
      ToastMessageStore.addMessage(ToastMessage.create('error', "Sorry, you don't have access to view this page"));
      return Request.goBack(this);
    }
    
    this.unsubscribers['accounts'] = AccountStore.subscribeToActiveOrgAccounts(
      (accounts: Array<Account>) => {
        this.setState({
          accounts: accounts
        })
      },
      this.state.accounts
    )
    
    this.unsubscribers['members'] = MemberStore.subscribeToActiveOrgMembers(
      (members: Array<Member>) => {
        this.setState({
          members: members
        })
      },
      this.state.members
    )

    AccountStore.syncActiveOrganisationAccounts();
    MemberStore.syncActiveOrganisationMembers();
  }

  public render()
  {
    let breadcrumbs = this.getBreadCrumbs();

    let submitButtonModifiers: Array<any> = [
      'primary',
      this.state.formSubmitting ? 'loading' : ''
    ];
    
    const formElements = this.getFormElements();
    
    formElements.push(
      <div className="cell">
        <div className="mt-4 mb-4 text--align-right" key={'submit'}>
          <Button type={'submit'} modifiers={submitButtonModifiers}>
            { 'Save Transaction' }
          </Button>
        </div>
      </div>
    );

    return <div className={'AnyOtherTransactionPage'}>

      <div className="grid-container">
        <PageBar
          title={<div><FontAwesomeIcon icon={faExchangeAlt}/>Add a Transaction</div>}
          rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}
        />
        <Panel>
          <Form
            name={'createTransaction'}
            elements={formElements}
            onFormSubmit={this.onFormSubmit.bind(this)}
            onFormCapture={this.onFormSubmit.bind(this)}
          />
        </Panel>

      </div>

    </div>
  }

  protected getFormElements()
  {
    let accounts = this.state.accounts;
    let bankAccounts = this.state.accounts.filter((account: Account) => {
      return account.type == 'bank';
    });

    let members = MemberStore.getAllMembersByActiveOrganisation();

    let accountsAndMembers = []
      // Expense/Expenditure accounts
      .concat(
        accounts.filter((account: Account) => {
          return Boolean(account.category == 'expense')
        })
      )
      // Members
      .concat(members)
      // All other accounts
      .concat(
        accounts.filter((account: Account) => {
          return Boolean(
            account.category !== 'expense'
            && account.category !== 'sub_account'
          )
        })
      )
    ;

    return [
      <div className="cell" key={'payment_details'}>
        <Header
          text={'Payment Details'}
          icon={faCreditCardFront}
        />
      </div>,
      // Account to debit (from)
      SelectField
        .create(
          'debitAccountId',
          'Account To Debit (From)',
          accountsAndMembers,
          (item: Account | Member) =>
          {
            // Account
            if (item.hasOwnProperty('name'))
            {
              return 'account_' + item.id;
            }
  
            // Member
            return 'member_' + item['userOrgId'];
          },
          (item: Account | Member) =>
          {
            if (item.hasOwnProperty('name'))
            {
              return <div><FontAwesomeIcon className={'mr-1'} icon={faCreditCard}/>{item['name']}</div>;
            }
  
            // @ts-ignore
            return <div><FontAwesomeIcon className={'mr-1'} icon={faUser}/>{Member.getFullPreferredName(item)}</div>;
          },
          [Validator.required()],
          null,
          null,
          true,
          (value: string, accountOrMember: Account | Member) => {
            if (accountOrMember['name'])
            {
              return AccountStore.onSearch(value, accountOrMember as Account);
            }
  
            return Member.onSearch(value, accountOrMember as Member);
          }
        )
        .setGridClass('cell medium-6'),
      // Account to credit (to)
      TextField
        .create(
          'amount',
          'Amount',
          [ Validator.required() ],
          null
        )
        .setGridClass('cell medium-6'),
      // Amount
      SelectField
        .create(
          'creditAccountId',
          'Account To Credit (To)',
          accountsAndMembers,
          (item: Account | Member) =>
          {
            // Account
            if (item.hasOwnProperty('name'))
            {
              return 'account_' + item.id;
            }
            
            // Member
            return 'member_' + item['userOrgId'];
          },
          (item: Account | Member) =>
          {
            if (item.hasOwnProperty('name'))
            {
              return <div><FontAwesomeIcon className={'mr-1'} icon={faCreditCard}/>{item['name']}</div>;
            }
            
            // @ts-ignore
            return <div><FontAwesomeIcon className={'mr-1'} icon={faUser}/>{Member.getFullPreferredName(item)}</div>;
          },
          [Validator.required()],
          null,
          null,
          true,
          (value: string, accountOrMember: Account | Member) => {
            if (accountOrMember['name'])
            {
              return AccountStore.onSearch(value, accountOrMember as Account);
            }
            
            return Member.onSearch(value, accountOrMember as Member);
          },
        )
        .setGridClass('cell medium-6'),
      // Date
      DateField
        .create(
          'date',
          'Date',
          [ Validator.required() ],
          null
        )
        .setGridClass('cell medium-6'),
      TextField
        .create(
          'description',
          'Description',
          [ Validator.required() ]
        )
    ]
  }

  protected getBreadCrumbs()
  {
    return [
      {
        key: 'home',
        title: <span><FontAwesomeIcon icon={faHome}/> Home</span>,
        href: '/'
      },
      {
        key: 'transactions',
        title: 'Transactions',
        href: '/transactions'
      },
      {
        key: 'other',
        title: 'Other'
      },
    ];
  }

  protected onFormSubmit(formState)
  {
    if (this.state.formSubmitting)
    {
      return;
    }

    if (formState.valid)
    {
      if (formState.valid)
      {
        let formValue = formState.value;

        // Set the debit account id or user org id
        let debitAccountParts = formValue.debitAccountId.split('_');

        if (debitAccountParts[0] == 'account')
        {
          formValue.debitAccountId = debitAccountParts[1];
        }
        else if (debitAccountParts[0] == 'member')
        {
          delete formValue.debitAccountId;
          formValue['userOrgId'] = debitAccountParts[1];
        }
        else
        {
          // We don't know what's happening so set to null to make backend fail to avoid any incorrect transactions
          formValue.debitAccountId = null;
        }

        // Set the credit account id or user org id
        let creditAccountParts = formValue.creditAccountId.split('_');

        if (creditAccountParts[0] == 'account')
        {
          formValue.creditAccountId = creditAccountParts[1];
        }
        else if (creditAccountParts[0] == 'member')
        {
          if (formValue['userOrgId'])
          {
            return ToastMessageStore.addMessage(
              ToastMessage.create('error', 'Transactions between members are not permitted')
            )
          }

          delete formValue.creditAccountId;
          formValue['userOrgId'] = creditAccountParts[1];
        }
        else
        {
          // We don't know what's happening so set to null to make backend fail to avoid any incorrect transactions
          formValue.creditAccountId = null;
        }


        this.setState({
          formSubmitting: true
        });

        let newTransaction = Transaction.create(formValue);

        let promise = TransactionStore.createNewTransaction(newTransaction);

        promise.then((transaction: Transaction) =>
        {
          this.setState({
            dataUpdating: false,
            formSubmitting: false
          });

          ToastMessageStore.addMessage(ToastMessage.create('success', 'Transaction saved'));

          let timeout = setTimeout(() =>
          {
            let matchingAccounts = this.state.accounts.filter((account: Account) =>
            {
              return Boolean(account.id == transaction.creditAccountId);
            });

            if (matchingAccounts.length)
            {
              Request.redirect('/accounts/' + matchingAccounts[0]['id'], this);
            }
            else
            {
              Request.redirect('/members/' + transaction.userOrgId, this);
            }


            clearTimeout(timeout);
          }, 0);
        });

        promise.catch((error: DataStoreError) => {
          this.setState({
            dataUpdating: false,
            formSubmitting: false
          });
        })
      }
    }
  }

  protected onFormCapture(formValue)
  {

  }
}
