import { faBalanceScale } from "@fortawesome/pro-solid-svg-icons/faBalanceScale";
import { faAddressCard } from "@fortawesome/pro-solid-svg-icons/faAddressCard";
import { faLandmark } from "@fortawesome/pro-solid-svg-icons/faLandmark";
import { ToastMessage } from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";
import { ToastMessageStore } from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import { Cookies } from "phusion/src/Core/Cookies/Cookies";
import * as React from "react";
import { Selector } from "appex-theme/src/Layout/Dashboard/Selector/Selector";
import { Organisation } from "../data/Organisation/Entity/Organisation";
import { OrganisationStore } from "../data/Organisation/OrganisationStore";
import { PermissionsStore } from "../data/Permissions/PermissionsStore";
import { ViewBalanceSheetPage } from "./Page/BalanceSheet/View/ViewBalanceSheetPage";
import { DashboardPage } from "./Page/Dashboard/DashboardPage";
import { AbstractComponent } from "../component/AbstractComponent";
import { DashboardLayout } from "appex-theme/src/Layout/Dashboard/Layout/DashboardLayout";
import { DashboardHeader } from "appex-theme/src/Layout/Dashboard/Header/DashboardHeader";
import { DashboardFooter } from "appex-theme/src/Layout/Dashboard/Footer/DashboardFooter";
import { DashboardPageContainer } from "appex-theme/src/Layout/Dashboard/PageContainer/DashboardPageContainer";
import { SideNav } from "appex-theme/src/Layout/Dashboard/SideNav/SideNav";
import { Logo } from "../component/Logo/Logo";
import { EntryLayout } from "appex-theme/src/Layout/Entry/Layout/EntryLayout";
import { EntryPageContainer } from "appex-theme/src/Layout/Entry/PageContainer/EntryPageContainer";
import { DashboardNotFoundPage } from "./Page/DashboardNotFound/DashboardNotFoundPage";
import { LoginPage } from "./Page/Login/LoginPage";
import { ProfileDropdown } from "appex-theme/src/Layout/Dashboard/ProfileDropdown/ProfileDropdown";
import { Dropdown } from "appex-theme/src/Core/Dropdown/Dropdown";
import { ViewAllMembersPage } from "./Page/Members/ViewAll/ViewAllMembersPage";
import { CreateUpdateMemberPage } from "./Page/Members/Create/CreateUpdateMemberPage";
import { ViewMemberPage } from "./Page/Members/View/ViewMemberPage";
import { ViewAllEventsPage } from "./Page/Events/ViewAll/ViewAllEventsPage";
import { CreateUpdateEventPage } from "./Page/Events/CreateUpdate/CreateUpdateEventPage";
import { ViewEventPage } from "./Page/Events/View/ViewEventPage";
import { ViewAllAccountsPage } from "./Page/Accounts/ViewAll/ViewAllAccountsPage";
import { CreateUpdateAccountPage } from "./Page/Accounts/CreateUpdate/CreateUpdateAccountPage";
import { ViewAccountPage } from "./Page/Accounts/View/ViewAccountPage";
import { CreateUpdateMembershipPage } from "./Page/Membership/CreateUpdate/CreateUpdateMembershipPage";
import { ProcessMembershipFeesPage } from "./Page/Membership/ProcessMembershipFees/ProcessMembershipFeesPage";
import { ViewMembershipPage } from "./Page/Membership/View/ViewMembershipPage";
import { ViewAllMembershipsPage } from "./Page/Membership/ViewAll/ViewAllMembershipsPage";
import { ViewMyAccountPage } from "./Page/MyAccount/View/ViewAccountPage";
import { UpdateMyAccountPage } from "./Page/MyAccount/Update/UpdateMyAccountPage";
import { List } from "appex-theme/src/Core/List/List";
import { ToastMessageContainer } from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/ToastMessageContainer";
import { User } from "../data/User/Entity/User";
import { UserStore } from "../data/User/UserStore";
import { UpdateOrganisationPage } from "./Page/Organisation/Update/UpdateOrganisationPage";
import { ViewOrganisationPage } from "./Page/Organisation/View/ViewOrganisationPage";
import { EntryNotFoundPage } from "./Page/EntryNotFound/EntryNotFoundPage";
import { SignupPage } from "./Page/Signup/SignupPage";
import { IssueAChequePage } from "./Page/Transactions/IssueACheque/IssueAChequePage";
import { TransactionsPage } from "./Page/Transactions/TransactionsPage/TransactionsPage";
import { ReceiveAChequePage } from "./Page/Transactions/ReceiveACheque/ReceiveAChequePage";
import { MakeAPaymentPage } from "./Page/Transactions/MakeAPayment/MakeAPaymentPage";
import { ReceiveDirectCreditPage } from "./Page/Transactions/ReceiveDirectCredit/ReceiveDirectCreditPage";
import { ReceiveCashPage } from "./Page/Transactions/ReceiveCash/ReceiveCashPage";
import { ProcessPayingInSlipPage } from "./Page/Transactions/ProcessPayingInSlip/ProcessPayingInSlipPage";
import { CreateUpdateRolePage } from "./Page/Roles/CreateUpdate/CreateUpdateRolePage";
import { ViewAllRolesPage } from "./Page/Roles/ViewAll/ViewAllRolesPage";
import { ViewRolePage } from "./Page/Roles/View/ViewRolePage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from '@fortawesome/pro-solid-svg-icons/faCreditCard'
import { faUserLock } from '@fortawesome/pro-solid-svg-icons/faUserLock'
import { faExchange } from '@fortawesome/pro-solid-svg-icons/faExchange'
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons/faCalendarAlt'
import { faBars } from '@fortawesome/pro-solid-svg-icons/faBars'
import { faUserCircle } from '@fortawesome/pro-solid-svg-icons/faUserCircle'
import { faThLarge } from '@fortawesome/pro-solid-svg-icons/faThLarge'
import { faUsers } from '@fortawesome/pro-solid-svg-icons/faUsers'
import { LocalStorage } from "phusion/src/Core/Storage/LocalStorage";
import { BookingPage } from "./Page/Events/Booking/BookingPage";
import { ScreenSize } from "appex-theme/src/Utility/ScreenSize";
import { AnyOtherTransactionPage } from "./Page/Transactions/AnyOtherTransaction/AnyOtherTransactionsPage";
import { PayingInSlipPage } from "./Page/Transactions/PayingInSlip/PayingInSlipPage";

const centeredLayoutBackgroundImage = require('./img/abstract-wave.jpeg');
import { ThemeContext } from "appex-theme/src/Context/ThemeContext";

export interface WebAppProps {}

export interface WebAppState
{
  layoutSideNavOpen: boolean,
  profileDropdownOpen: boolean,
  orgSelectionDropdownOpen: boolean,
  authedUser: User,
  activeOrganisation: Organisation,
  organisations: Array<Organisation>,
  renderKey: string | number,
  theme: 'light' | 'dark'
}

export class WebApp extends AbstractComponent<WebAppProps, WebAppState>
{
  protected unsubscribers = {};
  protected onResizeHandler;
  
  public constructor(props: WebAppProps, state: {})
  {
    super(props, state);
    
    const theme = LocalStorage.get('theme')
    const stateTheme = theme || 'light';
    document.body.classList.add('theme');
    document.body.classList.add(`theme--${stateTheme}`);
    
    const activeOrg = OrganisationStore.getActiveOrganisation();
    
    this.state = {
      layoutSideNavOpen: (window.innerWidth > 1075),
      profileDropdownOpen: false,
      orgSelectionDropdownOpen: false,
      authedUser: UserStore.getUser(),
      activeOrganisation: activeOrg,
      organisations: OrganisationStore.getOrganisations(),
      renderKey: activeOrg ? activeOrg.id : 0,
      theme: stateTheme
    };
  }
  
  public componentDidMount(): void
  {
    // When user updates, update this component
    this.unsubscribers['user'] = UserStore.subscribeToUser(
      (authedUser) => {
        this.setState({
          authedUser: authedUser
        })
      },
      this.state.authedUser
    );
    
    this.unsubscribers['activeOrganisation'] = OrganisationStore.subscribeToActiveOrganisation(
      (activeOrganisation: Organisation) => {
        this.setState({
          activeOrganisation: activeOrganisation,
          renderKey: activeOrganisation ? activeOrganisation.id : 0
        })
      },
      this.state.activeOrganisation
    )
    
    this.unsubscribers['organisations'] = OrganisationStore.subscribeToOrganisations(
      (organisations: Array<Organisation>) => {
        this.setState({
          organisations: organisations
        })
      },
      this.state.organisations
    )
    
    this.onResizeHandler = this.onResize.bind(this);
    
    window.addEventListener('resize', this.onResizeHandler)
    
    this.createGetParamToastMessage();
  }
  
  public componentWillUnmount()
  {
    document.body.classList.remove('theme');
    document.body.classList.remove(`theme--${this.state.theme}`);
    
    for (let key in this.unsubscribers)
    {
      this.unsubscribers[key]();
    }
    
    window.removeEventListener('resize', this.onResizeHandler);
  }
  
  public componentDidUpdate(prevProps: Readonly<WebAppProps>, prevState: Readonly<WebAppState>, snapshot?: any)
  {
    // console.log('WebApp updated: ', this.state);
  }
  
  public render()
  {
    let content;
    
    if (this.getAuthedUser())
    {
      content = <DashboardLayout
        key={this.state.renderKey}
        sideNavOpen={this.state.layoutSideNavOpen}
        header={this.getLayoutHeader()}
        footer={this.getLayoutFooter()}
        sideNav={this.getLayoutSideNav()}
        pageContainer={this.getPageContainer()}
        toastMessageContainer={<ToastMessageContainer/>}
      />
    }
    else
    {
      content = <EntryLayout
        pageContainer={this.getPageContainer()}
        backgroundImage={centeredLayoutBackgroundImage}
      />
    }
    
    return <ThemeContext.Provider value={this.state.theme} key={this.state.theme}>
      {content}
    </ThemeContext.Provider>
    
  }
  
  protected onResize(event)
  {
    if (window.innerWidth <= 1075)
    {
      this.closeLayoutSideNav();
    }
    else
    {
      this.openLayoutSideNav();
    }
  }
  
  private getLayoutHeader()
  {
    if (!this.getAuthedUser())
    {
      return null;
    }
    
    let headerLeft = <div>
      <a onClick={() => this.toggleLayoutSideNav()}>
        <FontAwesomeIcon
          style={{'fontSize': '18px'}}
          icon={faBars}
          className={[
            'ml-5 mr-5 color--text'
          ].join(' ')}
        />
      </a>
    </div>;
  
    let headerRight = <div className={'mr-md-2'}>
  
      {
        this.state.activeOrganisation
          ? <div className={'mr-md-2'} onClick={this.toggleOrgSelectionDropdown.bind(this)}>
            <Selector
              selected={this.state.activeOrganisation ? this.state.activeOrganisation.id : null}
              items={
                this.state.organisations.map(
                  (organisation: Organisation) => {
                    return {value: organisation.id, displayValue: organisation.name}
                  })
              }
            >
              <Dropdown
                open={this.state.orgSelectionDropdownOpen} closeDropdown={this.closeOrgSelectionDropdown.bind(this)}
                minWidth={200}
              >
                <List
                  selectedKey={this.state.activeOrganisation ? this.state.activeOrganisation.id : null}
                  items={
                    this.state.organisations.map((organisation: Organisation) => {
                      return {
                        key: organisation.id,
                        displayText: organisation.name,
                        // icon: <i className={'fal fa-sign-out-alt mr-1'}/>,
                        onClick: () => {
                          let newOrg = this.state.organisations.find((org: Organisation) => {
                            return org.id === organisation.id;
                          })
                          if (newOrg)
                          {
                            OrganisationStore.setActiveOrganisation(newOrg);
                          }
                  
                        }
                      }
                    })
                  }
                />
              </Dropdown>
            </Selector>
          </div>
          : null
      }
      
        <div onClick={this.toggleProfileDropdown.bind(this)}>
          <ProfileDropdown
            name={this.getProfileName()}
            image={
              <FontAwesomeIcon icon={faUserCircle}/>
            }
          >
            <Dropdown open={this.state.profileDropdownOpen} closeDropdown={this.closeProfileDropdown.bind(this)}>
              <List
                items={
                  [
                    {
                      key: 'my-account',
                      href: '/my-account',
                      displayText: 'My Account',
                      icon: <i className={'fal fa-cog mr-1'}/>
                    },
                    {
                      key: 'lights',
                      displayText: this.state.theme === 'light' ? 'Lights Out' : 'Lights On',
                      icon: <i
                        className={`${this.state.theme === 'light' ? 'fal fa-lightbulb-slash' : 'fal fa-lightbulb-on'} mr-1`}/>,
                      onClick: (e) => {
                        const newTheme = this.state.theme === 'light' ? 'dark' : 'light';
                      
                        LocalStorage.set('theme', newTheme);
                        document.body.classList.remove(`theme--${this.state.theme}`);
                        document.body.classList.add(`theme--${newTheme}`);
                      
                        this.setState({
                          theme: newTheme
                        })
                      }
                    },
                    {
                      key: 'logout',
                      displayText: 'Logout',
                      icon: <i className={'fal fa-sign-out-alt mr-1'}/>,
                      onClick: () => {
                      
                        const hostParts = window.location.host.split('.');
                      
                        Cookies.remove(
                          'jwt',
                          `.${hostParts.splice(1, hostParts.length - 1).join('.')}`
                        );
                      
                        LocalStorage.remove('persistedState');
                      
                        window.location.href = '/';
                      }
                    }
                  ]
                }
              />
            </Dropdown>
          </ProfileDropdown>
        </div>
      </div>;
  
    return <DashboardHeader
      left={headerLeft}
      right={headerRight}
      toggleLayoutSideNavCallback={this.toggleLayoutSideNav.bind(this)}
    />
  }
  
  private getProfileName(): string
  {
    return User.getPreferredFirstName(UserStore.getUser());
  }
  
  private toggleProfileDropdown()
  {
    this.setState({
      profileDropdownOpen: !this.state.profileDropdownOpen
    });
  }
  
  private toggleOrgSelectionDropdown()
  {
    this.setState({
      orgSelectionDropdownOpen: !this.state.orgSelectionDropdownOpen
    });
  }
  
  private closeProfileDropdown()
  {
    this.setState({
      profileDropdownOpen: false
    });
  }
  
  private closeOrgSelectionDropdown()
  {
    this.setState({
      orgSelectionDropdownOpen: false
    });
  }
  
  private getLayoutFooter()
  {
    if (!this.getAuthedUser())
    {
      return null;
    }
  
    return <DashboardFooter left={<div>&copy; Shumi</div>} right={''}/>
  }
  
  private getLayoutSideNav()
  {
    let menuItems = [
      {
        key: 'dashboard',
        title: 'Dashboard',
        href: '/',
        icon: <FontAwesomeIcon icon={faThLarge} className={'mr-2'}/>
      }
    ];
  
    const authedUser = UserStore.getUser();
    const activeOrg = OrganisationStore.getActiveOrganisation();
    
    // Members
    if (
      activeOrg
      && (
        PermissionsStore.hasPermission('member:read')
        || PermissionsStore.hasPermission('member:write')
      )
    )
    {
      menuItems.push(
        {
          key: 'members',
          title: 'Members',
          href: '/members',
          icon: <FontAwesomeIcon icon={faUsers} className={'mr-2'}/>
        }
      );
    }
    
    if (activeOrg)
    {
      // Events
      menuItems.push(
        {
          key: 'events',
          title: 'Events',
          href: '/events',
          // icon: <i className="fa fa-calendar-alt"/>,
          icon: <FontAwesomeIcon icon={faCalendarAlt} className={'mr-2'}/>,
        }
      );
    }
    
    // Accounts & Transactions
    if (
      activeOrg
      && PermissionsStore.hasPermission('financial:read')
    )
    {
      menuItems.push(
        {
          key: 'accounts',
          title: 'Accounts',
          href: '/accounts',
          icon: <FontAwesomeIcon icon={faCreditCard} className={'mr-2'}/>,
        }
      )
    }
    
    if (
      activeOrg
      && PermissionsStore.hasPermission('financial:write')
    )
    {
      menuItems.push(
        {
          key: 'transactions',
          title: 'Transactions',
          href: '/transactions',
          icon: <FontAwesomeIcon icon={faExchange} className={'mr-2'}/>,
        }
      )
    }
    
    if (
      activeOrg
      && PermissionsStore.hasPermission('financial:read')
    )
    {
      menuItems.push(
        {
          key: 'balance-sheet',
          title: 'Balance Sheet',
          href: '/balance-sheet',
          icon: <FontAwesomeIcon icon={faBalanceScale} className={'mr-2'}/>,
        }
      )
    }
    
    // Roles & Permissions
    if (
      activeOrg
      && (
        PermissionsStore.hasPermission('permissions:read')
        || PermissionsStore.hasPermission('permissions:write')
      )
    )
    {
      menuItems.push(
        {
          key: 'roles',
          title: 'Member Permissions',
          href: '/roles',
          icon: <FontAwesomeIcon icon={faUserLock} className={'mr-2'}/>,
        }
      )
    }
    
    // Membership
    if (
      activeOrg
      && (
        PermissionsStore.hasPermission('membership:read')
        || PermissionsStore.hasPermission('membership:write')
      )
    )
    {
      menuItems.push(
        {
          key: 'memberships',
          title: 'Memberships',
          href: '/memberships',
          icon: <FontAwesomeIcon icon={faAddressCard} className={'mr-2'}/>,
        }
      )
    }
    
    if (authedUser && activeOrg && authedUser.id === activeOrg.ownerUserId)
    {
      menuItems.push(
        {
          key: 'organisation',
          title: activeOrg.name,
          href: '/organisation',
          icon: <FontAwesomeIcon icon={faLandmark} className={'mr-2'}/>,
        }
      )
    }
    
    return <SideNav
      logo={<Logo color={'white'}/>}
      sideNavOpen={this.state.layoutSideNavOpen}
      menuItems={menuItems}
      onNavItemClick={this.closeLayoutSideNavIfMobile.bind(this)}
    />;
  }
  
  private getPageContainer()
  {
    if (this.getAuthedUser())
    {
      const appRoutes = [
        // Dashboard
        {
          path: '/',
          component: DashboardPage,
          exact: true,
        },
        // My Account
        {
          path: '/my-account',
          component: ViewMyAccountPage,
          exact: true,
        },
        {
          path: '/my-account/update',
          component: UpdateMyAccountPage,
          exact: true,
        },
        // Organisation
        {
          path: '/organisation/update',
          component: UpdateOrganisationPage,
          exact: true,
        },
        {
          path: '/organisation',
          component: ViewOrganisationPage,
          exact: true,
        },
        // Members
        {
          path: '/members',
          component: ViewAllMembersPage,
          exact: true,
        },
        {
          path: '/members/new',
          component: CreateUpdateMemberPage,
          exact: true,
        },
        {
          path: '/members/:userOrgId/update',
          component: CreateUpdateMemberPage,
          exact: true,
        },
        {
          path: '/members/:userOrgId',
          component: ViewMemberPage,
          exact: true,
        },
        // Events
        {
          path: '/events',
          component: ViewAllEventsPage,
          exact: true,
        },
        {
          path: '/events/:eventId/booking',
          component: BookingPage,
          exact: true,
        },
        {
          path: '/events/:eventId/booking/:userOrgId',
          component: BookingPage,
          exact: true,
        },
        {
          path: '/events/new',
          component: CreateUpdateEventPage,
          exact: true,
        },
        {
          path: '/events/:eventId/update',
          component: CreateUpdateEventPage,
          exact: true,
        },
        {
          path: '/events/:eventId',
          component: ViewEventPage,
          exact: true,
        },
        // Accounts
        {
          path: '/accounts',
          component: ViewAllAccountsPage,
          exact: true,
        },
        {
          path: '/accounts/new',
          component: CreateUpdateAccountPage,
          exact: true,
        },
        {
          path: '/accounts/:accountId/update',
          component: CreateUpdateAccountPage,
          exact: true,
        },
        {
          path: '/accounts/:accountId',
          component: ViewAccountPage,
          exact: true,
        },
        // Transactions
        {
          path: '/transactions',
          component: TransactionsPage,
          exact: true,
        },
        {
          path: '/transactions/ref/:reconcileReference',
          component: PayingInSlipPage,
          exact: true,
        },
        {
          path: '/transactions/issue-cheque',
          component: IssueAChequePage,
          exact: true,
        },
        {
          path: '/transactions/receive-cheque',
          component: ReceiveAChequePage,
          exact: true,
        },
        {
          path: '/transactions/make-online-payment',
          component: MakeAPaymentPage,
          exact: true,
        },
        {
          path: '/transactions/receive-direct-credit',
          component: ReceiveDirectCreditPage,
          exact: true,
        },
        {
          path: '/transactions/receive-cash',
          component: ReceiveCashPage,
          exact: true,
        },
        {
          path: '/transactions/process-paying-in-slip',
          component: ProcessPayingInSlipPage,
          exact: true,
        },
        {
          path: '/transactions/other',
          component: AnyOtherTransactionPage,
          exact: true,
        },
        {
          path: '/balance-sheet',
          component: ViewBalanceSheetPage,
          exact: true,
        },
        // Roles
        {
          path: '/roles/new',
          component: CreateUpdateRolePage,
          exact: true,
        },
        {
          path: '/roles/:roleId/update',
          component: CreateUpdateRolePage,
          exact: true,
        },
        {
          path: '/roles',
          component: ViewAllRolesPage,
          exact: true,
        },
        {
          path: '/roles/:roleId',
          component: ViewRolePage,
          exact: true,
        },
        // Memberships
        {
          path: '/memberships',
          component: ViewAllMembershipsPage,
          exact: true,
        },
        {
          path: '/memberships/process',
          component: ProcessMembershipFeesPage,
          exact: true,
        },
        {
          path: '/memberships/new',
          component: CreateUpdateMembershipPage,
          exact: true,
        },
        {
          path: '/memberships/:membershipId/update',
          component: CreateUpdateMembershipPage,
          exact: true,
        },
        {
          path: '/memberships/:membershipId',
          component: ViewMembershipPage,
          exact: true,
        },
        {
          path: '*',
          component: DashboardNotFoundPage
        }
      ];
      
      return <DashboardPageContainer
        navOpen={this.state.layoutSideNavOpen}
        closeNav={this.closeLayoutSideNav.bind(this)}
        routes={appRoutes}
      />
    }
    
    const loginRoutes = [
      {
        path: '/login',
        component: LoginPage,
        exact: true
      },
      {
        path: '/signup',
        component: SignupPage,
        exact: true
      },
      {
        path: '*',
        component: EntryNotFoundPage
      }
    ];
    
    return <EntryPageContainer routes={loginRoutes}/>
  }
  
  private toggleLayoutSideNav()
  {
    this.setState({
      layoutSideNavOpen: !this.state.layoutSideNavOpen
    });
  }
  
  private closeLayoutSideNav()
  {
    this.setState({
      layoutSideNavOpen: false
    })
  }
  
  private closeLayoutSideNavIfMobile()
  {
    if (ScreenSize.isSmall() || ScreenSize.isMedium())
    {
      this.setState({
        layoutSideNavOpen: false
      })
    }
  }
  
  private openLayoutSideNav()
  {
    this.setState({
      layoutSideNavOpen: true
    })
  }
  
  private getAuthedUser()
  {
    return this.state.authedUser;
  }
  
  private createGetParamToastMessage()
  {
    const url = new URL(window.location.href);
    
    if (url.searchParams.has('toast-message'))
    {
      const toastMessage = url.searchParams.get('toast-message');
      const toastMessageType = url.searchParams.has('toast-message-type') ? url.searchParams.get('toast-message-type') : 'info';
      const toast = ToastMessage.create(toastMessageType as any, toastMessage)
      ToastMessageStore.addMessage(toast);
      
      url.searchParams.delete('toast-message');
      url.searchParams.delete('toast-message-type');
      window.history.replaceState(null, document.title, url.href);
    }
  }
}
