import {DataStore} from "ts-redux/src/DataStore/DataStore";
import {Objects} from "phusion/src/Core/Objects/Objects";
import {Redux} from "ts-redux/src/Redux";
import {OrganisationStore} from "../Organisation/OrganisationStore";
import { UserStore } from "../User/UserStore";

declare type Permission =
  'member:read'
  | 'member:write'
  | 'event:read'
  | 'event:write'
  | 'financial:read'
  | 'financial:write'
  | 'permissions:read'
  | 'permissions:write'
  | 'membership:read'
  | 'membership:write';

export class PermissionsStore extends DataStore
{
  public static readonly key: string = 'permissions';
  protected static readonly SET_UPDATING: string = 'set_permissions_updating';
  protected static readonly SET_PERMISSIONS_BY_ORGANISATION: string = 'set_permissions_by_organisation';

  public static reduce(
    state = {
      updating: false,
      permissions: {}
    },
    action: {
      type: string,
      data: any
    }
  )
  {
    switch (action.type)
    {
      case PermissionsStore.SET_UPDATING:
        return Object.assign({}, state, {
          updating: action.data
        });
      case PermissionsStore.SET_PERMISSIONS_BY_ORGANISATION:

        let permissionsByOrgId = Objects.clone(state.permissions);
        permissionsByOrgId[action.data.organisationId] = action.data.permissions;

        return Object.assign({}, state, {
          permissions: permissionsByOrgId
        });
      default:
        return state;
    }
  }
  
  public static isOrganisationOwner(): boolean
  {
    const activeOrg = OrganisationStore.getActiveOrganisation();
    const authedUser = UserStore.getUser();
    
    return Boolean(activeOrg && authedUser && activeOrg.ownerUserId === authedUser.id)
  }
  
  public static hasPermission(permission: Permission)
  {
    let permissions = this.getByActiveOrganisation();
    
    return permissions.indexOf(permission) !== -1;
  }

  public static getByActiveOrganisation(): Array<Permission>
  {
    const activeOrganisation = OrganisationStore.getActiveOrganisation();
    
    if (!activeOrganisation)
    {
      return [];
    }
    
    return this.getAllByByOrganisationId(activeOrganisation.id);
  }

  protected static getAllByByOrganisationId(organisationId: number): Array<Permission>
  {
    let permissions = Objects.getByKeyPath('permissions:permissions:' + organisationId, Redux.getState());

    if (permissions)
    {
      return permissions;
    }

    return [];
  }

  protected static setUpdating(updating: boolean)
  {
    Redux.dispatch({
      type: PermissionsStore.SET_UPDATING,
      data: updating
    });
  }

  public static setPermissionsByOrganisation(organisationId: number, permissions: Array<Permission>)
  {
    Redux.dispatch({
      type: PermissionsStore.SET_PERMISSIONS_BY_ORGANISATION,
      data: {
        organisationId: organisationId,
        permissions: permissions
      }
    });
  }
}
