export class Strings
{
  private static readonly RANDOM_STRING_AVAILABLE_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
  private static readonly PASSWORD_AVAILABLE_CHARS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789$-_.+!*\'(),,';
  
  public static base64Decode(hash: string): string
  {
    let buffer = new Buffer(hash, 'base64');
    return buffer.toString('utf8')
  }
  
  public static base64Encode(string: string): string
  {
    return (new Buffer(string)).toString('base64');
  }
  
  public static random(length: number = 10, blacklistedStrings?: Array<string>): string
  {
    let string;
    
    while (!string || (blacklistedStrings && blacklistedStrings.length && blacklistedStrings.includes(string)))
    {
      string = this.generateRandomString(length, this.RANDOM_STRING_AVAILABLE_CHARS);
    }
    
    return string;
  }
  
  public static password(length: number)
  {
    return this.generateRandomString(length, this.PASSWORD_AVAILABLE_CHARS);
  }
  
  private static generateRandomString(
    length: number = 10,
    availableCharacters: string = this.RANDOM_STRING_AVAILABLE_CHARS
  )
  {
    let result = '';
    let charactersLength = availableCharacters.length;
    for ( let i = 0; i < length; i++ ) {
      result += availableCharacters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
