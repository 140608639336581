
import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import { faArrowRight } from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import { faBirthdayCake } from "@fortawesome/pro-solid-svg-icons/faBirthdayCake";
import { faEdit } from "@fortawesome/pro-solid-svg-icons/faEdit";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import { faHome } from "@fortawesome/pro-solid-svg-icons/faHome";
import { faIdCard } from "@fortawesome/pro-solid-svg-icons/faIdCard";
import { faMobile } from "@fortawesome/pro-solid-svg-icons/faMobile";
import { faPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Panel } from "appex-theme/src/Core/Panel/Panel";
import { DisplayItem } from "appex-theme/src/Data/DisplayItem/DisplayItem";
import * as React from 'react';
import { Link } from 'react-router-dom';
import { AbstractComponent } from "../../../../component/AbstractComponent";
import { PageBar } from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import { Breadcrumbs } from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import { Member } from "../../../../data/Member/Entity/Member";
import { Organisation } from "../../../../data/Organisation/Entity/Organisation";
import { OrganisationStore } from "../../../../data/Organisation/OrganisationStore";
import { User } from "../../../../data/User/Entity/User";
import { UserStore } from "../../../../data/User/UserStore";
import './scss/ViewMyAccountPage.scss';

export interface ViewMyAccountPageProps {}
export interface ViewMyAccountPageState {
  user: User,
  updating: boolean,
  organisations: Array<Organisation>,
  activeOrganisation: Organisation
}

export class ViewMyAccountPage extends AbstractComponent<ViewMyAccountPageProps, ViewMyAccountPageState>
{
  public constructor(props: ViewMyAccountPageProps)
  {
    super(props);
    
    this.state = {
      user: UserStore.getUser(),
      updating: UserStore.isUpdating(),
      organisations: OrganisationStore.getOrganisations(),
      activeOrganisation: OrganisationStore.getActiveOrganisation()
    }
  }
  
  public componentDidMount()
  {
    // Authed User
    this.unsubscribers['user'] = UserStore.subscribeToUser((user) => {
      this.setState({
        user: user
      })
    }, this.state.user);
    
    UserStore.syncUser();
    
    // Organisations
    this.unsubscribers['organisations'] = OrganisationStore.subscribeToOrganisations((organisations) => {
      this.setState({
        organisations: organisations
      })
    }, this.state.organisations);
  
    // Active Org
    this.unsubscribers['active_organisation'] = OrganisationStore.subscribeToActiveOrganisation(
      (activeOrganisation: Organisation) => {
        this.setState({
          activeOrganisation: activeOrganisation
        })
      },
      this.state.activeOrganisation
    );
  }
  
  public render()
  {
    let breadcrumbs = [
      {
        key: 'home',
        title: <span><i className="fal fa-home"/> Home</span>,
        href: '/'
      },
      {
        key: 'my_account',
        title: 'My Account'
      }
    ];
    
    return <div className={'ViewMyAccountPage'}>
  
      <div className="grid-container">
  
        <PageBar title={'My Account'} rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}/>
    
        <div className="grid-x grid-margin-x">
          <div className="cell large-4">
            {this.getPersonalDetailsPanel()}
          </div>
          <div className="cell large-8">
            {this.getOrganisationsPanel()}
          </div>
        </div>
      </div>
      
    </div>
  }
  
  private getPersonalDetailsPanel()
  {
    const user = this.state.user;
    
    return <Panel
      headerLeft={<div className="Header">Personal Details</div>}
      headerRight={<Link to={'/my-account/update'}><FontAwesomeIcon icon={faEdit}/></Link>}
    >
  
      <div className="grid-x">
        <div className="cell">
          <DisplayItem
            icon={<FontAwesomeIcon icon={faIdCard}/>}
            content={Member.getFullPreferredName(user)}
            updating={this.state.updating}
          />
          <DisplayItem
            icon={<FontAwesomeIcon icon={faEnvelope}/>}
            content={user.emailAddress}
            updating={this.state.updating}
          />
          <DisplayItem
            icon={<FontAwesomeIcon icon={faMobile}/>}
            content={user.mobileContactNumber}
            updating={this.state.updating}
          />
          <DisplayItem
            icon={<FontAwesomeIcon icon={faPhone}/>}
            content={user.homeContactNumber}
            updating={this.state.updating}
          />
          <DisplayItem
            icon={<FontAwesomeIcon icon={faHome}/>}
            content={
          
              user.address
                ? <div>
                  { user.address.addressLine1 ? <p>{user.address.addressLine1}</p> : null }
                  { user.address.addressLine2 ? <p>{user.address.addressLine2}</p> : null }
                  { user.address.town ? <p>{user.address.town}</p> : null }
                  { user.address.county ? <p>{user.address.county}</p> : null }
                  { user.address.postCode ? <p>{user.address.postCode}</p> : null }
                </div>
                : '-'
            }
            updating={this.state.updating}
          />
          <DisplayItem
            icon={<FontAwesomeIcon icon={faBirthdayCake}/>}
            content={ user.dateOfBirth ? this.getDateOfBirthDisplayValue(user.dateOfBirth) : null }
            updating={this.state.updating}
          />
        </div>
      </div>
    </Panel>
  }
  
  private getOrganisationsPanel()
  {
    return <Panel
      headerLeft={<div className="Header">My Organisations</div>}
    >
      {
        !this.state.organisations.length
          ? 'No organisations found.'
          : this.state.organisations.map((organisation: Organisation, index) => {
            return <div
              className={'StatsRow cursor--pointer'}
              key={index}
              onClick={OrganisationStore.setActiveOrganisation.bind(undefined, organisation)}
            >
              <div className="StatsRow__Left">
                { organisation.name }
              </div>
              <div className="StatsRow__Right">
                {
                  this.state.activeOrganisation?.id === organisation.id
                    ? <span><FontAwesomeIcon className={'mr-1'} icon={faCheckCircle}/> Active</span>
                    : <FontAwesomeIcon icon={faArrowRight}/>
                }
              </div>
            </div>
          })
      }
    </Panel>
  }
  
  private getDateOfBirthDisplayValue(dateOfBirth: string): string
  {
    if (!dateOfBirth)
    {
      return '-';
    }
    
    let date = new Date(dateOfBirth);
    
    let monthNames = {
      0: 'January',
      1: 'February',
      2: 'March',
      3: 'April',
      4: 'May',
      5: 'June',
      6: 'July',
      7: 'August',
      8: 'September',
      9: 'October',
      10: 'November',
      11: 'December'
    };
    
    // 20 December 2019
    return date.getDate() + ' ' + monthNames[date.getMonth()] + ' ' + date.getFullYear() + ' (' + this.getAgeFromDate(date) + ' years old)'
  }
  
  private getAgeFromDate(date: Date)
  {
    const ageDifMs = Date.now() - date.getTime();
    const ageDate = new Date(ageDifMs); // milliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  }
}
