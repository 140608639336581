import {Objects} from "phusion/src/Core/Objects/Objects";
import {Ticket} from "./Ticket";

export class Event
{
  public id: number;
  public organisationId: number;
  public name: string;
  public description: string;
  public start: string;
  public finish: string;
  public closing: string;
  public created: string;
  public updated: string;
  public invitedMembers: Array<number>; // user_org ids
  public tickets: Array<number>; // ticket ids
  public guests: Array<number>; // guest ids

  public static create(data: Object = null): Event
  {
    if (!data)
    {
      return new this();
    }

    return Objects.hydrate(new Event(), data) as Event;
  }

  public serialize(): Object
  {
    let mutators = {
      invitedMembers: (invitedMembers: Array<Number>) => {
        return JSON.stringify(invitedMembers);
      },
      tickets: (tickets: Array<Object>) => {

        tickets.forEach((ticket: Ticket) => {
          ticket.price = Number(ticket.price);
        });

        return JSON.stringify(tickets);
      }
      // dateOfBirth: (dateOfBirth: Moment.Moment) => dateOfBirth.valueOf(),
      // created: (created: Moment.Moment) => created.valueOf(),
      // updated: (updated: Moment.Moment) => updated.valueOf()
    };

    return Objects.hydrate({}, this, mutators, true);
  }
}
