
import { Button } from "appex-theme/src/Core/Button/Button";
import * as React from 'react';
import {Link} from 'react-router-dom';
import {AbstractComponent} from "../../../../component/AbstractComponent";
import './scss/ViewEventPage.scss';
import {PageBar} from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import {Breadcrumbs} from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import {Panel} from "appex-theme/src/Core/Panel/Panel";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Member} from "../../../../data/Member/Entity/Member";
import { PermissionsStore } from "../../../../data/Permissions/PermissionsStore";
import { Url } from "../../../../utility/Url";
import {CreateMemberProps} from "../../Members/Create/CreateUpdateMemberPage";
import {OrganisationStore} from "../../../../data/Organisation/OrganisationStore";
import {EventStore} from "../../../../data/Event/EventStore";
import {Event} from "../../../../data/Event/Entity/Event";
import {Attendance} from "../../../../data/Event/Entity/Attendance";
import {Ticket} from "../../../../data/Event/Entity/Ticket";
import {MemberStore} from "../../../../data/Member/MemberStore";
import {EventAttendanceStore} from "../../../../data/Event/EventAttendanceStore";
import {EventTicketStore} from "../../../../data/Event/EventTicketStore";
import {faCalendarAlt} from "@fortawesome/pro-solid-svg-icons/faCalendarAlt";
import {faClock} from "@fortawesome/pro-solid-svg-icons/faClock";
import {faCalendarDay} from "@fortawesome/pro-duotone-svg-icons/faCalendarDay";
import {faTicketAlt} from "@fortawesome/pro-duotone-svg-icons/faTicketAlt";
import {faUsers} from "@fortawesome/pro-duotone-svg-icons/faUsers";
import {faCheckCircle} from "@fortawesome/pro-regular-svg-icons/faCheckCircle";
import {faTimesCircle} from "@fortawesome/pro-regular-svg-icons/faTimesCircle";
import {faEnvelopeOpenText} from "@fortawesome/pro-regular-svg-icons/faEnvelopeOpenText";
import {faTicketAlt as lightFaTicketAlt} from "@fortawesome/pro-light-svg-icons/faTicketAlt";
import {EventGuestStore} from "../../../../data/Event/EventGuestStore";
import {Guest} from "../../../../data/Event/Entity/Guest";
import {DisplayItem} from "appex-theme/src/Data/DisplayItem/DisplayItem";
import {Dates} from "appex-theme/src/Utility/Dates";
import {Times} from "appex-theme/src/Utility/Times";
import {Money} from "appex-theme/src/Utility/Money";
import {DataTable} from "appex-theme/src/Data/DataTable/DataTable/DataTable";
import {DisplayColumn} from "appex-theme/src/Data/DataTable/DataTable/Entity/DisplayColumn";
import {CtaPlaceholder} from "appex-theme/src/Placeholder/Cta/CtaPlaceholder";
import {TextLoader} from "appex-theme/src/Loader/TextLoader";
import {ScreenSize} from "appex-theme/src/Utility/ScreenSize";
import {faArrowRight} from "@fortawesome/pro-solid-svg-icons/faArrowRight";
import {Request} from "appex-theme/src/Utility/Request"

export interface ViewEventProps {}
export interface ViewEventState {
  event: Event,
  eventId: number,
  tickets: Array<Ticket>,
  guests: Array<Guest>,
  attendance: Array<Attendance>,
  bookingUserOrgId: number,
  eventUpdating: boolean,
  attendanceUpdating: boolean,
  ticketsUpdating: boolean,
  guestsUpdating: boolean,
}

export class ViewEventPage extends AbstractComponent<ViewEventProps, ViewEventState>
{
  protected unsubscribers = {};

  public constructor(props: CreateMemberProps)
  {
    super(props);

    let event = null;
    let attendance = [];
    let tickets = [];
    let guests = [];

    let eventId = props['params'].eventId;

    if (eventId)
    {
      event = EventStore.getByIdAndActiveOrganisation(eventId);
    }

    if (event)
    {
      attendance = EventAttendanceStore.getAllAttendanceByEventId(eventId);
      tickets = EventTicketStore.getAllTicketsByEventId(eventId);
      guests = EventGuestStore.getAllGuestsByEventId(eventId);
    }

    this.state = {
      event: event,
      eventId: eventId,
      attendance: attendance,
      tickets: tickets,
      guests: guests,
      bookingUserOrgId: OrganisationStore.getActiveOrganisation().userOrgId,
      eventUpdating: false,
      attendanceUpdating: false,
      ticketsUpdating: false,
      guestsUpdating: false
    }
  }

  public componentDidMount()
  {
    /**
     * Subscribe to Event
     */
    
    this.unsubscribers['event_updating'] = EventStore.subscribeToUpdating(
      (updating: boolean) => {
        this.setState({
          eventUpdating: updating
        })
      },
      this.state.eventUpdating
    )
    
    this.unsubscribers['event'] = EventStore.subscribeToEventById(
      this.state.eventId,
      (event: Event) => {
        this.setState({
          event: event
        })
      },
      this.state.event
    );
    
    /**
     * Subscribe to Event Attendance
     */
    
    this.unsubscribers['attendance_updating'] = EventAttendanceStore.subscribeToUpdating(
      (updating: boolean) => {
        this.setState({
          attendanceUpdating: updating
        })
      },
      this.state.attendanceUpdating
    );
    
    this.unsubscribers['attendance'] = EventAttendanceStore.subscribeToAttendanceByEventId(
      this.state.eventId,
      (attendance: Array<Attendance>) => {
        this.setState({
          attendance: attendance
        })
      },
      this.state.attendance
    )
    
    /**
     * Subscribe to Tickets
     */
    
    this.unsubscribers['tickets_updating'] = EventTicketStore.subscribeToUpdating(
      (updating: boolean) => {
        this.setState({
          ticketsUpdating: updating
        })
      },
      this.state.ticketsUpdating
    )
    
    // Listen for tickets changes
    this.unsubscribers['tickets'] = EventTicketStore.subscribeToTicketsByEventId(
      this.state.eventId,
      (tickets: Array<Ticket>) => {
        this.setState({
          tickets: tickets
        })
      },
      this.state.tickets
    );
    
    /**
     * Subscribe to Guests
     */
    
    this.unsubscribers['guests_updating'] = EventGuestStore.subscribeToUpdating(
      (updating: boolean) => {
        this.setState({
          guestsUpdating: updating
        })
      },
      this.state.guestsUpdating
    )
    
    this.unsubscribers['guests'] = EventGuestStore.subscribeToGuestsByEventId(
      this.state.eventId,
      (guests: Array<Guest>) => {
        this.setState({
          guests: guests
        })
      },
      this.state.guests
    )

    EventStore.syncEvent(this.state.eventId);
    MemberStore.syncActiveOrganisationMembers();
  }

  public componentWillUnmount()
  {
    if (Object.keys(this.unsubscribers).length)
    {
      for (let key in this.unsubscribers)
        if (this.unsubscribers.hasOwnProperty(key))
      {
        this.unsubscribers[key]();
      }
    }
  }

  public render()
  {
    let event = this.state.event;

    let breadcrumbs = [
      {
        key: 'home',
        title: <span><i className="fal fa-home"/> Home</span>,
        href: '/'
      },
      {
        key: 'events',
        title: 'Events',
        href: '/events'
      },
      {
        key: 'view_event',
        title: event ? event.name : '. . .'
      },
    ];

    let descriptionParagraphs: Array<any> = event && event.description ? event.description.split('\n') : [];

    if (!descriptionParagraphs.length && this.state.eventUpdating)
    {
      descriptionParagraphs = [
        <span>
          <span className={'mr-2'}><TextLoader width={100}/></span>
          <span className={'mr-2'}><TextLoader width={87}/></span>
          <span className={'mr-2'}><TextLoader width={110}/></span>
          <span className={'mr-2'}><TextLoader width={93}/></span>
          <span className={'mr-2'}><TextLoader width={140}/></span>
          <span className={'mr-2'}><TextLoader width={54}/></span>
          <span className={'mr-2'}><TextLoader width={62}/></span>
          <span className={'mr-2'}><TextLoader width={97}/></span>
          <span className={'mr-2'}><TextLoader width={127}/></span>
          <span className={'mr-2'}><TextLoader width={87}/></span>
        </span>,
        <span>
          <span className={'mr-2'}><TextLoader width={100}/></span>
          <span className={'mr-2'}><TextLoader width={87}/></span>
          <span className={'mr-2'}><TextLoader width={110}/></span>
          <span className={'mr-2'}><TextLoader width={93}/></span>
          <span className={'mr-2'}><TextLoader width={140}/></span>
          <span className={'mr-2'}><TextLoader width={54}/></span>
          <span className={'mr-2'}><TextLoader width={62}/></span>
          <span className={'mr-2'}><TextLoader width={97}/></span>
          <span className={'mr-2'}><TextLoader width={127}/></span>
          <span className={'mr-2'}><TextLoader width={87}/></span>
        </span>
      ];
    }

    return <div className={'ViewEventPage'}>

      <div className="grid-container">

        <div className="cell">
          <PageBar
            title={<div><FontAwesomeIcon icon={faCalendarDay}/>{(!event && this.state.eventUpdating) ? 'Loading event . . .' : (event ? event.name : 'View Event')}</div>}
            rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}
          />

          <div className="grid-x grid-margin-x">

            {/* Left Column */}
            <div className="cell medium-5 large-4">

              {/* Event Details */}
              <Panel
                headerLeft={<div className="Header">Event Details</div>}
                headerRight={
                  PermissionsStore.hasPermission('event:write')
                    ? <Link to={'/events/' + (event ? event.id : '') + '/update'}>
                      <i className="fas fa-edit"/>
                    </Link>
                    : null
                  }
              >
                <div className="grid-x">
                  <div className="cell">

                    <div
                      className={[ 'mt-2 mb-2' ].join(' ')}
                    >
                      <DisplayItem
                        icon={<FontAwesomeIcon icon={faCalendarAlt}/>}
                        content={Dates.getDisplayValue(event && event.start ? new Date(event.start) : null)}
                        updating={this.state.eventUpdating}
                      />
                      <DisplayItem
                        icon={<FontAwesomeIcon icon={faClock}/>}
                        content={Times.getDisplayValue(event && event.start ? new Date(event.start) : null)}
                        updating={this.state.eventUpdating}
                      />

                    </div>

                    <div className="Hr Hr--Space-3"/>

                    {
                      descriptionParagraphs.length
                      ? descriptionParagraphs.map((paragraph: string, index: number) => {
                        return <p
                          key={index}
                          className={[ (index == descriptionParagraphs.length - 1) ? '' : 'mb-4' ].join(' ')}
                        >
                          {paragraph}
                        </p>;
                      })
                      : '-'
                    }

                    <div className="Hr Hr--Space-3"/>

                    <p
                      className={[
                        'mb-2',
                      ].join(' ')}
                    >
                      {
                        this.areBookingClosed(event)
                          ? 'Tickets are no longer available for this event.'
                          : <span>Get your tickets by <span className="text--semi-bold">{ Dates.getDisplayValue(event ? new Date(event.closing): null) }</span></span>
                      }
                    </p>
                  </div>
                </div>
              </Panel>


              {/* Tickets */}
              <Panel
                headerLeft={<div className="Header">Ticket Prices</div>}
                headerRight={
                  PermissionsStore.hasPermission('event:write')
                    ? <Link to={'/events/' + (event ? event.id : '') + '/update'}>
                      <i className="fas fa-edit"/>
                    </Link>
                    : null
                }
              >
                <div className="grid-x">
                  <div className="cell">

                    <div>
                      { this.state.tickets.length ? this.getTicketsPanelContent(this.state.tickets) : this.getNoTicketsContent() }
                    </div>

                  </div>

                </div>
              </Panel>

              {/* Options */}
              {this.getAdminOptionsPanel(event)}
            </div>

            {/* Right Column */}

            <div className="cell medium-7 large-8">

              {/* My Tickets */}
              { event ? this.getMyTicketsPanel(event) : null }

              {/* Who's Invited? */}
              <Panel
                headerLeft={<div className="Header">Who's Invited?</div>}
                headerRight={
                  PermissionsStore.hasPermission('event:read')
                    ? <Button
                        modifiers={['primary']}
                        onClick={() => {
                          window.open(Url.api(`/organisation/${OrganisationStore.getActiveOrganisation().id}/event/${this.state.eventId}/attendance`), '_self');
                        }}
                      >
                        Download Guest List
                      </Button>
                    : null
              }
              >
                <div className="grid-x">
                  <div className="cell">

                    { this.getMembersTable() }

                  </div>
                </div>
              </Panel>

            </div>

          </div>

        </div>

      </div>

    </div>
  }

  protected getMyTicketsPanel(event: Event)
  {
    let bookingUserAttendance = this.getBookingUserAttendance();

    if (!bookingUserAttendance)
    {
      return null;
    }

    let bookingUserGuests = this.getBookingUserGuests();
    let content: any;
    let panelHeaderRight: any;

    // If no ticket reserved and no guests
    if (bookingUserAttendance.eventTicketId || bookingUserGuests.length)
    {
      content = this.getViewBookingContent(event, bookingUserAttendance, bookingUserGuests);
      panelHeaderRight = <Link to={'/events/' + (event ? event.id : '') + '/booking'}>
        <i className="fas fa-edit"/>
      </Link>;
    }
    else
    {
      content = this.getNoBookingContent(event);
    }

    return <Panel
      headerLeft={<div className="Header">My Tickets</div>}
      headerRight={panelHeaderRight}
    >
      <div className="grid-x">
        <div className="cell">

          { content }

        </div>
      </div>
    </Panel>
  }

  protected getTicketsPanelContent(tickets: Array<Ticket>) {

    return <div>
      {
        tickets.map((ticket: Ticket, index) => {
          return <div className={'StatsRow'} key={index}>
            <div className="StatsRow__Left">
              { ticket.name }
            </div>
            <div className="StatsRow__Right">
              { Money.getFormattedPrice(ticket.price) }
            </div>
          </div>
        })
      }
    </div>
  }

  protected getMembersTable()
  {
    return <DataTable
      data={this.state.attendance}
      displayColumns={[
        DisplayColumn.create(
          'Name',
          'lastName',
          (attendance: Attendance) => {
            let member = MemberStore.getByUserOrgIdAndActiveOrganisation(attendance.userOrgId);

            if (member)
            {
              return Member.getFullPreferredName(member);
            }

            return 'Loading member...';
          },
          2
        ),
        DisplayColumn.create(
          'Guests',
          null,
          (attendance: Attendance) => {
            return this.getGuestContent(attendance)
          },
          2
        ),
        DisplayColumn.create(
          'Status',
          null,
          (attendance: Attendance) => {
            return this.getStatusContent(attendance)
          },
          2
        )
      ]}
      searchable={true}
      sortable={true}
      onSearch={this.onSearch.bind(this)}
      noDataContent={this.getNoMembersContent()}
      paginationControls={true}
      defaultRowsPerPage={5}
      rowsPerPageOptions={[5, 10, 25]}
      dataUpdating={Boolean(this.state.attendanceUpdating)}
    />
  }

  protected getNoMembersContent()
  {
    let event = this.state.event;
    let heading = 'No Members Found';
    let text = 'No members have been invited';
    let buttonContent = 'Invite Members';
    let ctaUri = '/events/' + (event ? event.id : '');

    // TODO: Switch out content depending on permission status

    return <div className="cell">
      <CtaPlaceholder
        icon={faUsers}
        heading={heading}
        text={text}
        buttonContent={buttonContent}
        ctaUri={ctaUri}
      />
    </div>;
  }

  protected getNoTicketsContent()
  {
    if (this.state.ticketsUpdating && !this.state.tickets.length)
    {
      let placeholderTickets = [
        126, 145, 151, 127
      ];
      return <div>
        {
          placeholderTickets.map((ticket: number, index) => {
            return <div className={'StatsRow'} key={index}>
              <div className="StatsRow__Left">
                <TextLoader width={ticket}/>
              </div>
              <div className="StatsRow__Right">
                <TextLoader width={50}/>
              </div>
            </div>
          })
        }
      </div>
    }


    let event = this.state.event;
    let heading = 'No Tickets Found';
    let text = 'No tickets have been created for this event';
    let buttonContent = 'Create Tickets';
    let ctaUri = '/events/' + (event ? event.id : '') + '/update';

    // TODO: Switch out content depending on permission status

    return <div className="cell">
      <CtaPlaceholder
        icon={faTicketAlt}
        heading={heading}
        text={text}
        buttonContent={buttonContent}
        ctaUri={ctaUri}
      />
    </div>;
  }

  protected getViewBookingContent(event: Event, attendance: Attendance, guests: Array<Guest>)
  {
    let bookingTotal = 0;

    let bookingUserTickets = this.state.tickets.filter((ticket: Ticket) => {
      return (ticket.id == attendance.eventTicketId);
    });

    if (bookingUserTickets.length)
    {
      bookingTotal += bookingUserTickets[0].price
    }

    guests.forEach((guest: Guest) => {
      let bookingUserTickets = this.state.tickets.filter((ticket: Ticket) => {
        return (ticket.id == guest.eventTicketId);
      });

      if (bookingUserTickets.length)
      {
        bookingTotal += bookingUserTickets[0].price
      }
    });

    let data = [];

    if (attendance.eventTicketId)
    {
      data = data.concat([attendance]);
    }

    data = data.concat(guests);

    let value = data.reduce((accumulator, item: Attendance | Guest) => {
      let matchingTickets = this.state.tickets.filter((ticket: Ticket) => {
        return (ticket.id == item.eventTicketId);
      });

      if (matchingTickets.length)
      {
        accumulator += matchingTickets[0].price;
      }

      return accumulator;
    }, 0);

    if (ScreenSize.isMediumUp())
    {
      data = data.concat(
        [
          {
            bookingTotal: Money.getFormattedPrice(value)
          }
        ]
      );
    }

    return <div>

        <DataTable
          data={data}
          displayColumns={[
            DisplayColumn.create(
              'Name On Ticket',
              null,
              (item: Attendance | Guest) => {

                if (item.hasOwnProperty('bookingTotal'))
                {
                  return null;
                }

                if (item.hasOwnProperty('name'))
                {
                  return item['name'];
                }

                let member = MemberStore.getByUserOrgIdAndActiveOrganisation(item.userOrgId);

                return Member.getFullPreferredName(member);
              }
            ),
            DisplayColumn.create(
              'Ticket',
              null,
              (item: Attendance | Guest) => {

                if (item.hasOwnProperty('bookingTotal')) return null;

                let tickets = this.state.tickets.filter((ticket: Ticket) => {
                  return (ticket.id == item.eventTicketId);
                });

                if (tickets.length)
                {
                  return <div><FontAwesomeIcon icon={lightFaTicketAlt} className={'mr-1 pr-0'}/>{tickets[0].name}</div>;
                }

                return 'Unknown';
              }
            ),
            DisplayColumn.create(
              'Ticket Price',
              null,
              (item: Attendance | Guest | { bookingTotal: number }) => {

                if (item.hasOwnProperty('bookingTotal'))
                {
                  return <span className="text--semi-bold">{item['bookingTotal']}</span>;
                }

                let tickets = this.state.tickets.filter((ticket: Ticket) => {
                  return (ticket.id == item['eventTicketId']);
                });

                if (tickets.length)
                {
                  return Money.getFormattedPrice(tickets[0].price);
                }

                return 'Unknown';
              }
            ),
          ]}
        />
    </div>

  }

  protected getNoBookingContent(event: Event)
  {
    let bookingsClosed = this.areBookingClosed(event);

    let heading = 'Tickets Available Now';
    let buttonContent = 'Book Tickets';
    let text = 'Get your tickets by ' + Dates.getDisplayValue(new Date(event.closing));
    let ctaUri = '/events/' + (event ? event.id : '') + '/booking';

    if (bookingsClosed)
    {
      heading = 'Tickets Unavailable';
      text = 'Ticket bookings are now closed for this event';
      buttonContent = 'Upcoming Events';
      ctaUri = '/events';
    }

    // TODO: Switch out content depending on permission status

    return <div className="cell">
      <CtaPlaceholder
        icon={faTicketAlt}
        heading={heading}
        text={text}
        buttonContent={buttonContent}
        ctaUri={ctaUri}
      />
    </div>;
  }

  protected areBookingClosed(event: Event)
  {
    if (!event) return true;
    return Boolean((new Date()).valueOf() > (new Date(event.closing)).valueOf());
  }

  protected getBookingUserAttendance(): Attendance
  {
    let bookingUserOrgId = this.state.bookingUserOrgId;

    let matchingBookingUserAttendance = this.state.attendance.filter((attendance: Attendance) => {
      return (attendance.userOrgId == bookingUserOrgId);
    });

    return matchingBookingUserAttendance.length ? matchingBookingUserAttendance[0] : null;
  }

  protected getBookingUserGuests(): Array<Guest>
  {
    let bookingUserOrgId = this.state.bookingUserOrgId;

    let matchingBookingGuests = this.state.guests.filter((guest: Guest) => {
      return (guest.userOrgId == bookingUserOrgId);
    });

    return matchingBookingGuests.length ? matchingBookingGuests : [];
  }

  protected onSearch(value: string, item: Attendance)
  {
    let lowerCaseSearchValue = this.strip(value);
    let member = MemberStore.getByUserOrgIdAndActiveOrganisation(item.userOrgId);

    return (
      value == ''
      || this.strip(member.firstNames).indexOf(lowerCaseSearchValue) !== -1
      || this.strip(member.lastName).indexOf(lowerCaseSearchValue) !== -1
      || (this.strip(member.firstNames) + this.strip(member.lastName)).indexOf(lowerCaseSearchValue) !== -1
      || member.preferredName && (this.strip(member.preferredName + member.lastName)).indexOf(lowerCaseSearchValue) !== -1
      || this.strip(member.emailAddress).indexOf(lowerCaseSearchValue) !== -1
      || this.strip(item.status).indexOf(lowerCaseSearchValue) !== -1
    )
  }

  protected strip(string: string)
  {
    return string.toLowerCase().replace(' ', '');
  }

  protected getStatusContent(attendance: Attendance)
  {
    if (!attendance)
    {
      return '';
    }

    switch (attendance.status)
    {
      case 'invited':
        return <div><FontAwesomeIcon icon={faEnvelopeOpenText} className={'mr-1 pr-0'}/> Invited</div>;
      case 'going':
        return <div><FontAwesomeIcon icon={faCheckCircle} className={'mr-1 pr-0'}/> Going</div>;
      case 'not_going':
        return <div><FontAwesomeIcon icon={faTimesCircle} className={'mr-1 pr-0'}/> Not Going</div>;
    }
  }

  protected getGuestContent(attendance: Attendance)
  {
    if (!attendance)
    {
      return '';
    }

    let guests = this.state.guests.filter((guest: Guest) => {
      return guest.userOrgId == attendance.userOrgId;
    });

    return guests.length;
  }

  protected getAdminOptionsPanel(event: Event)
  {
    if (!PermissionsStore.hasPermission('event:write'))
    {
      return null;
    }

    let toggleText = 'Book tickets for another member';
    let toggleLink = '/events/' + (event ? event.id : '') + '/booking/' + this.state.bookingUserOrgId;

    return <Panel headerLeft={<div className="Header">More</div>}>
      <div className="grid-x">
        <div className="cell">

          <div>
            <Link className={'display--block'} to={toggleLink}>
              <div className={'StatsRow'}>
                <div className="StatsRow__Left">
                  {toggleText}
                </div>
                <div className="StatsRow__Right">
                  <FontAwesomeIcon icon={faArrowRight}/>
                </div>
              </div>
            </Link>
          </div>

        </div>

      </div>
    </Panel>
  }
}
