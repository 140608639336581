import {DataStore} from "ts-redux/src/DataStore/DataStore";
import { Member } from "../Member/Entity/Member";
import { MemberStore } from "../Member/MemberStore";
import { PermissionsStore } from "../Permissions/PermissionsStore";
import {User} from "./Entity/User";
import {Http} from "phusion/src/Core/Http/Http";
import {HttpResponse} from "phusion/src/Core/Http/HttpResponse";
import {HttpError} from "phusion/src/Core/Http/HttpError";
import {Objects} from "phusion/src/Core/Objects/Objects";
import {Redux} from "ts-redux/src/Redux";
import {OrganisationStore} from "../Organisation/OrganisationStore";
import {Organisation} from "../Organisation/Entity/Organisation";
import {HttpRequest} from "phusion/src/Core/Http/HttpRequest";
import {LocalStorage} from "phusion/src/Core/Storage/LocalStorage";
import {DeviceUUID} from "device-uuid";
import {Url} from "../../utility/Url";
import {Account} from "../Account/Entity/Account";
import {AccountStore} from "../Account/AccountStore";

declare const Promise: any;

export class UserStore extends DataStore
{
  public static readonly key: string = 'user';
  protected static readonly SET_AUTHENTICATING: string = 'set_user_authenticating';
  protected static readonly SET_UPDATING: string = 'set_user_updating';
  protected static readonly SET_USER: string = 'update_user';
  protected static readonly SET_JWT: string = 'update_jwt';

  public static reduce(
    state = {
      authenticating: false,
      updating: false,
      user: null,
      jwt: null
    },
    action: {
      type: string,
      data: any
    }
  )
  {
    switch (action.type)
    {
      case UserStore.SET_AUTHENTICATING:
        return Object.assign({}, state, {
          authenticating: action.data
        });
      case UserStore.SET_UPDATING:
        return Object.assign({}, state, {
          updating: action.data
        });
      case UserStore.SET_USER:
        return Object.assign({}, state, {
          user: action.data
        });
      case UserStore.SET_JWT:
        return Object.assign({}, state, {
          jwt: action.data
        });
      default:
        return state;
    }
  }

  public static authenticate(email: string, password: string)
  {
    const clientId = this.getClientId();

    return new Promise((resolve: Function, reject: Function) => {

      this.setAuthenticating(true);
      this.setUpdating(true);

      const promise = Http.post(Url.api('/auth'), {
        emailAddress: email,
        password: password,
        clientId: clientId
      });

      promise.then((response: HttpResponse) => {

        const success = this.handleAuthResponse(response);

        this.setAuthenticating(false);
        this.setUpdating(false);
        
        if (!success)
        {
          return reject(UserStore.createError(response));
        }
        
        return resolve(response);
      });

      promise.catch((httpError: HttpError) => {
        this.setAuthenticating(false);
        this.setUpdating(false);
        return reject(httpError);
      })
    })
  }

  public static signup(firstNames: string, lastName: string, email: string, password: string)
  {
    const clientId = this.getClientId();

    return new Promise((resolve: Function, reject: Function) => {

      this.setUpdating(true);

      const promise = Http.post(Url.api('/signup'), {
        firstNames: firstNames,
        lastName: lastName,
        emailAddress: email,
        password: password,
        clientId: clientId
      });

      promise.then((response: HttpResponse) => {
  
        const success = this.handleAuthResponse(response);
  
        this.setAuthenticating(false);
        this.setUpdating(false);
  
        if (!success)
        {
          return reject(UserStore.createError(response));
        }
  
        return resolve(response);
      });

      promise.catch((httpError: HttpError) => {
        this.setAuthenticating(false);
        this.setUpdating(false);
        return reject(httpError);
      })
    })
  }
  
  public static isUpdating(): boolean
  {
    return Boolean(Objects.getByKeyPath('user:updating', Redux.getState()));
  }

  public static getUser(): User
  {
    const user = Objects.getByKeyPath('user:user', Redux.getState());

    if (user)
    {
      return User.create(user);
    }

    return null;
  }

  public static syncUser(): Promise<User>
  {
    return new Promise((resolve: Function, reject: Function) =>
    {
      UserStore.setUpdating(true);
      const promise = Http.get(Url.api('/user'));

      promise.then((response: HttpResponse) => {

        const responseBody = Objects.getByKeyPath('data', response.data);
        const user = Objects.getByKeyPath('user', responseBody);

        if (user)
        {
          UserStore.setUpdating(false);
          UserStore.setUser(User.create(user));
          return resolve(User.create(user));
        }

        UserStore.setUpdating(false);
        return reject(UserStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        return reject(httpError);
      })
    });
  }

  public static update(user: User): Promise<User>
  {
    return new Promise((resolve: Function, reject: Function) =>
    {
      this.setUpdating(true);

      const promise = Http.put(Url.api(`/user/${user.id}`), user.serialize());

      promise.then((response: HttpResponse) => {

        const responseBody = Objects.getByKeyPath('data', response.data);
        const user = Objects.getByKeyPath('user', responseBody);

        if (user)
        {
          const userEntity = User.create(user);
          this.setUser(userEntity);
          this.setUpdating(false);
          return resolve(userEntity);
        }

        this.setUpdating(false);
        return reject(UserStore.createError(response));

      })
    });
  }
  
  public static async updatePassword(currentPassword: string, newPassword: string, confirmNewPassword: string): Promise<User>
  {
    this.setUpdating(true);
    
    const user = this.getUser();
    
    if (!user)
    {
      throw "Cannot update password without an authenticated user";
    }
    
    const response = await Http.put(
      Url.api(`/user/${user.id}/password`),
      {
        currentPassword: currentPassword,
        newPassword: newPassword,
        confirmNewPassword: confirmNewPassword
      }
    );
  
    const responseData = response.data;
    const updatedUser = Objects.getByKeyPath('data:user', responseData);
    
    if (!updatedUser)
    {
      this.setUpdating(false);
      const error = this.createError(response);
      throw error.message;
    }
    
    this.setUpdating(false);
    return updatedUser;
  }
  
  public static async updateEmailAddress(emailAddress: string, currentPassword: string): Promise<User>
  {
    console.log('updateEmailAddress');
    
    this.setUpdating(true);
    
    const user = this.getUser();
    
    if (!user)
    {
      throw "Cannot email address without an authenticated user";
    }
    
    const response = await Http.put(
      Url.api(`/user/${user.id}/update-email`),
      { newEmailAddress: emailAddress, password: currentPassword }
    );
    
    const responseData = response.data;
    const endpointResponse = Objects.getByKeyPath('data', responseData);
  
    if (!endpointResponse)
    {
      console.log('No response data');
      this.setUpdating(false);
      const error = this.createError(response);
      throw error.message;
    }
  
    this.setUpdating(false);
    return Objects.getByKeyPath('data:user', responseData);
  }
  
  private static handleAuthResponse(response): boolean
  {
    const responseBody = Objects.getByKeyPath('data', response.data);
    let user = Objects.getByKeyPath('user', responseBody);
    
    if (!user)
    {
      console.error('Cannot process auth response - no user found', response);
      return false;
    }
  
    // Add Organisations to OrganisationStore
    const responseOrgs = Objects.getByKeyPath('organisations', responseBody);
  
    if (responseOrgs && responseOrgs.length)
    {
      const organisationEntities = [];
    
      for (const key in responseOrgs)
        if (responseOrgs.hasOwnProperty(key))
        {
          const organisation = Organisation.create(responseOrgs[key]);
          organisationEntities.push(organisation);
        }
    
      // Set organisations
      OrganisationStore.setOrganisations(organisationEntities);
    
      // Select a default organisation
      if (organisationEntities.length)
      {
        OrganisationStore.setActiveOrganisation(organisationEntities[0]);
      }
    }
  
    // Add accounts to accounts store
    const accountsByOrgId = Objects.getByKeyPath('accountsByOrgId', responseBody);
  
    if (accountsByOrgId)
    {
      const accountEntitiesByOrgId = {};
    
      for (const organisationId in accountsByOrgId)
        if (accountsByOrgId.hasOwnProperty(organisationId))
        {
        
          const accounts = [];
        
          for (const accountId in accountsByOrgId[organisationId])
            if (accountsByOrgId[organisationId].hasOwnProperty(accountId))
            {
              const account = Account.create(accountsByOrgId[organisationId][accountId]);
              accounts.push(account);
            }
        
          if (!accountEntitiesByOrgId[organisationId])
          {
            accountEntitiesByOrgId[organisationId] = [];
          }
        
          accountEntitiesByOrgId[organisationId] = accounts;
        }
    
      for (const orgId in accountEntitiesByOrgId)
        if (accountEntitiesByOrgId.hasOwnProperty(orgId))
        {
          const accountEntities = accountEntitiesByOrgId[orgId];
        
          // Set organisations
          AccountStore.setAccountsByOrganisation(Number(orgId), accountEntities);
        }
    }
  
    // Add members to members store
    const membersByOrgId = Objects.getByKeyPath('membersByOrgId', responseBody);
  
    if (membersByOrgId)
    {
      const memberEntitiesByOrgId = {};
    
      for (const organisationId in membersByOrgId)
        if (membersByOrgId.hasOwnProperty(organisationId))
        {
        
          const members = [];
        
          for (const userOrgId in membersByOrgId[organisationId])
            if (membersByOrgId[organisationId].hasOwnProperty(userOrgId))
            {
              const member = Member.create(membersByOrgId[organisationId][userOrgId]);
              members.push(member);
            }
        
          if (!memberEntitiesByOrgId[organisationId])
          {
            memberEntitiesByOrgId[organisationId] = [];
          }
        
          memberEntitiesByOrgId[organisationId] = members;
        }
    
      for (const orgId in memberEntitiesByOrgId)
        if (memberEntitiesByOrgId.hasOwnProperty(orgId))
        {
          const memberEntities = memberEntitiesByOrgId[orgId];
        
          // Set organisations
          MemberStore.setMembersByOrganisation(Number(orgId), memberEntities);
        }
    }
  
    // Store permissions
    const permissionsByOrgId = Objects.getByKeyPath('permissionsByOrgId', responseBody);
  
    if (permissionsByOrgId)
    {
      for (const orgId in permissionsByOrgId)
        if (permissionsByOrgId.hasOwnProperty(orgId))
        {
          const permissions = permissionsByOrgId[orgId];
        
          // Set organisations
          PermissionsStore.setPermissionsByOrganisation(Number(orgId), permissions);
        }
    }
  
    user = User.create(user);
  
    const jwt = Objects.getByKeyPath('jwt', responseBody);
  
    // Set jwt in headers before every request
    Http.onBeforeRequest((request: HttpRequest) => {
      request.headers['Authorization'] = 'Bearer ' + jwt;
      request.headers['X-ClientId'] = this.getClientId();
      return request;
    });
  
    this.setJwt(jwt);
    this.setUser(user);
    
    return true;
  }
  
  private static getClientId(): string
  {
    let clientId = LocalStorage.get('device:clientId');
  
    if (!clientId)
    {
      clientId = new DeviceUUID().get();
    
      LocalStorage.set('device:clientId', clientId);
    }
    
    return clientId;
  }

  /** Internal Redux Actions **/

  protected static setUpdating(updating: boolean)
  {
    Redux.dispatch({
      type: UserStore.SET_UPDATING,
      data: updating
    });
  }

  protected static setAuthenticating(authenticating: boolean)
  {
    Redux.dispatch({
      type: UserStore.SET_AUTHENTICATING,
      data: authenticating
    });
  }

  protected static setUser(user: User)
  {
    Redux.dispatch({
      type: UserStore.SET_USER,
      data: user
    });
  }

  protected static setJwt(jwt: string)
  {
    Redux.dispatch({
      type: UserStore.SET_JWT,
      data: jwt
    });
  }
  
  public static subscribeToUser(callback: (user: User) => void, currentValue?: User)
  {
    return this.subscribe((state) => {
      const newValue = this.getUser();
      
      if (JSON.stringify(currentValue) !== JSON.stringify(newValue))
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }
  
  public static subscribeToUpdating(callback: (updating: boolean) => void, currentValue?: boolean)
  {
    return this.subscribe((state) => {
      const newValue = this.isUpdating();
      
      if (currentValue !== newValue)
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }

}
