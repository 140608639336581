import {Objects} from "phusion/src/Core/Objects/Objects";

export class Transaction
{
  public id: number;
  public date: string;
  public userOrgId: number;
  public organisationId: number;
  public description: string;
  public amount: number;
  public reconcileReference: number;
  public debitAccountId: number;
  public creditAccountId: number;
  
  public balance: number; // This is sometimes hydrated for display purposes

  public static create(data: Object = null): Transaction
  {
    if (!data)
    {
      return new this();
    }

    let mutators = {};

    return Objects.hydrate(new Transaction(), data, mutators) as Transaction;
  }

  public serialize(): Object
  {
    let mutators = {};
    return Objects.hydrate({}, this, mutators);
  }
}
