
declare const ENV;

export class Url
{
  private static getUrl(subdomain: string, uri: string): string
  {
    let protocol = 'http';
    let domain = 'shumi.co.uk';

    if (ENV === 'qa')
    {
      protocol = 'https';
      domain = 'qa-shumi.co.uk';
    }
    else if (ENV == 'dev')
    {
      domain = 'shumi.local';
    }

    if (uri.charAt(0) !== '/')
    {
      uri = '/' + uri
    }

    return protocol + '://' + subdomain +'.' + domain + uri;
  }

  public static api(uri: string): string
  {
    return this.getUrl('api', uri);
  }
}
