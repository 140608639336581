import {DataStore} from "ts-redux/src/DataStore/DataStore";
import {Http} from "phusion/src/Core/Http/Http";
import {HttpResponse} from "phusion/src/Core/Http/HttpResponse";
import {HttpError} from "phusion/src/Core/Http/HttpError";
import {Objects} from "phusion/src/Core/Objects/Objects";
import {Redux} from "ts-redux/src/Redux";
import {Url} from "../../utility/Url";
import {Member} from "./Entity/Member";
import {OrganisationStore} from "../Organisation/OrganisationStore";
import {Arrays} from "phusion/src/Core/Arrays/Arrays";
import {TransactionStore} from "../Transaction/TransactionStore";
import {Transaction} from "../Transaction/Entity/Transaction";

declare let Promise: any;

export class MemberStore extends DataStore
{
  public static readonly key: string = 'member';
  protected static readonly SET_UPDATING: string = 'set_member_updating';
  protected static readonly SET_MEMBERS_BY_ORGANISATION: string = 'set_members_by_organisation';

  public static reduce(
    state = {
      updating: false,
      members: {}
    },
    action: {
      type: string,
      data: any
    }
  )
  {
    switch (action.type)
    {
      case MemberStore.SET_UPDATING:
        return Object.assign({}, state, {
          updating: action.data
        });
        
      case MemberStore.SET_MEMBERS_BY_ORGANISATION:

        let members = state['members']
          ? state['members']
          : {};

        let membersByOrgId = Objects.clone(members);
        membersByOrgId[action.data.organisationId] = action.data.members;

        return Object.assign({}, state, {
          members: membersByOrgId
        });
      default:
        return state;
    }
  }

  public static getByUserOrgIdAndActiveOrganisation(userOrgId: number): Member
  {
    let activeOrganisation = OrganisationStore.getActiveOrganisation();
    let allMembers = this.getMembersByOrganisationId(activeOrganisation.id);

    if (!allMembers)
    {
      return null;
    }

    let matchingMembers = allMembers.filter((member: Member) => {
      return member.userOrgId == userOrgId;
    });

    if (matchingMembers.length)
    {
      return matchingMembers[0];
    }

    return null;
  }

  public static getAllMembersByActiveOrganisation(): Array<Member>
  {
    let activeOrganisation = OrganisationStore.getActiveOrganisation();
    return this.getMembersByOrganisationId(activeOrganisation.id);
  }

  protected static getMembersByOrganisationId(organisationId: number): Array<Member>
  {
    let members = Objects.getByKeyPath('member:members:' + organisationId, Redux.getState());

    if (members)
    {
      return members;
    }

    return [];
  }

  public static syncActiveOrganisationMembers(): Promise<Array<Member>>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      MemberStore.setUpdating(true);

      let promise = Http.get(Url.api('/organisation/' + activeOrg.id + '/members'));

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let members = Objects.getByKeyPath('members', responseBody);

        if (members && Object.keys(members).length)
        {
          let memberEntities = [];

          for (let userOrgId in members)
            if (members.hasOwnProperty(userOrgId))
          {
            memberEntities.push(Member.create(members[userOrgId]));
          }

          MemberStore.setMembersByOrganisation(activeOrg.id, memberEntities);
          MemberStore.setUpdating(false);

          return resolve(memberEntities);
        }

        MemberStore.setUpdating(false);
        return reject(MemberStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        MemberStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  public static syncMember(userOrgId: number): Promise<Member>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      MemberStore.setUpdating(true);

      let promise = Http.get(Url.api('/organisation/' + activeOrg.id + '/member/' + userOrgId));

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let member = Objects.getByKeyPath('member', responseBody);

        if (member)
        {
          member = Member.create(member);

          this.spliceMemberIntoOrgMembers(activeOrg.id, member);
          MemberStore.setUpdating(false);

          return resolve(member);
        }

        MemberStore.setUpdating(false);
        return reject(MemberStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        MemberStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  protected static spliceMemberIntoOrgMembers(organisationId: number, member: Member): Array<Member>
  {
    let allOrgMembers = this.getMembersByOrganisationId(organisationId);

    let existingKey = null;

    for (let key in allOrgMembers)
    {
      let existingMember = allOrgMembers[key];

      if (existingMember.userOrgId == member.userOrgId)
      {
        existingKey = Number(key);
        break;
      }
    }

    if (typeof existingKey == 'number')
    {
      allOrgMembers.splice(existingKey, 1, member);
    }
    else
    {
      allOrgMembers.push(member);
    }

    this.setMembersByOrganisation(organisationId, allOrgMembers);

    return allOrgMembers;
  }

  public static createNewMember(member: Member): Promise<Member>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      MemberStore.setUpdating(true);

      let promise = Http.post(Url.api('/organisation/' + activeOrg.id + '/member'), member);

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let member = Objects.getByKeyPath('member', responseBody);

        if (member)
        {
          member = Member.create(member);

          let existingOrgMembers = this.getAllMembersByActiveOrganisation();

          existingOrgMembers = existingOrgMembers ? Arrays.clone(existingOrgMembers) : [];


          existingOrgMembers.push(member);

          MemberStore.setMembersByOrganisation(activeOrg.id, existingOrgMembers);
          MemberStore.setUpdating(false);

          return resolve(member);
        }

        MemberStore.setUpdating(false);
        return reject(MemberStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        MemberStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  public static updateMember(member: Member): Promise<Member>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      MemberStore.setUpdating(true);

      let promise = Http.put(Url.api('/organisation/' + activeOrg.id + '/member/' + member.userOrgId), member);

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let member = Objects.getByKeyPath('member', responseBody);

        if (member)
        {
          member = Member.create(member);

          let existingOrgMembers = this.getAllMembersByActiveOrganisation();

          existingOrgMembers = existingOrgMembers ? Arrays.clone(existingOrgMembers) : [];

          let existingIndex = null;

          for (let key in existingOrgMembers)
          {
            let existingOrgMember = existingOrgMembers[key];

            if (existingOrgMember.userOrgId == member.userOrgId)
            {
              existingIndex = key;
              break;
            }
          }

          if (existingIndex)
          {
            existingOrgMembers.splice(existingIndex, 1, member);
          }
          else
          {
            existingOrgMembers.push(member);
          }

          MemberStore.setMembersByOrganisation(activeOrg.id, existingOrgMembers);
          MemberStore.setUpdating(false);

          return resolve(member);
        }

        MemberStore.setUpdating(false);
        return reject(MemberStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        MemberStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }
  
  public static getAllBalancesByUserOrgId()
  {
    const members = this.getAllMembersByActiveOrganisation();
    
    const balances = {};
    
    for (let key in members)
      if (members.hasOwnProperty(key))
    {
      const member = members[key];
      const userOrgId = member.userOrgId;
  
      let memberTransactions = TransactionStore.getAllByUserOrgId(userOrgId).reverse();
      let balance = member.broughtForwardBalance ? member.broughtForwardBalance * 100 : 0;
  
      memberTransactions.forEach((transaction: Transaction) => {
        if (transaction.creditAccountId && transaction.userOrgId == userOrgId)
        {
          balance -= transaction.amount * 100;
        }
        else if (transaction.debitAccountId && transaction.userOrgId == userOrgId)
        {
          balance += transaction.amount * 100;
        }
      });
  
      balances[userOrgId] = parseFloat((balance / 100).toFixed(2));
    }
    
    return balances;
  }

  public static getBalanceByUserOrgId(userOrgId: number): number
  {
    let member = MemberStore.getByUserOrgIdAndActiveOrganisation(userOrgId);

    if (!member)
    {
      return 0;
    }

    let memberTransactions = TransactionStore.getAllByUserOrgId(userOrgId).reverse();
    let balance = member.broughtForwardBalance ? member.broughtForwardBalance * 100 : 0;

    memberTransactions.forEach((transaction: Transaction) => {
      if (transaction.creditAccountId && transaction.userOrgId == userOrgId)
      {
        balance -= transaction.amount * 100;
      }
      else if (transaction.debitAccountId && transaction.userOrgId == userOrgId)
      {
        balance += transaction.amount * 100;
      }
    });

    return parseFloat((balance / 100).toFixed(2));
  }

  protected static setUpdating(updating: boolean)
  {
    Redux.dispatch({
      type: MemberStore.SET_UPDATING,
      data: updating
    });
  }

  public static setMembersByOrganisation(organisationId: number, members: Array<Member>)
  {
    Redux.dispatch({
      type: MemberStore.SET_MEMBERS_BY_ORGANISATION,
      data: {
        organisationId: organisationId,
        members: members
      }
    });
  }
  
  public static subscribeToUpdating(callback: (updating: boolean) => void, currentValue?: boolean)
  {
    return this.subscribe((state) => {
      let newValue = state.updating;
      
      if (currentValue !== newValue)
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }
  
  public static subscribeToActiveOrgMembers(callback: (members: Array<Member>) => void, currentValue?: Array<Member>)
  {
    return this.subscribe((state) => {
      let newValue = this.getAllMembersByActiveOrganisation();
      
      if (JSON.stringify(currentValue) !== JSON.stringify(newValue))
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }
  
  public static subscribeToMemberByUserOrgId(
    userOrgId: number,
    callback: (members: Member) => void,
    currentValue?: Member
  )
  {
    userOrgId = Number(userOrgId);
    
    return this.subscribe((state) => {
      let members = this.getAllMembersByActiveOrganisation();
      
      let newValue = members.find((member: Member) => {
        return member.userOrgId === userOrgId;
      })
      
      if (JSON.stringify(currentValue) !== JSON.stringify(newValue))
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }
  
  public static subscribeToAllMemberBalances(callback: (allBalancesByUserOrgId: Object) => void, currentValue: Object)
  {
    return this.subscribe((state) => {
      const newValue = this.getAllBalancesByUserOrgId();
      
      if (JSON.stringify(currentValue) !== JSON.stringify(newValue))
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }

  public static onSearch(value: string, item: Member)
  {
    let lowerCaseSearchValue = MemberStore.strip(value);

    return (
      value == ''
      || MemberStore.strip(item.firstNames).indexOf(lowerCaseSearchValue) !== -1
      || MemberStore.strip(item.lastName).indexOf(lowerCaseSearchValue) !== -1
      || (MemberStore.strip(item.firstNames) + MemberStore.strip(item.lastName)).indexOf(lowerCaseSearchValue) !== -1
      || item.preferredName && (MemberStore.strip(item.preferredName + item.lastName)).indexOf(lowerCaseSearchValue) !== -1
      || MemberStore.strip(item.emailAddress).indexOf(lowerCaseSearchValue) !== -1
    )
  }

  protected static strip(string: string)
  {
    return string.toLowerCase().replace(' ', '');
  }

}
