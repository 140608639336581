
import { Money } from "appex-theme/src/Utility/Money";
import * as React from 'react';
import {Link} from 'react-router-dom';
import { Request } from "appex-theme/src/Utility/Request";
import {AbstractComponent} from "../../../../component/AbstractComponent";
import {PageBar} from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import {Breadcrumbs} from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import {Panel} from "appex-theme/src/Core/Panel/Panel";
import {ToastMessageStore} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import {ToastMessage} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";
import './scss/ViewAllAccountsPage.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "appex-theme/src/Core/Button/Button";
import {AccountStore} from "../../../../data/Account/AccountStore";
import {Account} from "../../../../data/Account/Entity/Account";
import {faEdit} from "@fortawesome/pro-regular-svg-icons/faEdit";
import {faTrashAlt} from "@fortawesome/pro-regular-svg-icons/faTrashAlt";
import {faEye} from "@fortawesome/pro-regular-svg-icons/faEye";
import {faCreditCard} from "@fortawesome/pro-duotone-svg-icons/faCreditCard";
import {faLandmark} from "@fortawesome/pro-light-svg-icons/faLandmark";
import {faMoneyBillWave} from "@fortawesome/pro-light-svg-icons/faMoneyBillWave";
import {faMoneyCheckAlt} from "@fortawesome/pro-light-svg-icons/faMoneyCheckAlt";
import {faPiggyBank} from "@fortawesome/pro-light-svg-icons/faPiggyBank";
import {faReceipt} from "@fortawesome/pro-light-svg-icons/faReceipt";
import {faSackDollar} from "@fortawesome/pro-light-svg-icons/faSackDollar";
import {faMoneyCheck} from "@fortawesome/pro-light-svg-icons/faMoneyCheck";
import {DisplayColumn} from "appex-theme/src/Data/DataTable/DataTable/Entity/DisplayColumn";
import {DataTable} from "appex-theme/src/Data/DataTable/DataTable/DataTable";
import {CtaPlaceholder} from "appex-theme/src/Placeholder/Cta/CtaPlaceholder";
import { Member } from "../../../../data/Member/Entity/Member";
import { PermissionsStore } from "../../../../data/Permissions/PermissionsStore";
import {TransactionStore} from "../../../../data/Transaction/TransactionStore";
import {Modal} from "appex-theme/src/Core/Modal/Modal";
import {Header} from "appex-theme/src/Form/Input/Header/Header";
import {faTrashAlt as solidFaTrashAlt} from "@fortawesome/pro-solid-svg-icons/faTrashAlt";
import {DataStoreError} from "ts-redux/src/DataStore/DataStoreError";

export interface ViewAllAccountsProps {}
export interface ViewAllAccountsState {
  accounts: Array<Account>,
  accountBalancesById: Object,
  updating: boolean,
  deleteAccountModalOpen: boolean,
  deleteAccountModalAccountId: number,
  deleteAccountModalSubmitting: boolean
}

export class ViewAllAccountsPage extends AbstractComponent<ViewAllAccountsProps, ViewAllAccountsState>
{
  protected unsubscribers = {};

  public constructor(props: ViewAllAccountsProps)
  {
    super(props);

    const accounts = AccountStore.getAllAccountsByActiveOrganisation();

    this.state = {
      accounts: accounts ? accounts : [],
      accountBalancesById: AccountStore.getAllBalancesById(),
      updating: false,
      deleteAccountModalOpen: false,
      deleteAccountModalAccountId: null,
      deleteAccountModalSubmitting: false
    };
  }

  public componentDidMount()
  {
    // Redirect if you don't have financial:read permissions
    if (!PermissionsStore.hasPermission('financial:read'))
    {
      ToastMessageStore.addMessage(ToastMessage.create('error', "Sorry, you don't have access to view this page"));
      return Request.goBack(this);
    }
    
    this.unsubscribers['accounts_updating'] = AccountStore.subscribeToUpdating(
      (updating: boolean) => {
        this.setState({
          updating: updating
        });
      },
      this.state.updating
    );
    
    this.unsubscribers['accounts'] = AccountStore.subscribeToActiveOrgAccounts(
      (accounts: Array<Account>) => {
        this.setState({
          accounts: accounts
        })
      },
      this.state.accounts
    );
    
    this.unsubscribers['account_balances'] = AccountStore.subscribeToAllAccountBalances(
      (accountBalancesById: Array<Object>) => {
        this.setState({
          accountBalancesById: accountBalancesById
        })
      },
      this.state.accountBalancesById
    );
    
    AccountStore.syncActiveOrganisationAccounts();
  }

  public componentWillUnmount()
  {
    for (let key in this.unsubscribers)
      if (this.unsubscribers.hasOwnProperty(key))
    {
      this.unsubscribers[key]();
    }
  }

  public render()
  {
    let hasFinancialWritePermissions = PermissionsStore.hasPermission('financial:write');
    
    let breadcrumbs = [
      {
        key: 'home',
        title: <span><i className="fal fa-home"/> Home</span>,
        href: '/'
      },
      {
        key: 'accounts',
        title: 'Accounts'
      }
    ];

    // Don't display sub accounts
    let accounts = this
      .state
      .accounts
      .filter((account: Account) => {
        return account.category !== 'sub_account';
      })
      .map((account: Account) => {
        account.balance = this.state.accountBalancesById[account.id] || 0;
        return account;
      });

    return <div className={'ViewAllAccountsPage'}>

      <div className="grid-container">
        <PageBar title={<div><FontAwesomeIcon icon={faCreditCard}/>Accounts</div>} rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}/>

        <div className="grid-x">
          <div className="cell">
            <Panel>
              <DataTable
                data={accounts}
                displayColumns={[
                  DisplayColumn.create(
                    'Name',
                    'name',
                    (account: Account) => {
                      return account.name
                    },
                    2
                  ),
                  DisplayColumn.create(
                    'Category',
                    'category',
                    (account: Account) => {
                      switch (account.category)
                      {
                        case 'asset':
                          return <div><FontAwesomeIcon icon={faPiggyBank} className={'mr-1 pr-0'}/> Asset</div>;
                        case 'liability':
                          return <div><FontAwesomeIcon icon={faReceipt} className={'mr-1 pr-0'}/> Liability</div>;
                        case 'revenue':
                          return <div><FontAwesomeIcon icon={faMoneyBillWave} className={'mr-1 pr-0'}/> Revenue</div>;
                        case 'expense':
                          return <div><FontAwesomeIcon icon={faMoneyCheckAlt} className={'mr-1 pr-0'}/> Expense</div>;
                        default:
                          return account.category;
                      }
                    },
                    2
                  ),
                  DisplayColumn.create(
                    'Type',
                    'type',
                    (account: Account) => {
                      switch (account.type)
                      {
                        case 'bank':
                          return <div><FontAwesomeIcon icon={faLandmark} className={'mr-1 pr-0'}/> Bank Account</div>;
                        case 'cash':
                          return <div><FontAwesomeIcon icon={faSackDollar} className={'mr-1 pr-0'}/> Cash Account</div>;
                        case 'general':
                          return <div><FontAwesomeIcon icon={faMoneyCheck} className={'mr-1 pr-0'}/> General Account</div>;
                        case 'cheques_held':
                          return <div><FontAwesomeIcon icon={faMoneyCheckAlt} className={'mr-1 pr-0'}/> Cheques Held</div>;
                        default:
                          return account.type;
                      }
                    },

                    2
                  ),
                  DisplayColumn.create(
                    'Balance',
                    'balance',
                    (account: Account) => {
                      let prefix;
                      
                      if (account.balance >= 0)
                      {
                        prefix = '+';
                      }
                      
                      return `${prefix ? prefix : ''}${Money.getFormattedPrice(account.balance)}`;
                    },
                    2
                  ),
                  DisplayColumn.create(
                    'Action',
                    null,
                    (account: Account) => {
                      return <div>
                        <Link to={'/accounts/' + account.id} className={'mr-6'}>
                          <FontAwesomeIcon icon={faEye}/>
                        </Link>
                        
                        {
                          hasFinancialWritePermissions
                            ? <Link to={'/accounts/' + account.id + '/update'} className={'mr-6'}>
                                <FontAwesomeIcon icon={faEdit}/>
                              </Link>
                            : null
                        }
                        {
                          hasFinancialWritePermissions
                            ? <div onClick={this.openDeleteAccountModal.bind(this, account.id)} className={'cursor--pointer'}>
                              <FontAwesomeIcon icon={faTrashAlt}/>
                            </div>
                            : null
                        }

                      </div>
                    }
                  ),
                ]}
                searchable={true}
                sortable={true}
                onSearch={AccountStore.onSearch}
                noDataContent={
                  <CtaPlaceholder
                    ctaUri={'/accounts/new'}
                    icon={faCreditCard}
                    heading={'No Accounts Found'}
                    text={'No accounts have been created'}
                  />
                }
                paginationControls={true}
                dataUpdating={this.state.updating}
              />
              {
                hasFinancialWritePermissions
                  ? <div className="text--align-center-sm-only text--align-right-md mb-3">
                      <Link to={'/accounts/new'}>
                        <Button modifiers={['primary']}>
                          Create New Account
                        </Button>
                      </Link>
                    </div>
                  : null
              }

              <div>
                {this.getDeleteAccountModal()}
              </div>
            </Panel>
          </div>
        </div>
      </div>
    </div>
  }

  protected openDeleteAccountModal(accountId: number)
  {
    const account = AccountStore.getByIdAndActiveOrganisation(accountId);
    
    if (!account)
    {
      return ToastMessageStore.addMessage(
        ToastMessage.create('error', "Cannot delete an account that doesn't exist")
      )
    }
    else if (account.type === 'cheques_held')
    {
      return ToastMessageStore.addMessage(
        ToastMessage.create('error', "Cannot delete an organisation's cheques held account")
      )
    }
    else if (account.category === 'sub_account')
    {
      return ToastMessageStore.addMessage(
        ToastMessage.create('error', "Cannot delete a sub account")
      )
    }
    
    let accountTransactions = TransactionStore.getAllByAccountId(accountId);

    if (accountTransactions && accountTransactions.length)
    {
      return ToastMessageStore.addMessage(
        ToastMessage.create('error', 'Cannot delete an account with transactions in the current accounting period')
      )
    }

    this.setState({
      deleteAccountModalOpen: true,
      deleteAccountModalAccountId: accountId
    })
  }

  protected closeModal()
  {
    if (this.state.deleteAccountModalSubmitting)
    {
      return;
    }

    this.setState({
      deleteAccountModalOpen: false,
    });

    let timeout = setTimeout(() => {
      this.setState({
        deleteAccountModalAccountId: null
      });
      clearTimeout(timeout);
    }, 300);
  }

  protected deleteAccount(accountId: number)
  {
    this.setState({
      deleteAccountModalSubmitting: true
    });

    let promise = AccountStore.deleteAccount(accountId);

    promise.then((deletedAccountIds: Array<number>) => {

      this.setState({
        deleteAccountModalSubmitting: false
      });

      if (!deletedAccountIds.length)
      {
        ToastMessageStore.addMessage(
          ToastMessage.create('error', 'Failed to delete account')
        );
      }

      ToastMessageStore.addMessage(
        ToastMessage.create('success', 'Account deleted successfully')
      );

      this.closeModal();
    });

    promise.catch((error: DataStoreError) => {
      ToastMessageStore.addMessage(
        ToastMessage.create('error', error.message)
      );

      this.setState({
        deleteAccountModalSubmitting: false
      });
    })

  }

  protected getDeleteAccountModal()
  {
    if (!this.state.deleteAccountModalAccountId)
    {
      return null;
    }

    let accounts = this.state.accounts.filter((account: Account) => {
      return account.id == this.state.deleteAccountModalAccountId
    });

    if (!accounts.length)
    {
      return null;
    }

    let account = accounts[0];

    let buttonModifiers: Array<any> = ['primary'];

    if (this.state.deleteAccountModalSubmitting)
    {
      buttonModifiers.push('loading');
    }

    return <Modal
      open={this.state.deleteAccountModalOpen}
      close={this.closeModal.bind(this)}
    >
      <Header
        text={'Delete Account'}
        icon={solidFaTrashAlt}
      />
      <p>Are you sure you want to delete '{account.name}'</p>
      <div className="text--align-right mt-3 mb-3">
        <Button onClick={this.deleteAccount.bind(this, this.state.deleteAccountModalAccountId)} modifiers={buttonModifiers}>Confirm</Button>
      </div>
    </Modal>;
  }
}
