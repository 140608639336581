
import * as React from 'react';
import {Link} from 'react-router-dom';
import { Request } from "appex-theme/src/Utility/Request";
import {AbstractComponent} from "../../../../component/AbstractComponent";
import {PageBar} from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import {Breadcrumbs} from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import {Panel} from "appex-theme/src/Core/Panel/Panel";
import {OrganisationStore} from "../../../../data/Organisation/OrganisationStore";
import {ToastMessageStore} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import {ToastMessage} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";
import './scss/ViewAllRolesPage.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "appex-theme/src/Core/Button/Button";
import { PermissionsStore } from "../../../../data/Permissions/PermissionsStore";
import {RoleStore} from "../../../../data/Role/RoleStore";
import {Role} from "../../../../data/Role/Entity/Role";
import {faEdit} from "@fortawesome/pro-regular-svg-icons/faEdit";
import {faEye} from "@fortawesome/pro-regular-svg-icons/faEye";
import {faUserLock} from "@fortawesome/pro-duotone-svg-icons/faUserLock";
import {DataTable} from "appex-theme/src/Data/DataTable/DataTable/DataTable";
import {DisplayColumn} from "appex-theme/src/Data/DataTable/DataTable/Entity/DisplayColumn";
import {CtaPlaceholder} from "appex-theme/src/Placeholder/Cta/CtaPlaceholder";

export interface ViewAllRolesProps {}
export interface ViewAllRolesState {
  roles: Array<Role>,
  updating: boolean
}

export class ViewAllRolesPage extends AbstractComponent<ViewAllRolesProps, ViewAllRolesState>
{
  protected unsubscribers = {};

  public constructor(props: ViewAllRolesProps)
  {
    super(props);

    let roles = RoleStore.getAllRolesByActiveOrganisation();

    this.state = {
      roles: roles ? roles : [],
      updating: false
    };
  }

  public componentDidMount()
  {
    // Redirect if you don't have permissions:read permissions
    if (!PermissionsStore.hasPermission('permissions:read'))
    {
      ToastMessageStore.addMessage(ToastMessage.create('error', "Sorry, you don't have access to view this page"));
      return Request.goBack(this);
    }
    
    /**
     * Subscribe to Roles
     */
    
    this.unsubscribers['roles_updating'] = RoleStore.subscribeToUpdating(
      (updating: boolean) => {
        this.setState({
          updating: updating
        })
      },
      this.state.updating
    )
  
    this.unsubscribers['roles'] = RoleStore.subscribeToActiveOrgRoles(
      (roles: Array<Role>) => {
        this.setState({
          roles: roles
        })
      },
      this.state.roles
    )
    
    RoleStore.syncActiveOrganisationRoles();
  }

  public componentWillUnmount()
  {
    for (let key in this.unsubscribers)
      if (this.unsubscribers.hasOwnProperty(key))
    {
      this.unsubscribers[key]();
    }
  }

  public render()
  {
    let breadcrumbs = [
      {
        key: 'home',
        title: <span><i className="fal fa-home"/> Home</span>,
        href: '/'
      },
      {
        key: 'roles',
        title: 'Roles'
      }
    ];

    return <div className={'ViewAllRolesPage'}>

      <div className="grid-container">
        <PageBar
          title={<div><FontAwesomeIcon icon={faUserLock}/>Roles</div>}
          rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}
        />

        <div className="grid-x">
          <div className="cell">
            <Panel>
              <DataTable
                data={this.state.roles}
                displayColumns={[
                  DisplayColumn.create(
                    'Name',
                    'name',
                    (role: Role) => {
                      return role.name;
                    },
                    2
                  ),
                  DisplayColumn.create(
                    'Assigned Users',
                    null,
                    (role: Role) => {
                      return role.members.length;
                    },
                    2
                  ),
                  DisplayColumn.create(
                    'Permissions',
                    null,
                    (role: Role) => {
                      return role.permissions.length
                    },
                    2
                  ),
                  DisplayColumn.create(
                    'Action',
                    null,
                    (role: Role) => {
                      return <div>
                        <Link to={'/roles/' + role.id} className={'mr-6'}>
                          <FontAwesomeIcon icon={faEye}/>
                        </Link>
                        {
                          PermissionsStore.hasPermission('permissions:write')
                            ? <Link to={'/roles/' + role.id + '/update'}>
                                <FontAwesomeIcon icon={faEdit}/>
                              </Link>
                            : null
                        }
                      </div>
                    }
                  ),
                ]}
                searchable={true}
                sortable={true}
                onSearch={RoleStore.onSearch.bind(this)}
                noDataContent={
                  <CtaPlaceholder
                    ctaUri={'/roles/new'}
                    icon={faUserLock}
                    heading={'No User Roles Found'}
                    text={'No roles have been created'}
                  />
                }
                paginationControls={true}
                dataUpdating={this.state.updating}
              />
              {
                PermissionsStore.hasPermission('permissions:write')
                  ? <div className="text--align-center-sm-only text--align-right-md mt-4 mb-3">
                      <Link to={'/roles/new'}>
                        <Button modifiers={['primary']}>
                          Create New Role
                        </Button>
                      </Link>
                    </div>
                  : null
              }
            </Panel>
          </div>
        </div>
      </div>
    </div>
  }
}
