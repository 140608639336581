import {Objects} from "phusion/src/Core/Objects/Objects";

export class BalanceSheet
{
  public id: number;
  public periodStart: string;
  public periodEnd: string;
  public createdBy: number;
  public approvedBy: number;
  public cancelledBy: number;
  public organisationId: number;
  public status: 'draft' | 'approved' | 'cancelled';
  public created: string;
  public updated: string;
  
  
  public balance: number;

  public static create(data: Object = null): BalanceSheet
  {
    if (!data)
    {
      return new this();
    }

    let mutators = {};

    return Objects.hydrate(new BalanceSheet(), data, mutators) as BalanceSheet;
  }

  public serialize(): Object
  {
    let mutators = {};
    return Objects.hydrate({}, this, mutators);
  }
  
  public static getStatusDisplayName(balanceSheet: BalanceSheet)
  {
    if (!balanceSheet)
    {
      return null;
    }
    
    return balanceSheet.status.replace(/^./, balanceSheet.status.charAt(0).toUpperCase());
  }
}
