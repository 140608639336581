import {Http} from "phusion/src/Core/Http/Http";
import {HttpResponse} from "phusion/src/Core/Http/HttpResponse";
import {HttpError} from "phusion/src/Core/Http/HttpError";
import {Objects} from "phusion/src/Core/Objects/Objects";
import {Redux} from "ts-redux/src/Redux";
import {Url} from "../../utility/Url";
import {OrganisationStore} from "../Organisation/OrganisationStore";
import { DataStore } from "ts-redux/src/DataStore/DataStore";
import { BalanceSheet } from "./Entity/BalanceSheet";

declare let Promise: any;

export class BalanceSheetStore extends DataStore
{
  public static readonly key: string = 'balance_sheet';
  protected static readonly SET_UPDATING: string = 'set_balance_sheet_updating';
  protected static readonly SET_BALANCE_SHEETS_BY_ORGANISATION: string = 'set_balance_sheets_by_organisation';
  
  public static reduce(
    state = {
      updating: false,
      balance_sheets_by_org: {}
    },
    action: {
      type: string,
      data: any
    }
  )
  {
    switch (action.type)
    {
      case BalanceSheetStore.SET_UPDATING:
        return Object.assign({}, state, {
          updating: action.data
        });
      case BalanceSheetStore.SET_BALANCE_SHEETS_BY_ORGANISATION:

        let balanceSheets = state['balance_sheets_by_org']
          ? state['balance_sheets_by_org']
          : {};

        let balanceSheetsByOrg = Objects.clone(balanceSheets);
        balanceSheetsByOrg[action.data.organisationId] = action.data.balanceSheets;

        return Object.assign({}, state, {
          balance_sheets_by_org: balanceSheetsByOrg
        });
      default:
        return state;
    }
  }
  
  /**
   * Getters: Balance Sheets
   */

  public static getAllBalanceSheetsByActiveOrg(): Array<BalanceSheet>
  {
    const organisation = OrganisationStore.getActiveOrganisation();
    return this.getAllBalanceSheetsByOrganisationId(organisation.id);
  }

  public static isUpdating(): boolean
  {
    return Objects.getByKeyPath(`${this.key}:balance_sheets_by_org:updating`, Redux.getState()) || false;
  }

  private static getAllBalanceSheetsByOrganisationId(organisationId: number): Array<BalanceSheet>
  {
    return Objects.getByKeyPath(`${this.key}:balance_sheets_by_org:${organisationId}`, Redux.getState()) || [];
  }

  /**
   * Sync: BalanceSheets
   */

  public static syncActiveOrgBalanceSheets(): Promise<Array<BalanceSheet>>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      BalanceSheetStore.setUpdating(true);

      let promise = Http.get(Url.api('/organisation/' + activeOrg.id + '/balance-sheets'));

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let balanceSheets = Objects.getByKeyPath('balanceSheets', responseBody);

        let balanceSheetEntities = null;

        // Add balance sheets to redux
        if (balanceSheets)
        {
          balanceSheetEntities = [];

          for (let key in balanceSheets)
            if (balanceSheets.hasOwnProperty(key))
          {
            balanceSheetEntities.push(BalanceSheet.create(balanceSheets[key]));
          }
          
          BalanceSheetStore.setBalanceSheetsByOrganisation(activeOrg.id, balanceSheetEntities);
        }
        
        BalanceSheetStore.setUpdating(false);

        if (balanceSheetEntities)
        {
          return resolve(balanceSheetEntities);
        }

        return reject(BalanceSheetStore.createError(response));
      });

      promise.catch((httpError: HttpError) => {
        BalanceSheetStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  private static spliceBalanceSheetIntoOrgBalanceSheets(organisationId: number, balanceSheet: BalanceSheet): Array<BalanceSheet>
  {
    let allOrgBalanceSheets = this.getAllBalanceSheetsByOrganisationId(organisationId);

    let existingKey = null;

    for (let key in allOrgBalanceSheets)
      if (allOrgBalanceSheets.hasOwnProperty(key))
    {
      let existingBalanceSheet = allOrgBalanceSheets[key];

      if (existingBalanceSheet.id == balanceSheet.id)
      {
        existingKey = Number(key);
        break;
      }
    }

    if (typeof existingKey == 'number')
    {
      allOrgBalanceSheets.splice(existingKey, 1, balanceSheet);
    }
    else
    {
      allOrgBalanceSheets.push(balanceSheet);
    }

    this.setBalanceSheetsByOrganisation(organisationId, allOrgBalanceSheets);

    return allOrgBalanceSheets;
  }
  
  /**
   * Create/Update/Delete: BalanceSheets
   */
  
  public static createNewBalanceSheet(balanceSheetDate: string): Promise<BalanceSheet>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      BalanceSheetStore.setUpdating(true);

      let promise = Http.post(Url.api('/organisation/' + activeOrg.id + '/balance-sheet'), { periodEnd: balanceSheetDate });

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let balanceSheet = Objects.getByKeyPath('balanceSheet', responseBody);

        if (balanceSheet)
        {
          balanceSheet = BalanceSheet.create(balanceSheet);

          this.spliceBalanceSheetIntoOrgBalanceSheets(activeOrg.id, balanceSheet);
          BalanceSheetStore.setUpdating(false);

          return resolve(balanceSheet);
        }

        BalanceSheetStore.setUpdating(false);
        return reject(BalanceSheetStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        BalanceSheetStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  public static approveBalanceSheet(balanceSheetId: number): Promise<BalanceSheet>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      BalanceSheetStore.setUpdating(true);

      let promise = Http.put(Url.api(`/organisation/${activeOrg.id}/balance-sheet/${balanceSheetId}/approve`), {});

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let balanceSheet = Objects.getByKeyPath('balanceSheet', responseBody);

        if (balanceSheet)
        {
          balanceSheet = BalanceSheet.create(balanceSheet);

          this.spliceBalanceSheetIntoOrgBalanceSheets(activeOrg.id, balanceSheet);

          BalanceSheetStore.setUpdating(false);

          return resolve(balanceSheet);
        }

        BalanceSheetStore.setUpdating(false);
        return reject(BalanceSheetStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        BalanceSheetStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  public static cancelBalanceSheet(balanceSheetId: number): Promise<BalanceSheet>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      BalanceSheetStore.setUpdating(true);

      let promise = Http.put(Url.api(`/organisation/${activeOrg.id}/balance-sheet/${balanceSheetId}/cancel`), {});

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let balanceSheet = Objects.getByKeyPath('balanceSheet', responseBody);

        if (balanceSheet)
        {
          balanceSheet = BalanceSheet.create(balanceSheet);

          this.spliceBalanceSheetIntoOrgBalanceSheets(activeOrg.id, balanceSheet);

          BalanceSheetStore.setUpdating(false);

          return resolve(balanceSheet);
        }

        BalanceSheetStore.setUpdating(false);
        return reject(BalanceSheetStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        BalanceSheetStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  
  /**
   * Redux
   */

  protected static setUpdating(updating: boolean)
  {
    Redux.dispatch({
      type: BalanceSheetStore.SET_UPDATING,
      data: updating
    });
  }

  public static setBalanceSheetsByOrganisation(organisationId: number, balanceSheets: Array<BalanceSheet>)
  {
    Redux.dispatch({
      type: BalanceSheetStore.SET_BALANCE_SHEETS_BY_ORGANISATION,
      data: {
        organisationId: organisationId,
        balanceSheets: balanceSheets
      }
    });
  }

  /**
   * Subscribers
   */
  
  public static subscribeToActiveOrgBalanceSheets(callback: (balanceSheets: Array<BalanceSheet>) => void, currentValue?: Array<BalanceSheet>)
  {
    return this.subscribe((state) => {
      let newValue = this.getAllBalanceSheetsByActiveOrg();
      
      if (JSON.stringify(currentValue) !== JSON.stringify(newValue))
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }

  public static subscribeToUpdating(callback: (updating: boolean) => void, currentValue?: boolean)
  {
    return this.subscribe((state) => {
      let newValue = state.updating;
      
      if (currentValue !== newValue)
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }
  
}
