
import * as React from 'react';
import { Header } from "appex-theme/src/Form/Input/Header/Header";
import {AbstractComponent} from "../../../../component/AbstractComponent";
import {PageBar} from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import {Breadcrumbs} from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import {Form} from "appex-theme/src/Form/Form/Form";
import {TextField} from "appex-theme/src/Form/Input/Text/TextField";
import {Validator} from "appex-theme/src/Form/Form/Validator/Validator";
import {Button} from "appex-theme/src/Core/Button/Button";
import {Panel} from "appex-theme/src/Core/Panel/Panel";
import {DateField} from "appex-theme/src/Form/Input/Date/DateField";
import {ToastMessageStore} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import {ToastMessage} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";
import {InputGroupField} from "appex-theme/src/Form/Input/InputGroup/InputGroupField";
import './scss/UpdateMyAccountPage.scss';
import {Objects} from "phusion/src/Core/Objects/Objects";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/pro-light-svg-icons/faHome";
import {faUserEdit} from "@fortawesome/pro-duotone-svg-icons/faUserEdit";
import {faAddressCard} from "@fortawesome/pro-solid-svg-icons/faAddressCard";
import {faEnvelope} from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import {faLock} from "@fortawesome/pro-solid-svg-icons/faLock";
import {faHomeAlt} from "@fortawesome/pro-solid-svg-icons/faHomeAlt";
import {faUser} from "@fortawesome/pro-solid-svg-icons/faUser";
import {Request} from "appex-theme/src/Utility/Request";
import { User } from "../../../../data/User/Entity/User";
import { UserStore } from "../../../../data/User/UserStore";

export interface UpdateMyAccountPageProps {}

export interface UpdateMyAccountPageState
{
  accountDetailsUpdating: boolean,
  emailAddressUpdating: boolean,
  passwordUpdating: boolean,
  user: User
}

export class UpdateMyAccountPage extends AbstractComponent<UpdateMyAccountPageProps, UpdateMyAccountPageState>
{
  public constructor(props: UpdateMyAccountPageProps)
  {
    super(props);
    
    this.state = {
      accountDetailsUpdating: false,
      emailAddressUpdating: false,
      passwordUpdating: false,
      user: UserStore.getUser()
    }
  }
  
  public componentDidMount()
  {
    this.unsubscribers['user'] = UserStore.subscribeToUser(
      (user: User) => {
        this.setState({
          user: user
        })
      },
      this.state.user
    )
    
    UserStore.syncUser();
  }
  
  public render()
  {
    const breadcrumbs = this.getBreadCrumbs();
    
    return <div className={'UpdateMyAccountPage'}>
      
      <div className="grid-container">
  
        <div className="grid-x grid-margin-x">
          <div className="cell">
            <PageBar
              title={<div><FontAwesomeIcon icon={faUserEdit}/>{'Update Your Account'}</div>}
              rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}
            />
          </div>
  
          <div className="cell">
            {this.getUpdateAccountDetailsPanel()}
          </div>
  
          <div className="cell medium-4">
            {this.getUpdateEmailAddressPanel()}
          </div>
  
          <div className="cell medium-8">
            {this.getUpdatePasswordPanel()}
          </div>
        </div>
        
      
      </div>
    
    </div>
  }
  
  private getUpdateAccountDetailsPanel()
  {
    const user = this.state.user;
  
    const submitButtonModifiers: Array<any> = [
      'primary',
      this.state.accountDetailsUpdating ? 'loading' : ''
    ];
    
    const formElements = [
      <div className="cell" key={'personal_details'}>
        <Header
          text={'Personal Details'}
          icon={faUser}
        />
      </div>,
      // First name
      TextField
        .create(
          'firstNames',
          'First Name(s)',
          [ Validator.required() ],
          user && user.firstNames ? user.firstNames : null
        )
        .setGridClass('cell medium-6'),
      // Last Name
      TextField
        .create(
          'lastName',
          'Last Name',
          [ Validator.required() ],
          user && user.lastName ? user.lastName : null,
        )
        .setGridClass('cell medium-6'),
      // Preferred Name
      TextField
        .create(
          'preferredName',
          'Preferred Name',
          [],
          user && user.preferredName ? user.preferredName : null,
        )
        .setGridClass('cell medium-6'),
      // Date of Birth
      DateField
        .create(
          'dateOfBirth',
          'Date of Birth',
          [],
          user && user.dateOfBirth ? user.dateOfBirth : null,
        )
        .setGridClass('cell medium-6'),
      //  Address Header
      <div className="cell" key={'address_header'}>
        <Header
          text={'Address'}
          icon={faHomeAlt}
          additionalClasses={['mt-5']}
        />
      </div>,
      InputGroupField.create(
        'address',
        [
          // Address Line 1
          TextField
            .create(
              'addressLine1',
              'Address Line 1',
              [],
              null,
            )
            .setGridClass('cell medium-6'),
          // Address Line 2
          TextField
            .create(
              'addressLine2',
              'Address Line 2',
              [],
              null,
            )
            .setGridClass('cell medium-6'),
          // Town
          TextField
            .create(
              'town',
              'Town',
              [],
              null
            )
            .setGridClass('cell medium-6 large-4'),
          // County
          TextField
            .create(
              'county',
              'County',
              [],
              null
            )
            .setGridClass('cell medium-6 large-4'),
          // Post Code
          TextField
            .create(
              'postCode',
              'Post Code',
              [],
              null
            )
            .setGridClass('cell large-4'),
        ],
        user && user.address ? user.address : null,
        []
      ),
      // Contact Info Header
      <div className="cell" key={'contact_info'}>
        <Header
          text={'Contact Information'}
          icon={faAddressCard}
          additionalClasses={['mt-5']}
        />
      </div>,
      // Mobile Number
      TextField
        .create(
          'mobileContactNumber',
          'Mobile',
          [],
          user && user.mobileContactNumber ? user.mobileContactNumber : null,
        )
        .setGridClass('cell medium-6'),
      // Home Number
      TextField
        .create(
          'homeContactNumber',
          'Home',
          [],
          user && user.homeContactNumber ? user.homeContactNumber : null
        )
        .setGridClass('cell medium-6'),
      <div className="cell" key={'submit'}>
        <div className="mt-4 mb-4 text--align-right">
          <Button type={'submit'} modifiers={submitButtonModifiers}>
            Update Account Details
          </Button>
        </div>
      </div>
    ];
    
    return <Panel>
      <Form
        name={'update_account_details'}
        elements={formElements}
        onFormSubmit={(formState) => {
          
          if (this.state.accountDetailsUpdating || !formState.valid) return;
  
          const user: any = Objects.hydrate(
            Objects.clone(this.state.user),
            formState.value
          );
  
          return this.updateUser(user);
        }}
        onFormCapture={() => {}}
      />
    </Panel>
  }
  
  private getUpdateEmailAddressPanel()
  {
    const user = this.state.user;
  
    const submitButtonModifiers: Array<any> = [
      'primary',
      this.state.emailAddressUpdating ? 'loading' : ''
    ];
    
    const formElements = [
      // Update Email Address Header
      <div className="cell" key={'update_email_address'}>
        <Header
          text={'Update Email Address'}
          icon={faEnvelope}
        />
      </div>,
      // Email Address
      TextField
        .create(
          'emailAddress',
          'Email Address',
          [ Validator.required(), Validator.email() ],
          user ? user.emailAddress : null,
        ),
      // Current Password
      TextField
        .create(
          'password',
          'Current Password',
          [ Validator.required() ],
          ''
        )
        .setType('password'),
      <div className="cell" key={'submit'}>
        <div className="mt-4 mb-4 text--align-right">
          <Button type={'submit'} modifiers={submitButtonModifiers}>
            Update Email Address
          </Button>
        </div>
      </div>
    ];
    
    return <Panel>
      <Form
        name={'update_email_address'}
        elements={formElements}
        onFormSubmit={(formState) => {
          if (this.state.emailAddressUpdating || !formState.valid) return;
          return this.updateEmailAddress(formState.value.emailAddress, formState.value.password);
        }}
        onFormCapture={() => {}}
      />
    </Panel>
  }
  
  private getUpdatePasswordPanel()
  {
    const submitButtonModifiers: Array<any> = [
      'primary',
      this.state.passwordUpdating ? 'loading' : ''
    ];
    
    const formElements = [
      // Update Email Address Header
      <div className="cell" key={'update_password'}>
        <Header
          text={'Update Password'}
          icon={faLock}
        />
      </div>,
      // Current Password
      TextField
        .create(
          'currentPassword',
          'Current Password',
          [ Validator.required(), Validator.minLength(6) ],
          ''
        )
        .setType('password')
        .setGridClass('cell medium-4'),
      // New Password
      TextField
        .create(
          'newPassword',
          'New Password',
          [ Validator.required() ],
          ''
        )
        .setType('password')
        .setGridClass('cell medium-4'),
      // Confirm New Password
      TextField
        .create(
          'confirmNewPassword',
          'Confirm New Password',
          [ Validator.required() ],
          ''
        )
        .setType('password')
        .setGridClass('cell medium-4'),
      <div className="cell" key={'submit'}>
        <div className="mt-4 mb-4 text--align-right">
          <Button type={'submit'} modifiers={submitButtonModifiers}>
            Update Password
          </Button>
        </div>
      </div>
    ];
    
    return <Panel>
      <Form
        name={'update_password'}
        elements={formElements}
        onFormSubmit={async (formState) => {
          if (this.state.passwordUpdating || !formState.valid) return;
          
          return this.updatePassword(
            formState.value.currentPassword,
            formState.value.newPassword,
            formState.value.confirmNewPassword
          );
        }}
        onFormCapture={() => {}}
      />
    </Panel>
  }
  
  private getBreadCrumbs()
  {
    return [
      {
        key: 'home',
        title: <span><FontAwesomeIcon icon={faHome}/> Home</span>,
        href: '/'
      },
      {
        key: 'my_account',
        title: 'My Account',
        href: '/my-account'
      },
      {
        key: 'update',
        title: 'Update'
      },
    ];
  }
  
  private updateUser(user: User)
  {
    this.setState({
      accountDetailsUpdating: true
    });
    
    // Send to redux
    const promise = UserStore.update(user);
    
    promise.then((user: User) => {
      ToastMessageStore.addMessage(ToastMessage.create('success', 'Account details updated'));
      Request.redirect('/my-account', this);
    });
    
    promise.finally(() => {
      this.setState({
        accountDetailsUpdating: false
      });
    })
  }
  
  private updateEmailAddress(emailAddress: string, currentPassword: string)
  {
    this.setState({
      emailAddressUpdating: true
    });
    
    // Send to redux
    const promise = UserStore.updateEmailAddress(emailAddress, currentPassword);
    
    promise.then((user: User) => {
      ToastMessageStore.addMessage(ToastMessage.create('success', `Please click the confirmation link in the email we've just sent to ${emailAddress}`));
      Request.redirect('/my-account', this);
    });
    
    promise.finally(() => {
      this.setState({
        emailAddressUpdating: false
      });
    })
  }
  
  private updatePassword(currentPassword: string, newPassword: string, confirmNewPassword: string)
  {
    this.setState({
      passwordUpdating: true
    });
    
    // Send to redux
    const promise = UserStore.updatePassword(currentPassword, newPassword, confirmNewPassword);
    
    promise.then((user: User) => {
      console.log('user', user);
      ToastMessageStore.addMessage(ToastMessage.create('success', 'Password updated'));
      Request.redirect('/my-account', this);
    });
    
    promise.finally(() => {
      this.setState({
        passwordUpdating: false
      });
    })
  }
  
  
}
