import {DataStore} from "ts-redux/src/DataStore/DataStore";
import {Http} from "phusion/src/Core/Http/Http";
import {HttpResponse} from "phusion/src/Core/Http/HttpResponse";
import {HttpError} from "phusion/src/Core/Http/HttpError";
import {Objects} from "phusion/src/Core/Objects/Objects";
import {Redux} from "ts-redux/src/Redux";
import {Role} from "./Entity/Role";
import {OrganisationStore} from "../Organisation/OrganisationStore";
import {Url} from "../../utility/Url";

declare let Promise: any;

export class RoleStore extends DataStore
{
  public static readonly key: string = 'role';
  protected static readonly SET_UPDATING: string = 'set_role_updating';
  protected static readonly SET_ROLES_BY_ORGANISATION: string = 'set_roles_by_organisation';

  public static reduce(
    state = {
      updating: false,
      roles: {}
    },
    action: {
      type: string,
      data: any
    }
  )
  {
    switch (action.type)
    {
      case RoleStore.SET_UPDATING:
        return Object.assign({}, state, {
          updating: action.data
        });
      case RoleStore.SET_ROLES_BY_ORGANISATION:

        let roles = state['roles']
          ? state['roles']
          : {};

        let rolesByOrgId = Objects.clone(roles);
        rolesByOrgId[action.data.organisationId] = action.data.roles;

        return Object.assign({}, state, {
          roles: rolesByOrgId
        });
      default:
        return state;
    }
  }

  public static getByIdAndActiveOrganisation(id: number): Role
  {
    let activeOrganisation = OrganisationStore.getActiveOrganisation();
    let allRoles = this.getRolesByOrganisationId(activeOrganisation.id);

    if (!allRoles)
    {
      return null;
    }

    let match = allRoles.find((role: Role) => {
      return role.id == id;
    });

    return match ? match : null;
  }

  public static getAllRolesByActiveOrganisation(): Array<Role>
  {
    let activeOrganisation = OrganisationStore.getActiveOrganisation();
    return this.getRolesByOrganisationId(activeOrganisation.id);
  }

  protected static getRolesByOrganisationId(organisationId: number): Array<Role>
  {
    let roles = Objects.getByKeyPath('role:roles:' + organisationId, Redux.getState());

    if (roles)
    {
      return roles;
    }

    return [];
  }

  public static syncActiveOrganisationRoles(): Promise<Array<Role>>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      RoleStore.setUpdating(true);

      let promise = Http.get(Url.api('/organisation/' + activeOrg.id + '/roles'));

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let roles = Objects.getByKeyPath('roles', responseBody);

        if (roles)
        {
          let roleEntities = [];

          for (let key in roles)
            if (roles.hasOwnProperty(key))
          {
            roleEntities.push(Role.create(roles[key]));
          }

          RoleStore.setRolesByOrganisation(activeOrg.id, roleEntities);
          RoleStore.setUpdating(false);

          return resolve(roleEntities);
        }

        RoleStore.setUpdating(false);
        return reject(RoleStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        RoleStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  public static syncRole(id: number): Promise<Role>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      RoleStore.setUpdating(true);

      let promise = Http.get(Url.api('/organisation/' + activeOrg.id + '/role/' + id));

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let role = Objects.getByKeyPath('role', responseBody);

        if (role)
        {
          role = Role.create(role);

          this.spliceRoleIntoOrgRoles(activeOrg.id, role);
          RoleStore.setUpdating(false);

          return resolve(role);
        }

        RoleStore.setUpdating(false);
        return reject(RoleStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        RoleStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  protected static spliceRoleIntoOrgRoles(organisationId: number, role: Role): Array<Role>
  {
    let allOrgRoles = this.getRolesByOrganisationId(organisationId);

    let existingKey = null;

    for (let key in allOrgRoles)
      if (allOrgRoles.hasOwnProperty(key))
    {
      let existingRole = allOrgRoles[key];

      if (existingRole.id == role.id)
      {
        existingKey = Number(key);
        break;
      }
    }

    if (typeof existingKey == 'number')
    {
      allOrgRoles.splice(existingKey, 1, role);
    }
    else
    {
      allOrgRoles.push(role);
    }

    this.setRolesByOrganisation(organisationId, allOrgRoles);

    return allOrgRoles;
  }

  public static createNewRole(role: Role): Promise<Role>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      RoleStore.setUpdating(true);

      let promise = Http.post(Url.api('/organisation/' + activeOrg.id + '/role'), role);

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let role = Objects.getByKeyPath('role', responseBody);

        if (role)
        {
          role = Role.create(role);
          this.spliceRoleIntoOrgRoles(activeOrg.id, role);
          RoleStore.setUpdating(false);

          return resolve(role);
        }

        RoleStore.setUpdating(false);
        return reject(RoleStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        RoleStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  public static updateRole(role: Role): Promise<Role>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      RoleStore.setUpdating(true);

      let promise = Http.put(Url.api('/organisation/' + activeOrg.id + '/role/' + role.id), role);

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let role = Objects.getByKeyPath('role', responseBody);

        if (role)
        {
          role = Role.create(role);

          this.spliceRoleIntoOrgRoles(activeOrg.id, role);
          RoleStore.setUpdating(false);

          return resolve(role);
        }

        RoleStore.setUpdating(false);
        return reject(RoleStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        RoleStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  protected static setUpdating(updating: boolean)
  {
    Redux.dispatch({
      type: RoleStore.SET_UPDATING,
      data: updating
    });
  }

  public static setRolesByOrganisation(organisationId: number, roles: Array<Role>)
  {
    Redux.dispatch({
      type: RoleStore.SET_ROLES_BY_ORGANISATION,
      data: {
        organisationId: organisationId,
        roles: roles
      }
    });
  }

  public static onSearch(value: string, item: Role)
  {
    let lowerCaseSearchValue = RoleStore.strip(value);

    return (
      value == ''
      || RoleStore.strip(item.name).indexOf(lowerCaseSearchValue) !== -1
    )
  }

  protected static strip(string: string)
  {
    return string.toLowerCase().replace(' ', '');
  }
  
  public static subscribeToUpdating(callback: (updating: boolean) => void, currentValue?: boolean)
  {
    return this.subscribe((state) => {
      let newValue = state.updating;
      
      if (currentValue !== newValue)
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }
  
  public static subscribeToRoleById(
    id: number,
    callback: (roles: Role) => void,
    currentValue?: Role
  )
  {
    return this.subscribe((state) => {
      let roles = this.getAllRolesByActiveOrganisation();
      
      let newValue = roles.find((role: Role) => {
        return role.id === id;
      })
      
      if (JSON.stringify(currentValue) !== JSON.stringify(newValue))
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }
  
  public static subscribeToActiveOrgRoles(
    callback: (roles: Array<Role>) => void,
    currentValue?: Array<Role>
  )
  {
    return this.subscribe((state) => {
      let newValue = this.getAllRolesByActiveOrganisation();
      
      if (JSON.stringify(currentValue) !== JSON.stringify(newValue))
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }
  
  
  
}
