import {DataStore} from "ts-redux/src/DataStore/DataStore";
import {Http} from "phusion/src/Core/Http/Http";
import {HttpResponse} from "phusion/src/Core/Http/HttpResponse";
import {HttpError} from "phusion/src/Core/Http/HttpError";
import {Objects} from "phusion/src/Core/Objects/Objects";
import {Redux} from "ts-redux/src/Redux";
import {OrganisationStore} from "../Organisation/OrganisationStore";
import {Event} from './Entity/Event';
import {EventAttendanceStore} from "./EventAttendanceStore";
import {Attendance} from "./Entity/Attendance";
import {EventTicketStore} from "./EventTicketStore";
import {Ticket} from "./Entity/Ticket";
import {Guest} from "./Entity/Guest";
import {EventGuestStore} from "./EventGuestStore";
import {Url} from "../../utility/Url";

declare let Promise: any;

export class EventStore extends DataStore
{
  public static readonly key: string = 'event';
  protected static readonly SET_UPDATING: string = 'set_event_updating';
  protected static readonly SET_EVENTS_BY_ORGANISATION: string = 'set_events_by_organisation';

  public static reduce(
    state = {
      updating: false,
      events: {}
    },
    action: {
      type: string,
      data: any
    }
  )
  {
    switch (action.type)
    {
      case EventStore.SET_UPDATING:
        return Object.assign({}, state, {
          updating: action.data
        });
      case EventStore.SET_EVENTS_BY_ORGANISATION:

        let events = state['events']
          ? state['events']
          : {};

        let eventsByOrgId = Objects.clone(events);
        eventsByOrgId[action.data.organisationId] = action.data.events;

        return Object.assign({}, state, {
          events: eventsByOrgId
        });
      default:
        return state;
    }
  }

  public static getByIdAndActiveOrganisation(eventId: number): Event
  {
    let activeOrganisation = OrganisationStore.getActiveOrganisation();
    let allEvents = this.getAllEventsByOrganisationId(activeOrganisation.id);

    if (!allEvents)
    {
      return null;
    }

    let filterResults = allEvents.filter((event: Event) => {
      return event.id == eventId;
    });

    if (filterResults.length)
    {
      return filterResults[0];
    }

    return null;
  }

  public static getAllEventsByActiveOrganisation(): Array<Event>
  {
    let activeOrganisation = OrganisationStore.getActiveOrganisation();
    return this.getAllEventsByOrganisationId(activeOrganisation.id);
  }

  protected static getAllEventsByOrganisationId(organisationId: number): Array<Event>
  {
    let events = Objects.getByKeyPath('event:events:' + organisationId, Redux.getState());

    if (events)
    {
      return events;
    }

    return [];
  }

  public static syncActiveOrganisationEvents(): Promise<Array<Event>>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      EventStore.setUpdating(true);

      let promise = Http.get(Url.api('/organisation/' + activeOrg.id + '/events'));

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let events = Objects.getByKeyPath('events', responseBody);

        if (events && Array.isArray(events))
        {
          let eventEntities = [];
          let attendanceByEventId = {};
          let ticketsByEventId = {};
          let guestsByEventId = {};

          for (let key in events)
            if (events.hasOwnProperty(key))
          {
            let responseEvent = events[key];
            eventEntities.push(Event.create(responseEvent));

            // Add attendanceByEventId to an array to be pushed to EventAttendanceStore
            if (responseEvent.attendance && responseEvent.attendance.length)
            {
              let attendanceEntities = [];

              for (let key in responseEvent.attendance)
                if(responseEvent.attendance.hasOwnProperty(key))
              {
                let attendance = responseEvent.attendance[key];

                attendanceEntities.push(Attendance.create(attendance))
              }

              attendanceByEventId[responseEvent.id] = attendanceEntities;
              EventAttendanceStore.setAttendanceByEvent(responseEvent.id, attendanceEntities);
            }

            // Add tickets to an array to be pushed to EventTicketStore
            if (responseEvent.tickets && responseEvent.tickets.length)
            {
              let ticketEntities = [];

              for (let key in responseEvent.tickets)
                if(responseEvent.tickets.hasOwnProperty(key))
              {
                let ticket = responseEvent.tickets[key];

                ticketEntities.push(Ticket.create(ticket))
              }

              ticketsByEventId[responseEvent.id] = ticketEntities;
              EventTicketStore.setTicketsByEvent(responseEvent.id, ticketEntities);
            }

            // Add guests to an array to be pushed to EventGuestStore
            if (responseEvent.guests && responseEvent.guests.length)
            {
              let guestEntities = [];

              for (let key in responseEvent.guests)
                if(responseEvent.guests.hasOwnProperty(key))
              {
                let guest = responseEvent.guests[key];

                guestEntities.push(Guest.create(guest))
              }

              guestsByEventId[responseEvent.id] = guestEntities;
              EventGuestStore.setGuestsByEventId(responseEvent.id, guestEntities);
            }
          }

          EventStore.setEventsByOrganisation(activeOrg.id, eventEntities);
          EventStore.setUpdating(false);

          return resolve(eventEntities);
        }

        EventStore.setUpdating(false);
        return reject(EventStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        EventStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  public static syncEvent(eventId: number): Promise<Event>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      EventStore.setUpdating(true);
      EventAttendanceStore.setUpdating(true);
      EventTicketStore.setUpdating(true);

      let promise = Http.get(Url.api('/organisation/' + activeOrg.id + '/event/' + eventId));

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let event = Objects.getByKeyPath('event', responseBody);

        if (event)
        {
          let eventEntity = Event.create(event);

          // Add attendanceByEventId to be pushed to EventAttendanceStore
          let eventAttendance = Objects.getByKeyPath('attendance', responseBody);

          let attendanceEntities = [];

          if (eventAttendance && Array.isArray(eventAttendance))
          {
            attendanceEntities = this.hydrateAndSaveEventAttendance(event.id, eventAttendance);
          }

          // Add tickets to be pushed to EventTicketStore
          let tickets = Objects.getByKeyPath('tickets', responseBody);
          let ticketEntities = [];

          if (tickets)
          {
            ticketEntities = this.hydrateAndSaveEventTickets(event.id, tickets);
          }

          // Add tickets to be pushed to EventTicketStore
          let guests = Objects.getByKeyPath('guests', responseBody);
          let guestEntities = [];

          if (guests && Array.isArray(guests))
          {
            guestEntities = this.hydrateAndSaveEventGuests(event.id, guests);
          }

          event.invitedMembers = attendanceEntities.map((attendance: Attendance) => {
            return attendance.userOrgId;
          });

          event.tickets = ticketEntities.map((ticket: Ticket) => {
            return ticket.id;
          });

          event.guests = guestEntities.map((guest: Ticket) => {
            return guest.id;
          });

          this.spliceEventIntoOrgEvents(activeOrg.id, eventEntity);

          EventStore.setUpdating(false);
          EventAttendanceStore.setUpdating(false);
          EventTicketStore.setUpdating(false);

          return resolve(eventEntity);
        }

        EventStore.setUpdating(false);
        EventAttendanceStore.setUpdating(false);
        EventTicketStore.setUpdating(false);
        return reject(EventStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        EventStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  protected static spliceEventIntoOrgEvents(organisationId: number, event: Event): Array<Event>
  {
    let allOrgEvents = this.getAllEventsByOrganisationId(organisationId);

    let existingKey = null;

    for (let key in allOrgEvents)
      if (allOrgEvents.hasOwnProperty(key))
    {
      let existingEvent = allOrgEvents[key];

      if (existingEvent.id == event.id)
      {
        existingKey = Number(key);
        break;
      }
    }

    if (typeof existingKey == 'number')
    {
      allOrgEvents.splice(existingKey, 1, event);
    }
    else
    {
      allOrgEvents.push(event);
    }

    this.setEventsByOrganisation(organisationId, allOrgEvents);

    return allOrgEvents;
  }

  public static createNewEvent(event: Event): Promise<Event>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      EventStore.setUpdating(true);

      let promise = Http.post(Url.api('/organisation/' + activeOrg.id + '/event'), event.serialize());

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let event = Objects.getByKeyPath('event', responseBody);

        if (event)
        {
          event = Event.create(event);

          // Add attendanceByEventId to be pushed to EventAttendanceStore
          let rawEventAttendance = Objects.getByKeyPath('attendance', responseBody);

          let attendanceEntities = [];

          if (rawEventAttendance && Array.isArray(rawEventAttendance))
          {
            attendanceEntities = this.hydrateAndSaveEventAttendance(event.id, rawEventAttendance);
          }

          // Add tickets to be pushed to EventTicketStore
          let tickets = Objects.getByKeyPath('tickets', responseBody);
          let ticketEntities = [];

          if (tickets)
          {
            ticketEntities = this.hydrateAndSaveEventTickets(event.id, tickets);
          }

          event.invitedMembers = attendanceEntities.map((attendance: Attendance) => {
            return attendance.userOrgId;
          });

          event.tickets = ticketEntities.map((ticket: Ticket) => {
            return ticket.id;
          });

          this.spliceEventIntoOrgEvents(activeOrg.id, event);

          EventStore.setUpdating(false);

          return resolve(event);
        }

        EventStore.setUpdating(false);
        return reject(EventStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        EventStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  private static hydrateAndSaveEventAttendance(eventId, eventAttendance: Array<Object>): Array<Attendance>
  {
    let attendanceEntities = [];

    for (let key in eventAttendance)
      if (eventAttendance.hasOwnProperty(key))
      {
        let attendance = eventAttendance[key];

        attendanceEntities.push(Attendance.create(attendance));
      }

    EventAttendanceStore.setAttendanceByEvent(eventId, attendanceEntities);

    return attendanceEntities;
  }

  private static hydrateAndSaveEventTickets(eventId, eventTickets: Array<Object>): Array<Ticket>
  {
    let ticketEntities = [];

    for (let ticketId in eventTickets)
      if (eventTickets.hasOwnProperty(ticketId))
      {
        let ticket = eventTickets[ticketId];

        ticketEntities.push(Ticket.create(ticket))
      }

    EventTicketStore.setTicketsByEvent(eventId, ticketEntities);

    return ticketEntities;
  }

  private static hydrateAndSaveEventGuests(eventId, eventGuests: Array<Object>): Array<Guest>
  {
    let guestEntities = [];

    for (let key in eventGuests)
      if (eventGuests.hasOwnProperty(key))
      {
        let guest = eventGuests[key];

        guestEntities.push(Ticket.create(guest))
      }

    EventGuestStore.setGuestsByEventId(eventId, guestEntities);

    return guestEntities;
  }

  public static updateEvent(event: Event): Promise<Event>
  {
    let activeOrg = OrganisationStore.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      EventStore.setUpdating(true);

      let promise = Http.put(Url.api('/organisation/' + activeOrg.id + '/event/' + event.id), event.serialize());

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let event = Objects.getByKeyPath('event', responseBody) as Event;

        if (event)
        {
          event = Event.create(event);

          // Add attendanceByEventId to be pushed to EventAttendanceStore
          let rawEventAttendance = Objects.getByKeyPath('attendance', responseBody);

          let attendanceEntities = [];

          if (rawEventAttendance && Array.isArray(rawEventAttendance))
          {
            attendanceEntities = this.hydrateAndSaveEventAttendance(event.id, rawEventAttendance);
          }

          // Add tickets to be pushed to EventTicketStore
          let tickets = Objects.getByKeyPath('tickets', responseBody);

          let ticketEntities = [];

          if (tickets)
          {
            ticketEntities = this.hydrateAndSaveEventTickets(event.id, tickets);
          }

          event.invitedMembers = attendanceEntities.map((attendance: Attendance) => {
            return attendance.userOrgId;
          });

          event.tickets = ticketEntities.map((ticket: Ticket) => {
            return ticket.id;
          });

          this.spliceEventIntoOrgEvents(activeOrg.id, event);
          EventStore.setUpdating(false);

          return resolve(event);
        }

        EventStore.setUpdating(false);
        return reject(EventStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        EventStore.setUpdating(false);
        return reject(httpError);
      })
    });
  }

  protected static setUpdating(updating: boolean)
  {
    Redux.dispatch({
      type: EventStore.SET_UPDATING,
      data: updating
    });
  }

  public static setEventsByOrganisation(organisationId: number, events: Array<Event>)
  {
    Redux.dispatch({
      type: EventStore.SET_EVENTS_BY_ORGANISATION,
      data: {
        organisationId: organisationId,
        events: events
      }
    });
  }
  
  public static subscribeToUpdating(callback: (updating: boolean) => void, currentValue?: boolean)
  {
    return this.subscribe((state) => {
      let newValue = state.updating;
      
      if (currentValue !== newValue)
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }
  
  public static subscribeToActiveOrgEvents(callback: (events: Array<Event>) => void, currentValue?: Array<Event>)
  {
    return this.subscribe((state) => {
      let newValue = this.getAllEventsByActiveOrganisation();
      
      if (JSON.stringify(currentValue) !== JSON.stringify(newValue))
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }
  
  public static subscribeToEventById(id: number, callback: (event: Event) => void, currentValue?: Event)
  {
    return this.subscribe((state) => {
      let newValue = this.getByIdAndActiveOrganisation(id);
      
      if (JSON.stringify(currentValue) !== JSON.stringify(newValue))
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }

}
