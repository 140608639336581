import { Arrays } from "phusion/src/Core/Arrays/Arrays";
import {DataStore} from "ts-redux/src/DataStore/DataStore";
import {Http} from "phusion/src/Core/Http/Http";
import {HttpResponse} from "phusion/src/Core/Http/HttpResponse";
import {HttpError} from "phusion/src/Core/Http/HttpError";
import {Objects} from "phusion/src/Core/Objects/Objects";
import {Redux} from "ts-redux/src/Redux";
import {Url} from "../../utility/Url";
import {Organisation} from "./Entity/Organisation";
import { OrganisationInvitation } from "./Entity/OrganisationInvitation";

declare let Promise: any;

export class OrganisationStore extends DataStore
{
  public static readonly key: string = 'organisation';
  protected static readonly SET_UPDATING: string = 'organisation_set_organisation_updating';
  protected static readonly SET_ORGANISATIONS: string = 'organisation_set_organisation';
  protected static readonly SET_ACTIVE_ORGANISATION: string = 'organisation_set_active_organisation';
  protected static readonly SET_INVITATIONS: string = 'organisation_set_invitations';
  protected static readonly SET_MY_INVITATIONS: string = 'organisation_set_my_invitations';

  public static reduce(
    state = {
      updating: false,
      organisations: null,
      invitations: null,
      myInvitations: null,
      activeOrganisation: null
    },
    action: {
      type: string,
      data: any
    }
  )
  {
    switch (action.type)
    {
      case OrganisationStore.SET_UPDATING:
        return Object.assign({}, state, {
          updating: action.data
        });
      case OrganisationStore.SET_ORGANISATIONS:
        return Object.assign({}, state, {
          organisations: action.data
        });
      case OrganisationStore.SET_ACTIVE_ORGANISATION:
        return Object.assign({}, state, {
          activeOrganisation: action.data
        });
      case OrganisationStore.SET_INVITATIONS:
        return Object.assign({}, state, {
          invitations: action.data
        });
      case OrganisationStore.SET_MY_INVITATIONS:
        return Object.assign({}, state, {
          myInvitations: action.data
        });
      default:
        return state;
    }
  }

  public static getActiveOrganisation(): Organisation
  {
    return Objects.getByKeyPath('organisation:activeOrganisation', Redux.getState());
  }

  public static getOrganisations(): Array<Organisation>
  {
    return Objects.getByKeyPath('organisation:organisations', Redux.getState()) || [];
  }

  public static getInvitations(): Array<OrganisationInvitation>
  {
    return Objects.getByKeyPath('organisation:invitations', Redux.getState()) || [];
  }

  public static getMyInvitations(): Array<OrganisationInvitation>
  {
    return Objects.getByKeyPath('organisation:myInvitations', Redux.getState()) || [];
  }

  public static isUpdating(): boolean
  {
    return Boolean(Objects.getByKeyPath('organisation:updating', Redux.getState()));
  }

  public static syncActiveOrganisation(): Promise<Organisation>
  {
    let activeOrg = this.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      OrganisationStore.setUpdating(true);

      let promise = Http.get(Url.api('/organisation/' + activeOrg.id));

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let organisation = Objects.getByKeyPath('organisation', responseBody);

        if (organisation)
        {
          organisation = Organisation.create(organisation);

          OrganisationStore.setActiveOrganisation(organisation);
          
          this.spliceOrgIntoAllOrgs(organisation);
          
          OrganisationStore.setUpdating(false);

          return resolve(organisation);
        }

        OrganisationStore.setUpdating(false);
        return reject(OrganisationStore.createError(response));

      });

      promise.catch((httpError: HttpError) => {
        return reject(httpError);
      })
    });
  }

  public static syncInvitations(): Promise<Organisation>
  {
    const activeOrg = this.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      const promise = Http.get(Url.api(`/organisation/${activeOrg.id}/invitations`));

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let invitations = Objects.getByKeyPath('invitations', responseBody);
        
        if (invitations)
        {
          const invitationArr = [];
  
          for (const key in invitations)
          {
            invitationArr.push(invitations[key]);
          }
          
          this.setInvitations(invitationArr);
          
          return resolve(invitations);
        }

        return reject(OrganisationStore.createError(response));
      });

      promise.catch((httpError: HttpError) => {
        return reject(httpError);
      })
    });
  }

  public static syncMyInvitations(): Promise<Organisation>
  {
    return new Promise((resolve: Function, reject: Function) =>
    {
      const promise = Http.get(Url.api(`/organisation/invitations`));

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let invitations = Objects.getByKeyPath('invitations', responseBody);
        
        if (invitations)
        {
          const invitationArr = [];
  
          for (const key in invitations)
          {
            invitationArr.push(invitations[key]);
          }
          
          this.setMyInvitations(invitationArr);
          
          return resolve(invitations);
        }

        return reject(OrganisationStore.createError(response));
      });

      promise.catch((httpError: HttpError) => {
        return reject(httpError);
      })
    });
  }

  public static cancelInvitation(invitationId: number): Promise<Organisation>
  {
    const activeOrg = this.getActiveOrganisation();

    return new Promise((resolve: Function, reject: Function) =>
    {
      const promise = Http.post(Url.api(`/organisation/${activeOrg.id}/invitation/${invitationId}/update`), { expiry: Date.now() / 1000 });

      promise.then((response: HttpResponse) => {

        let responseBody = Objects.getByKeyPath('data', response.data);
        let invitation = Objects.getByKeyPath('invitation', responseBody);
        
        if (invitation)
        {
          invitation = OrganisationInvitation.create(invitation);
          this.spliceInvitationIntoAllInvitations(invitation)
          
          return resolve(invitation);
        }

        return reject(OrganisationStore.createError(response));
      });

      promise.catch((httpError: HttpError) => {
        return reject(httpError);
      })
    });
  }
  
  public static updateOrganisation(organisation: Organisation): Promise<Organisation>
  {
    let activeOrg = this.getActiveOrganisation();
  
    return new Promise((resolve: Function, reject: Function) =>
    {
      OrganisationStore.setUpdating(true);
    
      let promise = Http.put(Url.api(`/organisation/${activeOrg.id}`), organisation);
    
      promise.then((response: HttpResponse) => {
      
        const responseBody = Objects.getByKeyPath('data', response.data);
        let organisation = Objects.getByKeyPath('organisation', responseBody);
      
        if (organisation)
        {
          organisation = Organisation.create(organisation);
        
          OrganisationStore.setActiveOrganisation(organisation);
        
          this.spliceOrgIntoAllOrgs(organisation);
        
          OrganisationStore.setUpdating(false);
        
          return resolve(organisation);
        }
      
        OrganisationStore.setUpdating(false);
        return reject(OrganisationStore.createError(response));
      
      });
    
      promise.catch((httpError: HttpError) => {
        return reject(httpError);
      })
    });
  }
  
  public static sendInvitation(emailAddress: string, membershipId: number): Promise<OrganisationInvitation>
  {
    let activeOrg = this.getActiveOrganisation();
    
    return new Promise((resolve, reject) => {
      
      Http
        .post(Url.api(`/organisation/${activeOrg.id}/invitation`), { emailAddress: emailAddress, membershipId: membershipId })
        .then((response) => {
          const responseBody = Objects.getByKeyPath('data', response.data);
          let invitation = Objects.getByKeyPath('invitation', responseBody);
          
          if (invitation)
          {
            invitation = OrganisationInvitation.create(invitation);
            const allInvitations = this.getInvitations();
            allInvitations.push(invitation)
            this.setInvitations(allInvitations);
            return resolve(invitation);
          }
  
          return reject(OrganisationStore.createError(response));
        })
        .catch((httpError: HttpError) => {
          return reject(httpError);
        })
    })
  }
  
  public static acceptInvitation(id: number, organisationId: number): Promise<OrganisationInvitation>
  {
    return new Promise((resolve, reject) => {
      
      Http
        .post(Url.api(`/organisation/invitation/${id}/accept`), {
          organisationId: organisationId
        })
        .then((response) => {
          const responseBody = Objects.getByKeyPath('data', response.data);
          let invitation = Objects.getByKeyPath('invitation', responseBody);
          let organisation = Objects.getByKeyPath('organisation', responseBody);
          
          if (!invitation || !organisation)
          {
            return reject(OrganisationStore.createError(response));
          }
  
          // Save invitation
          invitation = OrganisationInvitation.create(invitation);
          this.spliceInvitationIntoMyInvitations(invitation);
  
          // Save organisation
          organisation = Organisation.create(organisation);
          this.spliceOrgIntoAllOrgs(organisation);
  
          return resolve(invitation);
        })
        .catch((httpError: HttpError) => {
          return reject(httpError);
        })
    })
  }
  
  public static subscribeToActiveOrganisation(callback: (activeOrganisation: Organisation) => void, currentValue?: Organisation)
  {
    return this.subscribe((state) => {
      let newValue = this.getActiveOrganisation();
      
      if (JSON.stringify(currentValue) !== JSON.stringify(newValue))
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }
  
  public static subscribeToActiveOrganisationUpdating(callback: (updating
    : boolean) => void, currentValue?: boolean)
  {
    return this.subscribe((state) => {
      let newValue = this.isUpdating();
      
      if (JSON.stringify(currentValue) !== JSON.stringify(newValue))
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }
  
  public static subscribeToOrganisations(callback: (organisations: Array<Organisation>) => void, currentValue?: Array<Organisation>)
  {
    return this.subscribe((state) => {
      let newValue = this.getOrganisations();
      
      if (JSON.stringify(currentValue) !== JSON.stringify(newValue))
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }
  
  public static subscribeToInvitations(callback: (invitations: Array<OrganisationInvitation>) => void, currentValue?: Array<OrganisationInvitation>)
  {
    return this.subscribe((state) => {
      const newValue = this.getInvitations();
      
      if (JSON.stringify(currentValue) !== JSON.stringify(newValue))
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }
  
  public static subscribeToMyInvitations(callback: (invitations: Array<OrganisationInvitation>) => void, currentValue?: Array<OrganisationInvitation>)
  {
    return this.subscribe((state) => {
      const newValue = this.getMyInvitations();
      
      if (JSON.stringify(currentValue) !== JSON.stringify(newValue))
      {
        currentValue = newValue;
        return callback(newValue);
      }
    })
  }

  protected static setUpdating(updating: boolean)
  {
    Redux.dispatch({
      type: OrganisationStore.SET_UPDATING,
      data: updating
    });
  }

  public static setActiveOrganisation(organisation: Organisation)
  {
    Redux.dispatch({
      type: OrganisationStore.SET_ACTIVE_ORGANISATION,
      data: organisation
    });
  }

  public static setOrganisations(organisations: Array<Organisation>)
  {
    Redux.dispatch({
      type: OrganisationStore.SET_ORGANISATIONS,
      data: organisations
    });
  }

  public static setInvitations(invitations: Array<OrganisationInvitation>)
  {
    Redux.dispatch({
      type: OrganisationStore.SET_INVITATIONS,
      data: invitations
    });
  }

  public static setMyInvitations(invitations: Array<OrganisationInvitation>)
  {
    Redux.dispatch({
      type: OrganisationStore.SET_MY_INVITATIONS,
      data: invitations
    });
  }
  
  private static spliceOrgIntoAllOrgs(organisation: Organisation): Array<Organisation>
  {
    const allOrgs = Arrays.clone(this.getOrganisations());
  
    let existingKey = null;
  
    for (let key in allOrgs)
    {
      let org = allOrgs[key];
    
      if (org.id == organisation.id)
      {
        existingKey = key;
        break;
      }
    }
  
    if (existingKey)
    {
      allOrgs.splice(existingKey, 1, organisation);
    }
    else
    {
      allOrgs.push(organisation);
    }
  
    this.setOrganisations(allOrgs);
    
    return allOrgs;
  }
  
  private static spliceInvitationIntoAllInvitations(invitation: OrganisationInvitation): Array<OrganisationInvitation>
  {
    const allInvitations = Arrays.clone(this.getInvitations());
  
    let existingKey = null;
  
    for (const key in allInvitations)
    {
      const invite = allInvitations[key];
    
      if (invite.id == invitation.id)
      {
        existingKey = key;
        break;
      }
    }
  
    if (existingKey)
    {
      allInvitations.splice(existingKey, 1, invitation);
    }
    else
    {
      allInvitations.push(invitation);
    }
  
    this.setInvitations(allInvitations);
    
    return allInvitations;
  }
  
  private static spliceInvitationIntoMyInvitations(invitation: OrganisationInvitation): Array<OrganisationInvitation>
  {
    const myInvitations = Arrays.clone(this.getMyInvitations());
  
    let existingKey = null;
  
    for (const key in myInvitations)
    {
      const invite = myInvitations[key];
    
      if (invite.id == invitation.id)
      {
        existingKey = key;
        break;
      }
    }
  
    if (existingKey)
    {
      myInvitations.splice(existingKey, 1, invitation);
    }
    else
    {
      myInvitations.push(invitation);
    }
  
    this.setMyInvitations(myInvitations);
    
    return myInvitations;
  }

}
