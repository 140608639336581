
import { faUsers } from "@fortawesome/pro-duotone-svg-icons/faUsers";
import { Form } from "appex-theme/src/Form/Form/Form";
import { Validator } from "appex-theme/src/Form/Form/Validator/Validator";
import { DateField } from "appex-theme/src/Form/Input/Date/DateField";
import { Money } from "appex-theme/src/Utility/Money";
import * as React from 'react';
import {Link} from 'react-router-dom';
import { Request } from "appex-theme/src/Utility/Request";
import {AbstractComponent} from "../../../../component/AbstractComponent";
import {PageBar} from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import {Breadcrumbs} from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import {Panel} from "appex-theme/src/Core/Panel/Panel";
import { Member } from "../../../../data/Member/Entity/Member";
import { MemberStore } from "../../../../data/Member/MemberStore";
import { Membership } from "../../../../data/Membership/Entity/Membership";
import { MembershipStore } from "../../../../data/Membership/MembershipStore";
import {ToastMessageStore} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import {ToastMessage} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";
import './scss/ProcessMembershipFeesPage.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "appex-theme/src/Core/Button/Button";
import { PermissionsStore } from "../../../../data/Permissions/PermissionsStore";
import {faEdit} from "@fortawesome/pro-regular-svg-icons/faEdit";
import {faEye} from "@fortawesome/pro-regular-svg-icons/faEye";
import {faAddressCard} from "@fortawesome/pro-duotone-svg-icons/faAddressCard";
import {DataTable} from "appex-theme/src/Data/DataTable/DataTable/DataTable";
import {DisplayColumn} from "appex-theme/src/Data/DataTable/DataTable/Entity/DisplayColumn";
import {CtaPlaceholder} from "appex-theme/src/Placeholder/Cta/CtaPlaceholder";
import { Transaction } from "../../../../data/Transaction/Entity/Transaction";

export interface ProcessMembershipFeesProps {}
export interface ProcessMembershipFeesState {
  memberships: Array<Membership>,
  membershipsUpdating: boolean,
  members: Array<Member>,
  membersUpdating: boolean,
  formSubmitting: boolean
}

export class ProcessMembershipFeesPage extends AbstractComponent<ProcessMembershipFeesProps, ProcessMembershipFeesState>
{
  public constructor(props: ProcessMembershipFeesProps)
  {
    super(props);

    this.state = {
      memberships: MembershipStore.getAllMembershipsByActiveOrganisation(),
      membershipsUpdating: false,
      members: MemberStore.getAllMembersByActiveOrganisation(),
      membersUpdating: false,
      formSubmitting: false
    };
  }

  public componentDidMount()
  {
    // Redirect if you don't have membership:read permissions
    if (!PermissionsStore.hasPermission('membership:write'))
    {
      ToastMessageStore.addMessage(ToastMessage.create('error', "Sorry, you don't have access to view this page"));
      return Request.goBack(this);
    }
    
    /**
     * Subscribe to Memberships
     */
    
    this.unsubscribers['memberships_updating'] = MembershipStore.subscribeToUpdating(
      (updating: boolean) => {
        this.setState({
          membershipsUpdating: updating
        })
      },
      this.state.membershipsUpdating
    )
  
    this.unsubscribers['memberships'] = MembershipStore.subscribeToActiveOrgMemberships(
      (memberships: Array<Membership>) => {
        this.setState({
          memberships: memberships
        })
      },
      this.state.memberships
    )
    
    MembershipStore.syncActiveOrganisationMemberships();
    
    /**
     * Subscribe to Members
     */
    
    this.unsubscribers['members_updating'] = MemberStore.subscribeToUpdating(
      (updating: boolean) => {
        this.setState({
          membersUpdating: updating
        })
      },
      this.state.membersUpdating
    )
  
    this.unsubscribers['members'] = MemberStore.subscribeToActiveOrgMembers(
      (memberships: Array<Member>) => {
        this.setState({
          members: memberships
        })
      },
      this.state.members
    )
    
    MemberStore.syncActiveOrganisationMembers();
  }

  public render()
  {
    let breadcrumbs = [
      {
        key: 'home',
        title: <span><i className="fal fa-home"/> Home</span>,
        href: '/'
      },
      {
        key: 'membership_fees',
        title: 'Process Membership Fees'
      }
    ];
    
    const activeMembers = this.state.members.filter((member: Member) => { return member.status === 'active'; })
    
    const submitButtonModifiers: Array<any> = ['primary'];
    
    if (this.state.formSubmitting)
    {
      submitButtonModifiers.push('loading');
    }

    return <div className={'ProcessMembershipFeesPage'}>

      <div className="grid-container">
        <PageBar
          title={<div><FontAwesomeIcon icon={faAddressCard}/>Process Membership Fees</div>}
          rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}
        />

        <div className="grid-x">
          <div className="cell">
            <Panel>
              
              <Form
                elements={[
                  <div className="cell" key={'table'}>
                    <DataTable
                      data={activeMembers}
                      displayColumns={[
                        DisplayColumn.create(
                          'Member',
                          'lastName',
                          (member: Member) => {
                            return Member.getFullPreferredName(member);
                          },
                          2
                        ),
                        DisplayColumn.create(
                          'Membership',
                          null,
                          (member: Member) => {
          
                            if (!member.membershipId)
                            {
                              return '-';
                            }
          
                            const membership = this.state.memberships.find((membership: Membership) => {
                              return membership.id === member.membershipId;
                            });
          
                            if (!membership)
                            {
                              return 'Unknown';
                            }
          
                            return membership.name;
                          },
                          2
                        ),
                        DisplayColumn.create(
                          'Fee',
                          null,
                          (member: Member) => {
          
                            if (!member.membershipId)
                            {
                              return '-';
                            }
          
                            const membership = this.state.memberships.find((membership: Membership) => {
                              return membership.id === member.membershipId;
                            });
          
                            if (!membership)
                            {
                              return 'Unknown';
                            }
          
                            return Money.getFormattedPrice(membership.price);
                          },
                          2
                        ),
                        DisplayColumn.create(
                          'Action',
                          null,
                          (member: Member) => {
                            return <div>
                              {
                                PermissionsStore.hasPermission('member:read')
                                  ? <Link to={'/members/' + member.id} className={'mr-6'}>
                                    <FontAwesomeIcon icon={faEye}/>
                                  </Link>
                                  : null
              
                              }
                              {
                                PermissionsStore.hasPermission('member:write')
                                  ? <Link to={'/members/' + member.id + '/update'}>
                                    <FontAwesomeIcon icon={faEdit}/>
                                  </Link>
                                  : null
                              }
                              {
                                !PermissionsStore.hasPermission('member:read')
                                && !PermissionsStore.hasPermission('member:write')
                                  ? '-'
                                  : null
                              }
                            </div>
                          }
                        ),
                      ]}
                      searchable={true}
                      sortable={true}
                      onSearch={MemberStore.onSearch.bind(this)}
                      noDataContent={
                        <CtaPlaceholder
                          icon={faUsers}
                          heading={'No Members Found'}
                          text={'This organisation currently has no active members'}
                        />
                      }
                      paginationControls={false}
                      dataUpdating={Boolean(this.state.membersUpdating || this.state.membershipsUpdating)}
                    />
                  </div>,
                  DateField
                    .create('date', 'Transaction Date', [ Validator.required() ])
                    .setGridClass('cell'),
                  <div className="cell text--align-right mb-3" key={'submit'}>
                    <Button modifiers={submitButtonModifiers} type={'submit'}>
                      Process
                    </Button>
                  </div>
                ] }
                onFormCapture={() => {}}
                onFormSubmit={this.onFormSubmit.bind(this)}
              />
            </Panel>
          </div>
        </div>
      </div>
    </div>
  }
  
  private onFormSubmit(formState)
  {
    if (this.state.formSubmitting || !formState.valid)
    {
      return;
    }
    
    this.setState({
      formSubmitting: true
    })
    
    MembershipStore
      .processMemberships(formState.value.date)
      .then((transactions: Array<Transaction>) => {
        this.setState({
          formSubmitting: false,
        });
  
        ToastMessageStore.addMessage(ToastMessage.create('success', `Successfully processed ${transactions.length} membership fee${transactions.length === 1 ? '' : 's'}`));
      })
      .catch(() => {
        this.setState({
          formSubmitting: false
        });
      })
  }
}
