
import * as React from "react";
import { Strings } from "../utility/Strings";

export class AbstractComponent<P, S> extends React.Component<P, S>
{
  protected unsubscribers: { [key: string]: any } = {};
  protected timeouts: { [key: string]: any } = {};
  protected intervals: { [key: string]: any } = {};
  
  public componentWillUnmount()
  {
    // Unsubscribe from all listeners
    for (let key in this.unsubscribers)
      if (this.unsubscribers.hasOwnProperty(key))
      {
        this.unsubscribers[key]();
      }
    
    // Clear all timeouts
    for (let key in this.timeouts)
      if (this.timeouts.hasOwnProperty(key))
      {
        clearTimeout(this.timeouts[key]);
      }
    
    // Clear all intervals
    for (let key in this.intervals)
      if (this.intervals.hasOwnProperty(key))
      {
        clearInterval(this.intervals[key]);
      }
  }
  
  protected wait(timeout: number): Promise<void>
  {
    return new Promise((resolve) => {
      const id = Strings.random(10, Object.keys(this.timeouts));
      
      this.timeouts[id] = setTimeout(() => {
        clearTimeout(this.timeouts[id]);
        return resolve();
      }, timeout)
    })
  }
  
  protected defer(callback: () => any, timeout: number): () => void
  {
    const id = Strings.random(10, Object.keys(this.timeouts));
    
    this.timeouts[id] = setTimeout(() => {
      callback();
      clearTimeout(this.timeouts[id]);
    }, timeout)
    
    return () => {
      clearTimeout(this.timeouts[id]);
    }
  }
  
  protected repeat(callback: () => any, interval: number): () => void
  {
    const id = Strings.random(10, Object.keys(this.intervals));
    this.intervals[id] = setInterval(callback, interval);
    
    return () => {
      clearInterval(this.intervals[id]);
    }
  }
}
