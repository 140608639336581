
import { ToastMessage } from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";
import { ToastMessageStore } from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import * as React from 'react';
import {Link} from 'react-router-dom';
import { TextField } from "appex-theme/src/Form/Input/Text/TextField";
import {AbstractComponent} from "../../../component/AbstractComponent";
import './scss/SignupPage.scss';
import {Form} from "appex-theme/src/Form/Form/Form";
import { Logo } from "../../../component/Logo/Logo";
import { SplitPanel } from "../../../component/SplitPanel/SplitPanel";
import {UserStore} from "../../../data/User/UserStore";
import {Validator} from "appex-theme/src/Form/Form/Validator/Validator";
import {Button} from "appex-theme/src/Core/Button/Button";
import {DataStoreError} from "ts-redux/src/DataStore/DataStoreError";

export interface SignupPageProps {}
export interface SignupPageState {
  signingUp: boolean,
}

export class SignupPage extends AbstractComponent<SignupPageProps, SignupPageState>
{
  private BLOCK_NAME: 'SignupPage' = 'SignupPage';
  protected userStoreSubscriber;

  public constructor(props: SignupPageProps)
  {
    super(props);

    this.state = {
      signingUp: false
    };
  }

  public render()
  {
    return <div className={this.BLOCK_NAME}>
      {this.getSignupFormContent()}
    </div>
  }
  
  protected getSignupFormContent()
  {
    let buttonModifierArray = [
      'primary' as 'primary',
      this.state.signingUp ? 'loading' as 'loading' : 'primary' as 'primary'
    ];
    
    return <SplitPanel
      primaryContent={
        <div className={`${this.BLOCK_NAME}__LeftPanel`}>
      
          <Logo/>
      
          <Form
            elements={[
              TextField
                .create(
                  'name',
                  'Name',
                  [ Validator.required() ]
                ),
              TextField
                .create(
                  'email',
                  'Email Address',
                  [
                    Validator.required(),
                    Validator.email()
                  ]
                ),
              TextField
                .create(
                  'password',
                  'Password',
                  [
                    Validator.required()
                  ]
                )
                .setType('password'),
              <div className="cell" key={'submit'}>
                <div className="mt-6 text--align-right" key={'submit'}>
                  <Button
                    type={'submit'}
                    modifiers={buttonModifierArray}
                  >
                    Create Account
                  </Button>
                </div>
              </div>
            ]}
            onFormSubmit={this.onFormSubmit.bind(this)}
            onFormCapture={() => {}}
          />
      
          <div className={`${this.BLOCK_NAME}__LinksContainer`}>
            <Link to={'/login'}>Already have an account?</Link>
          </div>
        </div>
      }
      secondaryContent={
        <div className={`${this.BLOCK_NAME}__RightPanel`}>
          <h3>Create your Shumi account.</h3>
          <p>To create an account, all we need is a name, email address and password.</p>
      
          <div className={`${this.BLOCK_NAME}__Logo`}>
            <Logo color={'white'}/>
          </div>
        </div>
      }
    />
  }
  
  protected onFormSubmit(formState)
  {
    if (!formState.valid)
    {
      return;
    }
    
    this.setState({
      signingUp: true
    });
    
    const nameParts = formState.value.name.split(' ');
    const lastName = nameParts.splice(nameParts.length - 1, 1).join(' ');
    const firstNames = nameParts.join(' ');
    
    if (!firstNames || !lastName)
    {
      this.setState({
        signingUp: false
      });
      
      return ToastMessageStore.addMessage(
        ToastMessage.create('error', "Please provide your first and last name")
      );
    }

    this.signup(firstNames, lastName, formState.value.email, formState.value.password);
  }

  private signup(firstNames: string, lastName: string, email: string, password: string)
  {
    UserStore
      .signup(firstNames, lastName, email, password)
      .then((response) => {})
      .catch((error: DataStoreError) => {
  
        this.setState({
          signingUp: false
        });
        
        ToastMessageStore.addMessage(
          ToastMessage.create('error', error.message)
        );
      })
  }
}
