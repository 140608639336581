import {Objects} from "phusion/src/Core/Objects/Objects";

export class Membership
{
  public id: number;
  public name: string;
  public organisationId: number;
  public billingCycle: string;
  public price: number;
  public creditAccountId: number;
  public transactionDescription: string;
  public created: string;
  public updated: string;
  
  public members: Array<number> = [];

  public static create(data: Object = null): Membership
  {
    if (!data)
    {
      return new this();
    }

    let mutators = {};

    return Objects.hydrate(new Membership(), data, mutators) as Membership;
  }

  public serialize(): Object
  {
    let mutators = {};
    return Objects.hydrate({}, this, mutators);
  }
}
