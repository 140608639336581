
import * as React from "react";
import * as ReactDOM from "react-dom";
import {WebApp} from "./src/app/WebApp";
import {Redux} from "ts-redux/src/Redux";
import { BalanceSheetStore } from "./src/data/BalanceSheet/BalanceSheetStore";
import { MembershipStore } from "./src/data/Membership/MembershipStore";
import { PermissionsStore } from "./src/data/Permissions/PermissionsStore";
import {ToastMessageStore} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import {UserStore} from "./src/data/User/UserStore";
import {OrganisationStore} from "./src/data/Organisation/OrganisationStore";
import {MemberStore} from "./src/data/Member/MemberStore";
import {EventStore} from "./src/data/Event/EventStore";
import {EventAttendanceStore} from "./src/data/Event/EventAttendanceStore";
import {EventTicketStore} from "./src/data/Event/EventTicketStore";
import {EventGuestStore} from "./src/data/Event/EventGuestStore";
import {AccountStore} from "./src/data/Account/AccountStore";
import {TransactionStore} from "./src/data/Transaction/TransactionStore";
import {RoleStore} from "./src/data/Role/RoleStore";
import {LocalStorage} from "phusion/src/Core/Storage/LocalStorage";
import {Objects} from "phusion/src/Core/Objects/Objects";
import {Http} from "phusion/src/Core/Http/Http";
import {HttpRequest} from "phusion/src/Core/Http/HttpRequest";
import {HttpResponse} from "phusion/src/Core/Http/HttpResponse";
import {ToastMessage} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";

export class ReactApp
{
  public init(rootElementSelector: string)
  {
    this.initRedux();
    this.setupToastMessageListener();
    this.setupPermissionsListener();

    ReactDOM.render(
      <WebApp/>,
      document.querySelector(rootElementSelector)
    );
  }

  private initRedux(): this
  {
    /** Register data store reducers */
    this.registerReduxStores();

    /** Get persisted state from local storage */
    let initialState;
    
    const storageState = LocalStorage.get('persistedState');
    
    /** If there is an initial state  */
    if (storageState)
    {
      initialState = storageState;
      this.setAuthHeadersFromInitialReduxState(initialState)
    }

    Redux.createStore(initialState);

    /** Persist Redux state on change */
    Redux.subscribe(() => {
      LocalStorage.set('persistedState', Redux.getState());
    });

    return this;
  }

  private registerReduxStores(): this
  {
    // Toast Message
    Redux.addReducer(ToastMessageStore.key, ToastMessageStore.reduce);

    // User
    Redux.addReducer(UserStore.key, UserStore.reduce);

    // Organisation
    Redux.addReducer(OrganisationStore.key, OrganisationStore.reduce);

    // Member
    Redux.addReducer(MemberStore.key, MemberStore.reduce);

    // Event
    Redux.addReducer(EventStore.key, EventStore.reduce);
    Redux.addReducer(EventAttendanceStore.key, EventAttendanceStore.reduce);
    Redux.addReducer(EventTicketStore.key, EventTicketStore.reduce);
    Redux.addReducer(EventGuestStore.key, EventGuestStore.reduce);

    // Account
    Redux.addReducer(AccountStore.key, AccountStore.reduce);

    // Balamce Sheet
    Redux.addReducer(BalanceSheetStore.key, BalanceSheetStore.reduce);

    // Transaction
    Redux.addReducer(TransactionStore.key, TransactionStore.reduce);

    // Role
    Redux.addReducer(RoleStore.key, RoleStore.reduce);

    // Membership
    Redux.addReducer(MembershipStore.key, MembershipStore.reduce);

    // Permissions
    Redux.addReducer(PermissionsStore.key, PermissionsStore.reduce);

    return this;
  }

  private setAuthHeadersFromInitialReduxState(initialReduxState: Object): this
  {
    /** Set auth header on all HTTP requests */
    let jwt = Objects.getByKeyPath('user:jwt', initialReduxState);
    let clientId = LocalStorage.get('device:clientId');

    if (jwt && clientId)
    {
      Http.onBeforeRequest((request: HttpRequest) => {
        request.headers['Authorization'] = 'Bearer ' + jwt;
        request.headers['X-ClientId'] = clientId;
        return request;
      })
    }

    return this;
  }

  private setupToastMessageListener()
  {
    Http.onSuccess((httpResponse: HttpResponse) => {
      let messages = Objects.getByKeyPath('data:messages', httpResponse);
      if (messages && Array.isArray(messages) && messages.length)
      {
        messages.forEach((message: {type: 'info' | 'warning' | 'success' | 'error', message: string}) => {
          ToastMessageStore.addMessage(ToastMessage.create(message.type, message.message));
        })
      }
    })
  }

  private setupPermissionsListener()
  {
    Http.onSuccess((httpResponse: HttpResponse) => {
      let permissions = Objects.getByKeyPath('data:permissions', httpResponse);
      if (permissions && Array.isArray(permissions))
      {
        let activeOrg = OrganisationStore.getActiveOrganisation();
        PermissionsStore.setPermissionsByOrganisation(activeOrg.id, permissions);
      }
    })
  }

}
