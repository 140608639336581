import { faLandmark as duoFaLandmark } from "@fortawesome/pro-duotone-svg-icons/faLandmark";
import { faLandmark } from "@fortawesome/pro-solid-svg-icons/faLandmark";
import { Button } from "appex-theme/src/Core/Button/Button";
import { Form } from "appex-theme/src/Form/Form/Form";
import { Validator } from "appex-theme/src/Form/Form/Validator/Validator";
import { Header } from "appex-theme/src/Form/Input/Header/Header";
import { TextField } from "appex-theme/src/Form/Input/Text/TextField";
import { Dates } from "appex-theme/src/Utility/Dates";
import { Objects } from "phusion/src/Core/Objects/Objects";
import * as React from 'react';
import { Request } from "appex-theme/src/Utility/Request";
import { DataStoreError } from "ts-redux/src/DataStore/DataStoreError";
import { AbstractComponent } from "../../../../component/AbstractComponent";
import { PageBar } from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import { Breadcrumbs } from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import { Panel } from "appex-theme/src/Core/Panel/Panel";
import { Organisation } from "../../../../data/Organisation/Entity/Organisation";
import { OrganisationStore } from "../../../../data/Organisation/OrganisationStore";
import { PermissionsStore } from "../../../../data/Permissions/PermissionsStore";
import { ToastMessageStore } from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import { ToastMessage } from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './scss/UpdateOrganisationPage.scss';

export interface ViewOrganisationProps {}

export interface ViewOrganisationState
{
  organisation: Organisation,
  organisationUpdating: boolean,
  formSubmitting: boolean
}

export class UpdateOrganisationPage extends AbstractComponent<ViewOrganisationProps, ViewOrganisationState>
{
  private BLOCK_NAME: 'UpdateOrganisationPage' = 'UpdateOrganisationPage';
  
  public constructor(props: ViewOrganisationProps)
  {
    super(props);
    
    this.state = {
      organisation: OrganisationStore.getActiveOrganisation(),
      organisationUpdating: OrganisationStore.isUpdating(),
      formSubmitting: false
    }
  }
  
  public componentDidMount()
  {
    // Redirect if user isn't the organisation owner
    if (!PermissionsStore.isOrganisationOwner())
    {
      ToastMessageStore.addMessage(ToastMessage.create('error', "Sorry, you don't have access to view this page"));
      return Request.goBack(this);
    }
    
    /**
     * Subscribe to Organisation
     */
    
    this.unsubscribers['organisation'] = OrganisationStore.subscribeToActiveOrganisation((organisation) => {
      this.setState({
        organisation: organisation
      })
    })
    
    this.unsubscribers['organisation_updating'] = OrganisationStore.subscribeToActiveOrganisationUpdating((updating) => {
      this.setState({
        organisationUpdating: updating
      })
    })
    
    OrganisationStore.syncActiveOrganisation();
  }
  
  public render()
  {
    let activeOrg = this.state.organisation;
    let breadcrumbs = [
      {
        key: 'home',
        title: <span><i className="fal fa-home"/> Home</span>,
        href: '/'
      },
      {
        key: 'organisation',
        title: activeOrg.name,
        href: '/organisation'
      }
    ];
    
    const submitButtonModifiers: Array<any> = ['primary'];
    
    if (this.state.formSubmitting)
    {
      submitButtonModifiers.push('loading');
    }
    
    return <div className={this.BLOCK_NAME}>
      
      <div className="grid-container">
        
        <PageBar
          title={<div>
            <FontAwesomeIcon icon={duoFaLandmark}/>
            {
              (!this.state.organisation && this.state.organisationUpdating)
                ? 'Loading organisation . . .'
                : (this.state.organisation ? `Update ${this.state.organisation.name}` : 'Update Organisation')
            }
          </div>}
          rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}
        />
        
        <div className="grid-x grid-margin-x">
          <div className="cell">
            <Panel>
              <Form
                elements={[
                  <div key={'org_details_header'} className="cell">
                    <Header
                      text={'Organisation Details'}
                      icon={faLandmark}
                    />
                  </div>,
                  TextField
                    .create(
                      'name',
                      'Name',
                      [Validator.required()],
                      this.state.organisation.name
                    )
                    .setGridClass('cell medium-6'),
                  TextField
                    .create(
                      'broughtForwardDate',
                      'Brought Forward Date',
                      [Validator.required()],
                      Dates.getDisplayValue((new Date(this.state.organisation.broughtForwardDate.valueOf())))
                    )
                    .setGridClass('cell medium-6')
                    .setReadOnly(true),
                  <div key={'submit'} className="cell text--align-right mt-4 mb-4">
                    <Button type={'submit'} modifiers={submitButtonModifiers}>Update Organisation</Button>
                  </div>,
                ]}
                onFormCapture={() => {
                }}
                onFormSubmit={(formState) => {
                  if (!formState.valid)
                  {
                    return;
                  }
                  
                  this.setState({
                    formSubmitting: true
                  })
                  
                  const newOrganisation: any = Objects.hydrate(
                    Objects.clone(this.state.organisation),
                    formState.value
                  );
                  
                  this.updateOrganisation(newOrganisation);
                }}
              />
            </Panel>
          </div>
        </div>
      </div>
    </div>
  }
  
  private updateOrganisation(organisation: Organisation)
  {
    // Send to redux
    let promise = OrganisationStore.updateOrganisation(organisation);
    
    promise.then((organisation: Organisation) => {
      this.setState({
        formSubmitting: false,
      });
      
      ToastMessageStore.addMessage(ToastMessage.create('success', 'Organisation updated'));
      Request.redirect('/organisation', this);
    });
    
    promise.catch((error: DataStoreError) => {
      this.setState({
        formSubmitting: false
      });
    })
  }
}
