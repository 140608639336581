
import { faBalanceScale as faBalanceScaleDuo } from "@fortawesome/pro-duotone-svg-icons/faBalanceScale";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons/faSpinnerThird";
import { faEye } from "@fortawesome/pro-regular-svg-icons/faEye";
import { faBalanceScale } from "@fortawesome/pro-solid-svg-icons/faBalanceScale";
import { faCloudDownload } from "@fortawesome/pro-regular-svg-icons/faCloudDownload";
import { faPiggyBank } from "@fortawesome/pro-regular-svg-icons/faPiggyBank";
import { faReceipt } from "@fortawesome/pro-regular-svg-icons/faReceipt";
import { faSackDollar } from "@fortawesome/pro-regular-svg-icons/faSackDollar";
import { faCalendarAlt } from "@fortawesome/pro-solid-svg-icons/faCalendarAlt";
import { faFileAlt } from "@fortawesome/pro-solid-svg-icons/faFileAlt";
import { faUserPlus } from "@fortawesome/pro-solid-svg-icons/faUserPlus";
import { DataTable } from "appex-theme/src/Data/DataTable/DataTable/DataTable";
import { DisplayColumn } from "appex-theme/src/Data/DataTable/DataTable/Entity/DisplayColumn";
import { DisplayItem } from "appex-theme/src/Data/DisplayItem/DisplayItem";
import { CtaPlaceholder } from "appex-theme/src/Placeholder/Cta/CtaPlaceholder";
import { Dates } from "appex-theme/src/Utility/Dates";
import moment from "moment";
import * as React from 'react';
import { Button } from "appex-theme/src/Core/Button/Button";
import { Panel } from "appex-theme/src/Core/Panel/Panel";
import { Form } from "appex-theme/src/Form/Form/Form";
import { Validator } from "appex-theme/src/Form/Form/Validator/Validator";
import { DateField } from "appex-theme/src/Form/Input/Date/DateField";
import { ToastMessage } from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";
import { ToastMessageStore } from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import { Money } from "appex-theme/src/Utility/Money";
import { Request } from "appex-theme/src/Utility/Request";
import {AbstractComponent} from "../../../../component/AbstractComponent";
import {PageBar} from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import {Breadcrumbs} from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './scss/ViewBalanceSheetPage.scss';
import {faHome} from "@fortawesome/pro-light-svg-icons/faHome";
import { AccountStore } from "../../../../data/Account/AccountStore";
import { BalanceSheetStore } from "../../../../data/BalanceSheet/BalanceSheetStore";
import { BalanceSheet } from "../../../../data/BalanceSheet/Entity/BalanceSheet";
import { Member } from "../../../../data/Member/Entity/Member";
import { MemberStore } from "../../../../data/Member/MemberStore";
import { PermissionsStore } from "../../../../data/Permissions/PermissionsStore";
import { TransactionStore } from "../../../../data/Transaction/TransactionStore";
import { Url } from "../../../../utility/Url";

export interface ViewBalanceSheetProps {}
export interface ViewBalanceSheetState {
  profitAndLoss: {
    accountGroups: {
      [accGroupId: number]: {
        name: string,
        expenditure: Array<{
          [accountId: number]: {
            name: string,
            balance: string
          }
        }>,
        expenditureBalance: number,
        revenue: Array<{
          [accountId: number]: {
            name: string,
            balance: string
          }
        }>,
        revenueBalance: number,
        incomeOverExpenditure: number,
        expenditureOverIncome: number,
      }
    }
  },
  liabilities: {
    accountGroups: {
      [accGroupId: number]: {
        name: string,
        broughtForwardBalance: number,
        balance: number,
        incomeOverExpenditure: number,
        otherAdjustments: number
      }
    },
    other: {
      [accountId: number]: {
        name: string,
        balance: number
      }
    },
    overallBalance: number
  },
  assets: any,
  formSubmitting: boolean,
  fetching: boolean,
  balanceSheetDate: string,
  balanceSheets: Array<BalanceSheet>,
  balanceSheetsUpdating: boolean,
  cancellingBalanceSheetDraft: boolean,
  approvingBalanceSheetDraft: boolean,
  savingBalanceSheetDraft: boolean,
  loadedBalanceSheets: boolean,
  members: Array<Member>
}

export class ViewBalanceSheetPage extends AbstractComponent<ViewBalanceSheetProps, ViewBalanceSheetState>
{
  public constructor(props: ViewBalanceSheetProps)
  {
    super(props);
    
    this.state = {
      profitAndLoss: null,
      liabilities: null,
      assets: null,
      formSubmitting: false,
      fetching: false,
      balanceSheetDate: null,
      balanceSheets: BalanceSheetStore.getAllBalanceSheetsByActiveOrg(),
      balanceSheetsUpdating: BalanceSheetStore.isUpdating(),
      cancellingBalanceSheetDraft: false,
      approvingBalanceSheetDraft: false,
      savingBalanceSheetDraft: false,
      loadedBalanceSheets: false,
      members: MemberStore.getAllMembersByActiveOrganisation()
    };
  }
  
  public componentDidMount()
  {
    if (!PermissionsStore.hasPermission('financial:read'))
    {
      ToastMessageStore.addMessage(ToastMessage.create('error', "Sorry, you don't have access to view this page"));
      return Request.goBack(this);
    }
    
    // Subscribe to members
    this.unsubscribers['members'] = MemberStore.subscribeToActiveOrgMembers((members: Array<Member>) => {
      this.setState({
        members: members
      })
    })
    
    // Subscribe to balance sheets
    this.unsubscribers['balance_sheets'] = BalanceSheetStore.subscribeToActiveOrgBalanceSheets((balanceSheets: Array<BalanceSheet>) => {
      this.setState({
        balanceSheets: balanceSheets
      })
    }, this.state.balanceSheets);
    
    // Subscribe to balance sheet updating
    this.unsubscribers['balance_sheets_updating'] = BalanceSheetStore.subscribeToUpdating((updating: boolean) => {
      this.setState({
        balanceSheetsUpdating: updating
      })
    }, this.state.balanceSheetsUpdating);
    
    // Sync members
    MemberStore.syncActiveOrganisationMembers();
    
    // Sync balance sheets
    BalanceSheetStore
      .syncActiveOrgBalanceSheets()
      .then((balanceSheets: Array<BalanceSheet>) => {
        
        const draftBalanceSheet = balanceSheets.find((balanceSheet: BalanceSheet) => {
          return balanceSheet.status === 'draft';
        })

        if (draftBalanceSheet)
        {
          this.setState({
            fetching: true
          })
          
          const date = draftBalanceSheet.periodEnd;
          
          TransactionStore
            .generateBalanceSheet(date)
            .then((balanceSheet) => {
                this.setState({
                  profitAndLoss: balanceSheet.profitAndLoss,
                  liabilities: balanceSheet.liabilities,
                  assets: balanceSheet.assets,
                  fetching: false,
                  balanceSheetDate: date
                })
              }
            )
            .catch(() => {
              this.setState({
                fetching: false
              })
            })
        }
        
        
        this.setState({
          loadedBalanceSheets: true
        })
      });
  }
  
  public render()
  {
    let breadcrumbs = this.getBreadCrumbs();
    
    return <div className={'ViewBalanceSheetPage'}>
      <div className="grid-container">
        <div className="cell">
          <PageBar
            title={<div><FontAwesomeIcon icon={faBalanceScaleDuo}/>Balance Sheet</div>}
            rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}
          />
          
          { this.getCurrentDraftPanel() }
          { this.getFormPanel() }
          { this.getBalanceSheetPanel()}
          {this.getApprovedBalanceSheetsPanel()}
        </div>
      </div>
    </div>
  }
  
  public onFormSubmit(formState)
  {
    if (
      !formState.valid
      || this.state.formSubmitting
      || this.state.savingBalanceSheetDraft
      || this.state.cancellingBalanceSheetDraft
      || this.state.approvingBalanceSheetDraft
      || this.state.fetching
    )
    {
      return;
    }
    
    this.setState({
      formSubmitting: true,
      fetching: true
    })
    
    const date = formState.value.date;
    
    TransactionStore
      .generateBalanceSheet(date)
      .then((balanceSheet) => {
          this.setState({
            profitAndLoss: balanceSheet.profitAndLoss,
            liabilities: balanceSheet.liabilities,
            assets: balanceSheet.assets,
            formSubmitting: false,
            fetching: false,
            balanceSheetDate: date
          })
        }
      )
      .catch(() => {
        this.setState({
          fetching: false,
          formSubmitting: false
        })
      })
  }
  
  protected getDefaultDate(): string
  {
    return moment.utc().format('YYYY-MM-DD');
  }
  
  protected getCurrentDraftPanel()
  {
    if (!this.state.loadedBalanceSheets)
    {
      return <div className="cell">
        <Panel>
          <CtaPlaceholder spinIcon={true} icon={faSpinnerThird} heading={'Loading...'} text={'Checking for existing drafts'}/>
        </Panel>
      </div>
    }
    
    const draftBalanceSheet = this.state.balanceSheets.find((balanceSheet: BalanceSheet) => {
      return balanceSheet.status === 'draft';
    });
    
    if (!draftBalanceSheet)
    {
      return null;
    }
    
    const createdByMember = draftBalanceSheet && draftBalanceSheet.createdBy
      ? MemberStore.getByUserOrgIdAndActiveOrganisation(draftBalanceSheet.createdBy)
      : null;
  
    let panelOptions = null;
  
    if (PermissionsStore.hasPermission('financial:write'))
    {
      panelOptions = <div>
        <Button
          modifiers={[this.state.cancellingBalanceSheetDraft ? 'loading' : null]}
          onClick={this.cancelBalanceSheetDraft.bind(this, draftBalanceSheet.id)}
        >
          Cancel
        </Button>
        <span className={'ml-md-3 mt-smo-2 display--inline-block'}>
              <Button
                modifiers={[
                  'primary',
                  this.state.approvingBalanceSheetDraft ? 'loading' : null
                ]}
                onClick={this.approveBalanceSheetDraft.bind(this, draftBalanceSheet.id)}
              >
                Approve
              </Button>
            </span>
      </div>
    }
    
    return <div className="cell">
      <Panel
        headerLeft={<div className="Header">{'Draft Balance Sheet'}</div>}
        headerRight={panelOptions}
      >
        <div className={'grid-container'}>
          <div className="grid-x grid-margin-x">
      
            <div className="cell medium-3">
              <DisplayItem
                icon={<FontAwesomeIcon icon={faCalendarAlt}/>}
                header={'Date'}
                content={moment.utc(draftBalanceSheet.periodEnd).format('ddd D MMMM YYYY')}
                updating={this.state.balanceSheetsUpdating}
              />
      
            </div>
      
            <div className="cell medium-3">
              <DisplayItem
                icon={<FontAwesomeIcon icon={faUserPlus}/>}
                header={'Created By'}
                content={Member.getFullPreferredName(createdByMember)}
                updating={this.state.balanceSheetsUpdating}
              />
            </div>
      
            <div className="cell medium-3">
              <DisplayItem
                icon={<FontAwesomeIcon icon={faBalanceScale}/>}
                header={'Balance Sheet'}
                content={
                  <div>
                    <a
                      target={'_blank'}
                      href={Url.api(`/organisation/${draftBalanceSheet.organisationId}/balance-sheet/${draftBalanceSheet.id}/view`)}
                      className={'mr-3 text--color-default'}
                    >
                      <FontAwesomeIcon icon={faEye}/>
                    </a>
                    <a
                      href={Url.api(`/organisation/${draftBalanceSheet.organisationId}/balance-sheet/${draftBalanceSheet.id}/download`)}
                      className={'text--color-default'}
                    >
                      <FontAwesomeIcon icon={faCloudDownload}/>
                    </a>
                  </div>
                }
                updating={this.state.balanceSheetsUpdating}
              />
            </div>
      
            <div className="cell medium-3">
              <DisplayItem
                icon={<FontAwesomeIcon icon={faFileAlt}/>}
                header={'Audit'}
                content={
                  <div>
                    <a
                      target={'_blank'}
                      href={Url.api(`/organisation/${draftBalanceSheet.organisationId}/balance-sheet/${draftBalanceSheet.id}/audit/view`)}
                      className={'mr-3 text--color-default'}
                    >
                      <FontAwesomeIcon icon={faEye}/>
                    </a>
                    <a
                      href={Url.api(`/organisation/${draftBalanceSheet.organisationId}/balance-sheet/${draftBalanceSheet.id}/audit/download`)}
                      className={'text--color-default'}
                    >
                      <FontAwesomeIcon icon={faCloudDownload}/>
                    </a>
                  </div>
                }
                updating={this.state.balanceSheetsUpdating}
              />
            </div>
    
          </div>
  
        </div>
      </Panel>
    </div>
  }
  
  protected getFormPanel()
  {
    const draftBalanceSheet = this.state.balanceSheets.find((balanceSheet: BalanceSheet) => {
      return balanceSheet.status === 'draft';
    })
    
    if (draftBalanceSheet)
    {
      return null;
    }
    
    return <div className="cell">
      <Panel
        headerLeft={<div className="Header">{'Generate New Balance Sheet'}</div>}
      >
        <Form
          elements={[
            DateField
              .create(
                'date',
                'Closing Date',
                [
                  Validator.required()
                ],
                this.getDefaultDate()
              ),
            <div className="cell" key={'submit'}>
              <div className="mb-3 text--align-right">
                <Button
                  type={'submit'}
                  modifiers={[
                    'primary',
                    this.state.formSubmitting ? 'loading' : null
                  ]}
                >
                  Generate Balance Sheet
                </Button>
              </div>
            </div>
          ]}
          onFormCapture={() => {}}
          onFormSubmit={this.onFormSubmit.bind(this)}
        />
      </Panel>
    </div>
  }
  
  protected getBalanceSheetPanel()
  {
    if (this.state.fetching)
    {
      return <div className="cell">
        <Panel>
          <CtaPlaceholder spinIcon={true} icon={faSpinnerThird} heading={'Loading...'} text={'Please wait while we generate your balance sheet'}/>
        </Panel>
      </div>
    }
    
    if (
      !this.state.profitAndLoss
      || !this.state.liabilities
      || !this.state.assets
    )
    {
      return null;
    }
    
    const draftBalanceSheet = this.state.balanceSheets.find((balanceSheet: BalanceSheet) => {
      return balanceSheet.status === 'draft';
    })
    
    return <div className="cell">
      <Panel
        headerLeft={<div className="Header">Balance Sheet as of {moment.utc(this.state.balanceSheetDate).format('ddd D MMM YYYY')}</div>}
        headerRight={
          draftBalanceSheet
            ? null
            : <div>
                <Button
                  onClick={() => {
                    this.setState({
                      profitAndLoss: null,
                      liabilities: null,
                      assets: null
                    })
                  }}
                >
                  Cancel
                </Button>
                <span className={'ml-md-3 mt-smo-2 display--inline-block'}>
                      <Button
                        modifiers={[
                          'primary',
                          this.state.savingBalanceSheetDraft ? 'loading' : null
                        ]}
                        onClick={this.saveBalanceSheetAsDraft.bind(this)}
                      >
                        Save as Draft
                      </Button>
                    </span>
              </div>
        }
      >
        <h1 className={'ViewBalanceSheetPage__Title'}>
          <FontAwesomeIcon className={'mr-2'} icon={faSackDollar}/> Profit & Loss
        </h1>
        <div className="mb-6">
          {this.getProfitAndLossContent()}
        </div>
      
        <h1 className={'ViewBalanceSheetPage__Title'}>
          <FontAwesomeIcon icon={faReceipt}/> Liabilities
        </h1>
        <div className="mb-6">
          {this.getLiabilitiesContent()}
        </div>
      
        <h1 className={'ViewBalanceSheetPage__Title'}>
          <FontAwesomeIcon icon={faPiggyBank}/> Assets
        </h1>
        <div className="mb-6">
          {this.getAssetsContent()}
        </div>
      </Panel>
    </div>
  }
  
  protected getApprovedBalanceSheetsPanel()
  {
    const approvedBalanceSheets = this
      .state
      .balanceSheets
      .filter((balanceSheet: BalanceSheet) => {
        return balanceSheet.status === 'approved';
      })
      .sort((a, b) => {
        return (new Date(b.periodEnd)).valueOf() - (new Date(a.periodEnd).valueOf());
      })
    
    if (!approvedBalanceSheets || !approvedBalanceSheets.length)
    {
      return null;
    }
    
    if (!this.state.loadedBalanceSheets)
    {
      return <div className="cell">
        <Panel headerLeft={<div className="Header">Balance Sheet History</div>}>
          <CtaPlaceholder spinIcon={true} icon={faSpinnerThird} heading={'Loading...'} text={'Checking for previously approved balance sheets'}/>
        </Panel>
      </div>
    }
    
    return <div className="cell">
      <Panel headerLeft={<div className="Header">Balance Sheet History</div>}>
        <DataTable
          data={approvedBalanceSheets}
          paginationControls={approvedBalanceSheets.length > 10}
          displayColumns={[
            DisplayColumn.create(
              'Date',
              'date',
              (balanceSheet: BalanceSheet) => {
                return Dates.getShortDisplayValue(new Date(balanceSheet.periodEnd));
              }
            ),
            DisplayColumn.create(
              'Created By',
              'createdBy',
              (balanceSheet: BalanceSheet) => {
                const member = this.state.members.find((member: Member) => {
                  return member.userOrgId === balanceSheet.createdBy;
                })
                return Member.getFullPreferredName(member);
              }
            ),
            DisplayColumn.create(
              'Approved By',
              'approvedBy',
              (balanceSheet: BalanceSheet) => {
                const member = this.state.members.find((member: Member) => {
                  return member.userOrgId === balanceSheet.approvedBy;
                })
                return Member.getFullPreferredName(member);
              }
            ),
            DisplayColumn.create(
              'Balance Sheet',
              null,
              (balanceSheet: BalanceSheet) => {
                return <div>
                  <a target={'_blank'} href={Url.api(`/organisation/${balanceSheet.organisationId}/balance-sheet/${balanceSheet.id}/view`)} className={'mr-3'}>
                    <FontAwesomeIcon icon={faEye}/>
                  </a>
                  <a href={Url.api(`/organisation/${balanceSheet.organisationId}/balance-sheet/${balanceSheet.id}/download`)}>
                    <FontAwesomeIcon icon={faCloudDownload}/>
                  </a>
                </div>;
              }
            ),
            DisplayColumn.create(
              'Audit',
              null,
              (balanceSheet: BalanceSheet) => {
                return <div>
                  <a target={'_blank'} href={Url.api(`/organisation/${balanceSheet.organisationId}/balance-sheet/${balanceSheet.id}/audit/view`)} className={'mr-3'}>
                    <FontAwesomeIcon icon={faEye}/>
                  </a>
                  <a href={Url.api(`/organisation/${balanceSheet.organisationId}/balance-sheet/${balanceSheet.id}/audit/download`)}>
                    <FontAwesomeIcon icon={faCloudDownload}/>
                  </a>
                </div>;
              }
            ),
          ]}
        />
      </Panel>
    </div>
  }
  
  protected getProfitAndLossContent()
  {
    if (!this.state.profitAndLoss)
    {
      return <div>
        No Profit & Loss Data Found
      </div>
    }
    
    const elements = [];
    
    for (let accGroupId in this.state.profitAndLoss.accountGroups)
    {
      const accGroup = this.state.profitAndLoss.accountGroups[accGroupId];
      
      // Don't show group if it's empty
      if (!Object.keys(accGroup.expenditure).length && !Object.keys(accGroup.revenue).length)
      {
        continue;
      }
      
      // Get expenditure data
      const expenditureData = [];
      
      for (let accountId in accGroup.expenditure)
        if (accGroup.expenditure.hasOwnProperty(accountId))
        {
          expenditureData.push(accGroup.expenditure[accountId]);
        }
      
      // Get revenue data
      const revenueData = [];
      
      for (let accountId in accGroup.revenue)
        if (accGroup.revenue.hasOwnProperty(accountId))
        {
          revenueData.push(accGroup.revenue[accountId]);
        }
      
      elements.push(
        <div className={'grid-x grid-margin-x mb-10'} key={accGroupId}>
          <div className="cell">
            <h6 className="ViewBalanceSheetPage__SubTitle text--align-center">{accGroup.name}</h6>
          </div>
          <div className="cell medium-6">
            
            <h6 className="ViewBalanceSheetPage__Heading text--align-center">Expenditure</h6>
            
            <div className="ViewBalanceSheetPage__Table">
              {
                expenditureData.map((expenditureRow) => {
                  return <div className="ViewBalanceSheetPage__TableRow" key={expenditureRow.name}>
                    <div className="ViewBalanceSheetPage__TableCell">
                      {expenditureRow.name}
                    </div>
                    <div className="ViewBalanceSheetPage__TableCell">
                      {Money.getFormattedPrice(expenditureRow.balance / 100)}
                    </div>
                  </div>
                })
              }
            </div>
          </div>
          <div className="cell medium-6">
            
            <h6 className="ViewBalanceSheetPage__Heading text--align-center">Income</h6>
            
            <div className="ViewBalanceSheetPage__Table">
              {
                revenueData.map((revenueRow) => {
                  return <div className="ViewBalanceSheetPage__TableRow" key={revenueRow.name}>
                    <div className="ViewBalanceSheetPage__TableCell">
                      {revenueRow.name}
                    </div>
                    <div className="ViewBalanceSheetPage__TableCell">
                      {Money.getFormattedPrice(revenueRow.balance / 100)}
                    </div>
                  </div>
                })
              }
            </div>
          </div>
          
          {/*Income over Expenditure*/}
          
          <div className="cell medium-6">
            {
              accGroup.incomeOverExpenditure > 0
                ? <div className="ViewBalanceSheetPage__Table mt-md-5">
                  <div className="ViewBalanceSheetPage__TableRow">
                    <div className="ViewBalanceSheetPage__TableCell text--bold">
                      Excess of Income Over Expenditure
                    </div>
                    <div className="ViewBalanceSheetPage__TableCell text--bold">
                      {Money.getFormattedPrice(accGroup.incomeOverExpenditure / 100)}
                    </div>
                  </div>
                </div>
                : null
            }
          </div>
          
          <div className="cell medium-6">
            {
              accGroup.expenditureOverIncome > 0
                ? <div className="ViewBalanceSheetPage__Table mt-md-5">
                  <div className="ViewBalanceSheetPage__TableRow">
                    <div className="ViewBalanceSheetPage__TableCell text--bold">
                      Excess of Expenditure Over Income
                    </div>
                    <div className="ViewBalanceSheetPage__TableCell text--bold">
                      {Money.getFormattedPrice(accGroup.expenditureOverIncome / 100)}
                    </div>
                  </div>
                </div>
                : null
            }
          </div>
          
          
          {/* Overall Balance */}
          
          <div className="cell medium-6">
            {
              <div className="ViewBalanceSheetPage__Table mt-3">
                <div className="ViewBalanceSheetPage__TableRow">
                  <div className="ViewBalanceSheetPage__TableCell text--bold"/>
                  <div className="ViewBalanceSheetPage__TableCell text--bold">
                    {
                      Money.getFormattedPrice(
                        accGroup.incomeOverExpenditure > 0
                          ? (accGroup.expenditureBalance + accGroup.incomeOverExpenditure) / 100
                          : accGroup.expenditureBalance / 100
                      )
                    }
                  </div>
                </div>
              </div>
            }
          </div>
          
          <div className="cell medium-6">
            {
              <div className="ViewBalanceSheetPage__Table mt-3">
                <div className="ViewBalanceSheetPage__TableRow">
                  <div className="ViewBalanceSheetPage__TableCell text--bold"/>
                  <div className="ViewBalanceSheetPage__TableCell text--bold">
                    
                    {
                      Money.getFormattedPrice(
                        accGroup.expenditureOverIncome > 0
                          ? (accGroup.revenueBalance + accGroup.expenditureOverIncome) / 100
                          : accGroup.revenueBalance / 100
                      )
                    }
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      )
    }
    
    return <div className={'grid-container'}>
      { elements }
    </div>
  }
  
  protected getLiabilitiesContent()
  {
    if (!this.state.liabilities)
    {
      return <div>
        No Liabilities Data Found
      </div>
    }
    
    const accGroups = [];
    
    for (let key in this.state.liabilities.accountGroups)
      if (this.state.liabilities.accountGroups.hasOwnProperty(key))
      {
        accGroups.push(this.state.liabilities.accountGroups[key]);
      }
    
    return <div>
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell">
            {
              accGroups.map((accGroup) => {
                return <div className="ViewBalanceSheetPage__Table" key={accGroup.name}>
                  <div className="ViewBalanceSheetPage__TableRow">
                    <div className="ViewBalanceSheetPage__TableCell text--bold">
                      {accGroup.name}
                    </div>
                    <div className="ViewBalanceSheetPage__TableCell">
                    
                    </div>
                    <div className="ViewBalanceSheetPage__TableCell">
                    
                    </div>
                  </div>
                  
                  
                  <div className="ViewBalanceSheetPage__TableRow">
                    <div className="ViewBalanceSheetPage__TableCell">
                      Brought Forward Balance
                    </div>
                    <div className="ViewBalanceSheetPage__TableCell">
                      {Money.getFormattedPrice(accGroup.broughtForwardBalance / 100)}
                    </div>
                    <div className="ViewBalanceSheetPage__TableCell">
                    
                    </div>
                  </div>
                  
                  {
                    (
                      accGroup.incomeOverExpenditure > 0
                      || accGroup.expenditureOverIncome > 0
                    )
                      ? <div className="ViewBalanceSheetPage__TableRow">
                          <div className="ViewBalanceSheetPage__TableCell">
                            {
                              accGroup.incomeOverExpenditure > 0
                                ? 'Excess of Income Over Expenditure'
                                : 'Excess of Expenditure Over Income'
                            }
                          </div>
                          <div className="ViewBalanceSheetPage__TableCell">
                            {
                              Money.getFormattedPrice(
                                accGroup.incomeOverExpenditure > 0
                                  ? accGroup.incomeOverExpenditure / 100
                                  : accGroup.expenditureOverIncome / 100
                              )
                            }
                          </div>
                          <div className="ViewBalanceSheetPage__TableCell">
                          
                          </div>
                      </div>
                      : null
                  }
                  
                  {
                    accGroup.otherAdjustments
                      ? <div className="ViewBalanceSheetPage__TableRow">
                        <div className="ViewBalanceSheetPage__TableCell">
                          Other Adjustments
                        </div>
                        <div className="ViewBalanceSheetPage__TableCell">
                          {
                            Money.getFormattedPrice(accGroup.otherAdjustments / 100)
                          }
                        </div>
                        <div className="ViewBalanceSheetPage__TableCell">
    
                        </div>
                      </div>
                      : null
                  }
                  
                  <div className="ViewBalanceSheetPage__TableRow">
                    <div className="ViewBalanceSheetPage__TableCell">
                    
                    </div>
                    <div className="ViewBalanceSheetPage__TableCell">
                    
                    </div>
                    <div className="ViewBalanceSheetPage__TableCell">
                      {Money.getFormattedPrice(accGroup.balance / 100)}
                    </div>
                  </div>
                
                </div>
              })
            }
          </div>
        
        </div>
      </div>
      <h6 className={'ViewBalanceSheetPage__Heading'}>Other Liabilities</h6>
      
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell">
            <div className="ViewBalanceSheetPage__Table">
              
              {
                Object.keys(this.state.liabilities.other).map((accountId) => {
                  let row = this.state.liabilities.other[accountId];
                  
                  return <div className="ViewBalanceSheetPage__TableRow" key={accountId}>
                    <div className="ViewBalanceSheetPage__TableCell">
                      {row.name}
                    </div>
                    <div className="ViewBalanceSheetPage__TableCell">
                      {Money.getFormattedPrice(row.balance / 100)}
                    </div>
                    <div className="ViewBalanceSheetPage__TableCell">
                    
                    </div>
                  </div>
                })
              }
              
              <div className="ViewBalanceSheetPage__TableRow mt-3">
                <div className="ViewBalanceSheetPage__TableCell">
                
                </div>
                <div className="ViewBalanceSheetPage__TableCell">
                </div>
                <div className="ViewBalanceSheetPage__TableCell text--bold">
                  {Money.getFormattedPrice(this.state.liabilities.overallBalance / 100)}
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </div>
    
    </div>
  }
  
  protected getAssetsContent()
  {
    if (!this.state.assets)
    {
      return <div>
        No Assets Data Found
      </div>
    }
    
    return <div>
      <h6 className={'ViewBalanceSheetPage__Heading'}>Assets</h6>
      
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell">
            <div className="ViewBalanceSheetPage__Table">
              
              {
                Object.keys(this.state.assets.assets).map((accountId) => {
                  let row = this.state.assets.assets[accountId];
                  
                  return <div className="ViewBalanceSheetPage__TableRow" key={accountId}>
                    <div className="ViewBalanceSheetPage__TableCell">
                      {row.name}
                    </div>
                    <div className="ViewBalanceSheetPage__TableCell">
                      {Money.getFormattedPrice(row.balance / 100)}
                    </div>
                    <div className="ViewBalanceSheetPage__TableCell">
                    
                    </div>
                  </div>
                })
              }
              
              <div className="ViewBalanceSheetPage__TableRow mt-3">
                <div className="ViewBalanceSheetPage__TableCell">
                
                </div>
                <div className="ViewBalanceSheetPage__TableCell">
                </div>
                <div className="ViewBalanceSheetPage__TableCell text--bold">
                  {Money.getFormattedPrice(this.state.assets.overallBalance / 100)}
                </div>
              </div>
            
            </div>
          </div>
        </div>
      </div>
    </div>
    
  }
  
  protected getBreadCrumbs()
  {
    return [
      {
        key: 'home',
        title: <span><FontAwesomeIcon icon={faHome}/> Home</span>,
        href: '/'
      },
      {
        key: 'balance-sheet',
        title: 'Balance Sheet',
      },
    ];
  }
  
  private cancelBalanceSheetDraft(balanceSheetId: number)
  {
    if (this.state.cancellingBalanceSheetDraft || this.state.approvingBalanceSheetDraft || this.state.savingBalanceSheetDraft)
    {
      return;
    }
  
    this.setState({
      cancellingBalanceSheetDraft: true
    });
  
    BalanceSheetStore
      .cancelBalanceSheet(balanceSheetId)
      .then(() => {
        this.setState({
          cancellingBalanceSheetDraft: false,
          profitAndLoss: null,
          liabilities: null,
          assets: null
        })
  
        ToastMessageStore.addMessage(ToastMessage.create('success', 'Balance sheet cancelled'));
      })
      .catch((error) => {
        this.setState({
          cancellingBalanceSheetDraft: false,
          fetching: false
        })
        
        ToastMessageStore.addMessage(ToastMessage.create('error', error.message));
      })
  }
  
  private approveBalanceSheetDraft(balanceSheetId: number)
  {
    if (this.state.approvingBalanceSheetDraft || this.state.cancellingBalanceSheetDraft || this.state.savingBalanceSheetDraft)
    {
      return;
    }
    
    this.setState({
      approvingBalanceSheetDraft: true
    });
    
    BalanceSheetStore
      .approveBalanceSheet(balanceSheetId)
      .then(() => {
        AccountStore.syncActiveOrganisationAccounts()
      })
      .then(() => {
        this.setState({
          approvingBalanceSheetDraft: false,
          profitAndLoss: null,
          liabilities: null,
          assets: null
        })
  
        ToastMessageStore.addMessage(ToastMessage.create('success', 'Balance sheet approved'));
      })
      .catch((error) => {
        this.setState({
          approvingBalanceSheetDraft: false
        })
  
        ToastMessageStore.addMessage(ToastMessage.create('error', error.message));
      })
  }
  
  protected saveBalanceSheetAsDraft()
  {
    if (this.state.savingBalanceSheetDraft || this.state.approvingBalanceSheetDraft || this.state.cancellingBalanceSheetDraft)
    {
      return;
    }
    
    this.setState({
      savingBalanceSheetDraft: true
    })
  
    BalanceSheetStore
      .createNewBalanceSheet(this.state.balanceSheetDate)
      .then(() =>
      {
        this.setState({
          savingBalanceSheetDraft: false
        })
  
        ToastMessageStore.addMessage(ToastMessage.create('success', 'Draft saved'));
      });
  }
}
