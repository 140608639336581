
import { Objects } from "phusion/src/Core/Objects/Objects";
import { Address } from "../../Member/Entity/Address";

export class User
{
  public id: number;
  public firstNames: string;
  public lastName: string;
  public preferredName: string;
  public emailAddress: string;
  public dateOfBirth: string;
  public homeContactNumber: string;
  public mobileContactNumber: string;
  public created: string;
  public updated: string;
  
  public address: Address;

  public static create(data: Object = null): User
  {
    if (!data)
    {
      return new this();
    }
  
    const mutators = {
      address: (address: any) => {
        return Address.create(address);
      }
    };
  
    return Objects.hydrate(new User(), data, mutators) as User;
  }

  public serialize(): Object
  {
    let mutators = {
      // dateOfBirth: (dateOfBirth: Moment.Moment) => dateOfBirth.valueOf(),
      // created: (created: Moment.Moment) => created.valueOf(),
      // updated: (updated: Moment.Moment) => updated.valueOf()
    };

    return Objects.hydrate({}, this, mutators);
  }

  public static getPreferredFirstName(user: User)
  {
    if (!user)
    {
      return '';
    }

    let firstName = user.firstNames.split(' ')[0];
    return user.preferredName ? user.preferredName : firstName;
  }
}
