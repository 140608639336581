
import * as React from 'react';
import { Header } from "appex-theme/src/Form/Input/Header/Header";
import { Numbers } from "appex-theme/src/Utility/Numbers";
import {AbstractComponent} from "../../../../component/AbstractComponent";
import {PageBar} from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import {Breadcrumbs} from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import {Form} from "appex-theme/src/Form/Form/Form";
import {TextField} from "appex-theme/src/Form/Input/Text/TextField";
import {Validator} from "appex-theme/src/Form/Form/Validator/Validator";
import {Button} from "appex-theme/src/Core/Button/Button";
import {Panel} from "appex-theme/src/Core/Panel/Panel";
import {Account} from "../../../../data/Account/Entity/Account";
import {ToastMessageStore} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import {DataStoreError} from "ts-redux/src/DataStore/DataStoreError";
import {ToastMessage} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";
import {Objects} from "phusion/src/Core/Objects/Objects";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome} from '@fortawesome/pro-light-svg-icons/faHome'
import './scss/CreateUpdateAccountPage.scss';
import {AccountStore} from "../../../../data/Account/AccountStore";
import {SelectField} from "appex-theme/src/Form/Input/Select/SelectField";
import {faCreditCard} from "@fortawesome/pro-solid-svg-icons/faCreditCard";
import {Request} from "appex-theme/src/Utility/Request";
import { PermissionsStore } from "../../../../data/Permissions/PermissionsStore";

export interface CreateUpdateAccountProps {}

export interface CreateUpdateAccountState {
  dataUpdating: boolean,
  allAccounts: Array<Account>,
  account: Account,
  isUpdatePage: boolean,
  formSubmitting: boolean,
  accountId: number
}

export class CreateUpdateAccountPage extends AbstractComponent<CreateUpdateAccountProps, CreateUpdateAccountState>
{
  public constructor(props: CreateUpdateAccountProps)
  {
    super(props);

    let account = null;

    let accountId = props['params'].accountId;

    if (accountId)
    {
      account = AccountStore.getByIdAndActiveOrganisation(accountId);
    }

    this.state = {
      dataUpdating: false,
      allAccounts: AccountStore.getAllAccountsByActiveOrganisation(),
      account: account,
      isUpdatePage: (accountId),
      formSubmitting: false,
      accountId: accountId
    }

  }

  public componentDidMount()
  {
    // Redirect if you don't have financial:read permissions
    if (!PermissionsStore.hasPermission('financial:write'))
    {
      ToastMessageStore.addMessage(ToastMessage.create('error', "Sorry, you don't have access to view this page"));
      return Request.goBack(this);
    }
    
    if (!this.state.isUpdatePage)
    {
      return;
    }

    this.unsubscribers['account'] = AccountStore.subscribeToAccountById(
      this.state.accountId,
      (account) => {
        this.setState({
          account: account
        })
      },
      this.state.account
    );
    
    this.unsubscribers['all_accounts'] = AccountStore.subscribeToActiveOrgAccounts(
      (accounts) => {
        this.setState({
          allAccounts: accounts
        })
      },
      this.state.allAccounts
    );

    AccountStore.syncAccount(this.state.accountId);
  }

  public render()
  {
    let breadcrumbs = this.getBreadCrumbs();

    let submitButtonModifiers: Array<any> = [
      'primary',
      this.state.dataUpdating ? 'loading' : ''
    ];
    
    const formElements = this.getFormElements();
    
    formElements.push(
      <div className="cell" key={'submit'}>
        <div className="mt-4 mb-4 text--align-right">
          <Button type={'submit'} modifiers={submitButtonModifiers}>
            { this.state.isUpdatePage ? 'Update Account' : 'Create Account' }
          </Button>
        </div>
      </div>
    )

    return <div className={'CreateUpdateAccountPage'}>

      <div className="grid-container">
        <PageBar
          title={this.state.isUpdatePage && this.state.account ? 'Update ' + this.state.account.name : 'Create a New Account'}
          rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}
        />
        <Panel>
          <Form
            name={'createAccount'}
            elements={formElements}
            onFormSubmit={this.onFormSubmit.bind(this)}
            onFormCapture={this.onFormSubmit.bind(this)}
          />
        </Panel>

      </div>

    </div>
  }

  protected getFormElements()
  {
    let account = this.state.account;
    const accountGroups = this.state.allAccounts.filter((account: Account) => {
      
      if (account.category !== 'liability')
      {
        return false;
      }
      
      const childAccounts = this.state.allAccounts.filter((childAccount: Account) => {
        return childAccount.accountGroupId == account.id;
      })
      
      return Boolean(childAccounts.length);
    })

    return [
      <div className="cell" key={'account_details'}>
        <Header
          text={'Account Details'}
          icon={faCreditCard}
        />
      </div>,
      // Name
      TextField
        .create(
          'name',
          'Account Name',
          [ Validator.required() ],
          account ? account.name : null
        )
        .setGridClass('cell medium-6'),
      // Brought Forward Balance
      TextField
        .create(
          'broughtForwardBalance',
          'Brought Forward Balance',
          [
            Validator.required(),
            Validator.decimal()
          ],
          account ? account.broughtForwardBalance : null
        )
        .setGridClass('cell medium-6')
        .setFormatter(
          (inputValue: string) => {
            if (inputValue === '') return inputValue;
            return Numbers.toDecimal(inputValue);
          }
        ),
      // Category (asset, liability, revenue, expense, sub_account)
      SelectField
        .create(
          'category',
          'Category',
          [
            {
              displayValue: 'Asset',
              value: 'asset',
            },
            {
              displayValue: 'Liability',
              value: 'liability',
            },
            {
              displayValue: 'Revenue',
              value: 'revenue',
            },
            {
              displayValue: 'Expense',
              value: 'expense',
            },
          ],
          (item: { displayValue: string, value: string }) =>
          {
            return item.value;
          },
          (item: { displayValue: string, value: string }) =>
          {
            return item.displayValue;
          },
          [Validator.required()],
          account ? account.category : null,
          null,
          null
        )
        .setGridClass('cell medium-6'),
      // Account Group (dynamically create and add to UI)

      // Type (bank, cash, cheques_held, paying_in)
      SelectField
        .create(
          'type',
          'Type',
          [
            {
              displayValue: 'Bank Account',
              value: 'bank',
            },
            {
              displayValue: 'Cash Account',
              value: 'cash',
            },
            {
              displayValue: 'General Account',
              value: 'general',
            },
          ],
          (item: { displayValue: string, value: string }) =>
          {
            return item.value;
          },
          (item: { displayValue: string, value: string }) =>
          {
            return item.displayValue;
          },
          [Validator.required()],
          account ? account.type : null,
          null,
          null,
        )
        .setGridClass('cell medium-6'),
      // Account group
      SelectField
        .create(
          'accountGroupId',
          'Account Group',
          accountGroups,
          (accountGroup: Account) => {
            return accountGroup.id;
          },
          (accountGroup: Account) => {
            return accountGroup.name
          },
          [],
          account ? account.accountGroupId : null
        )
        .setGridClass('cell medium-6')
    ]
  }

  protected getBreadCrumbs()
  {
    if (
      this.state.isUpdatePage
      && this.state.account
    )
    {
      return [
        {
          key: 'home',
          title: <span><FontAwesomeIcon icon={faHome}/> Home</span>,
          href: '/'
        },
        {
          key: 'accounts',
          title: 'Accounts',
          href: '/members'
        },
        {
          key: 'update_account',
          title: 'Update ' + this.state.account.name
        },
      ];
    }

    return [
      {
        key: 'home',
        title: <span><FontAwesomeIcon icon={faHome}/> Home</span>,
        href: '/'
      },
      {
        key: 'accounts',
        title: 'Accounts',
        href: '/members'
      },
      {
        key: 'create_accounts',
        title: 'New Account'
      },
    ];
  }

  protected onFormSubmit(formState)
  {
    if (this.state.formSubmitting)
    {
      return;
    }

    if (formState.valid)
    {
      if (this.state.account && this.state.account.id)
      {
        let newAccount: any = Objects.hydrate(
          Objects.clone(this.state.account),
          formState.value
        );

        this.setState({
          dataUpdating: true
        });

        return this.updateAccount(newAccount);
      }

      let newAccount = Account.create(formState.value);

      this.setState({
        dataUpdating: true
      });

      return this.createAccount(newAccount);
    }
  }

  protected updateAccount(account: Account)
  {
    // Send to redux
    let promise = AccountStore.updateAccount(account);

    promise.then((account: Account) => {
      this.setState({
        dataUpdating: false
      });

      ToastMessageStore.addMessage(ToastMessage.create('success', 'Account updated'));
      Request.redirect('/accounts', this);
    });

    promise.catch((error: DataStoreError) => {
      this.setState({
        dataUpdating: false
      });
    })
  }

  protected createAccount(account: Account)
  {
    this.setState({
      formSubmitting: true
    });
    // Send to redux
    let promise = AccountStore.createNewAccount(account);

    promise.then((account: Account) => {
      this.setState({
        dataUpdating: false,
        formSubmitting: false,
      });

      ToastMessageStore.addMessage(ToastMessage.create('success', 'Account created'));
      Request.redirect('/accounts', this);
    });

    promise.catch((error: DataStoreError) => {
      this.setState({
        dataUpdating: false,
        formSubmitting: false
      });
    })
  }

  protected onFormCapture(formValue)
  {

  }
}
