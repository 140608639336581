
import * as React from 'react';
import { Header } from "appex-theme/src/Form/Input/Header/Header";
import {AbstractComponent} from "../../../../component/AbstractComponent";
import {PageBar} from "appex-theme/src/Layout/Dashboard/PageBar/PageBar";
import {Breadcrumbs} from "appex-theme/src/Layout/Dashboard/Breadcrumbs/Breadcrumbs";
import {Form} from "appex-theme/src/Form/Form/Form";
import {TextField} from "appex-theme/src/Form/Input/Text/TextField";
import {Validator} from "appex-theme/src/Form/Form/Validator/Validator";
import {Button} from "appex-theme/src/Core/Button/Button";
import {Panel} from "appex-theme/src/Core/Panel/Panel";
import {Member} from "../../../../data/Member/Entity/Member";
import {MemberStore} from "../../../../data/Member/MemberStore";
import {ToastMessageStore} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/ToastMessageStore";
import {DataStoreError} from "ts-redux/src/DataStore/DataStoreError";
import {ToastMessage} from "appex-theme/src/Layout/Dashboard/Notification/ToastMessage/data/Entity/ToastMessage";
import './scss/CreateUpdateRolePage.scss';
import {Objects} from "phusion/src/Core/Objects/Objects";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SelectField} from "appex-theme/src/Form/Input/Select/SelectField";
import { PermissionsStore } from "../../../../data/Permissions/PermissionsStore";
import {RoleStore} from "../../../../data/Role/RoleStore";
import {Role} from "../../../../data/Role/Entity/Role";
import {faHome} from "@fortawesome/pro-light-svg-icons/faHome";
import {faKey} from "@fortawesome/pro-solid-svg-icons/faKey";
import {faUserLock} from "@fortawesome/pro-solid-svg-icons/faUserLock";
import {faUserTag} from "@fortawesome/pro-solid-svg-icons/faUserTag";
import {faUserLock as duoFaUserLock} from "@fortawesome/pro-duotone-svg-icons/faUserLock";
import {Request} from 'appex-theme/src/Utility/Request';

export interface CreateRoleProps {}

export interface CreateRoleState {
  dataUpdating: boolean,
  role: Role,
  isUpdatePage: boolean,
  formSubmitting: boolean,
  roleId: number,
  members: Array<Member>
}

export class CreateUpdateRolePage extends AbstractComponent<CreateRoleProps, CreateRoleState>
{
  public constructor(props: CreateRoleProps)
  {
    super(props);

    let role = null;

    let roleId = parseInt(props['params'].roleId);

    if (roleId)
    {
      role = RoleStore.getByIdAndActiveOrganisation(roleId);
    }

    let members = MemberStore.getAllMembersByActiveOrganisation();

    this.state = {
      dataUpdating: false,
      role: role,
      isUpdatePage: Boolean(roleId),
      formSubmitting: false,
      roleId: roleId,
      members: members
    }

  }

  public componentDidMount()
  {
    // Redirect if you don't have permissions:read permissions
    if (!PermissionsStore.hasPermission('permissions:write'))
    {
      ToastMessageStore.addMessage(ToastMessage.create('error', "Sorry, you don't have access to view this page"));
      return Request.goBack(this);
    }
    
    this.unsubscribers['members'] = MemberStore.subscribeToActiveOrgMembers(
      (members: Array<Member>) => {
        this.setState({
          members: members
        })
      },
      this.state.members
    )

    // Sync Members
    MemberStore.syncActiveOrganisationMembers();

    if (!this.state.isUpdatePage)
    {
      return;
    }

    this.unsubscribers['role'] = RoleStore.subscribeToRoleById(
      this.state.roleId,
      (role: Role) => {
        this.setState({
          role: role
        })
      },
      this.state.role
    );

    // Sync role
    RoleStore.syncRole(this.state.roleId);
  }

  public componentWillUnmount()
  {
    if (this.unsubscribers)
    {
      for (let key in this.unsubscribers)
        if (this.unsubscribers.hasOwnProperty(key))
      {
        this.unsubscribers[key]();
      }
    }
  }

  public render()
  {
    let breadcrumbs = this.getBreadCrumbs();

    let submitButtonModifiers: Array<any> = [
      'primary',
      this.state.dataUpdating ? 'loading' : ''
    ];
    
    const formElements = this.getFormElements()
    
    formElements.push(
      <div className="cell" key={'submit'}>
        <div className="mt-4 mb-4"><Button type={'submit'} modifiers={submitButtonModifiers}>
          { this.state.isUpdatePage ? 'Update Role' : 'Create Role' }
        </Button></div>
      </div>
    )

    return <div className={'CreateUpdateRolePage'}>

      <div className="grid-container">
        <PageBar
          title={<div><FontAwesomeIcon icon={duoFaUserLock}/>{this.state.isUpdatePage ? 'Update User Role' : 'Create a New User Role'}</div>}
          rightContent={<Breadcrumbs breadcrumbs={breadcrumbs}/>}
        />

        <Panel>
          <Form
            name={'createRole'}
            elements={formElements}
            onFormSubmit={this.onFormSubmit.bind(this)}
            onFormCapture={this.onFormSubmit.bind(this)}
          />
        </Panel>

      </div>

    </div>
  }

  protected getFormElements()
  {
    let role = this.state.role;
    let permissions = role ? role.permissions : [];

    // Member permissions
    let memberPermissionsValue = 'none';
    if (permissions.indexOf('member:read') !== -1) memberPermissionsValue = 'member:read';
    if (permissions.indexOf('member:write') !== -1) memberPermissionsValue = 'member:write';

    // Event permissions
    let eventPermissionsValue = 'none';
    if (permissions.indexOf('event:read') !== -1) eventPermissionsValue = 'event:read';
    if (permissions.indexOf('event:write') !== -1) eventPermissionsValue = 'event:write';

    // Financial permissions
    let financialPermissionsValue = 'none';
    if (permissions.indexOf('financial:read') !== -1) financialPermissionsValue = 'financial:read';
    if (permissions.indexOf('financial:write') !== -1) financialPermissionsValue = 'financial:write';

    // Role permissions
    let rolePermissionsValue = 'none';
    if (permissions.indexOf('permissions:read') !== -1) rolePermissionsValue = 'permissions:read';
    if (permissions.indexOf('permissions:write') !== -1) rolePermissionsValue = 'permissions:write';

    // Membership permissions
    let membershipPermissionsValue = 'none';
    if (permissions.indexOf('membership:read') !== -1) membershipPermissionsValue = 'membership:read';
    if (permissions.indexOf('membership:write') !== -1) membershipPermissionsValue = 'membership:write';

    return [
      <div className="cell" key={'role_details'}>
        <Header
          text={'Role Details'}
          icon={faUserTag}
        />
      </div>,
      // Name
      TextField
        .create(
          'name',
          'Name',
          [ Validator.required() ],
          role ? role.name : null
        ),
      // Permissions
      <div className="cell" key={'assign_permissions'}>
        <Header
          text={'Assign Permissions'}
          additionalClasses={['mt-5']}
          icon={faKey}
        />
      </div>,
      SelectField
        .create(
          'member_permissions',
          'Member Permissions',
          [
            {
              value: 'none',
              displayValue: 'None',
            },
            {
              value: 'member:read',
              displayValue: 'Read Only',
            },
            {
              value: 'member:write',
              displayValue: 'Read & Write',
            }
          ],
          (item: { value: string, displayValue: string }) => {
            return item.value;
          },
          (item: { value: string, displayValue: string }) => {
            return item.displayValue;
          },
          [ Validator.required() ],
          memberPermissionsValue,
        )
        .setGridClass('cell medium-6'),
      SelectField
        .create(
          'event_permissions',
          'Event Permissions',
          [
            {
              value: 'none',
              displayValue: 'None',
            },
            {
              value: 'event:read',
              displayValue: 'Read Only',
            },
            {
              value: 'event:write',
              displayValue: 'Read & Write',
            }
          ],
          (item: { value: string, displayValue: string }) => {
            return item.value;
          },
          (item: { value: string, displayValue: string }) => {
            return item.displayValue;
          },
          [ Validator.required() ],
          eventPermissionsValue
        )
        .setGridClass('cell medium-6'),
      SelectField
        .create(
          'financial_permissions',
          'Financial Data Permissions',
          [
            {
              value: 'none',
              displayValue: 'None',
            },
            {
              value: 'financial:read',
              displayValue: 'Read Only',
            },
            {
              value: 'financial:write',
              displayValue: 'Read & Write',
            },
          ],
          (item: { value: string, displayValue: string }) => {
            return item.value;
          },
          (item: { value: string, displayValue: string }) => {
            return item.displayValue;
          },
          [ Validator.required() ],
          financialPermissionsValue
        )
        .setGridClass('cell medium-6'),
      SelectField
        .create(
          'role_permissions',
          'User Role Permissions',
          [
            {
              value: 'none',
              displayValue: 'None',
            },
            {
              value: 'permissions:read',
              displayValue: 'Read Only',
            },
            {
              value: 'permissions:write',
              displayValue: 'Read & Write',
            },
          ],
          (item: { value: string, displayValue: string }) => {
            return item.value;
          },
          (item: { value: string, displayValue: string }) => {
            return item.displayValue;
          },
          [ Validator.required() ],
          rolePermissionsValue
        )
        .setGridClass('cell medium-6'),
      SelectField
        .create(
          'membership_permissions',
          'Membership Permissions',
          [
            {
              value: 'none',
              displayValue: 'None',
            },
            {
              value: 'membership:read',
              displayValue: 'Read Only',
            },
            {
              value: 'membership:write',
              displayValue: 'Read & Write',
            },
          ],
          (item: { value: string, displayValue: string }) => {
            return item.value;
          },
          (item: { value: string, displayValue: string }) => {
            return item.displayValue;
          },
          [ Validator.required() ],
          membershipPermissionsValue
        )
        .setGridClass('cell medium-6'),
      // Assigned Users
      <div className="cell" key={'assign_members'}>
        <Header
          text={'Assign Members'}
          additionalClasses={[ 'mt-5' ]}
          icon={faUserLock}
        />
      </div>,
      SelectField.create(
        'members',
        'Members',
        this.state.members,
        (member: Member) => {
          return member.userOrgId;
        },
        (member: Member) => {
          return Member.getFullPreferredName(member);
        },
        [],
        role ? role.members : [],
        'multi'
      ),
    ]
  }

  protected getBreadCrumbs()
  {
    if (
      this.state.isUpdatePage
      && this.state.role
    )
    {
      return [
        {
          key: 'home',
          title: <span><FontAwesomeIcon icon={faHome}/> Home</span>,
          href: '/'
        },
        {
          key: 'role',
          title: 'Roles',
          href: '/roles'
        },
        {
          key: 'update_role',
          title: 'Update ' + this.state.role.name
        },
      ];
    }

    return [
      {
        key: 'home',
        title: <span><FontAwesomeIcon icon={faHome}/> Home</span>,
        href: '/'
      },
      {
        key: 'roles',
        title: 'Roles',
        href: '/roles'
      },
      {
        key: 'create_role',
        title: 'New Role'
      },
    ];
  }

  // TODO: Update all this logic
  protected onFormSubmit(formState)
  {
    if (this.state.formSubmitting)
    {
      return;
    }

    if (formState.valid)
    {
      // Build up list of all permissions
      let permissions = [];

      // Member permissions
      let memberPermissions = formState.value.member_permissions;
      if (memberPermissions !== 'none')
      {
        permissions.push('member:read');

        if (memberPermissions == 'member:write')
        {
          permissions.push('member:write');
        }
      }

      // Event permissions
      let eventPermissions = formState.value.event_permissions;
      if (eventPermissions !== 'none')
      {
        permissions.push('event:read');

        if (eventPermissions == 'event:write')
        {
          permissions.push('event:write');
        }
      }

      // Financial data permissions
      let financialPermissions = formState.value.financial_permissions;
      if (financialPermissions !== 'none')
      {
        permissions.push('financial:read');

        if (financialPermissions == 'financial:write')
        {
          permissions.push('financial:write');
        }
      }

      // Role permissions
      let rolePermissions = formState.value.role_permissions;
      if (rolePermissions !== 'none')
      {
        permissions.push('permissions:read');

        if (rolePermissions == 'permissions:write')
        {
          permissions.push('permissions:write');
        }
      }

      // Membership permissions
      let membershipPermissions = formState.value.membership_permissions;
      if (membershipPermissions !== 'none')
      {
        permissions.push('membership:read');

        if (membershipPermissions == 'membership:write')
        {
          permissions.push('membership:write');
        }
      }

      delete formState.value.member_permissions;
      delete formState.value.event_permissions;
      delete formState.value.financial_permissions;
      delete formState.value.role_permissions;
      formState.value.permissions = permissions;

      if (this.state.role && this.state.role.id)
      {
        let newRole: any = Objects.hydrate(
          Objects.clone(this.state.role),
          formState.value
        );

        this.setState({
          dataUpdating: true
        });

        return this.updateRole(newRole);
      }

      let newRole = Role.create(formState.value);

      this.setState({
        dataUpdating: true
      });

      return this.createRole(newRole);
    }
  }

  protected updateRole(role: Role)
  {
    // Send to redux
    let promise = RoleStore.updateRole(role);

    promise.then((role: Role) => {
      this.setState({
        dataUpdating: false,
      });

      ToastMessageStore.addMessage(ToastMessage.create('success', 'Role updated'));
      Request.redirect('/roles', this);
    });

    promise.catch((error: DataStoreError) => {
      this.setState({
        dataUpdating: false
      });
    })
  }

  protected createRole(role: Role)
  {
    this.setState({
      formSubmitting: true
    });
    // Send to redux
    let promise = RoleStore.createNewRole(role);

    promise.then((member: Role) => {
      this.setState({
        dataUpdating: false,
        formSubmitting: false,
      });

      ToastMessageStore.addMessage(ToastMessage.create('success', 'Role created'));
      Request.redirect('/roles', this);
    });

    promise.catch((error: DataStoreError) => {
      this.setState({
        dataUpdating: false,
        formSubmitting: false
      });
    })
  }

  protected onFormCapture(formValue)
  {

  }
}
